import appConfig from '../../config/appConfig';
import DEVICE_TYPES from '../../consts/device/deviceTypes';
import analyticsService from '../../services/analyticsService';
import appRouterService from '../../services/appRouterService';
import marketConfigService from '../../services/marketConfigService';
import {setConsumerRecentlyPairedDevices} from '../../state/ducks/consumer';
import {makeSelectConsumerRecentlyPairedDevices} from '../../state/selectors/consumer';
import {makeSelectDeviceActivationStatus} from '../../state/selectors/iotDevice';
import {dispatch, getState} from '../../state/store';
import {getPairingGuideContent} from './pairingGuideContent';

const getConnectionStepIndex = (deviceType) =>
    isP4(deviceType)
        ? getPairingGuideContent().steps.TITAN_CONNECTION_STEP_NUMBER
        : getPairingGuideContent().steps.COMMON_CONNECTION_STEP_NUMBER;

const getPairingStepIndex = (deviceType) =>
    isP4(deviceType)
        ? getPairingGuideContent().steps.TITAN_PAIRING_STEP_NUMBER
        : getPairingGuideContent().steps.COMMON_PAIRING_STEP_NUMBER;

const getPairingFinishedStepIndex = (deviceType) =>
    isP4(deviceType)
        ? getPairingGuideContent().steps.TITAN_PAIRING_FINISH_STEP_NUMBER
        : getPairingGuideContent().steps.COMMON_PAIRING_FINISH_STEP_NUMBER;

const isP4 = (deviceType) => deviceType === DEVICE_TYPES.DEV_TYPE_P4_M3_G2;

const makeFinishRedirect = (deviceType, forwardAction) => {
    const isDeviceActivated = makeSelectDeviceActivationStatus()(getState());
    const isDeviceSettingAvailable = appConfig.getIsDeviceSettingAvailable();

    if (isDeviceActivated) {
        if (isDeviceSettingAvailable) {
            const isDeviceTypeExistsInConsumerRecentlyPairedDevices = _isDeviceTypeExistsInConsumerRecentlyPairedDevices(
                deviceType
            );

            if (isDeviceTypeExistsInConsumerRecentlyPairedDevices) {
                appRouterService.forwardToDeviceSettingsPage();
            } else {
                dispatch(setConsumerRecentlyPairedDevices(deviceType));

                appRouterService.forwardToQuickStartGuidePage();
            }
        } else {
            forwardAction();
        }
    } else {
        const isIccJourneyScreensEnabled = marketConfigService.isIccJourneyScreensEnabled();

        analyticsService.pushActivationPreactivationEvent();

        if (isIccJourneyScreensEnabled) {
            appRouterService.forwardToDeviceRegistrationPage();
        } else {
            appRouterService.forwardToDeviceActivationPage();
        }
    }
};

const _isDeviceTypeExistsInConsumerRecentlyPairedDevices = (deviceType) => {
    const consumerRecentlyPairedDevices = makeSelectConsumerRecentlyPairedDevices()(getState());

    if (consumerRecentlyPairedDevices?.length) {
        return consumerRecentlyPairedDevices?.some((type) => type === deviceType);
    }
};

export default {
    getConnectionStepIndex,
    getPairingFinishedStepIndex,
    getPairingStepIndex,
    makeFinishRedirect,
};
