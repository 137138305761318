import React from 'react';

import Button from '../../../components/Button/Button';
import ButtonDark from '../../../components/Button/ButtonDark/ButtonDark';
import * as localizationKeys from '../../../consts/app/localizationKeys';
import * as routePaths from '../../../consts/route/routePaths';
import analyticsService from '../../../services/analyticsService';
import appRouterService from '../../../services/appRouterService';
import {getLocalizedStrings} from '../../../services/localization/localizationService';
import {setNotificationInfo} from '../../../state/ducks/global';
import {dispatch} from '../../../state/store';
import elementsTesIds from '../../../test/consts/elementsTesIds';
import styles from '../MyDevicesPage.module.scss';

const DevicesListButton = ({isIotProduct, isActivationNeeded, isDeviceReady, type, isTabletLayout}) => {
    const localizedStrings = getLocalizedStrings();

    const onPairDeviceButtonClick = () => {
        if (type) {
            analyticsService.pushPrePairingPairRegisteredDeviceEvent();
            appRouterService.forwardToPairingGuidePage(type);
        } else {
            appRouterService.forwardToSelectDevice();
        }
    };

    const onDisableClick = () => {
        if (!isDeviceReady) {
            dispatch(setNotificationInfo(localizedStrings[localizationKeys.DEVICE_SETTINGS_CONNECT_NOTIFICATION_TEXT]));
        }
    };

    if (isIotProduct) {
        return isActivationNeeded ? (
            <ButtonDark
                className={`${styles.SettingsButton} ${!isDeviceReady && styles.SettingsButtonUnavailable}`}
                disabled={!isDeviceReady}
                onDisableClick={onDisableClick}
                showArrow={!isTabletLayout}
                testId={elementsTesIds.DEVICE_LIST_BTN_ACTIVATE}
                to={isDeviceReady ? routePaths.REGISTRATION : ''}
            >
                {localizedStrings[localizationKeys.YAP_ACTIVATION_DEVICES_AVAILABLE_ACTIVATE_BUTTON_TEXT]}
            </ButtonDark>
        ) : (
            <Button
                className={styles.SettingsButton}
                showArrow={!isTabletLayout}
                testId={elementsTesIds.DEVICE_LIST_BTN_SETTINGS}
                to={routePaths.DEVICE_SETTINGS}
            >
                {localizedStrings[localizationKeys.DEVICE_LIST_DEVICES_AVAILABLE_DEVICE_SETTINGS_TEXT]}
            </Button>
        );
    } else {
        return (
            <ButtonDark showArrow={!isTabletLayout} onClick={onPairDeviceButtonClick} className={styles.PairingButton}>
                {localizedStrings[localizationKeys.DEVICE_LIST_DEVICES_AVAILABLE_PAIR_BUTTON_TEXT]}
            </ButtonDark>
        );
    }
};

export default DevicesListButton;
