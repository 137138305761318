import {hideAllLoaders} from '../state/ducks/global';
import {clearIotDevice} from '../state/ducks/iotDevice/actions';
import {setMessage} from '../state/ducks/iotDevice/actions';
import {clearMwDeviceInternalId} from '../state/ducks/mwDevice';
import {setYapActivationInProgress, setYapSyncInProgress} from '../state/ducks/yapEncrypted';
import {dispatch} from '../state/store';
import appRouterService from './appRouterService';
import iqosBleService from './ble/iqosBleService';
import cmClientService from './communicationLayer/cmClientService';
import log from './logger/log';
import marketConfigService from './marketConfigService';
import iotService from './scpCloud/iotService';
import uamWebSocketService from './uam/uamWebSocketService';

const onAvailabilityChanged = (e) => {
    const isEnabled = e.target.value;

    log.info(`iqosBleService: onAvailabilityChanged, isEnabled: ${isEnabled}`);

    if (!isEnabled) {
        appRouterService.forwardToMyDevicesPage();
        //IA: exactly order that is needed
        //    first forward
        //    than clearDevice
        disconnectAndClearState();
        dispatch(hideAllLoaders());
    }
};

const addBleAvailabilityListener = () => {
    if (navigator.bluetooth.removeEventListener && navigator.bluetooth.addEventListener) {
        navigator.bluetooth.removeEventListener('availabilitychanged', onAvailabilityChanged);
        navigator.bluetooth.addEventListener('availabilitychanged', onAvailabilityChanged);
    }
};

const disconnectAndClearState = () => {
    iqosBleService.disconnect();
    iotService.disconnectIoT();
    disconnectAndClearUamState();
    dispatch(clearMwDeviceInternalId());
    dispatch(clearIotDevice());
    dispatch(setMessage(null));
};

const disconnectAndClearUamState = () => {
    uamWebSocketService.disconnectUamWebSocket();
    cmClientService.disconnectCmClient();
    dispatch(setYapActivationInProgress(false));
    dispatch(setYapSyncInProgress(false));
};

const disconnectAndForwardToMyDevicesPage = (isDeviceDisconnected) => {
    const isIccJourneyScreensEnabled = marketConfigService.isIccJourneyScreensEnabled();

    if (!isDeviceDisconnected) {
        disconnectAndClearState();
    }

    if (isIccJourneyScreensEnabled) {
        appRouterService.forwardToMyDevicesPage();
    } else {
        appRouterService.forwardToNoDevicesPage();
    }
};

const onPairingError = () => disconnectAndClearState();

export default {
    addBleAvailabilityListener,
    disconnectAndClearState,
    disconnectAndForwardToMyDevicesPage,
    onPairingError,
};
