import helpers from '../../utils/helpers';
import ScpCharacteristicClient from './scpCharacteristicClient';

const writeFrameAndPublishResponse = ({frames, onSuccess, onError, processImmediately = true}) => {
    const scpCharacteristicClient = new ScpCharacteristicClient();

    scpCharacteristicClient.addFramesToQueue({
        frames,
        processImmediately,
        onSuccess: (responses) => {
            helpers.runFunction(onSuccess, responses);
        },
        onError: onError,
    });
};

const createNew = () => {
    new ScpCharacteristicClient(true);
};

const processQueue = () => {
    const scpCharacteristicClient = new ScpCharacteristicClient();

    scpCharacteristicClient.processQueue();
};

const disable = () => {
    const scpCharacteristicClient = new ScpCharacteristicClient();

    scpCharacteristicClient.disable();
};

export default {
    createNew,
    disable,
    processQueue,
    writeFrameAndPublishResponse,
};
