import React, {useState} from 'react';
import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';

import BackButton from '../../components/BackButton/BackButton';
import Container from '../../components/Layout/Container/Container';
import * as localizationKeys from '../../consts/app/localizationKeys';
import {FW_SBL_FOR_BLE_ERROR} from '../../consts/iot/iotMessageErrorCodes';
import useFWUInfoLoaded from '../../hooks/effects/fwu/useFWUInfoLoaded';
import useHttpRegistrationErrorHandler from '../../hooks/effects/httpErrorsHandlers/useHttpRegistrationErrorHandler';
import useDidUpdate from '../../hooks/effects/useDidUpdate';
import useErrorHandler from '../../hooks/effects/useErrorHandler';
import useHideLoader from '../../hooks/effects/useHideLoader';
import analyticsService from '../../services/analyticsService';
import appRouterService from '../../services/appRouterService';
import getDeviceMessageRequestService from '../../services/deviceMessageRequestService';
import {getLocalizedStrings} from '../../services/localization/localizationService';
import productService from '../../services/productService';
import {hideLoader, setNotificationFailed, setNotificationInfo, showLoader} from '../../state/ducks/global';
import {makeSelectIotDeviceMergedWithIccProduct} from '../../state/selectors/consumer';
import {makeSelectIotMessage, makeSelectIsDeviceReady} from '../../state/selectors/iotDevice';
import {makeMwDeviceFirmwareData} from '../../state/selectors/mwDevice';
import RegistrationFailedPopup from '../DeviceRegistrationPage/components/RegistrationFailedPopup';
import LogoutButton from '../LogoutButton/LogoutButton';
import styles from './AboutDevicePage.module.scss';
import AboutDeviceHeader from './components/AboutDeviceHeader/AboutDeviceHeader';
import DeviceAndHolderInfo from './components/DeviceAndHolderInfo/DeviceAndHolderInfo';
import UpdateFWPopup from './components/UpdateFWPopup/UpdateFWPopup';

const mapStateToProps = createStructuredSelector({
    firmwareData: makeMwDeviceFirmwareData(),
    iotMessage: makeSelectIotMessage(),
    iotProduct: makeSelectIotDeviceMergedWithIccProduct(),
    isDeviceReady: makeSelectIsDeviceReady(),
});

const MIN_BATTERY_CHARGE_TO_FWU_START = 25;

const AboutDevicePage = (props) => {
    const {dispatch, iotProduct, isDeviceReady, firmwareData} = props;
    const {device, holder} = iotProduct || {};
    const {battery_charge, isDeviceActivated} = device || {};
    const [isHolderUpdate, setIsHolderUpdate] = useState(false);
    const [isVisibleUpdateFWPopup, setIsVisibleUpdateFWPopup] = useState(false);
    const [isUpdateFWUpdateError, setIsUpdateFWUpdateError] = useState(false);
    const localizedStrings = getLocalizedStrings();
    const canFWUStart = battery_charge >= MIN_BATTERY_CHARGE_TO_FWU_START;
    const isLogOutBtnVisible = isDeviceReady || isDeviceActivated;
    const [isVisibleRegistrationFailedPopup, setIsVisibleRegistrationFailedPopup] = useState(false);
    const [httpErrorType, setHttpErrorType] = useState(null);

    const isFWUInfoLoaded = useFWUInfoLoaded(isHolderUpdate ? holder : device);

    const onCancelBtnClick = () => {
        setIsVisibleUpdateFWPopup(!isVisibleUpdateFWPopup);
        setIsHolderUpdate(false);
    };

    const onCheckUpdateFWBtnClick = (isHolder) => {
        if (isDeviceReady) {
            dispatch(showLoader());

            getDeviceMessageRequestService().publishCheckFirmwareMessage(isHolder);
            setIsHolderUpdate(isHolder);
        }
        productService.fetchProductsStatusIfNeeded();
    };

    const onStartUpdateBtnClick = async () => {
        if (canFWUStart) {
            analyticsService.pushUsageSettingsFwUpdateEvent();
            setIsVisibleUpdateFWPopup(false);
            getDeviceMessageRequestService().publishStartUpdateFirmwareMessage(isHolderUpdate);
            appRouterService.forwardToUpdateFWPage();
        } else {
            dispatch(
                setNotificationFailed(
                    localizedStrings[localizationKeys.FIRMWARE_UPDATE_DEVICE_BATTERY_LOW_NOTIFICATION_TEXT]
                )
            );
        }
    };

    const onDisableClick = () => {
        dispatch(setNotificationInfo(localizedStrings[localizationKeys.DEVICE_SETTINGS_CONNECT_NOTIFICATION_TEXT]));
    };

    useErrorHandler({
        iotErrorCodes: FW_SBL_FOR_BLE_ERROR,
        handler: () => {
            setIsUpdateFWUpdateError(true);
            dispatch(hideLoader());
            setIsVisibleUpdateFWPopup(true);
        },
    });

    useHttpRegistrationErrorHandler({
        handler: (errorType) => {
            setHttpErrorType(errorType);
            setIsVisibleRegistrationFailedPopup(true);
        },
    });

    useDidUpdate(() => {
        setIsVisibleUpdateFWPopup(false);

        if (isFWUInfoLoaded && isDeviceReady) {
            setIsVisibleUpdateFWPopup(true);
        }
    }, [isDeviceReady, isFWUInfoLoaded]);

    useHideLoader(!isDeviceReady);

    const onCloseRegistrationFailedPopup = () => {
        setIsVisibleRegistrationFailedPopup(false);
    };

    return (
        <>
            <div className={styles.Page}>
                <Container className={styles.HeaderContainer} isFloatHeight>
                    <BackButton onClick={appRouterService.forwardToDeviceSettingsPage} />
                    {isLogOutBtnVisible && <LogoutButton />}
                </Container>
                <Container className={styles.CommonContainer}>
                    <AboutDeviceHeader device={device} holder={holder} />
                    <DeviceAndHolderInfo
                        iotDevice={iotProduct}
                        isDeviceReady={isDeviceReady}
                        onCheckUpdateFWBtnClick={onCheckUpdateFWBtnClick}
                        onDisableClick={onDisableClick}
                    />
                </Container>
            </div>
            {isVisibleUpdateFWPopup && isDeviceReady && (
                <UpdateFWPopup
                    isUpdateFWUpdateError={isUpdateFWUpdateError}
                    isHolderUpdate={isHolderUpdate}
                    firmwareData={firmwareData}
                    onClose={onCancelBtnClick}
                    onStartUpdate={onStartUpdateBtnClick}
                />
            )}
            {isVisibleRegistrationFailedPopup && (
                <RegistrationFailedPopup httpErrorType={httpErrorType} onClose={onCloseRegistrationFailedPopup} />
            )}
        </>
    );
};

export default connect(mapStateToProps)(AboutDevicePage);
