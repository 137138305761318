import converter from '../../utils/converter';
import ExponentialBackOff from '../../utils/exponentialBackOff';
import helpers from '../../utils/helpers';
import GetPermittedBuetoothDeviceError from '../errors/getPermittedBuetoothDeviceError';
import log from '../logger/log';

let instance = null;

export default class BLEClient {
    constructor(options) {
        if (instance) {
            return instance;
        }

        this._options = options;
        this._isRequested = false; //device is requested directly after requestDevice
        this._isRequestDeviceCalled = false; //true if request device prompt is visible
        this._isReconnected = false; //device is reconnected directly after success reconnection
        this._isConnected = false; //device is connected after getPrimaryService
        this._isPaired = false; //device is paired after first successful characteristic

        instance = this;
    }

    isDeviceConnected = () => this._isConnected;

    isDevicePaired = () => this._isPaired;

    getPermittedBluetoothDevices = async (services, onConnect, onError) => {
        const GET_PERMITTED_DEVICE_TIMEOUT_MS = 10 * 1000;
        let getPermittedDeviceTimeout;
        let removeAdverismentReceivedListener = () => {};

        const clearGetPermittedDeviceTimeout = () => clearTimeout(getPermittedDeviceTimeout);

        try {
            let devices = await navigator.bluetooth.getDevices({
                acceptAllDevices: false,
                filters: [{services}],
            });

            if (devices.length) {
                log.debug(`BLEClient: getPermittedBluetoothDevices, there are ${devices.length} permitted device(s)`);

                removeAdverismentReceivedListener = () => {
                    for (const device of devices) {
                        device.onadvertisementreceived = null;
                    }
                };

                getPermittedDeviceTimeout = setTimeout(() => {
                    removeAdverismentReceivedListener();
                    onError(new GetPermittedBuetoothDeviceError());
                }, GET_PERMITTED_DEVICE_TIMEOUT_MS);

                for (const device of devices) {
                    const abortController = new AbortController();

                    await device.watchAdvertisements({signal: abortController.signal});

                    device.onadvertisementreceived = async (evt) => {
                        log.debug(
                            `BLEClient: getPermittedBluetoothDevices, advertisementreceived, device name: ${evt.target?.name}`
                        );

                        clearGetPermittedDeviceTimeout();
                        abortController.abort();
                        removeAdverismentReceivedListener();

                        await evt.device.gatt.connect();
                        onConnect(evt.device);
                    };
                }
            }

            return devices;
        } catch (e) {
            clearGetPermittedDeviceTimeout();

            log.info(`BLEClient: getPermittedBluetoothDevices error: ${e}`);
            return null;
        }
    };

    requestDevice = async (services, onConnect) => {
        log.info('BLEClient: requesting Bluetooth Device');

        this._isRequestDeviceCalled = true;

        const device = await navigator.bluetooth.requestDevice({
            acceptAllDevices: false,
            filters: [{services}],
        });

        this._isRequestDeviceCalled = false;

        onConnect(device);
    };

    getDevice = async ({isNewDevice, services, onConnect, onError}) => {
        let isRequestDevice = true;

        if (!isNewDevice) {
            const permittedDevices = await this.getPermittedBluetoothDevices(services, onConnect, onError);
            isRequestDevice = !permittedDevices?.length;
        }

        if (isRequestDevice) {
            await this.requestDevice(services, onConnect);
        }
    };

    connectDevice = async ({isNewDevice, services, onConnect, onError}) => {
        try {
            this._isDisconnectedByUser = false;

            await this.getDevice({
                isNewDevice,
                services,
                onConnect: async (device) => {
                    this._device = device;

                    if (this._isReconnected) {
                        //e.g. if current device is reconnected after it's switching on on AddNew device page
                        log.info(`BLEClient: device with id: ${device.id} is already connected`);

                        return false;
                        //TODO: IA add Exception
                    }

                    log.info(`BLEClient: device with id: ${device.id} is connected`);

                    this._isRequested = true;
                    this._options.onDeviceSelect();

                    device.addEventListener('gattserverdisconnected', this._onDisconnected);

                    device.addEventListener('gattserverforcedisconnected', this._onGattServerForceDisconnected);

                    log.info('BLEClient: connecting to GATT Server');

                    const service = await device.gatt.connect();

                    if (this._isRequestDeviceCalled) {
                        log.info(
                            `BLEClient: onConnect, _isRequestDeviceCalled: ${this._isRequestDeviceCalled}, it meens that during requestDevice another device is connected, so it will be rejected`
                        );
                        return;
                    }

                    this._service = service;

                    onConnect();
                },
                onError,
            });
        } catch (e) {
            log.debug(`BLEClient: requestDeviceService failed, error: ${e}`);

            this._isRequestDeviceCalled = false;
            this._isRequested = false;

            throw e;
        }
    };

    getPrimaryService = async (serviceUuid) => {
        try {
            log.debug('BLEClient: getting Primary Service');
            this._primaryService = await this._service.getPrimaryService(serviceUuid);

            this._isConnected = true;
        } catch (e) {
            log.debug(`BLEClient: getPrimaryService failed, error: ${e}`);

            throw e;
        }
    };

    getPrimaryServiceCharacteristic = async (characteristicUuid) => {
        log.debug('BLEClient: getting characteristics');
        return this._primaryService.getCharacteristic(characteristicUuid);
    };

    addCharacteristicListener = async (characteristic, handler) => {
        try {
            const {uuid} = characteristic;

            if (!this._managedListeners) {
                this._managedListeners = {};
            }

            this._removeEventListener(uuid);

            this._managedListeners[uuid] = {
                characteristic,
                handler: (event) => {
                    this._isPaired = true;
                    handler(event.target.value);
                },
            };

            // log.debug(`BLEClient: add characteristic event listener`);
            characteristic.addEventListener('characteristicvaluechanged', this._managedListeners[uuid].handler);

            // await helpers.timeout(500);
            characteristic.startNotifications().catch((e) => {
                log.info(`BLEClient: addCharacteristicListener error: ${e}`);
            });
        } catch (e) {
            log.info(`BLEClient: addCharacteristicListener error: ${e}`);
        }
    };

    writeValueToCharacteristic = async (characteristic, frame, throwError) => {
        if (!this.isDeviceConnected()) return;

        try {
            const frameDecoded = converter.hex2bin(frame);
            const value = frameDecoded.buffer;

            return await characteristic.writeValue(value);
        } catch (e) {
            log.debug(`BLEClient: frame: ${frame}, writeValueToCharacteristic error: ${e}`);

            if (throwError) {
                throw e;
            }
        }
    };

    readCharacteristic = async (characteristic, handler) => {
        if (!this.isDeviceConnected()) return;

        try {
            log.debug('BLEClient: try to read characteristic');
            const value = await characteristic.readValue();
            handler(value);
        } catch (e) {
            log.debug(`BLEClient: read characteristic error: ${e}`);
        }
    };

    removeCharacteristicListener = (characteristic) => {
        const {uuid} = characteristic;

        this._removeEventListener(uuid);
    };

    disconnect = () => {
        this._isDisconnectedByUser = true;
        this._disconnectByDevice(this._device);
    };

    reconnect = () => {
        const toTry = async () => {
            log.debug('BLEClient: try to reconnect device');

            return this._device?.gatt.connect();
        };
        const success = async ({device}) => {
            log.info(`BLEClient: device with id: ${device.id} is reconnected`);

            if (this._isRequested) {
                this._removeOnDisconnectedListener(device);
                this._disconnectByDevice(device);
            } else {
                this._isReconnected = true;

                const service = await device.gatt.connect();

                this._device = device;
                this._service = service;

                this._options.onReconnectSuccess();
            }
        };
        const fail = () => {
            log.debug('BLEClient: device reconnection failed');

            helpers.runFunction(this._options.onReconnectFail);
        };

        const exponentialBackOff = new ExponentialBackOff();

        exponentialBackOff.run(3, 2000, toTry, success, fail);
    };

    _onGattServerForceDisconnected = () => {
        log.info(`BLEClient: "gattserverforcedisconnected" is fired`);

        this._options.onForcedDisconnect();
        this._onDisconnected();
        // IA - call _onDisconnected directly, because onDisconnect
        // is not called after gattserverforcedisconnected event
    };

    _onDisconnected = () => {
        const {_isDisconnectedByUser} = this;

        log.info('BLEClient: device disconnected');

        this._isRequested = false;
        this._isReconnected = false;
        this._isConnected = false;
        this._options.onDisconnected(_isDisconnectedByUser);
        this._removeAllEventListeners();

        if (_isDisconnectedByUser) {
            this._isPaired = false;
            this._removeOnDisconnectedListener(this._device);
            this._device = null;
            // instance = null;
        } else if (this.isDevicePaired()) {
            this._isPaired = false;
            this.reconnect();
        }
    };

    _removeOnDisconnectedListener = (device) => {
        device.removeEventListener('gattserverdisconnected', this._onDisconnected);
        device.removeEventListener('gattserverforcedisconnected', this._onGattServerForceDisconnected);
    };

    _removeAllEventListeners = () => {
        if (this._managedListeners) {
            Object.keys(this._managedListeners).forEach(this._removeEventListener);
        }

        this._managedListeners = {};
    };

    _removeEventListener = (uuid) => {
        try {
            const listener = this._managedListeners[uuid];

            if (listener) {
                const {characteristic, handler} = listener;

                // log.debug(`BLEClient: remove characteristic event listener`);
                characteristic.removeEventListener('characteristicvaluechanged', handler);
                delete this._managedListeners[uuid];
            }
        } catch (e) {
            log.info(`BLEClient: removeEventListener error: ${e}`);
        }
    };

    _disconnectByDevice = (device) => {
        if (device) {
            log.info(`BLEClient: disconnect device with id: ${device.id}`);
            device.gatt.disconnect();
        }
    };
}
