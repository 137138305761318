import classnames from 'classnames';
import React, {useEffect, useState} from 'react';

import checkedImg1 from '../../../assets/images/legal-disclaime/checked-icon.png';
import checkedImg2 from '../../../assets/images/legal-disclaime/checked-icon@2x.png';
import checkedImg3 from '../../../assets/images/legal-disclaime/checked-icon@3x.png';
import BackButton from '../../components/BackButton/BackButton';
import ButtonDark from '../../components/Button/ButtonDark/ButtonDark';
import Image from '../../components/Image/Image';
import Container from '../../components/Layout/Container/Container';
import Link from '../../components/Link/Link';
import * as localizationKeys from '../../consts/app/localizationKeys';
import appRouterService from '../../services/appRouterService';
import {getLocalizedStrings} from '../../services/localization/localizationService';
import routerService from '../../services/routerService';
import elementsTesIds from '../../test/consts/elementsTesIds';
import legalDisclaimerService from './legalDesclaimerService';
import styles from './LegalDisclaimerPage.module.scss';

const LegalDisclaimerPage = () => {
    const localizedStrings = getLocalizedStrings();
    const checkedImg = [checkedImg1, checkedImg2, checkedImg3];
    const [conversionLink, setConversionLink] = useState(null);
    const legalTextClassName = classnames(styles.LegalText, 'ica--txt-regular');

    const getConversionPractices = async () => {
        const currentConversionLink = await legalDisclaimerService.fetchConversionPractices();
        setConversionLink(currentConversionLink);
    };

    useEffect(() => {
        getConversionPractices();
    }, []);

    const inLinkClick = () => {
        routerService.blankLinkOpen(conversionLink);
    };

    const onClose = () => {
        appRouterService.forwardToAgeGatePage();
    };

    const inContinueClick = () => {
        appRouterService.forwardToTipsAndTricksPage();
    };

    return (
        <div className={styles.Page}>
            <Container isFloatHeight>
                <BackButton onClick={onClose} testId={elementsTesIds.LEGAL_DISCLAIMER_BTN_BACKS} />
            </Container>
            <Container>
                <Image src={checkedImg} className={styles.CheckedImage} />
                <div className={styles.Title}>{localizedStrings[localizationKeys.LEGAL_DISCLAIMER_TITLE]}</div>
                <div className={legalTextClassName}>
                    {localizedStrings[localizationKeys.LEGAL_DISCLAIMER_SCREEN_DESCRIPTION_TEXT]}
                    {conversionLink && (
                        <Link onClick={inLinkClick} className={styles.LinkText} isExternalLink isTargetBlank>
                            {localizedStrings[localizationKeys.LEGAL_DISCLAIMER_LINK_TITLE]}
                        </Link>
                    )}
                </div>
                <ButtonDark showArrow onClick={inContinueClick} testId={elementsTesIds.LEGAL_DISCLAIMER_BTN_CONTINUE}>
                    {localizedStrings[localizationKeys.LEGAL_DISCLAIMER_SCREEN_CONTINUE_BUTTON_TEXT]}
                </ButtonDark>
            </Container>
        </div>
    );
};

export default LegalDisclaimerPage;
