import React from 'react';
import {connect} from 'react-redux';
import {Route} from 'react-router-dom';
import {createStructuredSelector} from 'reselect';

import appConfig from '../../config/appConfig';
import useScrollTop from '../../hooks/effects/useScrollTop';
import userDeviceService from '../../services/userDeviceService';
import {makeSelectIsTncPopupVisible} from '../../state/selectors/tnc';
import LoaderGlobal from '../LoaderGlobal/LoaderGlobal';
import Notifications from '../Notifications/Notifications';
import ProductRegistrationStatus from '../ProductRegistrationStatus/ProductRegistrationStatus';
import ConsumerSettingsChecker from '../ServiceContainers/ConsumerSettingsChecker/ConsumerSettingsChecker';
import WindowResizeChecker from '../ServiceContainers/WindowResizeChecker/WindowResizeChecker';
import TermsOfUsePopup from '../TNC/TermsOfUsePopup/TermsOfUsePopup';
import styles from './AppLayout.module.scss';
import NotCompatibleOSPopup from './components/NotCompatibleOSPopup';
import OfflineDetector from './components/OfflineDetector';
import Logs from './Logs/Logs';

const mapStateToProps = createStructuredSelector({
    isTncPopupVisible: makeSelectIsTncPopupVisible(),
});

const AppLayout = (props) => {
    const {component: Component, path, isTncPopupVisible} = props;
    const componentClassName = `${styles.ComponentWrapper} ${isTncPopupVisible ? styles.Hidden : ''}`;
    const isNotCompatibleVersion = userDeviceService.isIos_13_4_x();

    useScrollTop();

    return (
        <Route
            path={path}
            render={(matchProps) => (
                <div className={`${styles.App} ica`}>
                    <Notifications />
                    {isTncPopupVisible && <TermsOfUsePopup />}
                    <div className={componentClassName}>
                        <Component {...matchProps} />
                    </div>
                    <LoaderGlobal />
                    {isNotCompatibleVersion ? (
                        <NotCompatibleOSPopup />
                    ) : (
                        <>
                            <ProductRegistrationStatus />
                            <ConsumerSettingsChecker />
                        </>
                    )}
                    {(appConfig.getIsLogsVisible() || appConfig.getIsAppVersionVisible()) && <Logs />}
                    <WindowResizeChecker />
                    <OfflineDetector />
                </div>
            )}
        />
    );
};

export default connect(mapStateToProps)(AppLayout);
