import * as iotCITypes from '../../consts/iot/iotCITypes';
import * as iotMessageStatusTypes from '../../consts/iot/iotMessageStatusTypes';
import * as iotMessageTypes from '../../consts/iot/iotMessageTypes';
import {makeMwDeviceIsIdentified} from '../../state/selectors/mwDevice';
import {getState} from '../../state/store';
import IoT from '../aws/awsIot';
import log from '../logger/log';
import iotService from './iotService';

let messageQueue = [];

const publishInitializationEvent = () => {
    const message = {
        type: iotMessageTypes.CONNECTION,
        status: iotMessageStatusTypes.CONNECTED,
        body: {
            data: {ci_type: iotCITypes.CI_TYPE_BLE},
        },
    };

    publishMWMessage(message);
};

const publishDisconnectedEvent = () => {
    const message = {
        type: iotMessageTypes.CONNECTION,
        status: iotMessageStatusTypes.DISCONNECTED,
    };

    publishMWMessage(message);
};

const publishResponseEvent = (type, request_id, frames) => {
    const isIdentified = makeMwDeviceIsIdentified()(getState());
    const body = {frames};

    if (!isIdentified) {
        body.data = {ci_type: iotCITypes.CI_TYPE_BLE};
    }

    const message = {
        type,
        request_id,
        body,
    };

    publishMWMessage(message);
};

const publishFwuPackageLoadedEvent = (targets_holder = false) => {
    const message = {
        type: iotMessageTypes.FIRMWARE_UPDATE,
        status: iotMessageStatusTypes.PACKAGE_LOADED,
        body: {
            data: {
                targets_holder,
            },
        },
    };

    publishMWMessage(message);
};

const publishFwuStatusEvent = (progress, targets_holder = false) => {
    const message = {
        type: iotMessageTypes.FIRMWARE_UPDATE,
        status: iotMessageStatusTypes.IN_PROGRESS,
        body: {
            data: {
                progress,
                targets_holder,
            },
        },
    };

    publishMWMessage(message);
};

const publishFwuFinishEvent = (targets_holder = false) => {
    const message = {
        type: iotMessageTypes.FIRMWARE_UPDATE,
        status: iotMessageStatusTypes.FINISHED,
        body: {
            data: {
                targets_holder,
            },
        },
    };

    publishMWMessage(message);
};

const publishDeviceCharacteristicEvent = (characteristic_frame) => {
    const message = {
        type: iotMessageTypes.DEVICE_STATUS_CHARACTERISTIC,
        body: {
            data: {
                characteristic_frame,
            },
        },
    };

    publishMWMessage(message);
};

const publishBatteryCharacteristicEvent = (characteristic_frame) => {
    const message = {
        type: iotMessageTypes.BATTERY_INFORMATION_CHARACTERISTIC,
        body: {
            data: {
                characteristic_frame,
            },
        },
    };

    publishMWMessage(message);
};

const publishFwuErrorEvent = (errorCode, error_frame) => {
    publishErrorEvent({
        type: iotMessageTypes.FIRMWARE_UPDATE,
        errorCode,
        error_frame,
    });
};

const publishErrorEvent = ({type, request_id, frames, error_frame, errorCode}) => {
    const body = {};

    if (frames) body.frames = frames;
    if (errorCode) body.error = {code: errorCode};
    if (error_frame) body.data = {error_frame};

    const message = {
        type,
        status: iotMessageStatusTypes.ERROR,
        request_id,
        body,
    };

    publishMWMessage(message);
};

const publishMWMessage = (message) => {
    processMessageQueue(message);
};

const processMessageQueue = (message) => {
    if (message) {
        messageQueue.push(message);
    }

    const isClientInitialized = IoT.isIotClientInitialized();

    if (isClientInitialized && messageQueue.length) {
        messageQueue.forEach((message) => {
            iotService.publishMwMessage(message).catch((e) => {
                log.info(`iotService: publish MW message error: ${e}`);
            });
        });
        clearMessageQueue();
    }
};

const clearMessageQueue = () => (messageQueue = []);

export default {
    clearMessageQueue,
    processMessageQueue,
    publishDeviceCharacteristicEvent,
    publishDisconnectedEvent,
    publishErrorEvent,
    publishFwuErrorEvent,
    publishFwuFinishEvent,
    publishFwuPackageLoadedEvent,
    publishFwuStatusEvent,
    publishInitializationEvent,
    publishResponseEvent,
    publishBatteryCharacteristicEvent,
};
