import React from 'react';

import PopupRounded from '../../../../components/Popup/PopupRounded';
import DeviceModeHeader from './components/DeviceModeHeader/DeviceModeHeader';
import styles from './DeviceModePopup.module.scss';

const DeviceModePopup = (props) => {
    const {className, onClose, children} = props;
    const {imgUrls, imgAlt, imgClassName, title, subTitle, headerChildren, layout} = props;

    return (
        <PopupRounded
            className={`${styles.Popup} ${className ? className : ''}`}
            isClosingBySwipeEnabled
            onClose={onClose}
        >
            <DeviceModeHeader
                imgAlt={imgAlt}
                imgClassName={imgClassName}
                imgUrls={imgUrls}
                layout={layout}
                subTitle={subTitle}
                title={title}
            >
                {headerChildren}
            </DeviceModeHeader>
            {children}
        </PopupRounded>
    );
};

export default DeviceModePopup;
