import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';

import imgLockedLock1 from '../../../assets/images/locked-lock.png';
import imgLockedLock2 from '../../../assets/images/locked-lock@2x.png';
import imgLockedLock3 from '../../../assets/images/locked-lock@3x.png';
import imgUnlockedLock1 from '../../../assets/images/unlocked-lock.png';
import imgUnlockedLock2 from '../../../assets/images/unlocked-lock@2x.png';
import imgUnlockedLock3 from '../../../assets/images/unlocked-lock@3x.png';
import FloatButton from '../../components/Button/FloatButton/FloatButton';
import Image from '../../components/Image/Image';
import Container from '../../components/Layout/Container/Container';
import * as localizationKeys from '../../consts/app/localizationKeys';
import useDeviceLockUnlock from '../../hooks/effects/useDeviceLockUnlock';
import {getLocalizedStrings} from '../../services/localization/localizationService';
import {makeSelectIotMessage} from '../../state/selectors/iotDevice';
import DeviceLockPopups from './components/DeviceLockPopups/DeviceLockPopups';
import styles from './DeviceLockBtn.module.css';

const LOCK_IMAGE_SET = {
    UNLOCKED: [imgUnlockedLock1, imgUnlockedLock2, imgUnlockedLock3],
    LOCKED: [imgLockedLock1, imgLockedLock2, imgLockedLock3],
};

const mapStateToProps = createStructuredSelector({
    iotMessage: makeSelectIotMessage(),
});

const DeviceLockBtn = (props) => {
    const {style, isDeviceReady, onDisableClick, isDeviceLocked, iotMessage, lockTestId, unlockTestId} = props;
    const [isVisibleLockPopup, setIsVisibleLockPopup] = useState(false);
    // eslint-disable-next-line no-unused-vars
    const [isParentModeFinished, setDeviceLockUnlockData] = useDeviceLockUnlock(
        iotMessage,
        isDeviceReady,
        isDeviceLocked
    );

    useEffect(() => {
        if (isDeviceReady) {
            setIsVisibleLockPopup(false);
        }
    }, [isDeviceReady]);

    const onLockPopupChange = () => {
        setIsVisibleLockPopup(!isVisibleLockPopup);
    };

    const onLockProduct = (messageDescription) => {
        setDeviceLockUnlockData({
            isLock: true,
            messageDescription,
        });
    };

    const onUnlockProduct = (messageDescription) => {
        setDeviceLockUnlockData({
            isLock: false,
            messageDescription,
        });
    };

    const onButtonClick = () => {
        if (isDeviceReady) {
            onLockPopupChange();
        } else {
            onDisableClick();
        }
    };
    const localizedStrings = getLocalizedStrings();

    return (
        <div data-testid='DeviceLockBtn' className={styles.HeaderButtonsContainer} style={style}>
            <Container isFloatHeight>
                <FloatButton
                    className={`${styles.LockButton} ${!isDeviceReady && styles.LockButtonDisabled}`}
                    onClick={onButtonClick}
                    testId={isDeviceLocked ? lockTestId : unlockTestId}
                >
                    {isDeviceLocked
                        ? localizedStrings[localizationKeys.UNLOCK_BUTTON_TEXT]
                        : localizedStrings[localizationKeys.LOCK_BUTTON_TEXT]}
                    <div className={styles.LockIcon}>
                        {isDeviceLocked ? (
                            <Image src={LOCK_IMAGE_SET.UNLOCKED} />
                        ) : (
                            <Image src={LOCK_IMAGE_SET.LOCKED} />
                        )}
                    </div>
                </FloatButton>
            </Container>
            <DeviceLockPopups
                isDeviceLocked={isDeviceLocked}
                isVisibleLockPopup={isVisibleLockPopup && isDeviceReady}
                onLockPopupChange={onLockPopupChange}
                onLockProduct={onLockProduct}
                onUnlockProduct={onUnlockProduct}
            />
        </div>
    );
};

export default connect(mapStateToProps)(DeviceLockBtn);
