import classNames from 'classnames';
import React, {useState} from 'react';

import ButtonDark from '../../../components/Button/ButtonDark/ButtonDark';
import Input from '../../../components/Input/Input';
import Link from '../../../components/Link/Link';
import appConfig from '../../../config/appConfig';
import * as localizationKeys from '../../../consts/app/localizationKeys';
import {getLocalizedStrings} from '../../../services/localization/localizationService';
import elementsTesIds from '../../../test/consts/elementsTesIds';
import styles from '../LoginPage.module.scss';

const LoginForm = ({onLogin}) => {
    const [loginEmailRegex, loginPasswordRegex, loginRegisterUrl, loginForgotPasswordUrl] = [
        appConfig.getLoginEmailRegex(),
        appConfig.getLoginPasswordRegex(),
        appConfig.getLoginRegisterUrl(),
        appConfig.getLoginForgotPasswordUrl(),
    ];
    const localizedStrings = getLocalizedStrings();

    const [isValidCredentials, setIsValidCredentials] = useState({
        isUsernameValid: true,
        isPasswordValid: true,
    });
    const {isUsernameValid, isPasswordValid} = isValidCredentials || {};

    const [isCredentialsEntered, setIsCredentialsEntered] = useState({
        isUsernameEntered: true,
        isPasswordEntered: true,
    });
    const {isUsernameEntered, isPasswordEntered} = isCredentialsEntered || {};

    const [loginCredentials, setLoginCredentials] = useState({
        username: '',
        password: '',
    });
    const {username, password} = loginCredentials || {};

    const getClassNames = (isValid, isEntered) => {
        return classNames(styles.InputElement, {
            [styles.InputError]: !isValid || !isEntered,
        });
    };

    const inputUsernameClassName = getClassNames(isUsernameValid, isUsernameEntered);
    const inputPasswordClassName = getClassNames(isPasswordValid, isPasswordEntered);

    const setEmailValue = ({target}) => {
        setLoginCredentials({...loginCredentials, username: target.value});
    };

    const setPasswordValue = ({target}) => {
        setLoginCredentials({...loginCredentials, password: target.value});
    };

    const validateCredentials = () => {
        const isValidUsername = username.match(loginEmailRegex);
        const isValidPassword = password.match(loginPasswordRegex);
        const isUsernameExist = loginCredentials.username;
        const isPasswordExist = loginCredentials.password;

        setIsValidCredentials({isUsernameValid: isValidUsername, isPasswordValid: isValidPassword});

        setIsCredentialsEntered({isUsernameEntered: isUsernameExist, isPasswordEntered: isPasswordExist});

        return isValidUsername && isValidPassword && isUsernameExist && isPasswordExist;
    };

    const onLoginBtnClick = () => {
        const isCredentialsValid = validateCredentials();

        if (isCredentialsValid) {
            onLogin(loginCredentials);
        }
    };

    const LoginFieldWarning = ({isValid, isEntered, username}) => {
        return (
            <>
                {(!isValid || !isEntered) && (
                    <div className={styles.ErrorMessage}>
                        {!isEntered
                            ? localizedStrings[localizationKeys?.REQUIRED_FIELD_TEXT]
                            : localizedStrings[
                                  username
                                      ? localizationKeys?.NOT_VALID_USERNAME_TEXT
                                      : localizationKeys?.NOT_VALID_PASSWORD_TEXT
                              ]}
                    </div>
                )}
            </>
        );
    };

    return (
        <div className={styles.LoginForm}>
            <div className={styles.InputLabel}>{localizedStrings[localizationKeys?.LOGIN_TITLE_TEXT]}</div>
            <LoginInput
                className={inputUsernameClassName}
                onChange={setEmailValue}
                placeholder={localizedStrings[localizationKeys?.LOGIN_HINT_TEXT]}
                testId={elementsTesIds.LOGIN_INPUT_USERNAME}
                type='text'
            />
            <LoginFieldWarning username isValid={isUsernameValid} isEntered={isUsernameEntered} />
            <div className={styles.InputLabel}>{localizedStrings[localizationKeys?.PASSWORD_TITLE_TEXT]}</div>
            <LoginInput
                className={inputPasswordClassName}
                onChange={setPasswordValue}
                placeholder={localizedStrings[localizationKeys?.PASSWORD_HINT_TEXT]}
                testId={elementsTesIds.LOGIN_INPUT_PASSWORD}
                type='password'
            />
            <LoginFieldWarning isValid={isPasswordValid} isEntered={isPasswordEntered} />
            {loginForgotPasswordUrl && (
                <Link className={styles.ForgotLink} to={loginForgotPasswordUrl} isExternalLink isTargetBlank>
                    {localizedStrings[localizationKeys?.FORGOT_PASSWORD_BUTTON_TEXT]}
                </Link>
            )}
            <ButtonDark
                className={styles.LoginButton}
                onClick={onLoginBtnClick}
                showArrow
                testId={elementsTesIds.LOGIN_BTN_LOGIN}
            >
                {localizedStrings[localizationKeys?.LOGIN_BUTTON_TEXT]}
            </ButtonDark>
            {loginRegisterUrl && (
                <div className={styles.SecondLink}>
                    <span>{localizedStrings[localizationKeys?.NEW_TO_IQOS_TEXT]} </span>
                    <Link className={styles.RegisterLink} to={loginRegisterUrl} isExternalLink isTargetBlank>
                        {localizedStrings[localizationKeys?.REGISTER_BUTTON_TEXT]}
                    </Link>
                </div>
            )}
        </div>
    );
};

const LoginInput = (props) => <Input {...props} maxLength={50} minLength={3} />;

export default LoginForm;
