import * as bleUuidTypes from '../../consts/ble/bleUuidTypes';
import converter from '../../utils/converter';
import helpers from '../../utils/helpers';
import BLEClient from './bleClient';

let instance = null;

export default class IqosBLEClient {
    constructor(options) {
        if (instance) {
            return instance;
        }

        instance = this;

        this._options = options;

        const bleClientOptions = {
            ...options,
            onReconnectSuccess: async () => {
                await this._onConnect();
                options.onDeviceReconnectSuccess();
            },
        };

        this._bleClient = new BLEClient(bleClientOptions);

        return instance;
    }

    connect = async ({isNewDevice, onConnect, onError}) => {
        await this._bleClient.connectDevice({
            isNewDevice,
            services: [bleUuidTypes.RRP_SERVICE],
            onConnect: async () => {
                this._options.onFinishDeviceSelect();

                await this._onConnect();
                onConnect();
            },
            onError,
        });
    };

    addScpCharacteristicListener = (handler) => {
        return this._bleClient.addCharacteristicListener(this.scpCharacteristic, handler);
    };

    addDeviceStatusCharacteristicListener = (handler) => {
        return this._bleClient.addCharacteristicListener(this.deviceStatusCharacteristic, handler);
    };

    addBatteryInformationCharacteristicListener = (handler) => {
        return this._bleClient.addCharacteristicListener(this.batteryInformationCharacteristic, handler);
    };

    addFwuStatusCharacteristicListener = (handler) => {
        return this._bleClient.addCharacteristicListener(this.fwuStatusCharacteristic, handler);
    };

    removeFwuStatusCharacteristicListener = () => {
        this._bleClient.removeCharacteristicListener(this.fwuStatusCharacteristic);
    };

    writeValueToScpCharacteristic = (frame, throwError) => {
        return this._writeFrameToCharacteristic(this.scpCharacteristic, frame, throwError);
    };

    writeValueToFwuControlCharacteristic = (frame, throwError) => {
        return this._writeFrameToCharacteristic(this.fwuControlCharacteristic, frame, throwError);
    };

    writeValueToFwuDataCharacteristic = (frame, throwError) => {
        return this._writeFrameToCharacteristic(this.fwuDataCharacteristic, frame, throwError);
    };

    readFwuDataCharacteristic = (handler) => {
        return this._bleClient.readCharacteristic(this.fwuDataCharacteristic, handler);
    };

    isDeviceConnected = () => this._bleClient?.isDeviceConnected();

    isDevicePaired = () => this._bleClient?.isDevicePaired();

    disconnect = () => this._bleClient?.disconnect();

    reconnect = () => this._bleClient?.reconnect();

    _onConnect = async () => {
        const {_bleClient} = this;

        await helpers.timeout(200);
        await _bleClient.getPrimaryService(bleUuidTypes.RRP_SERVICE);

        await helpers.timeout(200);
        this.scpCharacteristic = await _bleClient.getPrimaryServiceCharacteristic(bleUuidTypes.SCP_CONTROL_POINT);
        this.deviceStatusCharacteristic = await _bleClient.getPrimaryServiceCharacteristic(
            bleUuidTypes.DEVICE_STATUS_CHAR
        );
        this.batteryInformationCharacteristic = await _bleClient.getPrimaryServiceCharacteristic(
            bleUuidTypes.DEVICE_BATTERY_CHAR
        );
        this.fwuDataCharacteristic = await _bleClient.getPrimaryServiceCharacteristic(bleUuidTypes.FW_UPGRADE_DATA);
        this.fwuControlCharacteristic = await _bleClient.getPrimaryServiceCharacteristic(
            bleUuidTypes.FW_UPGRADE_CONTROL
        );
        this.fwuStatusCharacteristic = await _bleClient.getPrimaryServiceCharacteristic(bleUuidTypes.FW_UPGRADE_STATUS);

        this._options.onDeviceConnect();
    };

    _writeFrameToCharacteristic = async (characteristic, frame, throwError) => {
        const chunks = this._splitFrame(frame);

        for (let j = 0; j < chunks.length; j++) {
            const chunk = chunks[j];

            await this._bleClient.writeValueToCharacteristic(characteristic, chunk, throwError);
        }
    };

    _splitFrame = (frame) => {
        const result = [];

        this._splitChunkRecursive(frame, result);

        return result;
    };

    _splitChunkRecursive = (frame, result) => {
        const CHUNK_COUNT_LENGTH = 2;
        const CHUNK_LENGTH = 40;
        const chunk = frame.substring(0, CHUNK_LENGTH);

        result.push(chunk);

        const chunkLengthHex = frame.substring(0, CHUNK_COUNT_LENGTH);
        const chunkLengthDec = converter.hex2dec(chunkLengthHex);

        if (chunkLengthDec > 0 && frame.length > CHUNK_LENGTH) {
            const frameRest = frame.substring(CHUNK_LENGTH);

            this._splitChunkRecursive(frameRest, result);
        }
    };
}
