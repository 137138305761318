import * as localizationKeys from '../../consts/app/localizationKeys';
import {setNotificationFailed} from '../../state/ducks/global';
import {dispatch} from '../../state/store';
import appRouterService from '../appRouterService';
import {getLocalizedStrings} from '../localization/localizationService';
import pairingService from '../pairingService';

const showGlobalError = () => {
    dispatch(setNotificationFailed(getLocalizedStrings()[localizationKeys.GLOBAL_ERROR_NOTIFICATION_TEXT]));
};

const showGlobalErrorWithAppReset = () => {
    pairingService.disconnectAndForwardToMyDevicesPage();
    showGlobalError();
};

const showGlobalErrorWithAppHardReset = () => {
    showGlobalError();
    pairingService.disconnectAndClearState();
    appRouterService.forwardToBrowserVerificationPage();
};

export default {
    showGlobalErrorWithAppReset,
    showGlobalErrorWithAppHardReset,
    showGlobalError,
};
