import appConfig from '../../config/appConfig';
import * as gamMethods from '../../consts/gam/gamMethods';
import {setDeviceActivationStatus} from '../../state/ducks/iotDevice/actions';
import {
    setYapActivationInProgress,
    setYapAssets,
    setYapLastCheckRequestDate,
    setYapLastRequestId,
} from '../../state/ducks/yapEncrypted';
import {
    makeSelectFirstAsset,
    makeSelectIsYapActivationInProgress,
    makeSelectYapLastCheckRequestDate,
    makeSelectYapLastRequestId,
} from '../../state/selectors/yapEncrypted';
import {dispatch, getState} from '../../state/store';
import dateTimeUtils from '../../utils/dateTimeUtils';
import helpers from '../../utils/helpers';
import cmClientService from '../communicationLayer/cmClientService';
import iccConsumerProducts from '../icc/iccConsumerProducts';
import log from '../logger/log';
import {gamAssetMapping} from '../mapping/gamMappings';
import gamClient from './gamClient';
import gamWebSocketService from './gamWebSocketService';

const getGamAssets = async () => {
    await gamClient.callPost(gamMethods.GET_ASSETS, null, setYapAssets, gamAssetMapping);
    const gamAsset = makeSelectFirstAsset()(getState());
    if (gamAsset?.materialId) {
        await iccConsumerProducts.getProductsAssets([gamAsset.materialId]);
    }
};

const connectGamDevice = (assetId) => gamClient.callGet(gamMethods.CONNECT_DEVICE, {args: assetId});

const activateDevice = async () => {
    await tryActivateDeactivateDevice(true);
};

const deactivateDevice = async () => {
    await tryActivateDeactivateDevice(false);
};

let activateDeactivateTimeout = null;
const tryActivateDeactivateDevice = async (isActivate) => {
    clearTimeout(activateDeactivateTimeout);

    const isYapActivationInProgress = makeSelectIsYapActivationInProgress()(getState());
    if (isYapActivationInProgress) return;

    dispatch(setYapActivationInProgress(true));

    try {
        await helpers.timeout(1000);
        const gamAsset = makeSelectFirstAsset()(getState());
        if (gamAsset) {
            try {
                await syncAndSubscribeOnGamDevice();
                return await activateDeactivateDevice(gamAsset, isActivate);
            } catch (e) {
                log.error(`gamClientService: IsActivate: ${isActivate} failed. error: ${e}`);
            }
        }

        await getGamAssets();
        dispatch(setYapActivationInProgress(false));
    } catch (e) {
        log.error(`gamClientService: IsActivate: ${isActivate} failed. error: ${e}`);
        dispatch(setYapActivationInProgress(false));
    }
};

const syncAndSubscribeOnGamDevice = async () => {
    const state = getState();
    let gamAsset = makeSelectFirstAsset()(state);
    const assetId = gamAsset?.assetId;
    if (!assetId) throw new Error('Asset is not initialized');

    await gamWebSocketService.initGamWebSocket(assetId);

    await cmClientService.initCmClient(assetId);
};

const activateDeactivateDevice = async (gamAsset, isActivate) => {
    const isConnected = await cmClientService.waitUntilConnected();
    if (isConnected) {
        if (isActivate) {
            log.info('Start activation process.');
        } else {
            log.info('Start deactivation process.');
        }

        const method = isActivate ? gamMethods.ACTIVATE_DEVICE : gamMethods.DEACTIVATE_DEVICE;
        const {requestId} =
            (await gamClient.callPost(method, gamAsset.assetId).catch(() => {
                log.error(`gamClientService: IsActivate: ${isActivate} failed. Action error`);
                dispatch(setYapActivationInProgress(false));
            })) || {};

        if (requestId) {
            dispatch(setYapLastRequestId(requestId));

            activateDeactivateTimeout = setTimeout(function () {
                dispatch(setYapActivationInProgress(false));
            }, appConfig.getGamTimeout() * 1000);
        } else {
            dispatch(setYapActivationInProgress(false));
        }
    } else {
        dispatch(setYapActivationInProgress(false));
    }
};

const checkRequestStatus = async () => {
    const state = getState();
    const isGamActivationInProgress = makeSelectIsYapActivationInProgress()(state);
    if (!isGamActivationInProgress) return;

    const gamAsset = makeSelectFirstAsset()(state);
    if (!gamAsset?.assetId) return;

    const requestId = makeSelectYapLastRequestId()(state);
    if (!requestId) return;

    const timestamp = dateTimeUtils.getTimeNowInMilliseconds();
    const lastCheckDate = makeSelectYapLastCheckRequestDate()(state) + appConfig.getGamCheckRequestInterval() * 1000;

    if (lastCheckDate < timestamp) {
        dispatch(setYapLastCheckRequestDate(timestamp));

        //mock data
        dispatch(setDeviceActivationStatus(true));

        dispatch(setYapActivationInProgress(false));
    }
};

export default {
    getGamAssets,
    connectGamDevice,
    activateDevice,
    deactivateDevice,
    checkRequestStatus,
};
