export default {
    data: {
        app: {
            appVersion: undefined,
            assetsPath: undefined,
            backendType: undefined,
            baseName: undefined,
            deviceListOrder: undefined,
            enableP1Support: undefined,
            enableP4Support: undefined,
            env: undefined,
            isAccessProtected: undefined,
            isDeviceDiagnosticEnabled: undefined,
            isDeviceSettingAvailable: undefined,
            isFWAvailableP1: undefined,
            isFWAvailableP4: undefined,
            isHAVMandatory: undefined,
            isRememberMeEnabled: undefined,
            isTestLoginEnabled: undefined,
            isUnregistrationAvailable: undefined,
            legalDisclaimePdfName: undefined,
            externalConfigPath: undefined,
            publicUrl: undefined,
            tipsAndTricksAllowedSteps: undefined,
            welcomePageImagePath: undefined,
            yapMode: undefined,
        },
        appLinks: {
            linkAndroidStoreChrome: undefined,
            linkAndroidStoreIqos: undefined,
            linkDesktopApps: undefined,
            linkDesktopChrome: undefined,
            linkIosStoreBluenet: undefined,
            linkIosStoreIqos: undefined,
            linkMacWidget: undefined,
            linkWindowsWidget: undefined,
        },
        aws: {
            awsIotHost: undefined,
            awsRegion: undefined,
        },
        cm: {
            cmTimeout: undefined,
        },
        externalLinks: {
            connectingCareUrl: undefined,
            dcsRegisterUrl: undefined,
            dcsSupportUrl: undefined,
            iqosHomeUrl: undefined,
            iqosStoreUrl: undefined,
            loginUrl: undefined,
            productUnregistrationUrl: undefined,
        },
        gam: {
            gamCheckRequestInterval: undefined,
            gamTimeout: undefined,
            gamApiUrl: undefined,
        },
        icc: {
            anonymousToken: undefined,
            dataUserToken: undefined,
            iccApiBodyScope: undefined,
            iccApiHeader_a: undefined,
            iccApiHeader_l: undefined,
            iccApiHeader_m: undefined,
            iccApiHeaderAuthorization: undefined,
            iccApiHeaderUsername: undefined,
            iccApiUrl: undefined,
            iccAuthUrl: undefined,
            isICCConsumerServiceEnabled: undefined,
        },
        localization: {
            localizationFileName: undefined,
            localizationKey: undefined,
        },
        login: {
            ageGateMinAge: undefined,
            loginEmailRegex: undefined,
            loginForgotPasswordUrl: undefined,
            loginPasswordRegex: undefined,
            loginRegisterUrl: undefined,
        },
        logs: {
            awsLogLevel: undefined,
            isAppVersionVisible: undefined,
            isAwsLoggingEnabled: undefined,
            isDebug: undefined,
            isLogsVisible: undefined,
            logLevel: undefined,
            mqttDebugLevel: undefined,
        },
        market: {
            dcs: {isDCS: true},
            dce1: {
                isDCE1: undefined,
                type: undefined,
            },
            dce2: {isDCE2: true},
        },
        scp: {
            scpApiUrl: undefined,
            scpCloudTopicMarket: undefined,
            scpCloudTopicPrefix: undefined,
            scpCloudVersion: undefined,
        },
        uam: {
            uamCheckRequestInterval: undefined,
            uamTimeout: undefined,
            uamCheckStatusInterval: undefined,
        },
    },
};
