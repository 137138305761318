import {config} from '../config';

export default {
    getLinkAndroidStoreChrome: () => config.data.appLinks.linkAndroidStoreChrome,
    getLinkAndroidStoreIqos: () => config.data.appLinks.linkAndroidStoreIqos,
    getLinkDesktopApps: () => config.data.appLinks.linkDesktopApps,
    getLinkDesktopChrome: () => config.data.appLinks.linkDesktopChrome,
    getLinkIosStoreBluenet: () => config.data.appLinks.linkIosStoreBluenet,
    getLinkIosStoreIqos: () => config.data.appLinks.linkIosStoreIqos,
    getLinkMacWidget: () => config.data.appLinks.linkMacWidget,
    getLinkWindowsWidget: () => config.data.appLinks.linkWindowsWidget,
};
