import React from 'react';

import productService from '../../services/productService';
import stylesService from '../../services/stylesService';
import DeviceLockBtn from './DeviceLockBtn';

const DeviceLock = ({product, isDeviceReady, onDisableClick, lockTestId, unlockTestId}) => {
    if (!product) return null;

    const {parent_protection_mode, colorHex, status, isP4, isDeviceActivated} = product;
    const backgroundColorStyle = stylesService.getBackgroundColorStyle(colorHex);
    const isDeviceLockBtnVisible = isDeviceActivated && isP4;
    const isProductRegistered = productService.isStatusRegistered(status);

    return isDeviceLockBtnVisible ? (
        <DeviceLockBtn
            isDeviceLocked={parent_protection_mode}
            isDeviceReady={isDeviceReady}
            isProductRegistered={isProductRegistered}
            lockTestId={lockTestId}
            onDisableClick={onDisableClick}
            style={backgroundColorStyle}
            unlockTestId={unlockTestId}
        />
    ) : null;
};

export default DeviceLock;
