import * as iccProductStatusTypes from '../consts/icc/iccProductStatusTypes';
import PRODUCT_COLORS from '../consts/product/productColors';
import PRODUCT_COLORS_MAPPING from '../consts/product/productColorsMapping';
import {removeConsumerProductsStatus, updateConsumerProductData} from '../state/ducks/consumer';
import {setDeviceActivationStatus} from '../state/ducks/iotDevice/actions';
import {
    setProductToChangeRegisteringStatus,
    setProductToChangeUnregisteringStatus,
} from '../state/ducks/productSettings';
import {makeSelectIotDeviceMergedWithIccProduct, makeSelectProductStatus} from '../state/selectors/consumer';
import {makeSelectCurrentProductToChangeRegistrationStatus} from '../state/selectors/productSettings';
import {dispatch, getState} from '../state/store';
import backendService from './app/backendService';
import cryptoService from './cryptoService';
import gamService from './gam/gamService';
import iccMarketService from './icc/iccMarketService';
import log from './logger/log';
import marketConfigService from './marketConfigService';
import productIdentificatorService from './productIdentificatorService';
import uamClientService from './uam/uamClientService';
import uamService from './uam/uamService';

const getHexColorByMaterialColor = (type, color) => {
    const productTypeColor = PRODUCT_COLORS_MAPPING[type];

    return productTypeColor ? productTypeColor[color] : PRODUCT_COLORS.DEFAULT;
};

let productRegistrationAttempts = 0;

const setProductRegistrationAttempts = (attempts) => {
    productRegistrationAttempts = attempts;
};

const getProductRegistrationAttempts = () => productRegistrationAttempts;

const unregisterConsumerProduct = async ({productId, codentify, deviceSerialNumber, isP4, isHolder}) => {
    log.info(`Start unregistration process for codentify ${codentify}.`);

    dispatch(removeConsumerProductsStatus());

    const productIdentify = productId ? {productId} : {codentifyId: codentify};
    const response = await iccMarketService.removeConsumerProduct({data: productIdentify});

    if (response) {
        dispatch(
            updateConsumerProductData({
                deviceSerialNumber,
                codentify,
                status: iccProductStatusTypes.UNREGISTERING,
            })
        );
        dispatch(setProductToChangeUnregisteringStatus(isP4, deviceSerialNumber, codentify, isHolder));
    }
    return response;
};

const registerDevice = async (registerData) => {
    const currentProductToChangeRegistrationStatus = makeSelectCurrentProductToChangeRegistrationStatus()(getState());
    if (currentProductToChangeRegistrationStatus) return;

    const {deviceSerialNumber: serialNumber, holderInternalId, codentify, name, isP4, isHolder} = registerData;

    log.info(`Start registration process for codentify ${codentify}.`);

    dispatch(removeConsumerProductsStatus());

    const deviceSerialNumber = serialNumber || holderInternalId;

    let response;

    if (isP4) {
        response = await iccMarketService.setConsumerProductV2({codentify});
    } else {
        const encryptedSerialNumber = cryptoService.encryptDeviceSerialNumber(deviceSerialNumber);

        response = await iccMarketService.setConsumerProductV3({deviceSerialNumber: encryptedSerialNumber});
    }

    if (response) {
        dispatch(
            updateConsumerProductData({
                deviceSerialNumber,
                codentify,
                isP4,
                status: iccProductStatusTypes.REGISTERING,
            })
        );
        dispatch(setProductToChangeRegisteringStatus(isP4, deviceSerialNumber, codentify, name, isHolder));
    }

    return response;
};

const registerConsumerProduct = async (product, name) => {
    if (!product) return;

    const {device, holder, deviceSerialNumber} = product;
    const {isP4, codentify} = device;
    const holderInternalId = holder?.deviceSerialNumber;
    const isDeviceRegistered = isStatusRegistered(device.status);
    const isHolderRegistered = isStatusRegistered(holder?.status);

    let registerData;

    if (!isDeviceRegistered) {
        registerData = {deviceSerialNumber, codentify, name, isP4, isHolder: false};

        registerDevice(registerData);
    }
    if (!isHolderRegistered && holderInternalId) {
        registerData = {holderInternalId, codentify, name, isP4, isHolder: true};

        registerDevice(registerData);
    }
};

const fetchProductsStatusIfNeeded = () => {
    const currentProductToChangeRegistrationStatus = makeSelectCurrentProductToChangeRegistrationStatus()(getState());

    if (currentProductToChangeRegistrationStatus) {
        const {isHolder} = currentProductToChangeRegistrationStatus;

        fetchProductsStatusAndCheckIotProductStatus(isHolder);
    }
};

const fetchProductsStatusAndCheckIotProductStatus = async (isHolder) => {
    const iotProduct = makeSelectIotDeviceMergedWithIccProduct()(getState());
    if (!iotProduct) return;

    const {deviceSerialNumber} = isHolder ? iotProduct.holder : iotProduct;
    const {status, codentify, isP4} = isHolder ? iotProduct.holder : iotProduct.device;

    await iccMarketService.getConsumerProductsStatus();

    const productStatus = makeSelectProductStatus(isP4, deviceSerialNumber, codentify)(getState());

    if (!productStatus || productStatus.status !== status) {
        iccMarketService.getConsumerProducts();
    }
};

const isStatusRegistered = (status) => status === iccProductStatusTypes.REGISTERED;

const registerProductIfNeeded = (isDeviceActivated = true, iotProduct, isP4) => {
    if (!isDeviceActivated) return;

    const {device, holder} = iotProduct || {};
    const isCurrentProductRegistered = isStatusRegistered(device.status);
    const isCurrentProductHolderRegistered = isStatusRegistered(holder?.status);
    const isNeedsToRegister = !isCurrentProductRegistered || (!isP4 && !isCurrentProductHolderRegistered);

    if (isNeedsToRegister) {
        registerConsumerProduct(iotProduct, device.name);
    }
};

const checkDeviceActivatedAndRegisterIfNeeded = async () => {
    if (backendService.isGamBackend()) {
        //to setup activation status
        await gamService.isDeviceActivatedInGam();
        return;
    }

    const isIccJourneyScreensEnabled = marketConfigService.isIccJourneyScreensEnabled();
    const iotProduct = makeSelectIotDeviceMergedWithIccProduct()(getState());
    const {device, deviceSerialNumber} = iotProduct || {};
    const {isP4} = device || {};
    const isYAPFlowEnabled = marketConfigService.isYAPFlowEnabled();

    let isDeviceActivated;
    if (isP4 && isYAPFlowEnabled) {
        if (uamService.isUamMode(isP4)) {
            await uamClientService.getAssets();
            isDeviceActivated = await uamService.isDeviceActivatedInUam(deviceSerialNumber);
        } else {
            if (isIccJourneyScreensEnabled) {
                isDeviceActivated = await iccMarketService.getConsumerProductActivationStatus({
                    data: deviceSerialNumber,
                });
            }
        }
    } else {
        dispatch(setDeviceActivationStatus(true));
    }

    if (isIccJourneyScreensEnabled) {
        registerProductIfNeeded(isDeviceActivated, iotProduct, isP4);
    }
};

const findProduct = ({products, isP4, deviceSerialNumber, codentify}) => {
    const {identificatorName, identificatorValue} = productIdentificatorService.getProductIdentificator({
        isP4,
        deviceSerialNumber,
        codentify,
    });

    return products?.find((product) => product[identificatorName] === identificatorValue);
};

const someProduct = ({products, isP4, deviceSerialNumber, codentify}) => {
    const {identificatorName, identificatorValue} = productIdentificatorService.getProductIdentificator({
        isP4,
        deviceSerialNumber,
        codentify,
    });

    return products?.some((product) => product[identificatorName] === identificatorValue);
};

const filterProduct = ({products, isP4, deviceSerialNumber, codentify}) => {
    const {identificatorName, identificatorValue} = productIdentificatorService.getProductIdentificator({
        isP4,
        deviceSerialNumber,
        codentify,
    });

    return products?.filter((product) => product[identificatorName] !== identificatorValue);
};

export default {
    fetchProductsStatusAndCheckIotProductStatus,
    fetchProductsStatusIfNeeded,
    getHexColorByMaterialColor,
    getProductRegistrationAttempts,
    isStatusRegistered,
    registerConsumerProduct,
    registerDevice,
    setProductRegistrationAttempts,
    unregisterConsumerProduct,
    checkDeviceActivatedAndRegisterIfNeeded,
    findProduct,
    someProduct,
    filterProduct,
};
