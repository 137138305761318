import React, {useState} from 'react';
import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect/lib/index';

import Button from '../../../components/Button/Button';
import ButtonDark from '../../../components/Button/ButtonDark/ButtonDark';
import PopupError from '../../../components/Popup/PopupError';
import * as localizationKeys from '../../../consts/app/localizationKeys';
import * as iccProductStatusTypes from '../../../consts/icc/iccProductStatusTypes';
import useDidUpdate from '../../../hooks/effects/useDidUpdate';
import {getLocalizedStrings} from '../../../services/localization/localizationService';
import log from '../../../services/logger/log';
import pairingService from '../../../services/pairingService';
import productService from '../../../services/productService';
import uamClientService from '../../../services/uam/uamClientService';
import uamService from '../../../services/uam/uamService';
import {setNotificationInfo, setNotificationSuccess} from '../../../state/ducks/global';
import {makeSelectIotDeviceMergedWithIccProduct} from '../../../state/selectors/consumer';
import {makeSelectIsNetworkOnline} from '../../../state/selectors/global';
import {makeSelectDeviceActivationStatus, makeSelectIsDeviceReady} from '../../../state/selectors/iotDevice';
import {makeSelectIsYapActivationInProgress} from '../../../state/selectors/yapEncrypted';
import * as deactivationStatuses from './deactivationStatuses';

const mapStateToProps = createStructuredSelector({
    iotProduct: makeSelectIotDeviceMergedWithIccProduct(),
    isDeviceActivated: makeSelectDeviceActivationStatus(),
    isYapActivationInProgress: makeSelectIsYapActivationInProgress(),
    isDeviceReady: makeSelectIsDeviceReady(),
    isNetworkOnline: makeSelectIsNetworkOnline(),
});

const DeviceUamDeactivation = (props) => {
    const {
        dispatch,
        isYapActivationInProgress,
        isDeviceActivated,
        iotProduct,
        onChange,
        isDeviceReady,
        isNetworkOnline,
    } = props;

    const {device, deviceSerialNumber} = iotProduct || {};
    const {productId, codentify, isP4, status} = device || {};
    const [isUnregisteringInProgress, setIsUnregisteringInProgress] = useState(false);
    const [isDeactivationFailed, setIsDeactivationFailed] = useState(false);
    const localizedStrings = getLocalizedStrings();

    useDidUpdate(() => {
        if (isNetworkOnline) {
            return uamClientService.checkRequestStatusBySN(deviceSerialNumber);
        }
    }, [isNetworkOnline]);

    useDidUpdate(() => {
        if (!isYapActivationInProgress) {
            if (uamService.isUamMode(isP4)) {
                if (isDeviceActivated) {
                    log.info('End deactivation process. Device deactivation is failed.');
                    setIsDeactivationFailed(true);
                    onChange(deactivationStatuses.DEACTIVATION_FAILED);
                } else {
                    log.info('End deactivation process. Device is successfully deactivated.');
                    return unregDevice();
                }
            }
        }
    }, [isYapActivationInProgress]);

    const unregDevice = async () => {
        dispatch(setNotificationSuccess(localizedStrings[localizationKeys.YAP_DEACTIVATION_SUCCESSFULLY_TEXT]));

        try {
            await productService.unregisterConsumerProduct({
                productId,
                codentify,
                deviceSerialNumber,
            });
            onChange(deactivationStatuses.UNREGISTERING);
        } catch (e) {
            log.error(`DeviceUamDeactivation: failed to unregister device. Error: ${e}`);
            onChange(deactivationStatuses.DEACTIVATION_FINISH);
            unpairDevice();
        }
    };

    useDidUpdate(() => {
        switch (status) {
            case iccProductStatusTypes.UNREGISTERING:
                setIsUnregisteringInProgress(true);
                break;
            default:
                if (isUnregisteringInProgress) {
                    setIsUnregisteringInProgress(false);

                    uamClientService.getAssets();
                    onChange(deactivationStatuses.DEACTIVATION_FINISH);
                    unpairDevice();
                }
        }
    }, [status]);

    const unpairDevice = async () => {
        pairingService.disconnectAndForwardToMyDevicesPage();
    };

    const onClose = () => {
        setIsDeactivationFailed(false);
    };

    const tryAgain = async () => {
        if (isDeviceReady) {
            onChange(deactivationStatuses.DEACTIVATING);
            setIsDeactivationFailed(false);
            await uamClientService.deactivateDevice(deviceSerialNumber);
        } else {
            setIsDeactivationFailed(false);
            dispatch(
                setNotificationInfo(getLocalizedStrings()[localizationKeys.DEVICE_SETTINGS_CONNECT_NOTIFICATION_TEXT])
            );
        }
    };

    return (
        isDeactivationFailed && (
            <PopupError onClose={onClose}>
                <h2>{localizedStrings[localizationKeys.YAP_DEACTIVATION_FAILED__TITLE_TEXT]}</h2>
                <p className={`ica--txt-regular`}>
                    {localizedStrings[localizationKeys.YAP_DEACTIVATION_FAILED_DESCRIPTION_TEXT]}
                </p>
                <ButtonDark onClick={tryAgain} showArrow>
                    {localizedStrings[localizationKeys.YAP_DEACTIVATION_FAILED_TRY_AGAIN_BUTTON_TEXT]}
                </ButtonDark>
                <Button onClick={onClose} showUnderline>
                    {localizedStrings[localizationKeys.YAP_DEACTIVATION_FAILED_CANCEL_BUTTON_TEXT]}
                </Button>
            </PopupError>
        )
    );
};

export default connect(mapStateToProps)(DeviceUamDeactivation);
