import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';

import FloatButton from '../../components/Button/FloatButton/FloatButton';
import Container from '../../components/Layout/Container/Container';
import * as localizationKeys from '../../consts/app/localizationKeys';
import * as routePaths from '../../consts/route/routePaths';
import LogoutButton from '../../containers/LogoutButton/LogoutButton';
import analyticsService from '../../services/analyticsService';
import appRouterService from '../../services/appRouterService';
import iccConsumerProducts from '../../services/icc/iccConsumerProducts';
import iotDeviceConnectionStatusService from '../../services/iotDevice/iotDeviceConnectionStatusService';
import {getLocalizedStrings} from '../../services/localization/localizationService';
import stylesService from '../../services/stylesService';
import {setNotificationInfo} from '../../state/ducks/global';
import {makeSelectLayoutType} from '../../state/selectors/global';
import {makeSelectCommonProducts} from '../../state/selectors/products';
import {dispatch} from '../../state/store';
import elementsTesIds from '../../test/consts/elementsTesIds';
import DeviceLock from '../DeviceLock/DeviceLock';
import DevicesList from './components/DevicesList/DevicesList';
import styles from './MyDevicesPage.module.scss';

const mapStateToProps = createStructuredSelector({
    commonProducts: makeSelectCommonProducts(),
    layout: makeSelectLayoutType(),
});

const MyDevicesPage = (props) => {
    const {commonProducts, layout} = props;
    const [currentProductIndex, setCurrentProductIndex] = useState(0);
    const productExists = !!commonProducts?.length && currentProductIndex < commonProducts.length;
    const product = productExists ? commonProducts[currentProductIndex] : null;
    const {colorHex, deviceConnectionStatus, device_management_features_config, isDeviceActivated} = product || {};
    const isDeviceReady = iotDeviceConnectionStatusService.isDeviceReady(deviceConnectionStatus);
    const backgroundColorStyle = stylesService.getBackgroundColorStyle(colorHex);
    const localizedStrings = getLocalizedStrings();

    const onSlide = (productIndex) => {
        setCurrentProductIndex(productIndex);
    };

    const onDisableClick = () => {
        dispatch(setNotificationInfo(localizedStrings[localizationKeys.DEVICE_SETTINGS_CONNECT_NOTIFICATION_TEXT]));
    };

    useEffect(() => {
        analyticsService.pushUsageMyDevicesScreenviewEvent();
        iccConsumerProducts.getDetailedProducts();
    }, []);

    useEffect(() => {
        if (commonProducts?.length === 0) {
            appRouterService.forwardToNoDevicesPage();
        }
    }, [commonProducts]);

    const plusSymbol = <span className={styles.PlusSymbol}>+</span>;

    return (
        <>
            <div data-testid='MyDevicesPage' className={`${styles.Page} page`} style={backgroundColorStyle}>
                <Container isFloatHeight>
                    <FloatButton
                        className={`${styles.AddDeviceButton} ica--txt-regular`}
                        isLeft
                        testId={elementsTesIds.DEVICE_LIST_BTN_ADD_NEW}
                        to={routePaths.PAIRING_ALERT}
                    >
                        {localizedStrings.formatString(
                            localizedStrings[localizationKeys.DEVICE_LIST_DEVICES_AVAILABLE_ADD_DEVICE_BUTTON_TEXT],
                            plusSymbol
                        )}
                    </FloatButton>
                    {!isDeviceActivated && <LogoutButton />}
                </Container>
                <DeviceLock
                    featuresConfig={device_management_features_config}
                    isDeviceReady={isDeviceReady}
                    lockTestId={elementsTesIds.DEVICE_LIST_BTN_UNLOCK}
                    onDisableClick={onDisableClick}
                    product={product}
                    unlockTestId={elementsTesIds.DEVICE_LIST_BTN_LOCK}
                />
                {productExists && <DevicesList customerDeviceList={commonProducts} onSlide={onSlide} layout={layout} />}
            </div>
        </>
    );
};

export default connect(mapStateToProps)(MyDevicesPage);
