import React from 'react';

import ActivationSkipBtn from '../../../components/ActivationSkipBtn/ActivationSkipBtn';
import Container from '../../../components/Layout/Container/Container';
import CommonLoaderScreen from '../../../components/Loader/CommonLoaderScreen';
import * as localizationKeys from '../../../consts/app/localizationKeys';
import {getLocalizedStrings} from '../../../services/localization/localizationService';
import styles from '../DeviceActivationPage.module.scss';

const DeviceActivationNotConnectedPage = (props) => {
    const {iotDevice} = props;
    const localizedStrings = getLocalizedStrings();
    const header = localizedStrings[localizationKeys.YAP_ACTIVATION_PAUSED_TITLE];
    const description = localizedStrings[localizationKeys.YAP_ACTIVATION_PAUSED_BLUETOOTH_DESCRIPTION];

    return (
        <Container className={styles.Container}>
            <ActivationSkipBtn />
            <CommonLoaderScreen
                isBig
                isActive={false}
                header={header}
                description={description}
                iotDevice={iotDevice}
            />
        </Container>
    );
};

export default DeviceActivationNotConnectedPage;
