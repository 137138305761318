import {w3cwebsocket as W3CWebSocket} from 'websocket';

import log from '../logger/log';

let instance = null;

export default class UAMWebSocketClient {
    constructor(assetId, createNew = false, options) {
        if (createNew && instance) {
            instance.disconnect();
            instance = null;
        }

        if (instance) {
            return instance;
        }

        if (createNew) {
            instance = this;
            this.assetId = assetId;
            this.initClient(options);
            return instance;
        }
    }

    initClient(options) {
        this.isConnected = false;
        if (options?.url) {
            log.info(`uamWebSocketClient: start connection for assetId: ${this.assetId} with url: ${options.url}`);
            this.client = new W3CWebSocket(options.url);
        } else {
            throw new Error("Uam Client can't be initialized.");
        }
    }

    isConnectedCheck(assetId) {
        if (this.assetId !== assetId) {
            return false;
        }
        return this.isConnected;
    }

    disconnect() {
        if (this.client && this.isConnected) {
            this.client.close(1000);
        }
        instance = null;
    }

    attachMessageHandler(onNewMessageHandler) {
        this.client.onmessage = (message) => {
            log.info(`uamWebSocketClient: new message: ${JSON.stringify(message?.data)}`);
            onNewMessageHandler(message);
        };
    }

    attachConnectHandler(onConnectHandler) {
        this.client.onopen = () => {
            this.isConnected = true;
            log.info(`uamWebSocketClient: connected`);
            onConnectHandler();
        };
    }

    attachCloseHandler(onCloseHandler) {
        this.client.onclose = (message) => {
            this.isConnected = false;
            log.info(`uamWebSocketClient: offline. Message code: ${message.code}`);
            onCloseHandler(message);
        };
    }
}
