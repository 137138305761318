import * as notificationTypes from '../../consts/app/notificationTypes';
import helpers from '../../utils/helpers';

export const SET_IS_APP_LOADING = 'app/global/SET_IS_APP_LOADING';
export const SET_IS_LOADER_VISIBLE = 'app/global/SET_IS_LOADER_VISIBLE';
export const SET_NOTIFICATION = 'app/global/SET_NOTIFICATION';
export const REMOVE_NOTIFICATION = 'app/global/REMOVE_NOTIFICATION';
export const SET_LAYOUT = 'app/global/layout/SET_LAYOUT';
export const HIDE_ALL_LOADERS = 'app/global/HIDE_ALL_LOADERS';
export const SET_IS_NETWORK_ONLINE = 'app/global/SET_IS_NETWORK_ONLINE';
export const LOGOUT_CLEAR_DATA = 'app/global/LOGOUT_CLEAR_DATA';

export default function reducer(
    state = {
        isAppLoading: true,
        loaders: [],
        notifications: [],
    },
    action
) {
    switch (action.type) {
        case SET_IS_APP_LOADING: {
            const isAppLoading = action.payload;

            return {...state, isAppLoading};
        }
        case SET_IS_LOADER_VISIBLE: {
            const {name, text, isVisible} = action.payload;
            const {loaders} = state;
            let loadersNew = [...loaders];

            if (isVisible) {
                loadersNew.push({name, text});
            } else {
                loadersNew = loaders.filter((e) => e.name !== name);
            }

            return {...state, loaders: loadersNew};
        }
        case SET_NOTIFICATION: {
            const {type, text} = action.payload;
            const {notifications} = state;
            let notificationsNew = [...notifications];

            if (notificationsNew.some((el) => el.text === text)) return state;

            const notification = {
                notificationId: helpers.guid(),
                type,
                text,
            };
            notificationsNew.push(notification);

            return {...state, notifications: notificationsNew};
        }
        case REMOVE_NOTIFICATION: {
            const {notificationId} = action.payload;
            const {notifications} = state;
            const notificationsNew = notifications.filter(
                (notification) => notification.notificationId !== notificationId
            );

            return {...state, notifications: notificationsNew};
        }
        case HIDE_ALL_LOADERS: {
            return {...state, loaders: []};
        }
        case SET_LAYOUT: {
            const layout = action.payload;

            return {...state, layout};
        }
        case SET_IS_NETWORK_ONLINE: {
            return {
                ...state,
                isNetworkOnline: action.payload,
            };
        }
        case LOGOUT_CLEAR_DATA: {
            return {
                ...state,
                isAppLoading: false,
            };
        }
        default:
            return state;
    }
}

export const setIsAppLoaded = () => ({
    type: SET_IS_APP_LOADING,
    payload: false,
});

const setNotification = (text, type) => ({
    type: SET_NOTIFICATION,
    payload: {text, type},
});
export const setLayout = (layout) => ({type: SET_LAYOUT, payload: layout});
export const setNotificationSuccess = (text) => setNotification(text, notificationTypes.SUCCESS);
export const setNotificationFailed = (text) => setNotification(text, notificationTypes.FAILED);
export const setNotificationInfo = (text) => setNotification(text, notificationTypes.INFO);

export const removeNotification = (notificationId) => ({
    type: REMOVE_NOTIFICATION,
    payload: {notificationId},
});
export const hideAllLoaders = () => ({type: HIDE_ALL_LOADERS});

export const logoutClearData = () => ({type: LOGOUT_CLEAR_DATA});

export const showLoader = (props) => {
    const name = props?.name;
    const text = props?.text;

    return setIsLoaderVisible({name, text, isVisible: true});
};

export const hideLoader = (name) => {
    return setIsLoaderVisible({name, isVisible: false});
};

const DEFAULT_LOADER_NAME = 'default';

export const setIsLoaderVisible = ({name = DEFAULT_LOADER_NAME, text, isVisible}) => {
    return {type: SET_IS_LOADER_VISIBLE, payload: {name, text, isVisible}};
};

export const setIsNetworkOnline = (data) => ({type: SET_IS_NETWORK_ONLINE, payload: data});
