import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';

import BackButton from '../../components/BackButton/BackButton';
import Container from '../../components/Layout/Container/Container';
import appConfig from '../../config/appConfig';
import * as localizationKeys from '../../consts/app/localizationKeys';
import useShowDisconnectNotification from '../../hooks/effects/connection/useShowDisconnectNotification';
import useHideLoader from '../../hooks/effects/useHideLoader';
import appRouterService from '../../services/appRouterService';
import iotDeviceConnectionStatusService from '../../services/iotDevice/iotDeviceConnectionStatusService';
import iotDeviceSettingsService from '../../services/iotDevice/iotDeviceSettingsService';
import {getLocalizedStrings} from '../../services/localization/localizationService';
import marketConfigService from '../../services/marketConfigService';
import productService from '../../services/productService';
import stylesService from '../../services/stylesService';
import {setNotificationInfo, showLoader} from '../../state/ducks/global';
import {setShouldShowSBLModePopup} from '../../state/ducks/iotDevice/actions';
import {makeSelectIotDeviceMergedWithIccProduct} from '../../state/selectors/consumer';
import {makeSelectLayoutType} from '../../state/selectors/global';
import {
    makeSelectDeviceConnectionStatus,
    makeSelectIotMessage,
    makeSelectIsDeviceConnectedStatus,
    makeSelectShouldShowSBLModePopup,
} from '../../state/selectors/iotDevice';
import elementsTesIds from '../../test/consts/elementsTesIds';
import DeviceLock from '../DeviceLock/DeviceLock';
import DeviceRenamePopup from './components/DeviceRenamePopup';
import DeviceSettingLockedPopup from './components/DeviceSettingLockedPopup';
import DeviceSettingsHeader from './components/DeviceSettingsHeader/DeviceSettingsHeader';
import HolderSBLModePopup from './components/HolderSBLModePopup/HolderSBLModePopup';
import UnPairBtn from './components/PairingButtons/components/UnPairBtn/UnPairBtn';
import SettingsOptions from './components/SettingsOptions/SettingsOptions';
import SettingsTable from './components/SettingsTable/SettingsTable';
import TipsTutorialsSection from './components/TipsTutorialsSection/TipsTutorialsSection';
import styles from './DeviceSettingsPage.module.scss';

const mapStateToProps = createStructuredSelector({
    iotMessage: makeSelectIotMessage(),
    iotProduct: makeSelectIotDeviceMergedWithIccProduct(),
    shouldShowSBLModePopup: makeSelectShouldShowSBLModePopup(),
    isDeviceConnectedStatus: makeSelectIsDeviceConnectedStatus(),
    connectionStatus: makeSelectDeviceConnectionStatus(),
    layout: makeSelectLayoutType(),
});

const LOADER_NAME = 'DEVICE_SETTINGS_DEVICE_CONNECTED';

const DeviceSettingsPage = (props) => {
    const {
        dispatch,
        iotMessage,
        isDeviceConnectedStatus,
        connectionStatus,
        isCurrentProductRegistered,
        iotProduct,
        layout,
        shouldShowSBLModePopup,
    } = props;
    const {device, holder, deviceSerialNumber, device_management_features_config} = iotProduct;
    const {parent_protection_mode, colorHex} = device || {};
    const [isVisibleRenamePopup, setIsVisibleRenamePopup] = useState(false);
    const [isVisibleDeviceLockedPopup, setIsVisibleDeviceLockedPopup] = useState(false);
    const backgroundColorStyle = stylesService.getBackgroundColorStyle(colorHex);
    const localizedStrings = getLocalizedStrings();
    const isHolderInSBLMode = iotDeviceSettingsService.isHolderInSBLMode(iotProduct);
    const isDeviceReady = iotDeviceConnectionStatusService.isDeviceReady(connectionStatus);
    const isIccJourneyScreensEnabled = marketConfigService.isIccJourneyScreensEnabled();
    const isDeviceSettingAvailable = appConfig.getIsDeviceSettingAvailable();

    const onHolderSBLModePopupCloseButtonClick = () => dispatch(setShouldShowSBLModePopup(false));

    const onDisableClick = () => {
        if (!isDeviceReady) {
            dispatch(setNotificationInfo(localizedStrings[localizationKeys.DEVICE_SETTINGS_CONNECT_NOTIFICATION_TEXT]));
        } else if (parent_protection_mode) {
            setIsVisibleDeviceLockedPopup(true);
        }
    };

    useEffect(() => {
        productService.fetchProductsStatusIfNeeded();
    }, []);

    useEffect(() => {
        if (!isDeviceReady) {
            setIsVisibleDeviceLockedPopup(false);
        }
    }, [isDeviceReady]);

    useShowDisconnectNotification(connectionStatus);

    useEffect(() => {
        if (isDeviceConnectedStatus) {
            dispatch(showLoader({name: LOADER_NAME}));
        }
    }, [isDeviceConnectedStatus]);

    useHideLoader(!isDeviceConnectedStatus, LOADER_NAME);

    if (!device) return null;

    const onCloseDeviceLockedPopup = () => {
        setIsVisibleDeviceLockedPopup(false);
    };

    return (
        <div className={styles.Page}>
            {isIccJourneyScreensEnabled && (
                <Container isFloatHeight>
                    <BackButton
                        onClick={() => appRouterService.forwardToMyDevicesPage()}
                        testId={elementsTesIds.DEVICE_SETTINGS_BTN_BACK}
                    />
                </Container>
            )}
            {isDeviceSettingAvailable && (
                <DeviceLock
                    featuresConfig={device_management_features_config}
                    isDeviceReady={isDeviceReady}
                    lockTestId={elementsTesIds.DEVICE_SETTINGS_BTN_UNLOCK}
                    onDisableClick={onDisableClick}
                    product={device}
                    unlockTestId={elementsTesIds.DEVICE_SETTINGS_BTN_LOCK}
                />
            )}
            <Container className={styles.PageContainer}>
                <DeviceSettingsHeader
                    background={backgroundColorStyle}
                    device={device}
                    holder={holder}
                    isDeviceReady={isDeviceReady}
                    layout={layout}
                    isLocked={parent_protection_mode}
                />
                {isDeviceSettingAvailable && (
                    <SettingsOptions
                        deviceColor={colorHex}
                        dispatch={dispatch}
                        iotDevice={iotProduct}
                        iotMessage={iotMessage}
                        isDeviceReady={isDeviceReady}
                        isLocked={parent_protection_mode}
                        layout={layout}
                        onDisableClick={onDisableClick}
                    />
                )}
                <TipsTutorialsSection layout={layout} />
                <SettingsTable
                    dispatch={dispatch}
                    iotDevice={iotProduct}
                    isCurrentProductRegistered={isCurrentProductRegistered}
                    layout={layout}
                    isDeviceReady={isDeviceReady}
                    setIsVisibleRenamePopup={setIsVisibleRenamePopup}
                />
                <div className={styles.PairingButtonContainer}>
                    <UnPairBtn isDeviceReady={isDeviceReady} onDisableClick={onDisableClick} />
                </div>
                {isVisibleRenamePopup && isDeviceSettingAvailable && (
                    <DeviceRenamePopup
                        device={device}
                        dispatch={dispatch}
                        deviceSerialNumber={deviceSerialNumber}
                        setIsVisibleRenamePopup={setIsVisibleRenamePopup}
                    />
                )}
                {isVisibleDeviceLockedPopup && isDeviceReady && (
                    <DeviceSettingLockedPopup
                        iotMessage={iotMessage}
                        isDeviceReady={isDeviceReady}
                        onClose={onCloseDeviceLockedPopup}
                    />
                )}
                {shouldShowSBLModePopup && isHolderInSBLMode && (
                    <HolderSBLModePopup onClose={onHolderSBLModePopupCloseButtonClick} />
                )}
            </Container>
        </div>
    );
};

export default connect(mapStateToProps)(DeviceSettingsPage);
