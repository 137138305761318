import React from 'react';

import Battery from '../../../../../../components/Device/Battery/Battery';
import DeviceConnectionStatus from '../../../../../../components/DeviceConnectionStatus/DeviceConnectionStatus';
import CommonLoaderScreen from '../../../../../../components/Loader/CommonLoaderScreen';
import * as localizationKeys from '../../../../../../consts/app/localizationKeys';
import * as iccProductStatusTypes from '../../../../../../consts/icc/iccProductStatusTypes';
import iotDeviceConnectionStatusService from '../../../../../../services/iotDevice/iotDeviceConnectionStatusService';
import {getLocalizedStrings} from '../../../../../../services/localization/localizationService';
import DeviceImage from '../../../../../DeviceImage/DeviceImage';
import DevicesListButton from '../../../DevicesListButton';
import styles from './DeviceListItem.module.scss';

const DeviceListItem = ({device, className, onClick, isTabletLayout}) => {
    if (!device) return null;

    const {
        deviceConnectionStatus,
        isIotProduct,
        productName,
        status,
        defaultProductName,
        battery_charge,
        parent_protection_mode,
        holder,
        isDeviceSupported,
        type,
        isDeviceActivated,
    } = device;

    const isDeviceReady = iotDeviceConnectionStatusService.isDeviceReady(deviceConnectionStatus);
    const isDeviceDisconnected = iotDeviceConnectionStatusService.isDeviceDisconnected(deviceConnectionStatus);
    const isActivationNeeded = isIotProduct && !isDeviceActivated;
    const isRegistrationScreenVisible = status === iccProductStatusTypes.REGISTERING && parent_protection_mode;
    const localizedStrings = getLocalizedStrings();

    const header = localizedStrings[localizationKeys.YAP_REGISTERING];
    const description =
        localizedStrings[localizationKeys.YAP_ACTIVATION_REGISTRATION_STATE_ACTIVATION_FLOW_PROGRESS_TEXT];

    return (
        <div
            data-testid='DeviceListItem'
            className={`${styles.Item} ${!isDeviceSupported ? styles.NotSupported : ''} ${className ? className : ''}`}
            onClick={onClick}
        >
            {/* eslint-disable-next-line no-constant-condition */}
            {isRegistrationScreenVisible && false ? ( //TODO: remove RegistrationScreenVisible block after approve
                <div className={styles.DeviceRegistrationLoaderContainer}>
                    <CommonLoaderScreen isSmall header={header} description={description} iotDevice={{device}} />
                </div>
            ) : (
                <>
                    <h2 className={styles.DeviceName}>{productName ? productName : defaultProductName}</h2>
                    {isIotProduct && !isDeviceDisconnected && (
                        <section className={styles.DeviceInfo}>
                            <DeviceConnectionStatus
                                isActivationNeeded={isActivationNeeded}
                                isDeviceReady={isDeviceReady}
                                isLocked={parent_protection_mode}
                            />
                            {isDeviceReady && <Battery percentage={battery_charge} className={styles.Battery} />}
                        </section>
                    )}
                    <DeviceImage
                        device={device}
                        holder={holder}
                        className={`${styles.ImageContainer} ${!isDeviceReady && styles.DeviceNotConnected} ${
                            !isDeviceSupported && styles.DeviceNotSupported
                        }`}
                        imgClassName={styles.DeviceImagePicture}
                    />
                    {isActivationNeeded && (
                        <div className={`${styles.DeviceListItemDescription} ica--device-description`}>
                            {
                                localizedStrings[
                                    isDeviceReady
                                        ? localizationKeys.YAP_ACTIVATION_DEVICES_AVAILABLE_ACTIVATION_DESCRIPTION_TEXT
                                        : localizationKeys.YAP_ACTIVATION_DEVICES_DISCONNECTED_DESCRIPTION_TEXT
                                ]
                            }
                        </div>
                    )}
                    {isDeviceSupported && (
                        <DevicesListButton
                            isActivationNeeded={isActivationNeeded}
                            isDeviceReady={isDeviceReady}
                            isIotProduct={isIotProduct && !isDeviceDisconnected}
                            type={type}
                            isTabletLayout={isTabletLayout}
                        />
                    )}
                </>
            )}
        </div>
    );
};

export default DeviceListItem;
