import {createSelector} from 'reselect';

import DEVICE_TYPES from '../../consts/device/deviceTypes';
import * as iccProductStatusTypes from '../../consts/icc/iccProductStatusTypes';
import devicePlatformService from '../../services/device/devicePlatformService';
import deviceTypesService from '../../services/device/deviceTypesService';
import marketConfigService from '../../services/marketConfigService';
import productService from '../../services/productService';
import {makeSelectDeviceActivationStatus, makeSelectIotDeviceData} from './iotDevice';

const selectConsumer = (state) => state.consumer;

const makeSelectConsumerProducts = () => createSelector(selectConsumer, (state) => state.products);

const makeSelectConsumerData = () => createSelector(selectConsumer, (state) => state.data);

const makeSelectConsumerFirstName = () => createSelector(selectConsumer, (state) => state.data.firstName);

const makeSelectAggregatedSettings = () => createSelector(selectConsumer, (state) => state.aggregatedSettings);

const makeSelectConsumerProduct = (device) => {
    const {codentify, deviceSerialNumber, isP4} = device;

    return createSelector(makeSelectConsumerProducts(), (products) =>
        productService.findProduct({
            products,
            isP4,
            deviceSerialNumber,
            codentify,
        })
    );
};

const makeSelectConsumerProductsInfo = () =>
    createSelector(selectConsumer, (state) => {
        return state.productsInfo;
    });

const makeSelectConsumerProductsMergedInfo = () =>
    createSelector(makeSelectConsumerProducts(), makeSelectConsumerProductsInfo(), (products, productsInfo) => {
        if (productsInfo) {
            const registeredProducts = products.filter(
                (product) => !product.isHolder && product.status === iccProductStatusTypes.REGISTERED
            );

            const consumerProductMergedInfo = registeredProducts.map((product) => {
                const productInfo = productsInfo.find(
                    (productInfo) => productInfo.deviceSerialNumber === product.deviceSerialNumber
                );
                const {colorHex, mediaId, defaultProductName, type} = productInfo || {};
                const productType = type
                    ? type
                    : devicePlatformService.getDeviceTypeBySerialNumber(product.deviceSerialNumber);

                return {
                    ...product,
                    colorHex: colorHex,
                    mediaId: mediaId,
                    defaultProductName: defaultProductName
                        ? defaultProductName
                        : deviceTypesService.getProductNameByType(productType),
                    isDeviceSupported: marketConfigService.isDeviceSupported(productType),
                    type: productType,
                };
            });

            const filteredProducts = consumerProductMergedInfo.filter(
                (product) => product.type !== DEVICE_TYPES.DEV_TYPE_UNKNOWN
            );

            return filteredProducts;
        }
    });

const makeSelectIotDeviceMergedWithIccProduct = () =>
    createSelector(
        makeSelectIotDeviceData(),
        makeSelectConsumerProducts(),
        makeSelectDeviceActivationStatus(),
        (iotDevice, products, isDeviceActivated) => {
            if (iotDevice?.device) {
                const device = {...iotDevice.device};
                const holder = {...iotDevice.holder};
                const isP4 = deviceTypesService.isP4(iotDevice?.device?.type);
                const consumerProduct = productService.findProduct({
                    products,
                    isP4,
                    deviceSerialNumber: iotDevice.deviceSerialNumber,
                    codentify: device.codentify,
                });

                const productCodentifyId = consumerProduct?.codentify;
                const iotDeviceCodentifyId = device.codentify;
                if (isP4) {
                    device.codentify = iotDeviceCodentifyId || productCodentifyId;
                } else {
                    device.codentify = productCodentifyId || iotDeviceCodentifyId;
                }

                if (consumerProduct) {
                    device.productId = consumerProduct.productId;
                    device.status = consumerProduct.status;
                }

                const consumerProductHolder = productService.findProduct({
                    products,
                    isP4: false,
                    deviceSerialNumber: iotDevice.holder?.deviceSerialNumber,
                    codentify: null,
                });

                if (consumerProductHolder) {
                    const productHolderCodentifyId = consumerProductHolder?.codentify;
                    const productHolderId = consumerProductHolder?.productId;
                    const iotDeviceHolderCodentifyId = holder?.codentify;

                    holder.codentify = productHolderCodentifyId ? productHolderCodentifyId : iotDeviceHolderCodentifyId;
                    holder.productId = productHolderId;
                    holder.status = consumerProductHolder.status;
                }

                const iotDeviceType = device?.type;
                const productName = consumerProduct?.productName;

                const isYAPFlowEnabled = marketConfigService.isYAPFlowEnabled();

                device.name = productName ? productName : deviceTypesService.getProductNameByType(iotDeviceType);
                device.isDeviceActivated = isP4 && isYAPFlowEnabled ? isDeviceActivated : true;
                device.isP4 = isP4;

                return {...iotDevice, device, holder};
            } else {
                return iotDevice;
            }
        }
    );

const makeSelectConsumerProductsAssets = () =>
    createSelector(selectConsumer, (state) => {
        return state.productsAsset;
    });

const makeSelectConsumerProductAsset = (mediaId) =>
    createSelector(makeSelectConsumerProductsAssets(), (assets) => {
        return mediaId ? assets?.find((asset) => asset.mediaId === mediaId) : null;
    });

const makeSelectConsumerProductsStatus = () => createSelector(selectConsumer, (state) => state.productsStatus);

const makeSelectConsumerRecentlyPairedDevices = () =>
    createSelector(selectConsumer, (state) => state.recentlyPairedDevices);

const makeSelectConsumerTnc = () => createSelector(selectConsumer, (state) => state.tnc);

const makeSelectConsumerAcceptedTnc = () => createSelector(selectConsumer, (state) => state.acceptedTnc);

const makeSelectTncByType = (type) =>
    createSelector(makeSelectConsumerTnc(), (tnc) => (tnc ? tnc.find((item) => item.screenId === type) : {}));

const makeSelectAcceptedTncByType = (type) =>
    createSelector(makeSelectConsumerAcceptedTnc(), (acceptedTnc) =>
        acceptedTnc ? acceptedTnc.find((item) => item.screenId === type) : {}
    );

const makeSelectTncLegals = () =>
    createSelector(makeSelectConsumerTnc(), makeSelectConsumerAcceptedTnc(), (tnc, acceptedTnc) => {
        const legals = tnc?.length
            ? tnc
                  .map(({legals}) => {
                      return legals ? [...legals] : [];
                  })
                  .reduce((prev, next) => [...prev, ...next])
            : [];
        const acceptedLegals = acceptedTnc?.length
            ? acceptedTnc
                  .map(({legals}) => {
                      return legals ? [...legals] : [];
                  })
                  .reduce((prev, next) => [...prev, ...next])
            : [];
        return [...legals, ...acceptedLegals];
    });

const makeSelectProductStatus = (isP4, deviceSerialNumber, codentify) => {
    return createSelector(makeSelectConsumerProductsStatus(), (productsStatuses) =>
        productService.findProduct({
            products: productsStatuses,
            isP4,
            deviceSerialNumber,
            codentify,
        })
    );
};

export {
    makeSelectAcceptedTncByType,
    makeSelectAggregatedSettings,
    makeSelectConsumerAcceptedTnc,
    makeSelectConsumerData,
    makeSelectConsumerFirstName,
    makeSelectConsumerProduct,
    makeSelectConsumerProductAsset,
    makeSelectConsumerProducts,
    makeSelectConsumerProductsAssets,
    makeSelectConsumerProductsInfo,
    makeSelectConsumerProductsMergedInfo,
    makeSelectConsumerProductsStatus,
    makeSelectConsumerTnc,
    makeSelectIotDeviceMergedWithIccProduct,
    makeSelectProductStatus,
    makeSelectTncByType,
    makeSelectTncLegals,
    makeSelectConsumerRecentlyPairedDevices,
};
