import React, {useEffect, useState} from 'react';

import * as localizationKeys from '../../../../../../consts/app/localizationKeys';
import * as responsiveDrawModeTypes from '../../../../../../consts/iot/responsiveDrawModeTypes';
import useHideLoader from '../../../../../../hooks/effects/useHideLoader';
import analyticsService from '../../../../../../services/analyticsService';
import getDeviceMessageRequestService from '../../../../../../services/deviceMessageRequestService';
import iotMessageService from '../../../../../../services/iotMessageService';
import {getLocalizedStrings} from '../../../../../../services/localization/localizationService';
import {showLoader} from '../../../../../../state/ducks/global';
import DeviceModePopup from '../../../DeviceModePopup/DeviceModePopup';
import DeviceSettingsItem from '../../../DeviceSettingsItem';
import styles from '../DeviceModeComponents/DeviceMode.module.scss';
import SelectedUnselectedButton from '../DeviceModeComponents/SelectedUnselectedButton';
import {getResponsiveDrawSet} from './responsiveDrawSet';

const LOADER_NAME = 'RESPONSIVE_DRAW_PAGE_LOADER';

const ResponsiveDrawMode = (props) => {
    const {dispatch, iotDevice, isConnected, disabled, iotMessage, onDisableClick, layout, testId} = props;
    const {responsiveDrawData} = iotDevice;
    const [isVisibleResponsiveDrawPopup, setIsVisibleResponsiveDrawPopup] = useState(false);
    const onVisibleResponsiveDrawPopup = () => {
        setIsVisibleResponsiveDrawPopup(!isVisibleResponsiveDrawPopup);
    };

    const responsiveDrawSet = getResponsiveDrawSet();
    const responsiveDrawProfile = responsiveDrawData?.responsive_draw_profile;
    const responsiveDrawValue = responsiveDrawSet.find(({mode}) => mode === responsiveDrawProfile);
    const isResponsiveDrawDataLoaded = responsiveDrawProfile;
    const isResponsiveDrawStatusFinished = iotMessageService.isResponsiveDrawStatusFinished(iotMessage);

    useEffect(() => {
        if (!isResponsiveDrawDataLoaded && isConnected) {
            dispatch(showLoader({name: LOADER_NAME}));
            getDeviceMessageRequestService().publishGetResponsiveDrawSettingsMessage();
        }
    }, [isConnected]);

    useEffect(() => {
        if (isResponsiveDrawDataLoaded && isConnected) {
            const isResponsiveModeDisabled = responsiveDrawProfile === responsiveDrawModeTypes.DISABLED;

            if (isResponsiveModeDisabled) {
                setResponsiveDrawMode(responsiveDrawModeTypes.VIVACE);
            }
        }
    }, [isResponsiveDrawDataLoaded]);

    useHideLoader(isResponsiveDrawStatusFinished || !isConnected, LOADER_NAME);

    const setResponsiveDrawMode = (mode) => {
        dispatch(showLoader({name: LOADER_NAME}));
        analyticsService.pushUsageSettingsResponsiveDrawModeEvent();
        getDeviceMessageRequestService().publishSetResponsiveDrawSettingsMessage(mode);
    };

    const {imgSet, title} = responsiveDrawValue || {};
    const localizedStrings = getLocalizedStrings();

    return (
        <>
            <DeviceSettingsItem
                disabled={disabled}
                imgUrls={imgSet}
                onClick={onVisibleResponsiveDrawPopup}
                onDisableClick={onDisableClick}
                testId={testId}
            >
                {localizedStrings.formatString(
                    localizedStrings[localizationKeys.DEVICE_SETTINGS_RESPONSIVE],
                    title || ''
                )}
            </DeviceSettingsItem>
            {isVisibleResponsiveDrawPopup && isConnected && (
                <DeviceModePopup
                    imgAlt={localizedStrings[localizationKeys.RESPONSIVE_FLOW_MODE_TITLE]}
                    imgClassName={`${styles.ImageResponsiveDrawHeaderIcon} ${styles.CenterElement}`}
                    imgUrls={imgSet}
                    subTitle={localizedStrings[localizationKeys.RESPONSIVE_FLOW_MODE_DESCRIPTION]}
                    title={localizedStrings[localizationKeys.RESPONSIVE_FLOW_MODE_TITLE]}
                    onClose={() => setIsVisibleResponsiveDrawPopup(false)}
                    isClosingBySwipeEnabled
                    layout={layout}
                >
                    <div className={`${styles.ButtonContainer} ${styles.DrawMode}`}>
                        {responsiveDrawSet.map(({mode, popupTitle, imgSet, testId}, i) => (
                            <SelectedUnselectedButton
                                imgSet={imgSet}
                                isActive={responsiveDrawProfile === mode}
                                key={i}
                                onClick={() => setResponsiveDrawMode(mode)}
                                testId={testId}
                                title={popupTitle}
                                titleClassName={styles.ResponsiveDrawButtonTitle}
                            />
                        ))}
                    </div>
                </DeviceModePopup>
            )}
        </>
    );
};

export default ResponsiveDrawMode;
