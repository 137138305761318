import React from 'react';

import BackButton from '../../components/BackButton/BackButton';
import Container from '../../components/Layout/Container/Container';
import * as localizationKeys from '../../consts/app/localizationKeys';
import appRouterService from '../../services/appRouterService';
import authService from '../../services/auth/authService';
import havService from '../../services/havService';
import iccMarketService from '../../services/icc/iccMarketService';
import {getLocalizedStrings} from '../../services/localization/localizationService';
import log from '../../services/logger/log';
import userSessionService from '../../services/userSessionService';
import {hideLoader, setNotificationFailed, showLoader} from '../../state/ducks/global';
import {dispatch} from '../../state/store';
import elementsTesIds from '../../test/consts/elementsTesIds';
import LoginForm from './components/LoginForm';
import styles from './LoginPage.module.scss';

const LoginPage = () => {
    const localizedStrings = getLocalizedStrings();

    const onLogin = async (loginCredentials) => {
        dispatch(showLoader());
        await authService
            .standaloneLogin(loginCredentials)
            .then(async () => {
                const isHAVConfirmed = await havService.verifyHav();

                if (isHAVConfirmed) {
                    await iccMarketService.getConsumerAggregatedSettings();

                    appRouterService.forwardToWelcomeUserPage();
                }
            })
            .catch((e) => {
                userSessionService.removeUserSessionSpiceTokenData();

                log.error(`LoginPage: standaloneLogin, ${e}`);
                dispatch(
                    setNotificationFailed(localizedStrings[localizationKeys?.LOGIN_INVALID_CREDENTIAL_WARNING_TEXT])
                );
            });

        userSessionService.setUserSessionLocalization();
        dispatch(hideLoader());
    };

    return (
        <>
            <div className={styles.Page}>
                <Container isFloatHeight>
                    <BackButton
                        testId={elementsTesIds.LOGIN_BTN_BACK}
                        className={styles.BackButton}
                        onClick={() => appRouterService.forwardToBrowserVerificationPage()}
                    />
                </Container>
                <Container className={styles.LoginPageContainer}>
                    <h2 className={styles.Title}>{localizedStrings[localizationKeys?.LOGIN_PAGE_TITLE_TEXT]}</h2>
                    <div className={styles.SubtitleText}>
                        {localizedStrings[localizationKeys?.LOGIN_DESCRIPTION_TEXT]}
                    </div>
                    <LoginForm onLogin={onLogin} />
                </Container>
            </div>
        </>
    );
};

export default LoginPage;
