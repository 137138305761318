import React from 'react';
import {connect} from 'react-redux';
import {Redirect} from 'react-router-dom';
import {createStructuredSelector} from 'reselect';

import imgP1NoRegisteredDeviceDesktop1 from '../../../assets/images/devices/no-registered-device/nrd-ro-desktop.png';
import imgP1NoRegisteredDeviceDesktop2 from '../../../assets/images/devices/no-registered-device/nrd-ro-desktop@2x.png';
import imgP1NoRegisteredDeviceDesktop3 from '../../../assets/images/devices/no-registered-device/nrd-ro-desktop@3x.png';
import imgP1NoRegisteredDeviceMobile1 from '../../../assets/images/devices/no-registered-device/nrd-ro-mobile.png';
import imgP1NoRegisteredDeviceMobile2 from '../../../assets/images/devices/no-registered-device/nrd-ro-mobile@2x.png';
import imgP1NoRegisteredDeviceMobile3 from '../../../assets/images/devices/no-registered-device/nrd-ro-mobile@3x.png';
import imgP4NoRegisteredDeviceDesktop1 from '../../../assets/images/devices/no-registered-device/nrd-yap-desktop-img.png';
import imgP4NoRegisteredDeviceDesktop2 from '../../../assets/images/devices/no-registered-device/nrd-yap-desktop-img@2x.png';
import imgP4NoRegisteredDeviceDesktop3 from '../../../assets/images/devices/no-registered-device/nrd-yap-desktop-img@3x.png';
import imgP4NoRegisteredDeviceMobile1 from '../../../assets/images/select-device/veev-mobile.png';
import imgP4NoRegisteredDeviceMobile2 from '../../../assets/images/select-device/veev-mobile@2x.png';
import imgP4NoRegisteredDeviceMobile3 from '../../../assets/images/select-device/veev-mobile@3x.png';
import Image from '../../components/Image/Image';
import appConfig from '../../config/appConfig';
import * as localizationKeys from '../../consts/app/localizationKeys';
import * as routePaths from '../../consts/route/routePaths';
import layoutService from '../../services/layoutService';
import {getLocalizedStrings} from '../../services/localization/localizationService';
import {makeSelectLayoutType} from '../../state/selectors/global';
import {makeSelectCommonProducts} from '../../state/selectors/products';
import LogoutButton from '../LogoutButton/LogoutButton';
import SetupNewDeviceButton from './components/SetupNewDeviceButton';
import styles from './NoDevices.module.scss';

const mapStateToProps = createStructuredSelector({
    commonProducts: makeSelectCommonProducts(),
    layout: makeSelectLayoutType(),
});

const NoDevicesPage = (props) => {
    const {commonProducts, layout} = props;
    const isP1SupportEnabled = appConfig.getEnableP1Support();
    const isDesktop = layoutService.isDesktopLayout(layout);
    const localizedStrings = getLocalizedStrings();

    const mobileImageSet = isP1SupportEnabled
        ? [imgP1NoRegisteredDeviceMobile1, imgP1NoRegisteredDeviceMobile2, imgP1NoRegisteredDeviceMobile3]
        : [imgP4NoRegisteredDeviceMobile1, imgP4NoRegisteredDeviceMobile2, imgP4NoRegisteredDeviceMobile3];

    const desktopImageSet = isP1SupportEnabled
        ? [imgP1NoRegisteredDeviceDesktop1, imgP1NoRegisteredDeviceDesktop2, imgP1NoRegisteredDeviceDesktop3]
        : [imgP4NoRegisteredDeviceDesktop1, imgP4NoRegisteredDeviceDesktop2, imgP4NoRegisteredDeviceDesktop3];

    if (commonProducts?.length) {
        return <Redirect to={routePaths.MY_DEVICES} />;
    }

    return (
        <div className={`${styles.Page} page`}>
            <div className={styles.TextContainer}>
                <h2 className={styles.Title}>{localizedStrings[localizationKeys.DEVICE_LIST_NO_DEVICE_TITLE_TEXT]}</h2>
                <p className={`${styles.Description} ica--txt-regular`}>
                    {localizedStrings[localizationKeys.DEVICE_LIST_NO_DEVICE_DESCRIPTION_TEXT]}
                </p>
                <p className={`${styles.Description} ica--txt-regular`}>
                    {localizedStrings[localizationKeys.DEVICE_LIST_NO_DEVICE_FEATURE_DESCRIPTION_TEXT]}
                </p>
                {isDesktop && (
                    <>
                        <SetupNewDeviceButton />
                        <LogoutButton isArrow className={styles.LogoutButton} />
                    </>
                )}
            </div>
            {isDesktop ? (
                <div className={`${styles.ImageContainer} ${styles.YAP}`}>
                    <Image src={desktopImageSet} />
                </div>
            ) : (
                <>
                    <div className={`${styles.ImageContainer} ${!isP1SupportEnabled ? styles.YAPImageContainer : ''}`}>
                        <Image src={mobileImageSet} />
                    </div>
                    <SetupNewDeviceButton />
                    <LogoutButton isArrow className={styles.LogoutButton} />
                </>
            )}
        </div>
    );
};

export default connect(mapStateToProps)(NoDevicesPage);
