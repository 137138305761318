import appConfig from '../config/appConfig';
import * as yapModeTypes from '../consts/yap/yapModeTypes';
import deviceTypesService from './device/deviceTypesService';

//TODO: rename service

const isDeviceSupported = (type) => {
    const enableP1Support = appConfig.getEnableP1Support();
    const enableP4Support = appConfig.getEnableP4Support();

    return (enableP1Support && deviceTypesService.isP1(type)) || (enableP4Support && deviceTypesService.isP4(type));
};

const isYAPFlowEnabled = () => {
    const yapMode = appConfig.getYapMode();

    return yapMode !== yapModeTypes.YAP_DISABLED_MODE;
};

const isIccJourneyScreensEnabled = () => appConfig.getIsICCConsumerServiceEnabled();

export default {
    isDeviceSupported,
    isIccJourneyScreensEnabled,
    isYAPFlowEnabled,
};
