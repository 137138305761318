import appConfig from '../../config/appConfig';
import * as httpErrorsTypes from '../../consts/app/httpErrorsTypes';
import * as gamMethods from '../../consts/gam/gamMethods';
import {makeSelectGamAuthToken} from '../../state/selectors/auth';
import {dispatch, getState} from '../../state/store';
import arrayUtils from '../../utils/arrayUtils';
import stringUtils from '../../utils/stringUtils';
import urlUtils from '../../utils/urlUtils';
import authDataStoreService from '../auth/authDataStoreService';
import domService from '../domService';
import log from '../logger/log';
import {authGamMapping} from '../mapping/gamMappings';
import server from '../server/server';
import storageService from '../storage/storageService';

let isTokenRefreshInProgress = false;

const getGamApiUrl = (methodName) => {
    const gamApiUrl = appConfig.getGamApiUrl();
    return urlUtils.join(gamApiUrl, methodName);
};

const getHeaders = () => {
    const headers = {
        Accept: 'application/json',
    };

    const state = getState();

    const gamAuthToken = makeSelectGamAuthToken()(state);
    if (gamAuthToken) {
        headers.Authorization = 'Bearer ' + gamAuthToken;
    }

    return headers;
};

const getGamGigyaToken = () => {
    try {
        const userData = storageService.getGamGigyaAccountDataFromStorage();
        if (userData) {
            const gigyaJWT = userData.gigyaJWT;
            if (gigyaJWT) return gigyaJWT;
        }
    } catch (e) {
        log.info(`gamClient: GigyaToken parse error: ${e}`);
    }
    return null;
};

const fetchGamToken = async () => {
    const codentify = domService.getDeviceCodentifyFromRoot();
    if (!codentify) throw new Error('gamClient: Device codentify does not exist in root element');

    const gigyaJWT = getGamGigyaToken();
    if (!gigyaJWT) throw new Error('gamClient: GigyaToken does not exist in local storage');

    const requestConfig = {
        headers: {Authorization: 'Bearer ' + gigyaJWT},
        args: codentify,
    };

    const response = await callPut(gamMethods.MANAGE_DEVICE, requestConfig);
    if (!response) throw new Error('bad response for manage-device method');

    const responseData = authGamMapping(response);

    authDataStoreService.setGamApiData(responseData);
};

const getMethodUrl = (methodName, args) => {
    let methodUrl = methodName;
    if (args) {
        const params = arrayUtils.toArray(args);
        methodUrl = stringUtils.formatString(methodName, ...params);
    }
    return methodUrl;
};

const callGet = (methodName, requestConfig, action, mapper, ignoreErrorCodes) => {
    const methodUrl = getMethodUrl(methodName, requestConfig?.args);
    return callRequest(server.get, methodUrl, requestConfig, action, mapper, ignoreErrorCodes);
};

const callPost = (methodName, requestConfig, action, mapper, ignoreErrorCodes) => {
    const methodUrl = getMethodUrl(methodName, requestConfig?.args);
    return callRequest(server.post, methodUrl, requestConfig, action, mapper, ignoreErrorCodes);
};

const callPut = (methodName, requestConfig, action, mapper, ignoreErrorCodes) => {
    const methodUrl = getMethodUrl(methodName, requestConfig?.args);
    return callRequest(server.put, methodUrl, requestConfig, action, mapper, ignoreErrorCodes);
};

const callRequest = async (httpMethod, methodUrl, requestConfig, action, mapper, ignoreErrorCodes) => {
    try {
        const response = await callGamMethod(httpMethod, methodUrl, requestConfig, ignoreErrorCodes);

        if (!response) return null;

        let {data} = response;

        if (mapper) {
            data = mapper(data);
        }

        if (action) {
            dispatch(action(data));
        } else {
            return data;
        }
    } catch (e) {
        return null;
    }
};

const callGamMethod = async (httpMethod, methodUrl, requestConfig, ignoreErrorCodes) => {
    const url = getGamApiUrl(methodUrl);
    const headers = requestConfig?.headers ? requestConfig.headers : getHeaders();
    const data = requestConfig?.data ? requestConfig.data : {};

    try {
        const response = await httpMethod(url, {headers, data});

        log.debug(`gamClient: request: '${methodUrl}' has successful response`);

        return response;
    } catch (e) {
        return await errorCheck(
            e,
            async () => {
                return await httpMethod(methodUrl, {headers});
            },
            ignoreErrorCodes
        );
    }
};

const errorCheck = async (error, onSuccess, ignoreErrorCodes = []) => {
    const status = error?.response?.status;
    ignoreErrorCodes = arrayUtils.toArray(ignoreErrorCodes);
    if (ignoreErrorCodes.includes(status)) return null;

    switch (status) {
        case httpErrorsTypes.NOT_AUTHORIZED: {
            if (!isTokenRefreshInProgress) {
                isTokenRefreshInProgress = true;
                try {
                    await fetchGamToken();
                    isTokenRefreshInProgress = false;
                    return onSuccess ? onSuccess() : null;
                } catch (e) {
                    isTokenRefreshInProgress = false;
                }
            }
            break;
        }
        default:
            throw error;
    }
};

export default {
    fetchGamToken,
    getHeaders,
    callGet,
    callPost,
    callPut,
};
