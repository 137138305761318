import React from 'react';

import FloatButton from '../../components/Button/FloatButton/FloatButton';
import * as localizationKeys from '../../consts/app/localizationKeys';
import authService from '../../services/auth/authService';
import {getLocalizedStrings} from '../../services/localization/localizationService';
import marketConfigService from '../../services/marketConfigService';
import pairingService from '../../services/pairingService';

const LogoutButton = ({isArrow, className}) => {
    const localizedStrings = getLocalizedStrings();
    const isIccJourneyScreensEnabled = marketConfigService.isIccJourneyScreensEnabled();
    const isRef = process.env.REACT_APP_IS_BUILD_REF === 'true';

    const onLogoutClick = () => {
        pairingService.disconnectAndClearState();
        authService.logout();
    };

    return (
        <>
            {isIccJourneyScreensEnabled && !isRef && (
                <FloatButton showArrow={isArrow} className={className} onClick={onLogoutClick}>
                    {localizedStrings[localizationKeys.LOGOUT_BUTTON_TEXT]}
                </FloatButton>
            )}
        </>
    );
};

export default LogoutButton;
