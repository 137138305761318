import React, {useEffect} from 'react';

import Button from '../../../../components/Button/Button';
import ButtonDark from '../../../../components/Button/ButtonDark/ButtonDark';
import PopupError from '../../../../components/Popup/PopupError';
import PAIRING_ERRORS from '../../../../consts/device/pairingErrors';
import analyticsService from '../../../../services/analyticsService';
import appRouterService from '../../../../services/appRouterService';
import marketConfigService from '../../../../services/marketConfigService';
import pairingService from '../../../../services/pairingService';
import {getPairingGuideContent} from '../../pairingGuideContent';
import styles from './PairingGuideErrorPopup.module.scss';

const PairingGuideErrorPopup = (props) => {
    const {deviceType, error, setError} = props;
    const {code, type} = error;
    const errorSet = getErrorSet(type, deviceType);
    const isIccJourneyScreensEnabled = marketConfigService.isIccJourneyScreensEnabled();

    useEffect(() => {
        analyticsService.pushPairingFailedEvent(code, type);
    }, [code, type]);

    if (!errorSet) return null;

    const {description, mainButtonText, secondaryButtonText, title} = errorSet;

    const onErrorPopupTryAgainBtnClick = () => {
        setError(null);

        switch (error.type) {
            case PAIRING_ERRORS.ERROR_CONNECTION_NOT_FOUND.type:
            case PAIRING_ERRORS.ERROR_PAIRING.type:
            case PAIRING_ERRORS.ERROR_NOT_SUPPORTED_DEVICE.type:
                appRouterService.forwardToSelectDevice();
                pairingService.disconnectAndClearState();
                break;
            default:
                break;
        }
    };

    const onErrorPopupSkipBtnClick = () => {
        switch (error.type) {
            case PAIRING_ERRORS.ERROR_CONNECTION_NOT_FOUND.type:
            case PAIRING_ERRORS.ERROR_NOT_SUPPORTED_DEVICE.type:
            case PAIRING_ERRORS.ERROR_PAIRING.type:
                isIccJourneyScreensEnabled
                    ? appRouterService.forwardToMyDevicesPage()
                    : appRouterService.forwardToNoDevicesPage();
                break;
            default:
                isIccJourneyScreensEnabled
                    ? appRouterService.forwardToMyDevicesPage()
                    : appRouterService.forwardToNoDevicesPage();
                //TODO: ?? remove switch
                break;
        }
    };

    return (
        <PopupContent
            description={description}
            mainButtonText={mainButtonText}
            onMainBtnClick={onErrorPopupTryAgainBtnClick}
            onSkipBtnClick={onErrorPopupSkipBtnClick}
            secondaryButtonText={secondaryButtonText}
            title={title}
        />
    );
};

const getErrorSet = (type, deviceType) => {
    const {pairing_errors, connection_errors, device_support_failed_errors} = getPairingGuideContent().errors;
    const deviceConnectionErrors = connection_errors[deviceType];

    switch (type) {
        case PAIRING_ERRORS.ERROR_CONNECTION_NOT_FOUND.type:
            return {...deviceConnectionErrors, ...connection_errors};
        case PAIRING_ERRORS.ERROR_PAIRING.type:
            return pairing_errors;
        case PAIRING_ERRORS.ERROR_NOT_SUPPORTED_DEVICE.type:
            return device_support_failed_errors;
        default:
            return null;
    }
};

const PopupContent = ({title, description, mainButtonText, secondaryButtonText, onMainBtnClick, onSkipBtnClick}) => {
    return (
        <>
            <PopupError>
                <h2>{title}</h2>
                <p className={`ica--txt-regular`}>{description}</p>
                <ButtonDark onClick={onMainBtnClick} showArrow>
                    {mainButtonText}
                </ButtonDark>
                <Button showUnderline onClick={onSkipBtnClick} className={styles.ButtonSecondary}>
                    {secondaryButtonText}
                </Button>
            </PopupError>
        </>
    );
};

export default PairingGuideErrorPopup;
