import appConfig from '../../config/appConfig';
import queryParameters from '../../consts/route/queryParameters';
import dateTimeUtils from '../../utils/dateTimeUtils';
import urlUtils from '../../utils/urlUtils';
import cryptoService from '../cryptoService';
import domService from '../domService';
import iccService from '../icc/iccService';
import dce1Service from '../market/dce1/dce1Service';
import marketTypeService from '../marketTypeService';
import authDataStoreService from './authDataStoreService';

const getInitialCredentials = () => {
    let spiceToken;
    let username;

    const dataUserToken = appConfig.getDataUserToken();
    const isTestLoginEnabled = appConfig.getIsTestLoginEnabled();
    const isDCE1 = marketTypeService.isDCE1();

    if (isTestLoginEnabled) {
        const [spiceTokenFromUrl, usernameFromUrl] = urlUtils.getDataFromUrl(
            queryParameters.DATA_USER_TOKEN,
            queryParameters.USERNAME
        );

        spiceToken = spiceTokenFromUrl;
        username = usernameFromUrl;
    }

    if (!spiceToken && !username) {
        if (process.env.REACT_APP_IS_BUILD_REF === 'true') {
            const userData = domService.getDataUserTokenFromRoot();

            spiceToken = userData;
        } else {
            spiceToken = dataUserToken;
        }
    }

    let decryptedUserToken = spiceToken;

    if (!isDCE1) {
        decryptedUserToken = spiceToken ? cryptoService.aesDecrypt(spiceToken) : undefined;
    }

    return {spiceToken: decryptedUserToken, username};
};

const getAuthToken = async () => {
    try {
        const {accessToken, expirationDate} = authDataStoreService.getAccessTokenData();

        if (accessToken) {
            const isAuthTokenExpired = dateTimeUtils.isExpired(expirationDate);

            if (isAuthTokenExpired) {
                if (process.env.REACT_APP_IS_BUILD_REF === 'true' && marketTypeService.isDCE1()) {
                    await dce1Service.fetchDce1AuthToken(); //update accessToken
                }

                const {accessToken} = await iccService.refreshIccToken();

                return accessToken;
            } else {
                return accessToken;
            }
        }
    } catch (e) {
        return Promise.reject(`getAuthToken error: ${e}`);
    }

    return Promise.reject(`getAuthToken error: return undefined`);
};

export default {
    getAuthToken,
    getInitialCredentials,
};
