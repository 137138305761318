import * as qs from 'qs';
import urlJoin from 'url-join';

import arrayUtils from './arrayUtils';

const join = (...params) => urlJoin(params);

const parseQueryString = (query) => qs.parse(query, {ignoreQueryPrefix: true});

const stringify = (params) => qs.stringify(params);

const getDataFromUrl = (...keys) => {
    const url = window.location.search;
    const parsedUrl = parseQueryString(url);
    const keysList = arrayUtils.toArray(keys);

    return keysList.map((key) => parsedUrl[key]);
};

const getOriginLocation = () => window.location.origin;
const getCurrentLocation = () => window.location.href;

const updateParams = (url, params, splitter = '&') => {
    const paramsArray = Array.isArray(params) ? params : [params];

    const isEntryDataValid = typeof url === 'string' && url && paramsArray.length;

    if (isEntryDataValid) {
        const splittedUrl = url.split(splitter);

        paramsArray.forEach((param) => {
            const {name, value, separator = '='} = param || {};

            const matchedParam = splittedUrl.find((urlParam) => urlParam.split(separator)[0] === name);

            if (matchedParam) {
                let matchedParamEdited = matchedParam.split('=');

                matchedParamEdited.pop();
                matchedParamEdited.push(value);
                matchedParamEdited = matchedParamEdited.join('=');

                const matchedParamIndex = splittedUrl.indexOf(matchedParam);

                if (matchedParamIndex !== -1) {
                    splittedUrl[matchedParamIndex] = matchedParamEdited;
                }
            } else {
                splittedUrl.push(`${name}=${value}`);
            }
        });
        return splittedUrl.join(splitter);
    } else {
        return url;
    }
};

export default {
    getCurrentLocation,
    getDataFromUrl,
    getOriginLocation,
    join,
    parseQueryString,
    stringify,
    updateParams,
};
