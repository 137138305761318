import React, {useEffect} from 'react';

import imgPersonalizationIcon1 from '../../../assets/images/tutorials/pairing/personalization-icon.png';
import imgPersonalizationIcon2 from '../../../assets/images/tutorials/pairing/personalization-icon@2x.png';
import imgPersonalizationIcon3 from '../../../assets/images/tutorials/pairing/personalization-icon@3x.png';
import imgSupportIcon1 from '../../../assets/images/tutorials/pairing/support-icon.png';
import imgSupportIcon2 from '../../../assets/images/tutorials/pairing/support-icon@2x.png';
import imgSupportIcon3 from '../../../assets/images/tutorials/pairing/support-icon@3x.png';
import Button from '../../components/Button/Button';
import ButtonBorder from '../../components/Button/ButtonBorder/ButtonBorder';
import Container from '../../components/Layout/Container/Container';
import * as localizationKeys from '../../consts/app/localizationKeys';
import useHidePage from '../../hooks/effects/useHidePage';
import analyticsService from '../../services/analyticsService';
import {getLocalizedStrings} from '../../services/localization/localizationService';
import helpers from '../../utils/helpers';
import IconTextRow from './components/IconTextRow';
import styles from './PairingSkipPopup.module.scss';

const PairingSkipPopup = ({onTryAgain, onSkip}) => {
    useEffect(() => analyticsService.pushPairingQuitSetupEvent(), []);
    const onSkipBtnClick = () => helpers.runFunction(onSkip);

    const featuresIconSet = [imgPersonalizationIcon1, imgPersonalizationIcon2, imgPersonalizationIcon3];
    const supportIconSet = [imgSupportIcon1, imgSupportIcon2, imgSupportIcon3];
    const localizedStrings = getLocalizedStrings();
    useHidePage();

    return (
        <div className={styles.Page}>
            <Container className={styles.Container}>
                <section>
                    <h2 className={styles.Title}>{localizedStrings[localizationKeys.QUIT_SET_UP_TITLE_TEXT]}</h2>
                    <p className={`${styles.Description} ica--txt-regular`}>
                        {localizedStrings[localizationKeys.QUIT_SET_UP_DESCRIPTION_TEXT]}
                    </p>
                    <IconTextRow
                        img={featuresIconSet}
                        imgClassName={styles.ImageSmall}
                        text={localizationKeys.QUIT_SET_UP_EXTRA_FEATURES_DESCRIPTION_TEXT}
                    />
                    <IconTextRow
                        img={supportIconSet}
                        text={localizationKeys.QUIT_SET_UP_TROUBLESHOOT_DESCRIPTION_TEXT}
                    />
                </section>
                <div className={styles.Footer}>
                    <ButtonBorder className={styles.ButtonAgree} onClick={onTryAgain} showArrow>
                        {localizedStrings[localizationKeys.QUIT_SET_UP_CONFIRM_BUTTON_TEXT]}
                    </ButtonBorder>
                    <Button className={styles.ButtonDecline} onClick={onSkipBtnClick} showUnderline>
                        {localizedStrings[localizationKeys.QUIT_SET_UP_SKIP_BUTTON_TEXT]}
                    </Button>
                </div>
            </Container>
        </div>
    );
};

export default PairingSkipPopup;
