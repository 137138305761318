import ConfigClient from './configClient';

const marketConfigsList = [
    {
        urls: [`https://arm.iqos-connect-stg.pnnproducts.com/`],
        configImport: () => import('../marketConfigs/uat/stg-web'),
    },
];

export default class ConfigClientStg extends ConfigClient {
    constructor() {
        super(marketConfigsList);
    }
}
