import React, {useState} from 'react';

import userDeviceService from '../../services/userDeviceService';
import helpers from '../../utils/helpers';
import Container from '../Layout/Container/Container';
import FixedContainer from '../Layout/FixedContainer/FixedContainer';
import Overlay from '../Layout/Overlay/Overlay';
import styles from './Popup.module.scss';
import PopupCloseButton from './PopupCloseButton/PopupCloseButton';

const Popup = ({children, className, fixedContainerClassName, onClose, isClosingBySwipeEnabled, testId}) => {
    const [isPopupClosing, setIsPopupClosing] = useState(false);
    const SWIPE_DELTA_TO_START_CLOSING = 30;
    const isTouchSupported = userDeviceService.isTouchSupported();
    const isClosingByTouch = isTouchSupported && isClosingBySwipeEnabled;
    const popupContainerClassName = `${styles.PopupContainer} ${isPopupClosing ? styles.PopupOnClose : ''}`;
    const popupClassName = `${styles.Popup} ${isClosingByTouch ? styles.PopupTouchCloseLine : ''} ${
        className ? className : ''
    }`;
    const overlayClassName = isPopupClosing ? styles.OverlayOnPopupClose : '';
    const isCloseFunction = typeof onClose === 'function';
    let touchYStart = 0;
    let touchYEnd = 0;

    const onTouchStart = (e) => {
        if (isClosingByTouch) {
            touchYStart = e.changedTouches[0].pageY;
        }
    };

    const onTouchMove = (e) => {
        if (isClosingByTouch) {
            touchYEnd = e.changedTouches[0].pageY;

            const delta = touchYEnd - touchYStart;

            if (delta >= SWIPE_DELTA_TO_START_CLOSING && onClose) {
                onPopupClose();
            }
        }
    };

    const onPopupCloseTransitionEnd = () => {
        if (isPopupClosing) {
            helpers.runFunction(onClose);
            setIsPopupClosing(false);
        }
    };

    const onPopupClose = () => {
        if (onClose) {
            setIsPopupClosing(true);
        }
    };

    return (
        <FixedContainer className={fixedContainerClassName}>
            <Overlay onClick={onPopupClose} className={overlayClassName} />
            <Container className={styles.PopupsContainer}>
                <div
                    className={popupContainerClassName}
                    onTransitionEnd={onPopupCloseTransitionEnd}
                    data-testid={testId}
                >
                    <div className={styles.PopupTopMargin} onClick={onClose} />
                    <div
                        className={popupClassName}
                        onTouchMove={(e) => onTouchMove(e)}
                        onTouchStart={(e) => onTouchStart(e)}
                    >
                        {isCloseFunction && <PopupCloseButton onClick={onPopupClose} />}
                        {children}
                    </div>
                </div>
            </Container>
        </FixedContainer>
    );
};

export default Popup;
