const unique = (a) => [...new Set(a)];

const toArray = (a) => (a ? (Array.isArray(a) ? a : [a]) : []);

const splitArray = (input, itemsPerChunk) => {
    return input.reduce((result, item, index) => {
        const chunkIndex = Math.floor(index / itemsPerChunk);

        if (!result[chunkIndex]) {
            result[chunkIndex] = [];
        }

        result[chunkIndex].push(item);

        return result;
    }, []);
};

const complementArray = (initialArray, complementToCount = 4) => {
    const LENGTH_TO_DOUBLE = 2;
    const isArray = Array.isArray(initialArray);
    const arrayLength = initialArray?.length;
    const isValid = isArray && arrayLength < complementToCount;

    if (isValid) {
        if (arrayLength === LENGTH_TO_DOUBLE) {
            const arrayToCheck = [...initialArray];
            const connectedDevice = arrayToCheck.find((element) => element?.isIotProduct);
            const connectedDeviceIndex = arrayToCheck.lastIndexOf(connectedDevice);

            if (connectedDevice) {
                arrayToCheck.splice(connectedDeviceIndex, 1);
            }

            return [...initialArray, ...arrayToCheck];
        } else {
            const arrayLastElement = initialArray[arrayLength - 1];

            return [arrayLastElement, ...initialArray];
        }
    }

    return initialArray;
};

export default {
    complementArray,
    splitArray,
    toArray,
    unique,
};
