import appConfig from '../../config/appConfig';
import {setYapDeviceConnected} from '../../state/ducks/yapEncrypted';
import {makeSelectIsYapDeviceConnected} from '../../state/selectors/yapEncrypted';
import {dispatch, getState} from '../../state/store';
import helpers from '../../utils/helpers';
import backendService from '../app/backendService';
import gamClientService from '../gam/gamClientService';
import log from '../logger/log';
import uamClientService from '../uam/uamClientService';
import CmClient from './cmClient';
import cmMessageRequestService from './cmMessageRequestService';
import cmMessageResponseService from './cmMessageResponseService';

let cmReconnectTimeout;
const clearReconnectTimeout = () => clearTimeout(cmReconnectTimeout);

const initCmClient = async (assetId) => {
    clearReconnectTimeout();

    if (isCmClientConnected(assetId)) {
        const isYapDeviceConnected = makeSelectIsYapDeviceConnected()(getState());
        if (!isYapDeviceConnected) {
            cmMessageRequestService.publishInitializationEvent();
        }

        return;
    }

    log.info('Connect to CM is started.');

    const onError = () => {
        const RECONNECT_TIMEOUT_MS = 5000;

        disconnectCmClient();
        cmReconnectTimeout = setTimeout(() => initCmClient(assetId), RECONNECT_TIMEOUT_MS);
    };

    let cmSettings;
    if (backendService.isGamBackend()) {
        cmSettings = await gamClientService.connectGamDevice(assetId);
    } else {
        cmSettings = await uamClientService.connectDevice(assetId);
    }

    if (cmSettings) {
        const cmClient = new CmClient(assetId, true, cmSettings);

        cmClient.attachCloseHandler(async (e) => {
            dispatch(setYapDeviceConnected(false));

            log.info(`CmClient: connection lost. ErrorCode: ${e.errorCode}`);
            switch (e.errorCode) {
                case 0:
                    //forced close from code
                    break;
                case 5:
                    //unhandled error from paho. ignored
                    break;
                default:
                    log.info(`CmClient: Try reconnect on ErrorCode: ${e.errorCode}`);
                    onError();
                    break;
            }
        });

        cmClient.attachConnectHandler(() => {
            subscribeOnMessage(cmSettings.topicSubscribe);
        });

        cmClient.connect();
    } else {
        onError();
    }
};

const disconnectCmClient = () => {
    const cmClient = new CmClient();

    clearReconnectTimeout();
    cmClient.disconnect();
};

const subscribeOnMessage = (topicSubscribe) => {
    const cmClient = new CmClient();

    cmClient.subscribe(topicSubscribe);

    cmClient.attachMessageHandler((message) => {
        log.info(`CmClient: new message: ${message.payloadString}`);
        const mes = JSON.parse(message.payloadString);
        cmMessageResponseService.onCmMessage(mes);
    });

    cmMessageRequestService.publishInitializationEvent();
};

const publishMessage = (message) => {
    new CmClient().publish(JSON.stringify(message));
};

const isCmClientConnected = (assetId) => new CmClient().isConnected(assetId);

const waitUntilConnected = async (timer = appConfig.getCmTimeout()) => {
    const isYapDeviceConnected = makeSelectIsYapDeviceConnected()(getState());
    if (isYapDeviceConnected) return true;

    if (timer-- < 0) {
        log.error('CM Client. CONNECTED status does not arrive from uam websocket.');
        return false;
    }

    await helpers.timeout(1000);
    return await waitUntilConnected(timer);
};

export default {
    initCmClient,
    publishMessage,
    disconnectCmClient,
    waitUntilConnected,
    isCmClientConnected,
};
