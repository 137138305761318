import classnames from 'classnames';
import React from 'react';

import imgTaTP4Mobile3 from '../../../../../assets/images/tips-tutorials/p4-mobile.png';
import ButtonText from '../../../../components/Button/ButtonText/ButtonText';
import Image from '../../../../components/Image/Image';
import appConfig from '../../../../config/appConfig';
import * as localizationKeys from '../../../../consts/app/localizationKeys';
import appRouterService from '../../../../services/appRouterService';
import layoutService from '../../../../services/layoutService';
import {getLocalizedStrings} from '../../../../services/localization/localizationService';
import elementsTesIds from '../../../../test/consts/elementsTesIds';
import styles from './TipsTutorialsSection.module.scss';

const TipsTutorialsSection = (props) => {
    const {layout} = props;
    const isMobileLayout = layoutService.isMobileLayout(layout);
    const enableP1Support = appConfig.getEnableP1Support();

    const onTipsButtonClick = () => appRouterService.forwardToTutorialList();
    const localizedStrings = getLocalizedStrings();

    const tipsHeaderClassname = classnames(styles.TipsHeader, {
        [styles.TipsHeaderWhite]: isMobileLayout,
    });
    const tipsMoreClassname = classnames(styles.TipsMore, {[styles.TipsMoreWhite]: isMobileLayout});

    return (
        <section className={styles.TipsTutorialsContainer}>
            <div className={styles.SettingsHeader}>
                {localizedStrings[localizationKeys.DEVICE_SETTINGS_USING_HEADER]}
            </div>
            {isMobileLayout && (
                <>
                    {enableP1Support ? (
                        <Image src={[imgTaTP4Mobile3]} className={styles.TipsImage} />
                    ) : (
                        <Image src={[imgTaTP4Mobile3]} className={styles.TipsImage} />
                    )}
                    <h3 className={tipsHeaderClassname} onClick={onTipsButtonClick}>
                        {localizedStrings[localizationKeys.DEVICE_SETTINGS_TIPS]}
                    </h3>
                    <div className={tipsMoreClassname}>
                        <ButtonText
                            onClick={onTipsButtonClick}
                            showArrow
                            testId={elementsTesIds.DEVICE_SETTINGS_BTN_TIPS_TRICKS_MORE}
                        >
                            {localizedStrings[localizationKeys.DEVICE_SETTINGS_TIPS_MORE]}
                        </ButtonText>
                    </div>
                </>
            )}
        </section>
    );
};

export default TipsTutorialsSection;
