export const SET_YAP_ASSETS = 'app/yapEncrypted/SET_YAP_ASSETS';
export const SET_YAP_ACTIVATION_IN_PROGRESS = 'app/yapEncrypted/SET_YAP_ACTIVATION_IN_PROGRESS';
export const SET_YAP_DEVICE_CONNECTED = 'app/yapEncrypted/SET_YAP_DEVICE_CONNECTED';
export const SET_YAP_LAST_REQUEST_ID = 'app/yapEncrypted/SET_YAP_LAST_REQUEST_ID';
export const SET_YAP_LAST_CHECK_REQUEST_DATE = 'app/yapEncrypted/SET_YAP_LAST_CHECK_REQUEST_DATE';
export const SET_YAP_SYNC_IN_PROGRESS = 'app/yapEncrypted/SET_YAP_SYNC_IN_PROGRESS';

const initialData = {
    yapAssets: undefined,
    isYapActivationInProgress: false,
    isYapDeviceConnected: false,
    lastRequestId: undefined,
};

export default function reducer(state = initialData, action) {
    switch (action.type) {
        case SET_YAP_ASSETS: {
            return {
                ...state,
                yapAssets: action.payload,
            };
        }
        case SET_YAP_ACTIVATION_IN_PROGRESS: {
            let result = state;
            if (!action.payload) {
                //clear requestId if activation process is ended
                result = {
                    ...result,
                    lastRequestId: undefined,
                };
            }

            return {
                ...result,
                isYapActivationInProgress: action.payload,
            };
        }
        case SET_YAP_SYNC_IN_PROGRESS: {
            return {
                ...state,
                isYapSyncInProgress: action.payload,
            };
        }
        case SET_YAP_DEVICE_CONNECTED: {
            return {
                ...state,
                isYapDeviceConnected: action.payload,
            };
        }
        case SET_YAP_LAST_REQUEST_ID: {
            return {
                ...state,
                lastRequestId: action.payload,
            };
        }
        case SET_YAP_LAST_CHECK_REQUEST_DATE: {
            return {
                ...state,
                lastCheckRequestDate: action.payload,
            };
        }
        default:
            return state;
    }
}

export const setYapAssets = (data) => ({type: SET_YAP_ASSETS, payload: data});

export const setYapActivationInProgress = (data) => ({type: SET_YAP_ACTIVATION_IN_PROGRESS, payload: data});

export const setYapSyncInProgress = (data) => ({type: SET_YAP_SYNC_IN_PROGRESS, payload: data});

export const setYapDeviceConnected = (data) => ({type: SET_YAP_DEVICE_CONNECTED, payload: data});

export const setYapLastRequestId = (data) => ({type: SET_YAP_LAST_REQUEST_ID, payload: data});

export const setYapLastCheckRequestDate = (data) => ({type: SET_YAP_LAST_CHECK_REQUEST_DATE, payload: data});
