import {addMiddleware} from 'redux-dynamic-middlewares';
import {logger} from 'redux-logger';

import log from '../services/logger/log';
import appConfig from './appConfig';
import configService from './configService';
import configTemplate from './marketConfigs/configTemplate';

const config = {...configTemplate};

const initConfig = async (market) => {
    const configData = await configService.getConfigData(market);

    config.data = {...config.data, ...configData};
};

const customInitConfig = async (countryCode) => {
    await initConfig(countryCode);

    log.setLevel(appConfig.getLogLevel(), appConfig.getAwsLogLevel());
    if (appConfig.getIsDebug()) {
        addMiddleware(logger);
    }
};

export {config, initConfig, customInitConfig};
