import formatTokenKeys from '../consts/app/formatTokenKeys';
import stringUtils from '../utils/stringUtils';
import {getInitialLanguageCode} from './localization/localizationService';
import marketTypeService from './marketTypeService';

const getLanguageFormattedString = (str) => {
    if (marketTypeService.isDCE1()) {
        const language = getInitialLanguageCode();

        return formatStringWithLanguage(str, language.toLowerCase());
    }

    return str;
};

const formatStringWithLanguage = (str, language) =>
    stringUtils.formatByName(str, {[formatTokenKeys.CULTURE_CODE]: language});

export default {getLanguageFormattedString, formatStringWithLanguage};
