import React from 'react';

import Button from '../../../../components/Button/Button';
import ButtonDark from '../../../../components/Button/ButtonDark/ButtonDark';
import PopupInfo from '../../../../components/Popup/PopupInfo';
import * as localizationKeys from '../../../../consts/app/localizationKeys';
import {getLocalizedStrings} from '../../../../services/localization/localizationService';
import elementsTesIds from '../../../../test/consts/elementsTesIds';
import styles from './DeviceLockPopups.module.scss';

const DeviceLockedPopup = ({onClose, onUnlockDevice}) => {
    const localizedStrings = getLocalizedStrings();

    return (
        <PopupInfo onClose={onClose}>
            <h2 className={styles.PopupHeaderTitle}>{localizedStrings[localizationKeys.UNLOCK_POPUP_TITLE]}</h2>
            <div className={`${styles.PopupDescription} ica--txt-regular`}>
                {localizedStrings[localizationKeys.UNLOCK_POPUP_TITLE_DESCRIPTION]}
            </div>
            <ButtonDark
                className={styles.Button}
                onClick={onUnlockDevice}
                showArrow
                testId={elementsTesIds.UNLOCK_POPUP_BTN_UNLOCK}
            >
                {localizedStrings[localizationKeys.UNLOCK_POPUP_UNLOCK_BUTTON_TEXT]}
            </ButtonDark>
            <Button showUnderline onClick={onClose} testId={elementsTesIds.UNLOCK_POPUP_BTN_CANCEL}>
                {localizedStrings[localizationKeys.UNLOCK_POPUP_CANCEL_BUTTON_TEXT]}
            </Button>
        </PopupInfo>
    );
};

export default DeviceLockedPopup;
