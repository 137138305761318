import appConfig from '../config/appConfig';
import {customInitConfig} from '../config/config';
import {initDataConfig} from '../config/dataConfig/dataConfig';
import externalConfigService from '../config/externalConfigService';
import urlUtils from '../utils/urlUtils';
import backendService from './app/backendService';
import appRouterService from './appRouterService';
import authDataService from './auth/authDataService';
import authService from './auth/authService';
import domService from './domService';
import gamService from './gam/gamService';
import havService from './havService';
import iccMarketService from './icc/iccMarketService';
import {initLocalizations} from './localization/localizationService';
import log from './logger/log';
import marketService from './marketService';
import marketTypeService from './marketTypeService';
import storageService from './storage/storageService';
import userDeviceService from './userDeviceService';
import userSessionService from './userSessionService';

const onAppStart = async (isRememberMeEnabled, languageCode) => {
    try {
        await initDataConfig();
        if (!isRememberMeEnabled) {
            await authService.login();
        }
        await onAppInit();
        await initLocalizations(languageCode);
        if (!isRememberMeEnabled) {
            await havService.verifyHav();
        }
    } catch (e) {
        log.error(`appService: onAppStart, error: ${e}`);
    }
};

const initializeUserSessionActions = async () => {
    const marketCodeFromLocalStorage = storageService.getMarketCodeFromLocalStorage();
    const accessTokenFromLocalStorage = storageService.getAccessTokenFromStorage();

    if (marketCodeFromLocalStorage && accessTokenFromLocalStorage) {
        await customInitConfig(marketCodeFromLocalStorage);
        await externalConfigService.initExternalConfig();

        const isRememberMeEnabled = appConfig.getIsRememberMeEnabled();

        if (isRememberMeEnabled) {
            const languageCodeFromLocalStorage = storageService.getLanguageCodeFromLocalStorage();

            await onAppStart(isRememberMeEnabled, languageCodeFromLocalStorage);

            await iccMarketService.getConsumerAggregatedSettings();
            appRouterService.forwardToTNCTermsOfUsePage();
        } else {
            appRouterService.forwardToSelectMarketPage();
        }
    } else {
        appRouterService.forwardToSelectMarketPage();
    }
};

const initializeStartActions = async () => {
    const isRememberMeEnabled = appConfig.getIsRememberMeEnabled();
    if (isRememberMeEnabled) {
        const marketCodeFromLocalStorage = storageService.getMarketCodeFromLocalStorage();
        const accessTokenFromStorage = storageService.getAccessTokenFromStorage();

        const currentMarket = marketService.currentMarket();

        if (marketCodeFromLocalStorage?.toLowerCase() === currentMarket && accessTokenFromStorage) {
            await initializeUserSessionActions();
        } else {
            await onAppStart();

            const {spiceToken} = authDataService.getInitialCredentials();

            if (spiceToken) {
                userSessionService.setUserSessionLocalization();
            }
            await externalConfigService.initExternalConfig();

            appRouterService.forwardToBrowserVerificationPage();
        }
    } else {
        await onAppStart();
        await externalConfigService.initExternalConfig();
        appRouterService.forwardToBrowserVerificationPage();
    }
};

const onAppInit = async () => {
    log.info(`appService: app is initialized, ua: ${userDeviceService.getUserAgent()}`);

    if (backendService.isGamBackend()) {
        await gamService.initGam();
    }
};

const getAppBaseUrl = () => {
    const baseName = getAppBaseName();
    const originLocation = urlUtils.getOriginLocation();
    const baseUrl = urlUtils.join(originLocation, baseName);

    return baseUrl;
};

const getAppBaseName = () => {
    const baseName = appConfig.getBaseName();

    if (process.env.REACT_APP_IS_BUILD_REF === 'true') {
        if (marketTypeService.isDCE1()) {
            const language = domService.getDataLanguageFromRoot();

            if (language) {
                const fullBaseName = urlUtils.join(language, baseName);

                return fullBaseName;
            }
        }
    }
    return baseName;
};

export default {
    getAppBaseUrl,
    initializeStartActions,
    onAppInit,
    initializeUserSessionActions,
    getAppBaseName,
};
