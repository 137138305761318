import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {Route} from 'react-router-dom';
import {createStructuredSelector} from 'reselect';

import * as routePaths from '../../consts/route/routePaths';
import appRouterService from '../../services/appRouterService';
import tncService from '../../services/tncService';
import {makeSelectAggregatedSettings} from '../../state/selectors/consumer';
import {makeSelectTncPopupCodesList} from '../../state/selectors/tnc';
import DataCollectionPage from './DataCollectionPage/DataCollectionPage';
import PreferenceCenterPage from './DataCollectionPage/PreferenceCenter/PreferenceCenterPage';
import TermsOfUsePage from './TermsOfUsePage/TermsOfUsePage';

const mapStateToProps = createStructuredSelector({
    tncPopupCodesList: makeSelectTncPopupCodesList(),
    aggregatedSettings: makeSelectAggregatedSettings(),
});

const TNC = (props) => {
    const {tncPopupCodesList, aggregatedSettings} = props;
    const {allNonMandatoryTermsReviewed, allMandatoryTermsApproved} = aggregatedSettings;
    const [isTncLoaded, setIsTncLoaded] = useState(false);

    useEffect(() => {
        if (isTncLoaded) {
            if (!allMandatoryTermsApproved) {
                appRouterService.forwardToTNCTermsOfUsePage();
            } else if (!allNonMandatoryTermsReviewed) {
                appRouterService.forwardToTNCDataCollectionPage();
            } else {
                appRouterService.forwardToMyDevicesPage();
            }
        }
    }, [allMandatoryTermsApproved, allNonMandatoryTermsReviewed, isTncLoaded]);

    const onFinally = () => {
        setIsTncLoaded(true);
    };

    useEffect(() => {
        tncService.getTncData(onFinally);
    }, []);

    return (
        !tncPopupCodesList?.length && (
            <>
                <Route path={routePaths.TNC_TERMS_OF_USE} component={TermsOfUsePage} />
                <Route path={routePaths.TNC_DATA_COLLECTION} component={DataCollectionPage} />
                <Route path={routePaths.TNC_DATA_PREFERENCE} component={PreferenceCenterPage} />
            </>
        )
    );
};

export default connect(mapStateToProps)(TNC);
