import * as iotMessageStatusTypes from '../../consts/iot/iotMessageStatusTypes';
import * as iotMessageTypes from '../../consts/iot/iotMessageTypes';
import {setMessage} from '../../state/ducks/iotDevice/actions';
import {dispatch} from '../../state/store';
import helpers from '../../utils/helpers';

//all data is mocked
const publishConnectionCareMessage = () => {
    const message = {
        type: iotMessageTypes.CONNECTION_CARE,
    };
    publishUiMessage(message);
};

const publishVibrationAlarmMessage = () => {
    const message = {
        type: iotMessageTypes.VIBRATION_ALARM,
    };

    publishUiMessage(message);
};

const publishStopVibrationAlarmMessage = () => {
    const message = {
        type: iotMessageTypes.VIBRATION_ALARM,
    };

    publishUiMessage(message);
};

const publishParentProtectionEnableMessage = () => {
    publishParentProtectionStatusMessage();
};

const publishParentProtectionDisableMessage = () => {
    publishParentProtectionStatusMessage();
};

const publishParentProtectionStatusMessage = () => {
    const message = {
        type: iotMessageTypes.PARENT_PROTECTION,
    };

    publishUiMessage(message);
};

const publishResetMessage = () => {
    const message = {
        type: iotMessageTypes.RESET,
    };

    publishUiMessage(message);
};

const publishCheckFirmwareMessage = () => {
    const message = {
        type: iotMessageTypes.FIRMWARE_UPDATE,
        status: iotMessageStatusTypes.CHECK,
    };

    publishUiMessage(message);
};

const publishStartUpdateFirmwareMessage = () => {
    const message = {
        type: iotMessageTypes.FIRMWARE_UPDATE,
        status: iotMessageStatusTypes.STARTED,
    };

    publishUiMessage(message);
};

const publishGetLedSettingsMessage = () => publishLedSettingsMessage();

const publishGetVibrationSettingsMessage = () => publishVibrationSettingsMessage();

const publishGetResponsiveDrawSettingsMessage = () => publishResponsiveDrawSettingsMessage();

const publishGetVapeCloudSizeSettingsMessage = () => publishVapeCloudSizeSettingsMessage();

const publishSetLedSettingsMessage = () => publishLedSettingsMessage();

const publishSetResponsiveDrawSettingsMessage = () => publishResponsiveDrawSettingsMessage();

const publishSetVapeCloudSizeSettingsMessage = () => publishVapeCloudSizeSettingsMessage();

const publishSetVibrationSettingsMessage = () => publishVibrationSettingsMessage();

const publishResetVibrationSettingsMessage = () => publishVibrationSettingsMessage();

const publishResponsiveDrawSettingsMessage = () => {
    const message = {
        type: iotMessageTypes.RESPONSIVE_DRAW_PROFILES,
    };

    publishUiMessage(message);
};

const publishVapeCloudSizeSettingsMessage = () => {
    const message = {
        type: iotMessageTypes.VAPE_CLOUD_SIZE,
    };

    publishUiMessage(message);
};

const publishLedSettingsMessage = () => {
    const message = {
        type: iotMessageTypes.LED,
    };

    publishUiMessage(message);
};

const publishVibrationSettingsMessage = () => {
    const message = {
        type: iotMessageTypes.VIBRATION,
    };

    publishUiMessage(message);
};

const publishCleaningMessage = () => {
    const message = {
        type: iotMessageTypes.CLEANING,
        status: iotMessageStatusTypes.STARTED,
    };

    publishUiMessage(message);
};

const publishHolderStatusMessage = () => {
    const message = {
        type: iotMessageTypes.HOLDER_STATUS,
        status: iotMessageStatusTypes.STARTED,
    };

    publishUiMessage(message);
};

let isSetup = false;
const publishUiMessage = async (message) => {
    if (isSetup) {
        await helpers.timeout(1000);
        return publishUiMessage(message);
    }

    isSetup = true;
    dispatch(setMessage(message));
    setTimeout(() => {
        message.status = iotMessageStatusTypes.FINISHED;
        dispatch(setMessage(message));
        isSetup = false;
    }, 1000);
};

export default {
    publishCheckFirmwareMessage,
    publishCleaningMessage,
    publishConnectionCareMessage,
    publishGetLedSettingsMessage,
    publishGetVibrationSettingsMessage,
    publishHolderStatusMessage,
    publishParentProtectionDisableMessage,
    publishParentProtectionEnableMessage,
    publishParentProtectionStatusMessage,
    publishResetMessage,
    publishResetVibrationSettingsMessage,
    publishSetLedSettingsMessage,
    publishSetVibrationSettingsMessage,
    publishStartUpdateFirmwareMessage,
    publishStopVibrationAlarmMessage,
    publishVibrationAlarmMessage,
    publishGetResponsiveDrawSettingsMessage,
    publishSetResponsiveDrawSettingsMessage,
    publishGetVapeCloudSizeSettingsMessage,
    publishSetVapeCloudSizeSettingsMessage,
};
