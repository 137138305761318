import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';

import ActivationSkipBtn from '../../components/ActivationSkipBtn/ActivationSkipBtn';
import Container from '../../components/Layout/Container/Container';
import CommonLoaderScreen from '../../components/Loader/CommonLoaderScreen';
import appConfig from '../../config/appConfig';
import * as localizationKeys from '../../consts/app/localizationKeys';
import * as yapModeTypes from '../../consts/yap/yapModeTypes';
import useHttpRegistrationErrorHandler from '../../hooks/effects/httpErrorsHandlers/useHttpRegistrationErrorHandler';
import appRouterService from '../../services/appRouterService';
import {getLocalizedStrings} from '../../services/localization/localizationService';
import productService from '../../services/productService';
import {makeSelectIotDeviceMergedWithIccProduct} from '../../state/selectors/consumer';
import {makeSelectIsDeviceConnected} from '../../state/selectors/iotDevice';
import elementsTesIds from '../../test/consts/elementsTesIds';
import RegistrationFailedPopup from './components/RegistrationFailedPopup';
import styles from './DeviceRegistrationPage.module.scss';

const mapStateToProps = createStructuredSelector({
    iotDevice: makeSelectIotDeviceMergedWithIccProduct(),
    isIotDeviceConnected: makeSelectIsDeviceConnected(),
});

const DeviceRegistrationPage = (props) => {
    const {iotDevice, isIotDeviceConnected} = props;
    const {device} = iotDevice || {};

    const {name, status, isP4} = device;
    const isCurrentProductRegistered = productService.isStatusRegistered(status);
    const [isVisibleRegistrationFailedPopup, setIsVisibleRegistrationFailedPopup] = useState(false);
    const [httpErrorType, setHttpErrorType] = useState(null);
    const isYapMode = appConfig.getYapMode() !== yapModeTypes.YAP_DISABLED_MODE;

    useEffect(() => {
        if (!isCurrentProductRegistered) {
            productService.registerConsumerProduct(iotDevice, name);
        }
    }, []);

    useEffect(() => {
        if (!isIotDeviceConnected) {
            //TODO: IA - isIotDeviceConnected?
            productService.fetchProductsStatusAndCheckIotProductStatus();
        }
    }, [isIotDeviceConnected]);

    useEffect(() => {
        if (isCurrentProductRegistered) {
            appRouterService.forwardToDeviceActivationPage();
        }
    }, [isCurrentProductRegistered]);

    useHttpRegistrationErrorHandler({
        handler: (errorType) => {
            setHttpErrorType(errorType);
            setIsVisibleRegistrationFailedPopup(true);
        },
    });

    if (!device) return null;

    const onCloseRegistrationFailedPopup = () => {
        setIsVisibleRegistrationFailedPopup(false);
        if (isYapMode && isP4) {
            appRouterService.forwardToMyDevicesPage();
        }
    };

    const localizedStrings = getLocalizedStrings();
    const header = localizedStrings[localizationKeys.YAP_REGISTERING];
    const description =
        localizedStrings[localizationKeys.YAP_ACTIVATION_REGISTRATION_STATE_ACTIVATION_FLOW_PROGRESS_TEXT];

    return (
        <section className={styles.Page}>
            <Container className={styles.Container}>
                <ActivationSkipBtn />
                <div className={styles.CommonLoaderContainer}>
                    <CommonLoaderScreen
                        description={description}
                        header={header}
                        headerTestId={elementsTesIds.ACTIVATION_FLOW_REGISTERING_TITLE}
                        iotDevice={iotDevice}
                        isBig
                    />
                </div>
                {isVisibleRegistrationFailedPopup && (
                    <RegistrationFailedPopup httpErrorType={httpErrorType} onClose={onCloseRegistrationFailedPopup} />
                )}
            </Container>
        </section>
    );
};

export default connect(mapStateToProps)(DeviceRegistrationPage);
