import React from 'react';

import ButtonRaw from '../../../components/Button/ButtonRaw';
import Image from '../../../components/Image/Image';
import styles from './SettingsOptions/SettingsOptions.module.scss';

const DeviceSettingsItem = (props) => {
    const {children, imgUrls, onClick, deviceColor, disabled, onDisableClick, testId} = props;
    const borderColor = deviceColor ? {borderColor: `#${deviceColor}`} : undefined;

    return (
        <ButtonRaw
            className={styles.DeviceSettingsItemContainer}
            disabled={disabled}
            onClick={onClick}
            onDisableClick={onDisableClick}
            style={borderColor}
            testId={testId}
        >
            <Image src={imgUrls} />
            <div className={'ica--txt-medium'}>{children}</div>
        </ButtonRaw>
    );
};

export default DeviceSettingsItem;
