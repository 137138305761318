import MqttClient from 'mqtt';

import log from '../logger/log';

let instance = null;

export default class IotClient {
    constructor(createNew = false, options) {
        if (createNew && instance) {
            instance.disconnect();
            instance = null;
        }

        if (instance) {
            return instance;
        }

        if (options) {
            instance = this;
            this.initClient(options);
            this.attachDebugHandlers();
        }
    }

    initClient(options = {}) {
        const {brokerUrl, ...restOpts} = options;

        this.client = MqttClient.connect(brokerUrl, restOpts);
    }

    disconnect() {
        if (this.client) {
            this.client.end();
        }
        instance = null;
    }

    attachDebugHandlers() {
        this.client.on('reconnect', () => {
            log.debug('IotClient: reconnect');
        });

        this.client.on('offline', () => {
            log.info('IotClient: offline');
        });

        this.client.on('error', (e) => {
            log.error(`IotClient: error: ${e}`);
        });

        this.client.on('message', (topic, message) => {
            log.debug(`IotClient: new message, topic: ${topic}, message: ${message}`);
        });
    }

    updateWebSocketCredentials(accessKeyId, secretKey, sessionToken) {
        this.client.updateWebSocketCredentials(accessKeyId, secretKey, sessionToken);
    }

    attachMessageHandler(onNewMessageHandler) {
        this.client.on('message', onNewMessageHandler);
    }

    attachConnectHandler(onConnectHandler) {
        this.client.on('connect', (connack) => {
            log.info(`IotClient: connected`);
            onConnectHandler(connack);
        });
    }

    attachErrorHandler(onErrorHandler) {
        this.client.on('error', (err) => {
            onErrorHandler(err);
        });
    }

    attachCloseHandler(onCloseHandler) {
        this.client.on('close', (err) => {
            log.info(`IotClient: closed`);
            onCloseHandler(err);
        });
    }

    publish(topic, message) {
        if (this.isClientInitialized()) {
            log.debug(`IotClient: publish message, topic: ${topic}, message: ${message})}`);
            this.client.publish(topic, message);

            return Promise.resolve();
        } else {
            return Promise.reject(new Error('IotClient is not initialized'));
        }
    }

    subscribe(topic) {
        log.info(`IotClient: subscribe on topic: ${topic}`);
        this.client.subscribe(topic);
    }

    unsubscribe(topic) {
        if (this.isClientInitialized()) {
            log.info(`IotClient: unsubscribe from topic: ${topic}`);
            this.client.unsubscribe(topic);
        }
    }

    isInitialized = () => !!instance;

    isClientInitialized = () => !!this.client;
}
