import appConfig from '../../config/appConfig';
import * as iotTopicTypes from '../../consts/iot/iotTopicTypes';
import {makeMwDeviceInternalId, makeMwDeviceIsIdentified} from '../../state/selectors/mwDevice';
import {getState} from '../../state/store';
import urlUtils from '../../utils/urlUtils';
import appErrorService from '../app/appErrorService';
import appInstanceService from '../appInstanceService';
// import awsAuthenticationService from '../aws/awsAuthenticationService';
import IoT from '../aws/awsIot';
import scpCharacteristicService from '../ble/scpCharacteristicService';
import localStorageClient from '../storage/localStorageClient';
import mwIotMessageRequestService from './mwIotMessageRequestService';
import mwIotMessageResponseService from './mwIotMessageResponseService';
import uiIotMessageResponseService from './uiIotMessageResponseService';
let iotReconnectTimeout;

const clearReconnectTimeout = () => clearTimeout(iotReconnectTimeout);

const initIoT = async () => {
    clearReconnectTimeout();

    const onError = () => {
        const RECONNECT_TIMEOUT_MS = 5000;

        disconnectIoT();
        iotReconnectTimeout = setTimeout(initIoT, RECONNECT_TIMEOUT_MS);
    };

    try {
        const mqttOptions = localStorageClient.getItem('mqttOptions');

        if (mqttOptions) {
            IoT.initNewClient(mqttOptions);
            IoT.attachErrorHandler(onError);

            IoT.attachConnectHandler(onIoTConnect);
        } else {
            onError();
            alert(`
                    Please set mqtt mqttOptions, e.g:
                    localStorage.setItem('mqttOptions', JSON.stringify({
                        brokerUrl: 'wss://mqtt-ws-iam-sandbox.mbedcloudintegration.net:443/mqtt',
                        clientId: 'clientId',
                        username: '{"key": "eyJhbGciOiJSUz..."}'
                    }));
            `);

            appErrorService.showGlobalErrorWithAppReset();
        }
    } catch (e) {
        appErrorService.showGlobalErrorWithAppReset();
    }
};

const onIoTConnect = () => {
    const isIdentified = makeMwDeviceIsIdentified()(getState());

    subscribeOnMessage();
    subscribeOnAppTopic();
    subscribeOnDeviceTopic();

    if (isIdentified) {
        scpCharacteristicService.processQueue();
        mwIotMessageRequestService.processMessageQueue();
    } else {
        mwIotMessageRequestService.clearMessageQueue();
        scpCharacteristicService.createNew();
        mwIotMessageRequestService.publishInitializationEvent();
    }
};

const reInitIoT = async () => {
    disconnectIoT();
    await initIoT();
};

//IA: only for arm solution
const resubscribe = (topic) => {
    unsubscribeFromTopic(topic);
    subscribeOnAppTopic();
};

const disconnectIoT = () => {
    clearReconnectTimeout();
    IoT.disconnect();
};

const subscribeOnAppTopic = (deviceSerialNumber) => {
    const topic = getTopicName(iotTopicTypes.PREFIX_UI, iotTopicTypes.SUFFIX_OUTPUT, deviceSerialNumber);

    subscribeOnTopic(topic);
};

const subscribeOnDeviceTopic = (deviceSerialNumber) => {
    const topic = getTopicName(iotTopicTypes.PREFIX_MW, iotTopicTypes.SUFFIX_OUTPUT, deviceSerialNumber);

    subscribeOnTopic(topic);
};

const subscribeOnTopic = (topic) => {
    IoT.subscribe(topic);
};

const unsubscribeFromTopic = (topic) => {
    IoT.unsubscribe(topic);
};

const unsubscribeFromDeviceTopic = () => {
    const topic = getTopicName(iotTopicTypes.PREFIX_MW, iotTopicTypes.SUFFIX_OUTPUT);

    if (topic) {
        unsubscribeFromTopic(topic);
    }
};

const DEVICE_TOPIC_PREFIX_INDEX = 1;

const subscribeOnMessage = () => {
    IoT.attachMessageHandler((topic, message) => {
        const topicSplitted = topic.split('/');
        const messageObj = JSON.parse(message.toString());
        const isMwTopic = topicSplitted[DEVICE_TOPIC_PREFIX_INDEX] === iotTopicTypes.PREFIX_MW;

        if (isMwTopic) {
            mwIotMessageResponseService.onIotMessage(topic, messageObj);
        } else {
            uiIotMessageResponseService.onMessage(topic, messageObj);
        }
    });
};

const publishUiMessage = (message) => publishMessage(message, iotTopicTypes.PREFIX_UI);

const publishMwMessage = (message) => publishMessage(message, iotTopicTypes.PREFIX_MW);

const publishMessage = (message, topicAppType) => {
    const topic = getTopicName(topicAppType, iotTopicTypes.SUFFIX_INPUT);

    if (topic) {
        return IoT.publish(topic, JSON.stringify(message));
    } else {
        return Promise.reject(new Error('topic is null'));
    }
};

const getInternalId = () => makeMwDeviceInternalId()(getState());

const getTopicName = (topicAppType, topicSuffix, deviceSerialNumber) => {
    const appInstanceId = appInstanceService.getAppInstanceId();
    const topicInternalId = deviceSerialNumber || getInternalId();

    return topicInternalId
        ? urlUtils.join(
              appConfig.getScpCloudTopicPrefix(),
              topicAppType,
              appConfig.getScpCloudTopicMarket(),
              appConfig.getScpCloudVersion(),
              appInstanceId,
              topicInternalId,
              topicSuffix
          )
        : null;
};

export default {
    disconnectIoT,
    initIoT,
    publishMwMessage,
    publishUiMessage,
    reInitIoT,
    subscribeOnAppTopic,
    subscribeOnDeviceTopic,
    subscribeOnTopic,
    unsubscribeFromDeviceTopic,
    unsubscribeFromTopic,
    resubscribe,
};
