import React from 'react';

import imgErrorIcon1 from '../../../assets/images/icons/error-icon.png';
import imgErrorIcon2 from '../../../assets/images/icons/error-icon@2x.png';
import imgErrorIcon3 from '../../../assets/images/icons/error-icon@3x.png';
import Image from '../Image/Image';
import Popup from './Popup';
import styles from './Popup.module.scss';

const PopupError = (props) => {
    return (
        <Popup className={styles.PopupError} {...props}>
            <div className={styles.HeaderIcon}>
                <Image src={[imgErrorIcon1, imgErrorIcon2, imgErrorIcon3]} />
            </div>
            {props.children}
        </Popup>
    );
};

export default PopupError;
