import classnames from 'classnames';
import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';

import BackButton from '../../components/BackButton/BackButton';
import ButtonDark from '../../components/Button/ButtonDark/ButtonDark';
import DropdownList from '../../components/DropdownList/DropdownList';
import Container from '../../components/Layout/Container/Container';
import WheelPicker from '../../components/WheelPicker/WheelPicker';
import appConfig from '../../config/appConfig';
import * as localizationKeys from '../../consts/app/localizationKeys';
import appRouterService from '../../services/appRouterService';
import layoutService from '../../services/layoutService';
import {getLocalizedStrings} from '../../services/localization/localizationService';
import {setNotificationFailed} from '../../state/ducks/global';
import {makeSelectLayoutType} from '../../state/selectors/global';
import {dispatch} from '../../state/store';
import elementsTesIds from '../../test/consts/elementsTesIds';
import styles from './AgeGatePage.module.scss';
import ageGatePageConsts from './ageGatePageConsts';
import {getAgeGatePageData} from './ageGatePageData';
import ageGatePageService from './ageGatePageService';
import AgeGatePageIcon from './components/AgeGatePageIcon';

const mapStateToProps = createStructuredSelector({
    layout: makeSelectLayoutType(),
});

const DROPDOWN_LIST_DAY_TYPE = 'DROPDOWN_LIST_DAY_TYPE';
const DROPDOWN_LIST_MONTH_TYPE = 'DROPDOWN_LIST_MONTH_TYPE';
const DROPDOWN_LIST_YEAR_TYPE = 'DROPDOWN_LIST_YEAR_TYPE';
const DEFAULT_MIN_AGE_TO_VERIFY = '18';

const AgeGatePage = ({layout}) => {
    const localizedStrings = getLocalizedStrings();
    const isMobileDevice = layoutService.isMobileLayout(layout);

    const descriptionTextClassName = classnames(styles.Description, 'ica--txt-regular');
    const selectTextClassName = classnames(styles.SelectText, 'ica--txt-medium');

    const data = getAgeGatePageData(localizedStrings);

    const [userAgeData, setUserAgeData] = useState({
        month: ageGatePageConsts.DEFAULT_VALUE,
        year: ageGatePageConsts.DEFAULT_VALUE,
        day: ageGatePageConsts.DEFAULT_VALUE,
    });
    const [daysList, setDaysList] = useState(null);

    useEffect(() => updateDaysArray(), [userAgeData]);

    const updateDaysArray = () => {
        const daysArray = [];
        const daysCount = ageGatePageService.getDaysCount(userAgeData);

        daysArray.push({
            value: ageGatePageConsts.DEFAULT_VALUE,
            text: localizedStrings[localizationKeys.AGE_GATE_DAY],
        });

        for (let i = 1; i <= daysCount; i++) {
            daysArray.push({
                value: i,
                text: i,
            });
        }

        setDaysList(daysArray);
    };

    const onChange = (data, type) => {
        if (type) {
            const {value} = data;

            switch (type) {
                case DROPDOWN_LIST_DAY_TYPE:
                    setUserAgeData({
                        ...userAgeData,
                        day: value,
                    });
                    break;

                case DROPDOWN_LIST_MONTH_TYPE:
                    setUserAgeData({
                        ...userAgeData,
                        month: value,
                    });
                    break;

                case DROPDOWN_LIST_YEAR_TYPE:
                    setUserAgeData({
                        ...userAgeData,
                        year: value,
                    });
                    break;

                default:
                    setUserAgeData(null);
                    break;
            }
        } else {
            setUserAgeData({
                year: data?.year,
                month: data?.month,
            });
        }
    };

    const onContinueClick = () => {
        const isAgeAcceptable = ageGatePageService.isAgeAcceptable(userAgeData);

        if (isAgeAcceptable) {
            appRouterService.forwardToLegalDisclaimerPage();
        } else {
            dispatch(
                setNotificationFailed(
                    localizedStrings.formatString(
                        localizedStrings[localizationKeys.AGE_GATE_ERROR_NOTIFICATION_TEXT],
                        appConfig.getAgeGateMinAge() || DEFAULT_MIN_AGE_TO_VERIFY
                    )
                )
            );
        }
    };

    const onBackButtonClick = () => {
        appRouterService.forwardToAppIntroPage();
    };

    const pickerInitialState = [ageGatePageConsts.DEFAULT_VALUE, ageGatePageConsts.DEFAULT_VALUE];

    return (
        <div className={styles.Page}>
            <Container isFloatHeight>
                <BackButton testId={elementsTesIds.VERIFY_AGE_BTN_BACK} onClick={onBackButtonClick} />
            </Container>
            <Container>
                <AgeGatePageIcon className={styles.Icon} />

                <h2 className={styles.Title}>{localizedStrings[localizationKeys.AGE_GATE_TITLE]}</h2>

                <div className={descriptionTextClassName}>
                    {localizedStrings[localizationKeys.AGE_GATE_DESCRIPTION]}
                </div>
                <div className={selectTextClassName}>{localizedStrings[localizationKeys.BIRTH_DATE_PICKER_TITLE]}</div>

                {isMobileDevice ? (
                    <WheelPicker
                        data={data}
                        initialState={pickerInitialState}
                        mapping={ageGatePageConsts.AGE_GATE_PAGE_DATA_MAPPING}
                        onChange={onChange}
                    />
                ) : (
                    <div className={styles.SelectBody}>
                        <div className={styles.ListLabel}>{localizedStrings[localizationKeys.AGE_GATE_LIST_LABEL]}</div>
                        <div className={styles.ListContainer}>
                            {daysList?.length && (
                                <DropdownList
                                    data={daysList}
                                    onClick={onChange}
                                    type={DROPDOWN_LIST_DAY_TYPE}
                                    isCheckOutOfRangeNeeded
                                    testId={elementsTesIds.VERIFY_AGE_INPUT_DAY}
                                />
                            )}
                            {data.map((dataChunk, index) => {
                                const type = index ? DROPDOWN_LIST_YEAR_TYPE : DROPDOWN_LIST_MONTH_TYPE;
                                const testId = index
                                    ? elementsTesIds.VERIFY_AGE_INPUT_YEAR
                                    : elementsTesIds.VERIFY_AGE_INPUT_MONTH;

                                return (
                                    <DropdownList
                                        key={index}
                                        data={dataChunk}
                                        onClick={onChange}
                                        type={type}
                                        testId={testId}
                                    />
                                );
                            })}
                        </div>
                        {!isMobileDevice && (
                            <ButtonDark
                                testId={elementsTesIds.VERIFY_AGE_BTN_CONFIRM}
                                showArrow
                                className={styles.Button}
                                onClick={onContinueClick}
                            >
                                {localizedStrings[localizationKeys.AGE_GATE_BUTTON_CONFIRM]}
                            </ButtonDark>
                        )}
                    </div>
                )}

                {isMobileDevice && (
                    <ButtonDark
                        testId={elementsTesIds.VERIFY_AGE_BTN_CONFIRM}
                        showArrow
                        className={styles.Button}
                        onClick={onContinueClick}
                    >
                        {localizedStrings[localizationKeys.AGE_GATE_BUTTON_CONFIRM]}
                    </ButtonDark>
                )}
            </Container>
        </div>
    );
};

export default connect(mapStateToProps)(AgeGatePage);
