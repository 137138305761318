import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';

import appConfig from '../../config/appConfig';
import * as yapModeTypes from '../../consts/yap/yapModeTypes';
import analyticsService from '../../services/analyticsService';
import {
    makeSelectDeviceActivationStatus,
    makeSelectIotDeviceData,
    makeSelectIsDeviceReady,
} from '../../state/selectors/iotDevice';
import {makeSelectIsYapActivationInProgress} from '../../state/selectors/yapEncrypted';
import DeviceActivationConnectedPage from './components/DeviceActivationConnectedPage';
import DeviceActivationNotConnectedPage from './components/DeviceActivationNotConnectedPage';
import DeviceUAMActivationPage from './components/DeviceUAMActivationPage';
import styles from './DeviceActivationPage.module.scss';

const mapStateToProps = createStructuredSelector({
    iotDevice: makeSelectIotDeviceData(),
    isDeviceReady: makeSelectIsDeviceReady(),
    isYapActivationInProgress: makeSelectIsYapActivationInProgress(),
    isDeviceActivated: makeSelectDeviceActivationStatus(),
});

const DeviceActivationPage = (props) => {
    const {iotDevice, isDeviceReady, isYapActivationInProgress, isDeviceActivated} = props;
    const yapMode = appConfig.getYapMode();

    useEffect(() => {
        analyticsService.pushActivationDeviceActivatingEvent();
    }, []);

    if (!iotDevice) {
        return null;
    }

    return (
        <section className={styles.Page}>
            {!isDeviceReady && !isYapActivationInProgress && !isDeviceActivated && (
                <DeviceActivationNotConnectedPage iotDevice={iotDevice} />
            )}
            {isDeviceReady && yapMode === yapModeTypes.YAP_PARENT_PROTECTION_MODE && <DeviceActivationConnectedPage />}
            {(isDeviceReady || isYapActivationInProgress || isDeviceActivated) &&
                yapMode === yapModeTypes.YAP_ENCRYPTED_MODE && <DeviceUAMActivationPage />}
        </section>
    );
};

export default connect(mapStateToProps)(DeviceActivationPage);
