import classnames from 'classnames';
import React, {useEffect, useRef, useState} from 'react';

import helpers from '../../utils/helpers';
import styles from './DropdownList.module.scss';

const DropdownList = ({data, onClick, type, isCheckOutOfRangeNeeded = false, testId}) => {
    const defaultDataElement = data?.[0];

    const [isExpanded, setIsExpanded] = useState(false);
    const [selectedItem, setSelectedItem] = useState(defaultDataElement);
    const instance = useRef();
    const mainInstance = useRef();
    const listType = type;

    useEffect(() => {
        if (data?.length && isCheckOutOfRangeNeeded) {
            const lastDataElementValue = data[data?.length - 1]?.value;

            const isCurrentValueOutOfRange = selectedItem?.value > lastDataElementValue;

            if (isCurrentValueOutOfRange) {
                setSelectedItem(defaultDataElement);
            }
        }
    }, [data]);

    const handleOutClick = (event) => {
        const clickTarget = event?.target;
        const mainChildren = [...mainInstance?.current?.children];

        const isWrapperClicked = instance?.current === clickTarget;
        const isMainClicked = mainInstance?.current === clickTarget;
        const isControlsClicked = mainChildren.some((element) => element === clickTarget);

        if (!isControlsClicked && !isWrapperClicked && !isMainClicked) {
            setIsExpanded(false);
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleOutClick);

        return () => document.removeEventListener('click', handleOutClick);
    }, []);

    if (!data?.length) return null;

    const arrowClassName = classnames(styles.Arrow, {[styles.Rotated]: isExpanded});
    const bodyClassName = classnames(styles.Body, {[styles.Expanded]: isExpanded});

    const onMainClick = () => {
        setIsExpanded(!isExpanded);
    };

    const onItemClick = (text, value) => {
        setIsExpanded(!isExpanded);
        setSelectedItem({text, value});

        helpers.runFunction(onClick, [{text, value}, listType]);
    };

    return (
        <div className={styles.DropdownList} ref={instance}>
            <div className={styles.Main} onClick={onMainClick} data-testid={testId} ref={mainInstance}>
                <div className={styles.MainTitle}>{selectedItem?.text}</div>
                <div className={arrowClassName} />
            </div>
            <div className={bodyClassName}>
                {data.map((element, index) => {
                    const {text, value} = element || {};

                    return (
                        <div className={styles.Item} key={index} onClick={() => onItemClick(text, value)}>
                            {text}
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default DropdownList;
