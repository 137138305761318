import React, {useState} from 'react';
import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';

import imgIconEdit from '../../../../../assets/images/icons/edit-icon.png';
import imgIconEdit2 from '../../../../../assets/images/icons/edit-icon@2x.png';
import imgIconEdit3 from '../../../../../assets/images/icons/edit-icon@3x.png';
import imgIconFind from '../../../../../assets/images/icons/find-icon.png';
import imgIconFind2 from '../../../../../assets/images/icons/find-icon@2x.png';
import imgIconFind3 from '../../../../../assets/images/icons/find-icon@3x.png';
import imgIconInfo from '../../../../../assets/images/icons/info-icon.png';
import imgIconInfo2 from '../../../../../assets/images/icons/info-icon@2x.png';
import imgIconInfo3 from '../../../../../assets/images/icons/info-icon@3x.png';
import imgIconLegal from '../../../../../assets/images/icons/legal-icon.png';
import imgIconLegal2 from '../../../../../assets/images/icons/legal-icon@2x.png';
import imgIconLegal3 from '../../../../../assets/images/icons/legal-icon@3x.png';
import imgIconLocation from '../../../../../assets/images/icons/location-icon.png';
import imgIconLocation2 from '../../../../../assets/images/icons/location-icon@2x.png';
import imgIconLocation3 from '../../../../../assets/images/icons/location-icon@3x.png';
import TableCell from '../../../../components/TableCell/TableCell';
import appConfig from '../../../../config/appConfig';
import * as localizationKeys from '../../../../consts/app/localizationKeys';
import * as tncTypes from '../../../../consts/tnc/tncTypes';
import useDidUpdate from '../../../../hooks/effects/useDidUpdate';
import appRouterService from '../../../../services/appRouterService';
import deviceConfigService from '../../../../services/iotDevice/iotDeviceConfigService';
import iotDeviceSettingsService from '../../../../services/iotDevice/iotDeviceSettingsService';
import layoutService from '../../../../services/layoutService';
import {getLocalizedStrings} from '../../../../services/localization/localizationService';
import marketConfigService from '../../../../services/marketConfigService';
import productService from '../../../../services/productService';
import routerService from '../../../../services/routerService';
import tncService from '../../../../services/tncService';
import {makeSelectAcceptedTncByType, makeSelectTncByType} from '../../../../state/selectors/consumer';
import elementsTesIds from '../../../../test/consts/elementsTesIds';
import SettingsTableChargerHolderIcon from './components/SettingsTableChargerHolderIcon';
import FindDevicePopup from './FindMode/DeviceFindMode';
import styles from './SettingsTable.module.scss';

const mapStateToProps = createStructuredSelector({
    dataCollectionTnc: makeSelectTncByType(tncTypes.WEB_PREFERENCE_CENTER),
    acceptedTermsOfUse: makeSelectAcceptedTncByType(tncTypes.WEB_PREFERENCE_CENTER),
});

const SettingsTable = (props) => {
    const {
        dispatch,
        setIsVisibleRenamePopup,
        iotDevice,
        isDeviceReady,
        layout,
        acceptedTermsOfUse,
        dataCollectionTnc,
    } = props;
    const {device_global_features_config, device} = iotDevice;
    const [isVisibleFindDevicePopup, setIsVisibleFindDevicePopup] = useState(false);
    const isDeviceSettingAvailable = appConfig.getIsDeviceSettingAvailable();

    const isAlarmEnabled = deviceConfigService.isDeviceAlarmEnabled(iotDevice);
    useDidUpdate(() => {
        if (isVisibleFindDevicePopup) {
            if (!iotDeviceSettingsService.checkSettingAvailable(iotDevice, isAlarmEnabled)) {
                setIsVisibleFindDevicePopup(false);
            }
        }
    }, [isAlarmEnabled]);

    // if (!device_global_features_config) return null;

    const isDeviceAlarmEnabled =
        isDeviceReady &&
        iotDeviceSettingsService.isSettingAvailable(
            iotDevice,
            device_global_features_config?.is_device_alarm,
            isAlarmEnabled
        );

    const isMobileLayout = layoutService.isMobileLayout(layout);
    const isCurrentProductRegistered = productService.isStatusRegistered(device?.status);
    const isIccJourneyScreensEnabled = marketConfigService.isIccJourneyScreensEnabled();

    const iconSets = {
        location: [imgIconLocation, imgIconLocation2, imgIconLocation3],
        find: [imgIconFind, imgIconFind2, imgIconFind3],
        edit: [imgIconEdit, imgIconEdit2, imgIconEdit3],
        info: [imgIconInfo, imgIconInfo2, imgIconInfo3],
        tips: <SettingsTableChargerHolderIcon />,
    };
    const iqosStoreUrl = appConfig.getIqosStoreUrlFormatted();

    const onNearestStoreClick = () => {
        routerService.blankLinkOpen(iqosStoreUrl);
    };

    const onFindDevicePopupChange = () => {
        if (isVisibleFindDevicePopup || iotDeviceSettingsService.checkSettingAvailable(iotDevice, isAlarmEnabled)) {
            setIsVisibleFindDevicePopup(!isVisibleFindDevicePopup);
        }
    };

    const onAboutDeviceClick = () => {
        appRouterService.forwardToAboutDevicePage();
    };

    const onTipsButtonClick = () => appRouterService.forwardToTutorialList();

    const onLegalSettingClick = () => {
        tncService.openLegalSettings();
    };
    const localizedStrings = getLocalizedStrings();

    return (
        <>
            <div className={styles.SettingsTable}>
                {iqosStoreUrl && isDeviceSettingAvailable && (
                    <TableCell
                        imgUrls={iconSets.location}
                        onClick={onNearestStoreClick}
                        layout={layout}
                        name={localizedStrings[localizationKeys.DEVICE_SETTINGS_NEAR_SHOP]}
                    />
                )}
                {isDeviceAlarmEnabled && isDeviceSettingAvailable && (
                    <TableCell
                        imgUrls={iconSets.find}
                        layout={layout}
                        name={localizedStrings[localizationKeys.DEVICE_SETTINGS_FIND_DEVICE]}
                        onClick={onFindDevicePopupChange}
                        testId={elementsTesIds.DEVICE_SETTINGS_BTN_LOCATE}
                    />
                )}
                {isCurrentProductRegistered && isDeviceSettingAvailable && isIccJourneyScreensEnabled && (
                    <TableCell
                        imgUrls={iconSets.edit}
                        layout={layout}
                        name={localizedStrings[localizationKeys.DEVICE_SETTINGS_RENAME_DEVICE]}
                        onClick={() => setIsVisibleRenamePopup(true)}
                        testId={elementsTesIds.DEVICE_SETTINGS_BTN_RENAME}
                    />
                )}
                <TableCell
                    imgUrls={iconSets.info}
                    layout={layout}
                    name={localizedStrings[localizationKeys.DEVICE_SETTINGS_ABOUT_DEVICE]}
                    onClick={onAboutDeviceClick}
                    testId={elementsTesIds.DEVICE_SETTINGS_BTN_ABOUT}
                />
                {!isMobileLayout && (
                    <TableCell
                        imgElement={iconSets.tips}
                        onClick={onTipsButtonClick}
                        layout={layout}
                        name={localizedStrings[localizationKeys.DEVICE_SETTINGS_TIPS]}
                    />
                )}
                {(dataCollectionTnc || acceptedTermsOfUse) &&
                    isDeviceSettingAvailable &&
                    isIccJourneyScreensEnabled && (
                        <TableCell
                            imgUrls={[imgIconLegal, imgIconLegal2, imgIconLegal3]}
                            onClick={appRouterService.forwardToTNCPreferenceCenterSettingsPage}
                            layout={layout}
                            name={localizedStrings[localizationKeys.DEVICE_SETTINGS_PREFERENCE_CENTER]}
                        />
                    )}
                {isIccJourneyScreensEnabled && isDeviceSettingAvailable && (
                    <TableCell
                        imgUrls={[imgIconLegal, imgIconLegal2, imgIconLegal3]}
                        layout={layout}
                        name={localizedStrings[localizationKeys.DEVICE_SETTINGS_LEGAL_STATEMENTS]}
                        onClick={onLegalSettingClick}
                        testId={elementsTesIds.DEVICE_SETTINGS_BTN_LEGALS}
                    />
                )}
            </div>
            {isVisibleFindDevicePopup && (
                <FindDevicePopup
                    dispatch={dispatch}
                    iotDevice={iotDevice}
                    layout={layout}
                    onClose={onFindDevicePopupChange}
                    isDeviceReady={isDeviceReady}
                />
            )}
        </>
    );
};

export default connect(mapStateToProps)(SettingsTable);
