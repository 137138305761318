import * as cmMessageErrorCodes from '../../consts/uam/cmMessageErrorCodes';
import scpCharacteristicService from '../ble/scpCharacteristicService';
import cmMessageRequestService from './cmMessageRequestService';

const onCmMessage = (message) => {
    const {type, token, request_id, frames} = message;

    if (frames?.length) {
        scpCharacteristicService.writeFrameAndPublishResponse({
            frames,
            onSuccess: (...frames) => {
                cmMessageRequestService.publishResponseEvent(type, request_id, token, frames);
            },
            onError: (isDeviceDisconnect) => {
                cmMessageRequestService.publishErrorEvent({
                    type,
                    request_id,
                    token,
                    errorCode: isDeviceDisconnect
                        ? cmMessageErrorCodes.DEVICE_DISCONNECTED
                        : cmMessageErrorCodes.UNKNOWN_ERROR,
                });
            },
        });
    }
};

export default {
    onCmMessage,
};
