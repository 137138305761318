export const ABOUT_DEVICE_CHECK_FIRMWARE_UPDATE_BUTTON_TEXT = 'ABOUT_DEVICE_CHECK_FIRMWARE_UPDATE_BUTTON_TEXT';
export const ABOUT_DEVICE_DEVICE_CODENTIFY_TEXT = 'ABOUT_DEVICE_DEVICE_CODENTIFY_TEXT';
export const ABOUT_DEVICE_DEVICE_FIRMWARE_TEXT = 'ABOUT_DEVICE_DEVICE_FIRMWARE_TEXT';
export const ABOUT_DEVICE_DEVICE_LAST_UPDATE_TEXT = 'ABOUT_DEVICE_DEVICE_LAST_UPDATE_TEXT';
export const ABOUT_DEVICE_DEVICE_MODEL_TEXT = 'ABOUT_DEVICE_DEVICE_MODEL_TEXT';
export const ABOUT_DEVICE_DEVICE_STATUS_TEXT = 'ABOUT_DEVICE_DEVICE_STATUS_TEXT';
export const AGE_GATE_DAY = 'AGE_GATE_DAY';
export const BENEFITS_FIRST_TEXT = 'BENEFITS_FIRST_TEXT';
export const BENEFITS_THIRD_TEXT = 'BENEFITS_THIRD_TEXT';
export const BENEFITS_FOUR_TEXT = 'BENEFITS_FOUR_TEXT';
export const BENEFITS_START_BUTTON_TEXT = 'BENEFITS_START_BUTTON_TEXT';
export const AGE_GATE_TITLE = 'AGE_GATE_TITLE';
export const APP_INTRO_PAGE_ALTERNATIVE_DOWNLOAD_DESCRIPTION_TEXT =
    'APP_INTRO_PAGE_ALTERNATIVE_DOWNLOAD_DESCRIPTION_TEXT';
export const APP_INTRO_PAGE_ALTERNATIVE_DOWNLOAD_LINK_TEXT = 'APP_INTRO_PAGE_ALTERNATIVE_DOWNLOAD_LINK_TEXT';
export const AGE_GATE_LIST_LABEL = 'AGE_GATE_LIST_LABEL';
export const ABOUT_DEVICE_DEVICE_UPDATE_TEXT = 'ABOUT_DEVICE_DEVICE_UPDATE_TEXT';
export const ABOUT_DEVICE_FAILED_STATUS_TEXT = 'ABOUT_DEVICE_FAILED_STATUS_TEXT';
export const ABOUT_DEVICE_FIRMWARE_UPDATE_DESCRIPTION_LINK = 'ABOUT_DEVICE_FIRMWARE_UPDATE_DESCRIPTION_LINK';
export const ABOUT_DEVICE_FIRMWARE_UPDATE_DESCRIPTION_TEXT = 'ABOUT_DEVICE_FIRMWARE_UPDATE_DESCRIPTION_TEXT';
export const ABOUT_DEVICE_FIRMWARE_UPDATE_DEVICE_BUTTON_TEXT = 'ABOUT_DEVICE_FIRMWARE_UPDATE_DEVICE_BUTTON_TEXT';
export const ABOUT_DEVICE_FIRMWARE_UPDATE_FAILED_TEXT = 'ABOUT_DEVICE_FIRMWARE_UPDATE_FAILED_TEXT';
export const COMPATIBLE_BROWSER_PAGE_CONNECT_DESCRIPTION_TEXT = 'COMPATIBLE_BROWSER_PAGE_CONNECT_DESCRIPTION_TEXT';
export const APP_INTRO_PAGE_TITLE_TEXT = 'APP_INTRO_PAGE_TITLE_TEXT';
export const ABOUT_DEVICE_FIRMWARE_UPDATE_HOLDER_BUTTON_TEXT = 'ABOUT_DEVICE_FIRMWARE_UPDATE_HOLDER_BUTTON_TEXT';
export const LOGIN_PAGE_TITLE_TEXT = 'LOGIN_PAGE_TITLE_TEXT';
export const ABOUT_DEVICE_FIRMWARE_UPDATE_IS_UP_TO_DATE_TEXT = 'ABOUT_DEVICE_FIRMWARE_UPDATE_IS_UP_TO_DATE_TEXT';
export const ABOUT_DEVICE_FIRMWARE_UPDATE_POPUP_BUTTON_PRIMARY = 'ABOUT_DEVICE_FIRMWARE_UPDATE_POPUP_BUTTON_PRIMARY';
export const ABOUT_DEVICE_FIRMWARE_UPDATE_POPUP_BUTTON_SECONDARY =
    'ABOUT_DEVICE_FIRMWARE_UPDATE_POPUP_BUTTON_SECONDARY';
export const ABOUT_DEVICE_FIRMWARE_UPDATE_POPUP_DESCRIPTION_PART_1 =
    'ABOUT_DEVICE_FIRMWARE_UPDATE_POPUP_DESCRIPTION_PART_1';
export const ABOUT_DEVICE_FIRMWARE_UPDATE_POPUP_DESCRIPTION_PART_2 =
    'ABOUT_DEVICE_FIRMWARE_UPDATE_POPUP_DESCRIPTION_PART_2';
export const ABOUT_DEVICE_FIRMWARE_UPDATE_POPUP_TITLE = 'ABOUT_DEVICE_FIRMWARE_UPDATE_POPUP_TITLE';
export const ABOUT_DEVICE_HOLDER_MODEL_TEXT = 'ABOUT_DEVICE_HOLDER_MODEL_TEXT';
export const ABOUT_DEVICE_HOLDER_STATUS_TEXT = 'ABOUT_DEVICE_HOLDER_STATUS_TEXT';
export const ABOUT_DEVICE_REGISTER_DEVICE_TEXT = 'ABOUT_DEVICE_REGISTER_DEVICE_TEXT';
export const ABOUT_DEVICE_REGISTERED_STATUS_TEXT = 'ABOUT_DEVICE_REGISTERED_STATUS_TEXT';
export const ABOUT_DEVICE_REGISTERING_STATUS_TEXT = 'ABOUT_DEVICE_REGISTERING_STATUS_TEXT';
export const ABOUT_DEVICE_REMOVE_DEVICE_TEXT = 'ABOUT_DEVICE_REMOVE_DEVICE_TEXT';
export const ABOUT_DEVICE_TITLE_TEXT = 'ABOUT_DEVICE_TITLE_TEXT';
export const HOLDER_IN_SBL_UPDATE_POPUP_DESCRIPTION_TEXT = 'HOLDER_IN_SBL_UPDATE_POPUP_DESCRIPTION_TEXT';
export const HOLDER_IN_SBL_UPDATE_POPUP_GO_TO_IQOS_COM_BUTTON_TEXT =
    'HOLDER_IN_SBL_UPDATE_POPUP_GO_TO_IQOS_COM_BUTTON_TEXT';
export const HOLDER_IN_SBL_UPDATE_POPUP_OK_GOT_IT_BUTTON_TEXT = 'HOLDER_IN_SBL_UPDATE_POPUP_OK_GOT_IT_BUTTON_TEXT';
export const ABOUT_DEVICE_UNREGISTERED_STATUS_TEXT = 'ABOUT_DEVICE_UNREGISTERED_STATUS_TEXT';
export const ABOUT_DEVICE_UNREGISTERING_STATUS_TEXT = 'ABOUT_DEVICE_UNREGISTERING_STATUS_TEXT';
export const ALL_ITEM_DESCRIPTION_TEXT = 'ALL_ITEM_DESCRIPTION_TEXT';
export const ALLEGRO_FLOW_TEXT = 'ALLEGRO_FLOW_TEXT';
export const ALLEGRO_FLOW_TILE_TEXT = 'ALLEGRO_FLOW_TILE_TEXT';
export const AMBIENT_TEMPERATURE_TOO_HIGH_ERROR_MESSAGE = 'AMBIENT_TEMPERATURE_TOO_HIGH_ERROR_MESSAGE';
export const AMBIENT_TEMPERATURE_TOO_LOW_ERROR_MESSAGE = 'AMBIENT_TEMPERATURE_TOO_LOW_ERROR_MESSAGE';
export const BATTERY_CHARGER_BQ24250_I2C_COMM_ERROR = 'BATTERY_CHARGER_BQ24250_I2C_COMM_ERROR';
export const BATTERY_CHARGER_FAULT = 'BATTERY_CHARGER_FAULT';
export const BATTERY_CHARGING_DURATION_EXCEEDED = 'BATTERY_CHARGING_DURATION_EXCEEDED';
export const BATTERY_VOLTAGE_TOO_LOW_ERROR_MESSAGE = 'BATTERY_VOLTAGE_TOO_LOW_ERROR_MESSAGE';
export const BEFORE_PAIRING_ACCEPT_BUTTON_TEXT = 'BEFORE_PAIRING_ACCEPT_BUTTON_TEXT';
export const BEFORE_PAIRING_CANCEL_BUTTON_TEXT = 'BEFORE_PAIRING_CANCEL_BUTTON_TEXT';
export const BEFORE_PAIRING_NOTIFICATION_TEXT = 'BEFORE_PAIRING_NOTIFICATION_TEXT';
export const BEFORE_PAIRING_TEXT = 'BEFORE_PAIRING_TEXT';
export const BEFORE_PAIRING_TITLE_TEXT = 'BEFORE_PAIRING_TITLE_TEXT';
export const BLE_PAIRING_FAIL_MESSAGE = 'BLE_PAIRING_FAIL_MESSAGE';
export const BLE_PAIRING_FAIL_TITLE = 'BLE_PAIRING_FAIL_TITLE';
export const BQ24250_CHG_EN_PULL_DOWN_RES_TEST_FAILURE = 'BQ24250_CHG_EN_PULL_DOWN_RES_TEST_FAILURE';
export const BROWSER_VERIFICATION_TEXT = 'BROWSER_VERIFICATION_TEXT';
export const BROWSER_VERIFICATION_TITLE = 'BROWSER_VERIFICATION_TITLE';
export const BUBBLY_FLOW_TEXT = 'BUBBLY_FLOW_TEXT';
export const BUBBLY_FLOW_TILE_TEXT = 'BUBBLY_FLOW_TILE_TEXT';
export const CANCEL_YAP_PROGRESS_POPUP_CANCEL_BUTTON = 'CANCEL_YAP_PROGRESS_POPUP_CANCEL_BUTTON';
export const CANCEL_YAP_PROGRESS_POPUP_CONTINUE_BUTTON = 'CANCEL_YAP_PROGRESS_POPUP_CONTINUE_BUTTON';
export const CANCEL_YAP_PROGRESS_POPUP_MESSAGE = 'CANCEL_YAP_PROGRESS_POPUP_MESSAGE';
export const CANCEL_YAP_PROGRESS_POPUP_TITLE = 'CANCEL_YAP_PROGRESS_POPUP_TITLE';
export const CHARGER_ERROR_BANK_CHRG_DEFECT = 'CHARGER_ERROR_BANK_CHRG_DEFECT';
export const CHARGER_ERROR_CHARGE_TEMP_OUT_OF_RANGE_CHRGN_ERROR_MESSAGE =
    'CHARGER_ERROR_CHARGE_TEMP_OUT_OF_RANGE_CHRGN_ERROR_MESSAGE';
export const CHARGER_ERROR_CHARGE_TEMP_OUT_OF_RANGE_ERROR_MESSAGE =
    'CHARGER_ERROR_CHARGE_TEMP_OUT_OF_RANGE_ERROR_MESSAGE';
export const CHARGER_ERROR_CHARGER_BATTERY_CHARGER_DEFECT = 'CHARGER_ERROR_CHARGER_BATTERY_CHARGER_DEFECT';
export const CHARGER_ERROR_CHARGER_BATTERY_CHARGING_TIMEOUT_ERROR_MESSAGE =
    'CHARGER_ERROR_CHARGER_BATTERY_CHARGING_TIMEOUT_ERROR_MESSAGE';
export const CHARGER_ERROR_DOOR_SWITCH_FAILURE = 'CHARGER_ERROR_DOOR_SWITCH_FAILURE';
export const CHARGER_ERROR_HOLDER_BATTERY_CHARGER_DEFECT = 'CHARGER_ERROR_HOLDER_BATTERY_CHARGER_DEFECT';
export const CHARGER_ERROR_LED_DRIVER_FAILURE = 'CHARGER_ERROR_LED_DRIVER_FAILURE';
export const CHARGER_ERROR_SELF_TEST_FAILURE = 'CHARGER_ERROR_SELF_TEST_FAILURE';
export const CHARGER_ERROR_SELF_TEST_FAILURE_2_4 = 'CHARGER_ERROR_SELF_TEST_FAILURE_2_4';
export const CLEAN_INSTRUCTION_RESET_BUTTON_TEXT = 'CLEAN_INSTRUCTION_RESET_BUTTON_TEXT';
export const CLEAN_INSTRUCTION_RESET_NAVIGATION_BUTTON_TEXT = 'CLEAN_INSTRUCTION_RESET_NAVIGATION_BUTTON_TEXT';
export const CLEAN_INSTRUCTION_SCREEN1_DESCRIPTION_TEXT = 'CLEAN_INSTRUCTION_SCREEN1_DESCRIPTION_TEXT';
export const CLEAN_INSTRUCTION_SCREEN1_TITLE_TEXT = 'CLEAN_INSTRUCTION_SCREEN1_TITLE_TEXT';
export const CLEAN_INSTRUCTION_SCREEN2_DESCRIPTION_TEXT = 'CLEAN_INSTRUCTION_SCREEN2_DESCRIPTION_TEXT';
export const CLEAN_INSTRUCTION_SCREEN2_TITLE_TEXT = 'CLEAN_INSTRUCTION_SCREEN2_TITLE_TEXT';
export const CLEAN_INSTRUCTION_SCREEN3_DESCRIPTION_TEXT = 'CLEAN_INSTRUCTION_SCREEN3_DESCRIPTION_TEXT';
export const CLEAN_INSTRUCTION_SCREEN3_TITLE_TEXT = 'CLEAN_INSTRUCTION_SCREEN3_TITLE_TEXT';
export const CLEAN_INSTRUCTION_SCREEN4_DESCRIPTION_TEXT = 'CLEAN_INSTRUCTION_SCREEN4_DESCRIPTION_TEXT';
export const CLEAN_INSTRUCTION_SCREEN4_TITLE_TEXT = 'CLEAN_INSTRUCTION_SCREEN4_TITLE_TEXT';
export const CLEAN_INSTRUCTION_SCREEN5_DESCRIPTION_TEXT = 'CLEAN_INSTRUCTION_SCREEN5_DESCRIPTION_TEXT';
export const CLEAN_INSTRUCTION_SCREEN5_TITLE_TEXT = 'CLEAN_INSTRUCTION_SCREEN5_TITLE_TEXT';
export const APP_INTRO_PAGE_DIVIDER_TEXT = 'APP_INTRO_PAGE_DIVIDER_TEXT';
export const CLEAN_INSTRUCTION_SCREEN6_DESCRIPTION_TEXT = 'CLEAN_INSTRUCTION_SCREEN6_DESCRIPTION_TEXT';
export const CLEAN_INSTRUCTION_SCREEN6_TITLE_TEXT = 'CLEAN_INSTRUCTION_SCREEN6_TITLE_TEXT';
export const CLEAN_INSTRUCTION_TITLE_TEXT = 'CLEAN_INSTRUCTION_TITLE_TEXT';
export const CLEANING_FAILED_NOTIFICATION_TEXT = 'CLEANING_FAILED_NOTIFICATION_TEXT';
export const CLEANING_HOLDER_NOT_CHARGED_NOTIFICATION_TEXT = 'CLEANING_HOLDER_NOT_CHARGED_NOTIFICATION_TEXT';
export const CLEANING_MODE_DESCRIPTION = 'CLEANING_MODE_DESCRIPTION';
export const CLEANING_MODE_DESCRIPTION_USB_NOTIFICATION = 'CLEANING_MODE_DESCRIPTION_USB_NOTIFICATION';
export const CLEANING_MODE_TITLE = 'CLEANING_MODE_TITLE';
export const CLEANING_NOT_NEEDED_NOTIFICATION_TEXT = 'CLEANING_NOT_NEEDED_NOTIFICATION_TEXT';
export const CLEANING_SUCCESSFUL_NOTIFICATION_TEXT = 'CLEANING_SUCCESSFUL_NOTIFICATION_TEXT';
export const CLEANING_UNPLUGGED_NOTIFICATION_TEXT = 'CLEANING_UNPLUGGED_NOTIFICATION_TEXT';
export const CLOUD_SIZE_DESCRIPTION_TEXT = 'CLOUD_SIZE_DESCRIPTION_TEXT';
export const CLOUD_SIZE_NORMAL_BUTTON_TEXT = 'CLOUD_SIZE_NORMAL_BUTTON_TEXT';
export const CLOUD_SIZE_NORMAL_TEXT = 'CLOUD_SIZE_NORMAL_TEXT';
export const CLOUD_SIZE_POPUP_DESCRIPTION = 'CLOUD_SIZE_POPUP_DESCRIPTION';
export const CLOUD_SIZE_POPUP_TITLE = 'CLOUD_SIZE_POPUP_TITLE';
export const CLOUD_SIZE_REDUCED_BUTTON_TEXT = 'CLOUD_SIZE_REDUCED_BUTTON_TEXT';
export const CLOUD_SIZE_REDUCED_TEXT = 'CLOUD_SIZE_REDUCED_TEXT';
export const CLOUD_SIZE_TITLE_TEXT = 'CLOUD_SIZE_TITLE_TEXT';
export const CLOUD_TEXT = 'CLOUD_TEXT';
export const COMPATIBLE_BROWSER_PAGE_APPLE_BUTTON_TEXT = 'COMPATIBLE_BROWSER_PAGE_APPLE_BUTTON_TEXT';
export const COMPATIBLE_BROWSER_PAGE_CONTINUE_BUTTON_TEXT = 'COMPATIBLE_BROWSER_PAGE_CONTINUE_BUTTON_TEXT';
export const COMPATIBLE_BROWSER_PAGE_DESCRIPTION_ICON_TEXT = 'COMPATIBLE_BROWSER_PAGE_DESCRIPTION_ICON_TEXT';
export const COMPATIBLE_BROWSER_PAGE_NOT_COMPATIBLE_DESCRIPTION_ICON_TEXT =
    'COMPATIBLE_BROWSER_PAGE_NOT_COMPATIBLE_DESCRIPTION_ICON_TEXT';
export const COMPATIBLE_BROWSER_PAGE_DESCRIPTION_TEXT = 'COMPATIBLE_BROWSER_PAGE_DESCRIPTION_TEXT';
export const COMPATIBLE_BROWSER_PAGE_DOWNLOAD_DESCRIPTION_TEXT = 'COMPATIBLE_BROWSER_PAGE_DOWNLOAD_DESCRIPTION_TEXT';
export const COMPATIBLE_BROWSER_PAGE_GOOGLE_BUTTON_TEXT = 'COMPATIBLE_BROWSER_PAGE_GOOGLE_BUTTON_TEXT';
export const COMPATIBLE_BROWSER_PAGE_MACOS_BUTTON_TEXT = 'COMPATIBLE_BROWSER_PAGE_MACOS_BUTTON_TEXT';
export const COMPATIBLE_BROWSER_PAGE_TITLE_TEXT = 'COMPATIBLE_BROWSER_PAGE_TITLE_TEXT';
export const COMPATIBLE_BROWSER_PAGE_TITLE_TEXT_CONNECT = 'COMPATIBLE_BROWSER_PAGE_TITLE_TEXT_CONNECT';
export const COMPATIBLE_BROWSER_PAGE_TITLE_TEXT_DESKTOP = 'COMPATIBLE_BROWSER_PAGE_TITLE_TEXT_DESKTOP';
export const COMPATIBLE_BROWSER_PAGE_TITLE_TEXT_MOBILE = 'COMPATIBLE_BROWSER_PAGE_TITLE_TEXT_MOBILE';
export const COMPATIBLE_BROWSER_PAGE_WINDOWS_BUTTON_TEXT = 'COMPATIBLE_BROWSER_PAGE_WINDOWS_BUTTON_TEXT';
export const CORTEZ_FIRST_ITEM_TITLE = 'CORTEZ_FIRST_ITEM_TITLE';
export const CORTEZ_FOURTH_ITEM_TITLE = 'CORTEZ_FOURTH_ITEM_TITLE';
export const CORTEZ_SECOND_ITEM_TITLE = 'CORTEZ_SECOND_ITEM_TITLE';
export const CORTEZ_THIRD_ITEM_TITLE = 'CORTEZ_THIRD_ITEM_TITLE';
export const CRESCENDO_FLOW_TEXT = 'CRESCENDO_FLOW_TEXT';
export const CRESCENDO_FLOW_TILE_TEXT = 'CRESCENDO_FLOW_TILE_TEXT';
export const DATA_COLLECTION_CONFIRM_BUTTON_TEXT = 'DATA_COLLECTION_CONFIRM_BUTTON_TEXT';
export const DATA_COLLECTION_DECLINE_BUTTON_TEXT = 'DATA_COLLECTION_DECLINE_BUTTON_TEXT';
export const DATA_COLLECTION_PREFERENCE_CENTER_BUTTON_TEXT = 'DATA_COLLECTION_PREFERENCE_CENTER_BUTTON_TEXT';
export const DATA_COLLECTION_TITLE_TEXT = 'DATA_COLLECTION_TITLE_TEXT';
export const DATA_COLLECTION_UPDATE_TITLE_TEXT = 'DATA_COLLECTION_UPDATE_TITLE_TEXT';
export const DEVICE_ALREADY_OWNED_MESSAGE = 'DEVICE_ALREADY_OWNED_MESSAGE';
export const DEVICE_ALREADY_OWNED_TITLE = 'DEVICE_ALREADY_OWNED_TITLE';
export const DEVICE_CAROUSEL_CORTEZ_NAME = 'DEVICE_CAROUSEL_CORTEZ_NAME';
export const DEVICE_CAROUSEL_ORDINARY_NAME = 'DEVICE_CAROUSEL_ORDINARY_NAME';
export const DEVICE_CAROUSEL_SOYUZ_NAME = 'DEVICE_CAROUSEL_SOYUZ_NAME';
export const DEVICE_CAROUSEL_TITAN_NAME = 'DEVICE_CAROUSEL_TITAN_NAME';
export const DEVICE_CAROUSEL_VESPUCCI_NAME = 'DEVICE_CAROUSEL_VESPUCCI_NAME';
export const DEVICE_CONNECTION_STATUS_PAGE_DEVICE_SETTINGS = 'DEVICE_CONNECTION_STATUS_PAGE_DEVICE_SETTINGS';
export const DEVICE_DIAGNOSTIC_RESULT_NO_ERROR_TITLE = 'DEVICE_DIAGNOSTIC_RESULT_NO_ERROR_TITLE';
export const DEVICE_EXPECTATION_REGISTER__TEXT = 'DEVICE_EXPECTATION_REGISTER__TEXT';
export const DEVICE_LIST_CHOOSER_TITLE = 'DEVICE_LIST_CHOOSER_TITLE';
export const DEVICE_LIST_DEVICES_AVAILABLE_ADD_DEVICE_BUTTON_TEXT =
    'DEVICE_LIST_DEVICES_AVAILABLE_ADD_DEVICE_BUTTON_TEXT';
export const DEVICE_LIST_DEVICES_AVAILABLE_DEVICE_SETTINGS_TEXT = 'DEVICE_LIST_DEVICES_AVAILABLE_DEVICE_SETTINGS_TEXT';
export const DEVICE_LIST_DEVICES_AVAILABLE_PAIR_BUTTON_TEXT = 'DEVICE_LIST_DEVICES_AVAILABLE_PAIR_BUTTON_TEXT';
export const DEVICE_LIST_NO_DEVICE_DESCRIPTION_TEXT = 'DEVICE_LIST_NO_DEVICE_DESCRIPTION_TEXT';
export const DEVICE_LIST_NO_DEVICE_FEATURE_DESCRIPTION_TEXT = 'DEVICE_LIST_NO_DEVICE_FEATURE_DESCRIPTION_TEXT';
export const DEVICE_LIST_NO_DEVICE_LEARN_MORE_BUTTON_TEXT = 'DEVICE_LIST_NO_DEVICE_LEARN_MORE_BUTTON_TEXT';
export const DEVICE_LIST_NO_DEVICE_SET_UP_BUTTON_TEXT = 'DEVICE_LIST_NO_DEVICE_SET_UP_BUTTON_TEXT';
export const DEVICE_LIST_NO_DEVICE_TITLE_TEXT = 'DEVICE_LIST_NO_DEVICE_TITLE_TEXT';
export const DEVICE_LOCK_DISCONNECTED_BUTTON_TEXT = 'DEVICE_LOCK_DISCONNECTED_BUTTON_TEXT';
export const DEVICE_LOCK_DISCONNECTED_POPUP_HEADER_TEXT = 'DEVICE_LOCK_DISCONNECTED_POPUP_HEADER_TEXT';
export const DEVICE_LOCK_DISCONNECTED_POPUP_ROW1_TEXT = 'DEVICE_LOCK_DISCONNECTED_POPUP_ROW1_TEXT';
export const DEVICE_LOCK_DISCONNECTED_POPUP_ROW2_TEXT = 'DEVICE_LOCK_DISCONNECTED_POPUP_ROW2_TEXT';
export const DEVICE_LOCK_LOCKED_NOTIFICATION = 'DEVICE_LOCK_LOCKED_NOTIFICATION';
export const DEVICE_LOCK_LOCKED_POPUP_BODY_TEXT = 'DEVICE_LOCK_LOCKED_POPUP_BODY_TEXT';
export const DEVICE_LOCK_LOCKED_POPUP_CANCEL_BUTTON = 'DEVICE_LOCK_LOCKED_POPUP_CANCEL_BUTTON';
export const DEVICE_LOCK_LOCKED_POPUP_HEADER_TEXT = 'DEVICE_LOCK_LOCKED_POPUP_HEADER_TEXT';
export const DEVICE_LOCK_LOCKED_POPUP_UNLOCK_BUTTON = 'DEVICE_LOCK_LOCKED_POPUP_UNLOCK_BUTTON';
export const DEVICE_LOCK_STATUS_LOCKED = 'DEVICE_LOCK_STATUS_LOCKED';
export const DEVICE_LOCK_STATUS_UNLOCKED = 'DEVICE_LOCK_STATUS_UNLOCKED';
export const DEVICE_LOCK_UNLOCKED_NOTIFICATION = 'DEVICE_LOCK_UNLOCKED_NOTIFICATION';
export const DEVICE_LOCK_UNLOCKED_POPUP_HEADER_TEXT = 'DEVICE_LOCK_UNLOCKED_POPUP_HEADER_TEXT';
export const DEVICE_LOCK_UNLOCKED_POPUP_ROW1_TEXT = 'DEVICE_LOCK_UNLOCKED_POPUP_ROW1_TEXT';
export const DEVICE_LOCK_UNLOCKED_POPUP_ROW2_FIRST_WORD = 'DEVICE_LOCK_UNLOCKED_POPUP_ROW2_FIRST_WORD';
export const DEVICE_LOCK_UNLOCKED_POPUP_ROW2_TEXT = 'DEVICE_LOCK_UNLOCKED_POPUP_ROW2_TEXT';
export const DEVICE_LOCK_UNLOCKED_POPUP_TIME_BUTTON_TEXT = 'DEVICE_LOCK_UNLOCKED_POPUP_TIME_BUTTON_TEXT';
export const DEVICE_NOT_IDENTIFIABLE = 'DEVICE_NOT_IDENTIFIABLE';
export const DEVICE_REGISTER_SUCCESSFUL_TEXT = 'DEVICE_REGISTER_SUCCESSFUL_TEXT';
export const DEVICE_REMOVED_FROM_MASTER_DB_MESSAGE = 'DEVICE_REMOVED_FROM_MASTER_DB_MESSAGE';
export const DEVICE_REMOVED_FROM_MASTER_DB_TITLE = 'DEVICE_REMOVED_FROM_MASTER_DB_TITLE';
export const DEVICE_SETTINGS_ABOUT_DEVICE = 'DEVICE_SETTINGS_ABOUT_DEVICE';
export const DEVICE_SETTINGS_CLEANING_MODE = 'DEVICE_SETTINGS_CLEANING_MODE';
export const DEVICE_SETTINGS_CLOUD = 'DEVICE_SETTINGS_CLOUD';
export const DEVICE_SETTINGS_CONNECT_NOTIFICATION_TEXT = 'DEVICE_SETTINGS_CONNECT_NOTIFICATION_TEXT';
export const DEVICE_SETTINGS_DIAGNOSTIC = 'DEVICE_SETTINGS_DIAGNOSTIC';
export const DEVICE_SETTINGS_FIND_DEVICE = 'DEVICE_SETTINGS_FIND_DEVICE';
export const DEVICE_SETTINGS_INSERT_HOLDER_NOTIFICATION_TEXT = 'DEVICE_SETTINGS_INSERT_HOLDER_NOTIFICATION_TEXT';
export const DEVICE_SETTINGS_LED_MODE = 'DEVICE_SETTINGS_LED_MODE';
export const DEVICE_SETTINGS_LEGAL_STATEMENTS = 'DEVICE_SETTINGS_LEGAL_STATEMENTS';
export const DEVICE_SETTINGS_PAIR = 'DEVICE_SETTINGS_PAIR';
export const DEVICE_SETTINGS_PREFERENCE_CENTER = 'DEVICE_SETTINGS_PREFERENCE_CENTER';
export const DEVICE_SETTINGS_RENAME_DEVICE = 'DEVICE_SETTINGS_RENAME_DEVICE';
export const DEVICE_SETTINGS_RESPONSIVE = 'DEVICE_SETTINGS_RESPONSIVE';
export const DEVICE_SETTINGS_TIPS = 'DEVICE_SETTINGS_TIPS';
export const DEVICE_SETTINGS_TIPS_MORE = 'DEVICE_SETTINGS_TIPS_MORE';
export const DEVICE_SETTINGS_UNPAIR = 'DEVICE_SETTINGS_UNPAIR';
export const DEVICE_SETTINGS_UPDATE_DEVICE_NOTIFICATION_TEXT = 'DEVICE_SETTINGS_UPDATE_DEVICE_NOTIFICATION_TEXT';
export const DEVICE_SETTINGS_UPDATE_DEVICE_WITH_HOLDER_NOTIFICATION_TEXT =
    'DEVICE_SETTINGS_UPDATE_DEVICE_WITH_HOLDER_NOTIFICATION_TEXT';
export const DEVICE_SETTINGS_USING_HEADER = 'DEVICE_SETTINGS_USING_HEADER';
export const DEVICE_SETTINGS_VIBRATE_MODE = 'DEVICE_SETTINGS_VIBRATE_MODE';
export const DEVICE_STATUS_CONNECTED = 'DEVICE_STATUS_CONNECTED';
export const DEVICE_STATUS_DISCONNECTED = 'DEVICE_STATUS_DISCONNECTED';
export const DEVICE_STATUS_LOCKED = 'DEVICE_STATUS_LOCKED';
export const DEVICE_UNREGISTER_SUCCESSFUL_TEXT = 'DEVICE_UNREGISTER_SUCCESSFUL_TEXT';
export const DUTY_CYCLE_TOO_LOW_WHILE_HEATING_ERROR_MESSAGE = 'DUTY_CYCLE_TOO_LOW_WHILE_HEATING_ERROR_MESSAGE';
export const EEPROM_INT_FAILURE = 'EEPROM_INT_FAILURE';
export const ENV_AMB_TEMP_HIGH_ERROR_MESSAGE = 'ENV_AMB_TEMP_HIGH_ERROR_MESSAGE';
export const ENV_AMB_TEMP_LOW_ERROR_MESSAGE = 'ENV_AMB_TEMP_LOW_ERROR_MESSAGE';
export const ENV_HIGH_MCU_VOLTAGE = 'ENV_HIGH_MCU_VOLTAGE';
export const ENV_LOW_MCU_VOLTAGE_ERROR_MESSAGE = 'ENV_LOW_MCU_VOLTAGE_ERROR_MESSAGE';
export const ERR_BAT_TEMP_HIGH_ERROR_MESSAGE = 'ERR_BAT_TEMP_HIGH_ERROR_MESSAGE';
export const ERR_BAT_TEMP_LOW_ERROR_MESSAGE = 'ERR_BAT_TEMP_LOW_ERROR_MESSAGE';
export const ERR_BAT_VOLTAGE_ERROR_MESSAGE = 'ERR_BAT_VOLTAGE_ERROR_MESSAGE';
export const ERR_BLE_INIT = 'ERR_BLE_INIT';
export const ERR_CHRG_CONFIG = 'ERR_CHRG_CONFIG';
export const ERR_CHRG_DRV_I2C = 'ERR_CHRG_DRV_I2C';
export const ERR_CHRG_FAILURE = 'ERR_CHRG_FAILURE';
export const ERR_CHRG_INIT = 'ERR_CHRG_INIT';
export const ERR_DISPLAY_INIT = 'ERR_DISPLAY_INIT';
export const ERR_EEPROM_DRV = 'ERR_EEPROM_DRV';
export const ERR_FLASH_DRV = 'ERR_FLASH_DRV';
export const ERR_FLASH_INIT = 'ERR_FLASH_INIT';
export const ERR_I2C_BUS = 'ERR_I2C_BUS';
export const ERR_LED_DRV = 'ERR_LED_DRV';
export const ERR_POST_FAIL = 'ERR_POST_FAIL';
export const ERR_SYS_VOLTAGE_ERROR_MESSAGE = 'ERR_SYS_VOLTAGE_ERROR_MESSAGE';
export const ERROR_EXT_ENERGY_HEATING_ERROR_MESSAGE = 'ERROR_EXT_ENERGY_HEATING_ERROR_MESSAGE';
export const ERROR_HCM_SWITCHES_FAILED = 'ERROR_HCM_SWITCHES_FAILED';
export const ERROR_HEATER_PMOS_OPEN = 'ERROR_HEATER_PMOS_OPEN';
export const ERROR_HEATER_RESISTANCE = 'ERROR_HEATER_RESISTANCE';
export const ERROR_HIGH_TEMP_ERROR_MESSAGE = 'ERROR_HIGH_TEMP_ERROR_MESSAGE';
export const ERROR_LOW_BATT_ERROR_MESSAGE = 'ERROR_LOW_BATT_ERROR_MESSAGE';
export const ERROR_LOW_TEMP_ERROR_MESSAGE = 'ERROR_LOW_TEMP_ERROR_MESSAGE';
export const ERROR_NMOS_SHORTED = 'ERROR_NMOS_SHORTED';
export const ERROR_OVERHEATING_ERROR_MESSAGE = 'ERROR_OVERHEATING_ERROR_MESSAGE';
export const ERROR_RESISTOR_CHAIN = 'ERROR_RESISTOR_CHAIN';
export const EXPLORE_BUTTON_TEXT = 'EXPLORE_BUTTON_TEXT';
export const EXT_VOLTAGE_REF_FAILURE = 'EXT_VOLTAGE_REF_FAILURE';
export const EXTERNAL_SYSTEM_UNAVAILABLE_MESSAGE = 'EXTERNAL_SYSTEM_UNAVAILABLE_MESSAGE';
export const EXTERNAL_SYSTEM_UNAVAILABLE_TITLE = 'EXTERNAL_SYSTEM_UNAVAILABLE_TITLE';
export const FAQ_TITLE_TEXT = 'FAQ_TITLE_TEXT';
export const FIND_DEVICE_MODE_TITLE = 'FIND_DEVICE_MODE_TITLE';
export const FIND_DEVICE_MODE_VIBRATE_DESCRIPTION = 'FIND_DEVICE_MODE_VIBRATE_DESCRIPTION';
export const FIND_DEVICE_MODE_WARNING_NOTIFICATION_TEXT = 'FIND_DEVICE_MODE_WARNING_NOTIFICATION_TEXT';
export const FIRMWARE_DOWNLOADING_FAILED_POPUP_MESSAGE = 'FIRMWARE_DOWNLOADING_FAILED_POPUP_MESSAGE';
export const FIRMWARE_DOWNLOADING_FAILED_YAP_POPUP_DO_IT_LATER_BUTTON =
    'FIRMWARE_DOWNLOADING_FAILED_YAP_POPUP_DO_IT_LATER_BUTTON';
export const FIRMWARE_DOWNLOADING_FAILED_YAP_POPUP_MESSAGE = 'FIRMWARE_DOWNLOADING_FAILED_YAP_POPUP_MESSAGE';
export const FIRMWARE_DOWNLOADING_FAILED_YAP_POPUP_TITLE = 'FIRMWARE_DOWNLOADING_FAILED_YAP_POPUP_TITLE';
export const FIRMWARE_DOWNLOADING_FAILED_YAP_POPUP_TRY_AGAIN_BUTTON =
    'FIRMWARE_DOWNLOADING_FAILED_YAP_POPUP_TRY_AGAIN_BUTTON';
export const FIRMWARE_INSTALL_CONNECTION_FAILED_CANCEL_BUTTON_TEXT =
    'FIRMWARE_INSTALL_CONNECTION_FAILED_CANCEL_BUTTON_TEXT';
export const FIRMWARE_INSTALL_CONNECTION_FAILED_DESCRIPTION = 'FIRMWARE_INSTALL_CONNECTION_FAILED_DESCRIPTION';
export const FIRMWARE_INSTALL_CONNECTION_FAILED_TITLE = 'FIRMWARE_INSTALL_CONNECTION_FAILED_TITLE';
export const FIRMWARE_INSTALL_CONNECTION_FAILED_TRY_AGAIN_BUTTON_TEXT =
    'FIRMWARE_INSTALL_CONNECTION_FAILED_TRY_AGAIN_BUTTON_TEXT';
export const FIRMWARE_INSTALL_DESCRIPTION_PART_1 = 'FIRMWARE_INSTALL_DESCRIPTION_PART_1';
export const FIRMWARE_INSTALL_DESCRIPTION_PART_2 = 'FIRMWARE_INSTALL_DESCRIPTION_PART_2';
export const FIRMWARE_INSTALL_ESTIMATING_TIME = 'FIRMWARE_INSTALL_ESTIMATING_TIME';
export const FIRMWARE_INSTALL_FAILED_CANCEL_BUTTON_TEXT = 'FIRMWARE_INSTALL_FAILED_CANCEL_BUTTON_TEXT';
export const FIRMWARE_INSTALL_FAILED_MESSAGE = 'FIRMWARE_INSTALL_FAILED_MESSAGE';
export const FIRMWARE_INSTALL_FAILED_TITLE = 'FIRMWARE_INSTALL_FAILED_TITLE';
export const FIRMWARE_INSTALL_FAILED_TRY_AGAIN_BUTTON_TEXT = 'FIRMWARE_INSTALL_FAILED_TRY_AGAIN_BUTTON_TEXT';
export const FIRMWARE_INSTALL_REMAINING_TIME = 'FIRMWARE_INSTALL_REMAINING_TIME';
export const FIRMWARE_INSTALL_TITLE = 'FIRMWARE_INSTALL_TITLE';
export const FIRMWARE_SBL_FOUND_POPUP_GOT_IT_BUTTON = 'FIRMWARE_SBL_FOUND_POPUP_GOT_IT_BUTTON';
export const FIRMWARE_SBL_FOUND_POPUP_MESSAGE = 'FIRMWARE_SBL_FOUND_POPUP_MESSAGE';
export const FIRMWARE_SBL_FOUND_POPUP_TITLE = 'FIRMWARE_SBL_FOUND_POPUP_TITLE';
export const FIRMWARE_UPDATE_DEVICE_BATTERY_LOW_NOTIFICATION_TEXT =
    'FIRMWARE_UPDATE_DEVICE_BATTERY_LOW_NOTIFICATION_TEXT';
export const FIRMWARE_UPDATE_NOT_NEEDED_CONFIRM_TEXT = 'FIRMWARE_UPDATE_NOT_NEEDED_CONFIRM_TEXT';
export const FIRMWARE_UPDATE_NOT_NEEDED_DESCRIPTION = 'FIRMWARE_UPDATE_NOT_NEEDED_DESCRIPTION';
export const FIRMWARE_UPDATE_NOT_NEEDED_TITLE = 'FIRMWARE_UPDATE_NOT_NEEDED_TITLE';
export const FIRMWARE_UPDATE_SUCCESS = 'FIRMWARE_UPDATE_SUCCESS';
export const GLOBAL_BACK_BUTTON_TEXT = 'GLOBAL_BACK_BUTTON_TEXT';
export const GLOBAL_DETECTOR_OFFLINE = 'GLOBAL_DETECTOR_OFFLINE';
export const GLOBAL_DETECTOR_ONLINE = 'GLOBAL_DETECTOR_ONLINE';
export const GLOBAL_ERROR_NOTIFICATION_TEXT = 'GLOBAL_ERROR_NOTIFICATION_TEXT';
export const GLOBAL_LOADER_DEFAULT_TEXT = 'GLOBAL_LOADER_DEFAULT_TEXT';
export const GLOBAL_LOADER_DEFAULT_TEXT_MAIN = 'GLOBAL_LOADER_DEFAULT_TEXT_MAIN';
export const GLOBAL_LOADER_DEFAULT_TEXT_SECONDARY = 'GLOBAL_LOADER_DEFAULT_TEXT_SECONDARY';
export const HAPTIC_MANAGEMENT_ALMOST_ENDED_TITLE_TEXT_KEY = 'HAPTIC_MANAGEMENT_ALMOST_ENDED_TITLE_TEXT_KEY';
export const HAPTIC_MANAGEMENT_DESCRIPTION = 'HAPTIC_MANAGEMENT_DESCRIPTION';
export const HAPTIC_MANAGEMENT_ENDED_TITLE_TEXT_KEY = 'HAPTIC_MANAGEMENT_ENDED_TITLE_TEXT_KEY';
export const HAPTIC_MANAGEMENT_FULLY_HEATED_TITLE_TEXT_KEY = 'HAPTIC_MANAGEMENT_FULLY_HEATED_TITLE_TEXT_KEY';
export const HAPTIC_MANAGEMENT_HEAT_TITLE = 'HAPTIC_MANAGEMENT_HEAT_TITLE';
export const HAPTIC_MANAGEMENT_HOLDER_TITLE = 'HAPTIC_MANAGEMENT_HOLDER_TITLE';
export const HAPTIC_MANAGEMENT_START_HEATING_TITLE_TEXT_KEY = 'HAPTIC_MANAGEMENT_START_HEATING_TITLE_TEXT_KEY';
export const HAPTIC_MANAGEMENT_TITLE = 'HAPTIC_MANAGEMENT_TITLE';
export const HCM_HMG_PULL_DOWN_RES_TEST_FAILURE = 'HCM_HMG_PULL_DOWN_RES_TEST_FAILURE';
export const HEATER_RESISTANCE_FAULT_DETECTED = 'HEATER_RESISTANCE_FAULT_DETECTED';
export const HEATER_TEMPERATURE_ABOVE_LIMIT_ERROR_MESSAGE = 'HEATER_TEMPERATURE_ABOVE_LIMIT_ERROR_MESSAGE';
export const HEATING_DURATION_EXCEEDED = 'HEATING_DURATION_EXCEEDED';
export const HEATING_HCM_SWITCHES_ERROR = 'HEATING_HCM_SWITCHES_ERROR';
export const HEATING_REGULATION_HEATER_CURRENT_HIGH = 'HEATING_REGULATION_HEATER_CURRENT_HIGH';
export const HEATING_REGULATION_HEATER_CURRENT_LOW = 'HEATING_REGULATION_HEATER_CURRENT_LOW';
export const HEATING_REGULATION_HEATER_POWER_OUT_RANGE_ERROR_MESSAGE =
    'HEATING_REGULATION_HEATER_POWER_OUT_RANGE_ERROR_MESSAGE';
export const HEATING_REGULATION_HEATER_TEMP_ABOVE_LIMIT_ERROR_MESSAGE =
    'HEATING_REGULATION_HEATER_TEMP_ABOVE_LIMIT_ERROR_MESSAGE   ';
export const HEATING_SYSTEM_FAULT_CANT_START_HW_SAFETY = 'HEATING_SYSTEM_FAULT_CANT_START_HW_SAFETY';
export const HEATING_SYSTEM_FAULT_HW_SAFETY_TRIGGERED = 'HEATING_SYSTEM_FAULT_HW_SAFETY_TRIGGERED';
export const HEATING_SYSTEM_FAULT_NO_VOLTAGE_VH_MINUS_SW_ON = 'HEATING_SYSTEM_FAULT_NO_VOLTAGE_VH_MINUS_SW_ON';
export const HEATING_SYSTEM_FAULT_NO_VOLTAGE_VH_PLUS_SW_ON = 'HEATING_SYSTEM_FAULT_NO_VOLTAGE_VH_PLUS_SW_ON';
export const HEATING_SYSTEM_FAULT_POWER_DIST_FAILURE = 'HEATING_SYSTEM_FAULT_POWER_DIST_FAILURE';
export const HEATING_SYSTEM_FAULT_UNEXP_VOLTAGE_SW_OFF = 'HEATING_SYSTEM_FAULT_UNEXP_VOLTAGE_SW_OFF';
export const HEATING_TIMEOUT_ERROR = 'HEATING_TIMEOUT_ERROR';
export const HOLDER_CANNOT_COMMUNICATE_WITH_FUEL_GAUGE = 'HOLDER_CANNOT_COMMUNICATE_WITH_FUEL_GAUGE';
export const HOLDER_ERROR_HLDR_CHARGER_DEFECT = 'HOLDER_ERROR_HLDR_CHARGER_DEFECT';
export const HOLDER_ERROR_HLDR_CHRG_ERROR = 'HOLDER_ERROR_HLDR_CHRG_ERROR';
export const HOLDER_ERROR_HLDR_CHRG_TIMEOUT = 'HOLDER_ERROR_HLDR_CHRG_TIMEOUT';
export const HOLDER_ERROR_HLDR_COMM_ERROR = 'HOLDER_ERROR_HLDR_COMM_ERROR';
export const HOLDER_ERROR_HLDR_TEMPERATURE_ERROR_MESSAGE = 'HOLDER_ERROR_HLDR_TEMPERATURE_ERROR_MESSAGE';
export const HOLDER_ERROR_HOLDER_BATTERY_CHARGING_TIMEOUT = 'HOLDER_ERROR_HOLDER_BATTERY_CHARGING_TIMEOUT';
export const HOLDER_ERROR_HOLDER_BATTERY_TEMP_OUTSIDE_RANGE_ERROR_MESSAGE =
    'HOLDER_ERROR_HOLDER_BATTERY_TEMP_OUTSIDE_RANGE_ERROR_MESSAGE';
export const HOLDER_ERROR_HOLDER_IDENTIFICATION_FAILURE = 'HOLDER_ERROR_HOLDER_IDENTIFICATION_FAILURE';
export const HOLDER_ERROR_HOLDER_SYSTEM_DEFECT = 'HOLDER_ERROR_HOLDER_SYSTEM_DEFECT';
export const HOLDER_ERROR_IDENTIFICATION_FAILURE = 'HOLDER_ERROR_IDENTIFICATION_FAILURE';
export const HOLDER_ERROR_SYSTEM_DEFECT = 'HOLDER_ERROR_SYSTEM_DEFECT';
export const HOLDER_WARNING_AUTH_FAILURE = 'HOLDER_WARNING_AUTH_FAILURE';
export const HOLDER_WARNING_BAD_COMM = 'HOLDER_WARNING_BAD_COMM';
export const HOLDER_WARNING_END_OF_LIFE = 'HOLDER_WARNING_END_OF_LIFE';
export const HOLDER_WARNING_HOLDER_BATTERY_AGED_ERROR_MESSAGE = 'HOLDER_WARNING_HOLDER_BATTERY_AGED_ERROR_MESSAGE';
export const HOLDER_WARNING_HOLDER_COMMUNICATION_PROBLEM = 'HOLDER_WARNING_HOLDER_COMMUNICATION_PROBLEM';
export const HOLDER_WARNING_NULL_HOLDER_CHARGING_CURRENT = 'HOLDER_WARNING_NULL_HOLDER_CHARGING_CURRENT';
export const HW_ISSUES_PWR_DIST_FAILURE = 'HW_ISSUES_PWR_DIST_FAILURE';
export const INTERNAL_ERR_ADC_COMP_TIMEOUT = 'INTERNAL_ERR_ADC_COMP_TIMEOUT';
export const INVALID_CALIBRATION_DATA_CRC = 'INVALID_CALIBRATION_DATA_CRC';
export const INVALID_CODENTIFYID_MESSAGE = 'INVALID_CODENTIFYID_MESSAGE';
export const INVALID_CODENTIFYID_TITLE = 'INVALID_CODENTIFYID_TITLE';
export const INVALID_DEVICE_STATUS_MESSAGE = 'INVALID_DEVICE_STATUS_MESSAGE';
export const INVALID_DEVICE_STATUS_TITLE = 'INVALID_DEVICE_STATUS_TITLE';
export const INVALID_MANUF_INFO_BLOCK_CRC = 'INVALID_MANUF_INFO_BLOCK_CRC';
export const IO_ERROR_NOTIFICATION_TEXT = 'IO_ERROR_NOTIFICATION_TEXT';
export const JTAG_RDP_LEVEL_ERROR = 'JTAG_RDP_LEVEL_ERROR';
export const LEARN_HOW_TO_USE = 'LEARN_HOW_TO_USE';
export const LED_CHARGER_NOT_CHANGED_NOTIFICATION_TEXT = 'LED_CHARGER_NOT_CHANGED_NOTIFICATION_TEXT';
export const LED_DRV_LM3535_I2C_COMM_ERROR = 'LED_DRV_LM3535_I2C_COMM_ERROR';
export const LED_DRV_VLED_EN_PULL_DOWN_TEST_FAILURE = 'LED_DRV_VLED_EN_PULL_DOWN_TEST_FAILURE';
export const LED_HOLDER_NOT_CHANGED_NOTIFICATION_TEXT = 'LED_HOLDER_NOT_CHANGED_NOTIFICATION_TEXT';
export const LED_MODE_DESCRIPTION = 'LED_MODE_DESCRIPTION';
export const LED_MODE_LEFT_BUTTON_TEXT = 'LED_MODE_LEFT_BUTTON_TEXT';
export const LED_MODE_RIGHT_BUTTON_TEXT = 'LED_MODE_RIGHT_BUTTON_TEXT';
export const LED_MODE_TITLE = 'LED_MODE_TITLE';
export const LOCK_BUTTON_TEXT = 'LOCK_BUTTON_TEXT';
export const LOCK_POPUP_CANCEL_BUTTON_TEXT = 'LOCK_POPUP_CANCEL_BUTTON_TEXT';
export const LOCK_POPUP_LOCK_BUTTON_TEXT = 'LOCK_POPUP_LOCK_BUTTON_TEXT';
export const LOCK_POPUP_TITLE = 'LOCK_POPUP_TITLE';
export const LOCK_POPUP_TITLE_DESCRIPTION = 'LOCK_POPUP_TITLE_DESCRIPTION';
export const LOW_SIDE_FAULT_DETECTED = 'LOW_SIDE_FAULT_DETECTED';
export const MULTI_FIRST_ITEM_TITLE = 'MULTI_FIRST_ITEM_TITLE';
export const MULTI_FOURTH_ITEM_TITLE = 'MULTI_FOURTH_ITEM_TITLE';
export const MULTI_SECOND_ITEM_TITLE = 'MULTI_SECOND_ITEM_TITLE';
export const MULTI_THIRD_ITEM_TITLE = 'MULTI_THIRD_ITEM_TITLE';
export const NOT_BROWSER_BUTTON_TEXT = 'NOT_BROWSER_BUTTON_TEXT';
export const NOT_BROWSER_COMP_HEADER_TEXT = 'NOT_BROWSER_COMP_HEADER_TEXT';
export const NOT_BROWSER_COMP_HEADER_TEXT_BOLD = 'NOT_BROWSER_COMP_HEADER_TEXT_BOLD';
export const NOT_BROWSER_COMP_INSTRUCTION_TITLE = 'NOT_BROWSER_COMP_INSTRUCTION_TITLE';
export const NOT_BROWSER_COMP_STEP_1 = 'NOT_BROWSER_COMP_STEP_1';
export const NOT_BROWSER_COMP_STEP_2 = 'NOT_BROWSER_COMP_STEP_2';
export const NOT_BROWSER_COMP_STEP_3 = 'NOT_BROWSER_COMP_STEP_3';
export const NOT_COMP_ANDROID_APP_BUTTON_TEXT = 'NOT_COMP_ANDROID_APP_BUTTON_TEXT';
export const NOT_COMP_BLUEFY_APP_BUTTON_TEXT = 'NOT_COMP_BLUEFY_APP_BUTTON_TEXT';
export const NOT_COMP_DESKTOP_DESCRIPTION_TEXT = 'NOT_COMP_DESKTOP_DESCRIPTION_TEXT';
export const NOT_COMP_DESKTOP_DOWNLOAD_TEXT = 'NOT_COMP_DESKTOP_DOWNLOAD_TEXT';
export const NOT_COMP_MAC_WIDGET_APP_BUTTON_TEXT = 'NOT_COMP_MAC_WIDGET_APP_BUTTON_TEXT';
export const NOT_COMP_WINDOWS_WIDGET_APP_BUTTON_TEXT = 'NOT_COMP_WINDOWS_WIDGET_APP_BUTTON_TEXT';
export const NOT_FOUND_MESSAGE = 'NOT_FOUND_MESSAGE';
export const NOT_FOUND_TITLE = 'NOT_FOUND_TITLE';
export const OFF_FLOW_TEXT = 'OFF_FLOW_TEXT';
export const OFF_FLOW_TILE_TEXT = 'OFF_FLOW_TILE_TEXT';
export const ONBOARD_HOW_TO_USE_TITAN_HINT_1 = 'ONBOARD_HOW_TO_USE_TITAN_HINT_1';
export const ONBOARD_HOW_TO_USE_TITAN_HINT_2 = 'ONBOARD_HOW_TO_USE_TITAN_HINT_2';
export const ONBOARD_HOW_TO_USE_TITAN_HINT_3 = 'ONBOARD_HOW_TO_USE_TITAN_HINT_3';
export const ONBOARD_HOW_TO_USE_TITAN_HINT_4 = 'ONBOARD_HOW_TO_USE_TITAN_HINT_4';
export const ONBOARD_HOW_TO_USE_TITAN_HINT_5 = 'ONBOARD_HOW_TO_USE_TITAN_HINT_5';
export const ONBOARD_HOW_TO_USE_TITAN_TITLE_1 = 'ONBOARD_HOW_TO_USE_TITAN_TITLE_1';
export const ONBOARD_HOW_TO_USE_TITAN_TITLE_2 = 'ONBOARD_HOW_TO_USE_TITAN_TITLE_2';
export const ONBOARD_HOW_TO_USE_TITAN_TITLE_3 = 'ONBOARD_HOW_TO_USE_TITAN_TITLE_3';
export const ONBOARD_HOW_TO_USE_TITAN_TITLE_4 = 'ONBOARD_HOW_TO_USE_TITAN_TITLE_4';
export const ONBOARD_HOW_TO_USE_TITAN_TITLE_5 = 'ONBOARD_HOW_TO_USE_TITAN_TITLE_5';
export const ORDINARY_FIRST_ITEM_TITLE = 'ORDINARY_FIRST_ITEM_TITLE';
export const ORDINARY_FOURTH_ITEM_TITLE = 'ORDINARY_FOURTH_ITEM_TITLE';
export const ORDINARY_SECOND_ITEM_TITLE = 'ORDINARY_SECOND_ITEM_TITLE';
export const ORDINARY_THIRD_ITEM_TITLE = 'ORDINARY_THIRD_ITEM_TITLE';
export const P4_FIRST_ITEM_TITLE = 'P4_FIRST_ITEM_TITLE';
export const P4_FOURTH_ITEM_TITLE = 'P4_FOURTH_ITEM_TITLE';
export const P4_SECOND_ITEM_TITLE = 'P4_SECOND_ITEM_TITLE';
export const P4_THIRD_ITEM_TITLE = 'P4_THIRD_ITEM_TITLE';
export const PAIR_DEVICE_CORTEZ_ENABLE_PAIRING_DESCRIPTION = 'PAIR_DEVICE_CORTEZ_ENABLE_PAIRING_DESCRIPTION';
export const PAIR_DEVICE_CORTEZ_ENABLE_PAIRING_TITLE = 'PAIR_DEVICE_CORTEZ_ENABLE_PAIRING_TITLE';
export const PAIR_DEVICE_CORTEZ_PAIRING_IN_PROCESS_TITLE = 'PAIR_DEVICE_CORTEZ_PAIRING_IN_PROCESS_TITLE';
export const PAIR_DEVICE_CORTEZ_SUCCESSFULLY_PAIRED_TITLE = 'PAIR_DEVICE_CORTEZ_SUCCESSFULLY_PAIRED_TITLE';
export const PAIR_DEVICE_CORTEZ_TURN_OFF_DESCRIPTION_1 = 'PAIR_DEVICE_CORTEZ_TURN_OFF_DESCRIPTION_1';
export const PAIR_DEVICE_CORTEZ_TURN_OFF_DESCRIPTION_2 = 'PAIR_DEVICE_CORTEZ_TURN_OFF_DESCRIPTION_2';
export const PAIR_DEVICE_CORTEZ_TURN_OFF_TITLE_1 = 'PAIR_DEVICE_CORTEZ_TURN_OFF_TITLE_1';
export const PAIR_DEVICE_CORTEZ_TURN_OFF_TITLE_2 = 'PAIR_DEVICE_CORTEZ_TURN_OFF_TITLE_2';
export const PAIR_DEVICE_NO_BLE_FOUNDED_SKIP_TEXT = 'PAIR_DEVICE_NO_BLE_FOUNDED_SKIP_TEXT';
export const PAIR_DEVICE_NO_BLE_FOUNDED_TRY_AGAIN_TEXT = 'PAIR_DEVICE_NO_BLE_FOUNDED_TRY_AGAIN_TEXT';
export const PAIR_DEVICE_ORDINARY_ENABLE_PAIRING_DESCRIPTION = 'PAIR_DEVICE_ORDINARY_ENABLE_PAIRING_DESCRIPTION';
export const PAIR_DEVICE_ORDINARY_ENABLE_PAIRING_TITLE = 'PAIR_DEVICE_ORDINARY_ENABLE_PAIRING_TITLE';
export const PAIR_DEVICE_ORDINARY_PAIRING_IN_PROCESS_TITLE = 'PAIR_DEVICE_ORDINARY_PAIRING_IN_PROCESS_TITLE';
export const PAIR_DEVICE_ORDINARY_SUCCESSFULLY_PAIRED_TITLE = 'PAIR_DEVICE_ORDINARY_SUCCESSFULLY_PAIRED_TITLE';
export const PAIR_DEVICE_ORDINARY_TURN_ON_DESCRIPTION_1 = 'PAIR_DEVICE_ORDINARY_TURN_ON_DESCRIPTION_1';
export const PAIR_DEVICE_ORDINARY_TURN_ON_DESCRIPTION_2 = 'PAIR_DEVICE_ORDINARY_TURN_ON_DESCRIPTION_2';
export const PAIR_DEVICE_ORDINARY_TURN_ON_TITLE_1 = 'PAIR_DEVICE_ORDINARY_TURN_ON_TITLE_1';
export const PAIR_DEVICE_ORDINARY_TURN_ON_TITLE_2 = 'PAIR_DEVICE_ORDINARY_TURN_ON_TITLE_2';
export const PAIR_DEVICE_PAIRED_TITLE = 'PAIR_DEVICE_PAIRED_TITLE';
export const PAIR_DEVICE_SOYUZ_ENABLE_PAIRING_DESCRIPTION = 'PAIR_DEVICE_SOYUZ_ENABLE_PAIRING_DESCRIPTION';
export const PAIR_DEVICE_SOYUZ_ENABLE_PAIRING_TITLE = 'PAIR_DEVICE_SOYUZ_ENABLE_PAIRING_TITLE';
export const PAIR_DEVICE_SOYUZ_PAIRING_IN_PROCESS_TITLE = 'PAIR_DEVICE_SOYUZ_PAIRING_IN_PROCESS_TITLE';
export const PAIR_DEVICE_SOYUZ_SUCCESSFULLY_PAIRED_TITLE = 'PAIR_DEVICE_SOYUZ_SUCCESSFULLY_PAIRED_TITLE';
export const PAIR_DEVICE_SOYUZ_TURNOFF_DESCRIPTION_1 = 'PAIR_DEVICE_SOYUZ_TURNOFF_DESCRIPTION_1';
export const PAIR_DEVICE_SOYUZ_TURNOFF_DESCRIPTION_2 = 'PAIR_DEVICE_SOYUZ_TURNOFF_DESCRIPTION_2';
export const PAIR_DEVICE_SOYUZ_TURNOFF_TITLE_1 = 'PAIR_DEVICE_SOYUZ_TURNOFF_TITLE_1';
export const PAIR_DEVICE_SOYUZ_TURNOFF_TITLE_2 = 'PAIR_DEVICE_SOYUZ_TURNOFF_TITLE_2';
export const PAIR_DEVICE_TITAN_ENABLE_PAIRING_DESCRIPTION_1 = 'PAIR_DEVICE_TITAN_ENABLE_PAIRING_DESCRIPTION_1';
export const PAIR_DEVICE_TITAN_ENABLE_PAIRING_DESCRIPTION_2 = 'PAIR_DEVICE_TITAN_ENABLE_PAIRING_DESCRIPTION_2';
export const PAIR_DEVICE_TITAN_ENABLE_PAIRING_DESCRIPTION_3 = 'PAIR_DEVICE_TITAN_ENABLE_PAIRING_DESCRIPTION_3';
export const PAIR_DEVICE_TITAN_ENABLE_PAIRING_TITLE_1 = 'PAIR_DEVICE_TITAN_ENABLE_PAIRING_TITLE_1';
export const PAIR_DEVICE_TITAN_ENABLE_PAIRING_TITLE_2 = 'PAIR_DEVICE_TITAN_ENABLE_PAIRING_TITLE_2';
export const PAIR_DEVICE_TITAN_ENABLE_PAIRING_TITLE_3 = 'PAIR_DEVICE_TITAN_ENABLE_PAIRING_TITLE_3';
export const PAIR_DEVICE_TITAN_PAIRING_IN_PROCESS_TITLE = 'PAIR_DEVICE_TITAN_PAIRING_IN_PROCESS_TITLE';
export const PAIR_DEVICE_TITAN_SUCCESSFULLY_PAIRED_TITLE = 'PAIR_DEVICE_TITAN_SUCCESSFULLY_PAIRED_TITLE';
export const PAIR_DEVICE_TITAN_TURN_OFF_DESCRIPTION_1 = 'PAIR_DEVICE_TITAN_TURN_OFF_DESCRIPTION_1';
export const PAIR_DEVICE_TITAN_TURN_OFF_DESCRIPTION_2 = 'PAIR_DEVICE_TITAN_TURN_OFF_DESCRIPTION_2';
export const PAIR_DEVICE_TITAN_TURN_OFF_TITLE_1 = 'PAIR_DEVICE_TITAN_TURN_OFF_TITLE_1';
export const PAIR_DEVICE_TITAN_TURN_OFF_TITLE_2 = 'PAIR_DEVICE_TITAN_TURN_OFF_TITLE_2';
export const PAIR_DEVICE_VESPUCCI_ENABLE_PAIRING_DESCRIPTION = 'PAIR_DEVICE_VESPUCCI_ENABLE_PAIRING_DESCRIPTION';
export const PAIR_DEVICE_VESPUCCI_ENABLE_PAIRING_TITLE = 'PAIR_DEVICE_VESPUCCI_ENABLE_PAIRING_TITLE';
export const PAIR_DEVICE_VESPUCCI_PAIRING_IN_PROCESS_TITLE = 'PAIR_DEVICE_VESPUCCI_PAIRING_IN_PROCESS_TITLE';
export const PAIR_DEVICE_VESPUCCI_SUCCESSFULLY_PAIRED_TITLE = 'PAIR_DEVICE_VESPUCCI_SUCCESSFULLY_PAIRED_TITLE';
export const PAIR_DEVICE_VESPUCCI_TURNOFF_DESCRIPTION_1 = 'PAIR_DEVICE_VESPUCCI_TURNOFF_DESCRIPTION_1';
export const PAIR_DEVICE_VESPUCCI_TURNOFF_DESCRIPTION_2 = 'PAIR_DEVICE_VESPUCCI_TURNOFF_DESCRIPTION_2';
export const PAIR_DEVICE_VESPUCCI_TURNOFF_TITLE_1 = 'PAIR_DEVICE_VESPUCCI_TURNOFF_TITLE_1';
export const PAIR_DEVICE_VESPUCCI_TURNOFF_TITLE_2 = 'PAIR_DEVICE_VESPUCCI_TURNOFF_TITLE_2';
export const PAIR_FAIL_SKIP_BUTTON = 'PAIR_FAIL_SKIP_BUTTON';
export const PAIR_FAIL_TRY_AGAIN_BUTTON = 'PAIR_FAIL_TRY_AGAIN_BUTTON';
export const PAIRING_FAIL_DESCRIPTION_CORTEZ = 'PAIRING_FAIL_DESCRIPTION_CORTEZ';
export const PAIRING_FAIL_DESCRIPTION_ORDINARY = 'PAIRING_FAIL_DESCRIPTION_ORDINARY';
export const PAIRING_FAIL_DESCRIPTION_SOYUZ = 'PAIRING_FAIL_DESCRIPTION_SOYUZ';
export const PAIRING_FAIL_DESCRIPTION_TITAN = 'PAIRING_FAIL_DESCRIPTION_TITAN';
export const PAIRING_FAIL_DESCRIPTION_VESPUCCI = 'PAIRING_FAIL_DESCRIPTION_VESPUCCI';
export const PAIRING_NOT_COMPATIBILITY_VERSION_BUTTON = 'PAIRING_NOT_COMPATIBILITY_VERSION_BUTTON';
export const PAIRING_NOT_COMPATIBILITY_VERSION_MESSAGE = 'PAIRING_NOT_COMPATIBILITY_VERSION_MESSAGE';
export const PAIRING_NOT_COMPATIBILITY_VERSION_TITLE = 'PAIRING_NOT_COMPATIBILITY_VERSION_TITLE';
export const PAIRING_TROUBLESHOOTING_TEXT_CORTEZ = 'PAIRING_TROUBLESHOOTING_TEXT_CORTEZ';
export const PAIRING_TROUBLESHOOTING_TEXT_ORDINARY = 'PAIRING_TROUBLESHOOTING_TEXT_ORDINARY';
export const PAIRING_TROUBLESHOOTING_TEXT_SOYUZ = 'PAIRING_TROUBLESHOOTING_TEXT_SOYUZ';
export const PAIRING_TROUBLESHOOTING_TEXT_TITAN = 'PAIRING_TROUBLESHOOTING_TEXT_TITAN';
export const PAIRING_TROUBLESHOOTING_TEXT_VESPUCCI = 'PAIRING_TROUBLESHOOTING_TEXT_VESPUCCI';
export const PAIRING_UNABLE_DEVICE_NOT_SUPPORTED_MESSAGE = 'PAIRING_UNABLE_DEVICE_NOT_SUPPORTED_MESSAGE';
export const PAIRING_UNABLE_DEVICE_NOT_SUPPORTED_SKIP_BUTTON = 'PAIRING_UNABLE_DEVICE_NOT_SUPPORTED_SKIP_BUTTON';
export const PAIRING_UNABLE_DEVICE_NOT_SUPPORTED_TITLE = 'PAIRING_UNABLE_DEVICE_NOT_SUPPORTED_TITLE';
export const PAIRING_UNABLE_DEVICE_NOT_SUPPORTED_TRY_AGAIN_BUTTON =
    'PAIRING_UNABLE_DEVICE_NOT_SUPPORTED_TRY_AGAIN_BUTTON';
export const PARENTAL_CONTROL_DEVICE_LOCKED_MESSAGE = 'PARENTAL_CONTROL_DEVICE_LOCKED_MESSAGE';
export const PARENTAL_CONTROL_FIFTEEN_HOURS_TEXT = 'PARENTAL_CONTROL_FIFTEEN_HOURS_TEXT';
export const PARENTAL_CONTROL_FIVE_HOURS_TEXT = 'PARENTAL_CONTROL_FIVE_HOURS_TEXT';
export const PARENTAL_CONTROL_HALF_HOUR_TEXT = 'PARENTAL_CONTROL_HALF_HOUR_TEXT';
export const PARENTAL_CONTROL_ONE_HOUR_TEXT = 'PARENTAL_CONTROL_ONE_HOUR_TEXT';
export const PARENTAL_CONTROL_TEN_HOURS_TEXT = 'PARENTAL_CONTROL_TEN_HOURS_TEXT';
export const PARENTAL_CONTROL_TWENTY_ONE_HOURS_TEXT = 'PARENTAL_CONTROL_TWENTY_ONE_HOURS_TEXT';
export const PARENTAL_CONTROL_TWO_HOURS_TEXT = 'PARENTAL_CONTROL_TWO_HOURS_TEXT';
export const POPUP_CARE_CLEAN_DEVICE_ACTION_BUTTON_TEXT = 'POPUP_CARE_CLEAN_DEVICE_ACTION_BUTTON_TEXT';
export const POPUP_CARE_CLEAN_DEVICE_DESCRIPTION_TEXT = 'POPUP_CARE_CLEAN_DEVICE_DESCRIPTION_TEXT';
export const POPUP_CARE_CLEAN_DEVICE_TITLE_TEXT = 'POPUP_CARE_CLEAN_DEVICE_TITLE_TEXT';
export const POPUP_CARE_CONTACT_DEVICE_ACTION_BUTTON_TEXT = 'POPUP_CARE_CONTACT_DEVICE_ACTION_BUTTON_TEXT';
export const POPUP_CARE_CONTACT_DEVICE_DESCRIPTION_TEXT = 'POPUP_CARE_CONTACT_DEVICE_DESCRIPTION_TEXT';
export const POPUP_CARE_CONTACT_DEVICE_TITLE_TEXT = 'POPUP_CARE_CONTACT_DEVICE_TITLE_TEXT';
export const POPUP_CARE_RESET_DEVICE_ACTION_BUTTON_TEXT = 'POPUP_CARE_RESET_DEVICE_ACTION_BUTTON_TEXT';
export const POPUP_CARE_RESET_DEVICE_DESCRIPTION_TEXT = 'POPUP_CARE_RESET_DEVICE_DESCRIPTION_TEXT';
export const POPUP_CARE_RESET_DEVICE_TITLE_TEXT = 'POPUP_CARE_RESET_DEVICE_TITLE_TEXT';
export const PREFERENCE_CENTER_DESCRIPTION_TEXT = 'PREFERENCE_CENTER_DESCRIPTION_TEXT';
export const PREFERENCE_CENTER_SAVE_AND_CONTINUE_BUTTON_TEXT = 'PREFERENCE_CENTER_SAVE_AND_CONTINUE_BUTTON_TEXT';
export const PREFERENCE_CENTER_TITLE_TEXT = 'PREFERENCE_CENTER_TITLE_TEXT';
export const QUICK_START_GUIDE_CORTEZ_DESCRIPTION_1 = 'QUICK_START_GUIDE_CORTEZ_DESCRIPTION_1';
export const QUICK_START_GUIDE_CORTEZ_DESCRIPTION_2 = 'QUICK_START_GUIDE_CORTEZ_DESCRIPTION_2';
export const QUICK_START_GUIDE_CORTEZ_DESCRIPTION_3 = 'QUICK_START_GUIDE_CORTEZ_DESCRIPTION_3';
export const QUICK_START_GUIDE_CORTEZ_DESCRIPTION_4 = 'QUICK_START_GUIDE_CORTEZ_DESCRIPTION_4';
export const QUICK_START_GUIDE_CORTEZ_DESCRIPTION_5 = 'QUICK_START_GUIDE_CORTEZ_DESCRIPTION_5';
export const QUICK_START_GUIDE_CORTEZ_TITLE_1 = 'QUICK_START_GUIDE_CORTEZ_TITLE_1';
export const QUICK_START_GUIDE_CORTEZ_TITLE_2 = 'QUICK_START_GUIDE_CORTEZ_TITLE_2';
export const QUICK_START_GUIDE_CORTEZ_TITLE_3 = 'QUICK_START_GUIDE_CORTEZ_TITLE_3';
export const QUICK_START_GUIDE_CORTEZ_TITLE_4 = 'QUICK_START_GUIDE_CORTEZ_TITLE_4';
export const QUICK_START_GUIDE_CORTEZ_TITLE_5 = 'QUICK_START_GUIDE_CORTEZ_TITLE_5';
export const QUICK_START_GUIDE_DUO_DESCRIPTION_1 = 'QUICK_START_GUIDE_DUO_DESCRIPTION_1';
export const QUICK_START_GUIDE_DUO_DESCRIPTION_2 = 'QUICK_START_GUIDE_DUO_DESCRIPTION_2';
export const QUICK_START_GUIDE_DUO_DESCRIPTION_3 = 'QUICK_START_GUIDE_DUO_DESCRIPTION_3';
export const QUICK_START_GUIDE_DUO_DESCRIPTION_4 = 'QUICK_START_GUIDE_DUO_DESCRIPTION_4';
export const QUICK_START_GUIDE_DUO_DESCRIPTION_5 = 'QUICK_START_GUIDE_DUO_DESCRIPTION_5';
export const QUICK_START_GUIDE_DUO_TITLE_1 = 'QUICK_START_GUIDE_DUO_TITLE_1';
export const QUICK_START_GUIDE_DUO_TITLE_2 = 'QUICK_START_GUIDE_DUO_TITLE_2';
export const QUICK_START_GUIDE_DUO_TITLE_3 = 'QUICK_START_GUIDE_DUO_TITLE_3';
export const QUICK_START_GUIDE_DUO_TITLE_4 = 'QUICK_START_GUIDE_DUO_TITLE_4';
export const QUICK_START_GUIDE_DUO_TITLE_5 = 'QUICK_START_GUIDE_DUO_TITLE_5';
export const QUICK_START_GUIDE_MULTI_DESCRIPTION_1 = 'QUICK_START_GUIDE_MULTI_DESCRIPTION_1';
export const QUICK_START_GUIDE_MULTI_DESCRIPTION_2 = 'QUICK_START_GUIDE_MULTI_DESCRIPTION_2';
export const QUICK_START_GUIDE_MULTI_DESCRIPTION_3 = 'QUICK_START_GUIDE_MULTI_DESCRIPTION_3';
export const QUICK_START_GUIDE_MULTI_DESCRIPTION_4 = 'QUICK_START_GUIDE_MULTI_DESCRIPTION_4';
export const QUICK_START_GUIDE_MULTI_DESCRIPTION_5 = 'QUICK_START_GUIDE_MULTI_DESCRIPTION_5';
export const QUICK_START_GUIDE_MULTI_TITLE_1 = 'QUICK_START_GUIDE_MULTI_TITLE_1';
export const QUICK_START_GUIDE_MULTI_TITLE_2 = 'QUICK_START_GUIDE_MULTI_TITLE_2';
export const QUICK_START_GUIDE_MULTI_TITLE_3 = 'QUICK_START_GUIDE_MULTI_TITLE_3';
export const QUICK_START_GUIDE_MULTI_TITLE_4 = 'QUICK_START_GUIDE_MULTI_TITLE_4';
export const QUICK_START_GUIDE_MULTI_TITLE_5 = 'QUICK_START_GUIDE_MULTI_TITLE_5';
export const QUICK_START_GUIDE_NEXT_BUTTON_TEXT = 'QUICK_START_GUIDE_NEXT_BUTTON_TEXT';
export const QUICK_START_GUIDE_ORDINARY_DESCRIPTION_1 = 'QUICK_START_GUIDE_ORDINARY_DESCRIPTION_1';
export const QUICK_START_GUIDE_ORDINARY_DESCRIPTION_2 = 'QUICK_START_GUIDE_ORDINARY_DESCRIPTION_2';
export const QUICK_START_GUIDE_ORDINARY_DESCRIPTION_3 = 'QUICK_START_GUIDE_ORDINARY_DESCRIPTION_3';
export const QUICK_START_GUIDE_ORDINARY_DESCRIPTION_4 = 'QUICK_START_GUIDE_ORDINARY_DESCRIPTION_4';
export const QUICK_START_GUIDE_ORDINARY_TITLE_1 = 'QUICK_START_GUIDE_ORDINARY_TITLE_1';
export const QUICK_START_GUIDE_ORDINARY_TITLE_2 = 'QUICK_START_GUIDE_ORDINARY_TITLE_2';
export const QUICK_START_GUIDE_ORDINARY_TITLE_3 = 'QUICK_START_GUIDE_ORDINARY_TITLE_3';
export const QUICK_START_GUIDE_ORDINARY_TITLE_4 = 'QUICK_START_GUIDE_ORDINARY_TITLE_4';
export const QUICK_START_GUIDE_VEEV_DESCRIPTION_1 = 'QUICK_START_GUIDE_VEEV_DESCRIPTION_1';
export const QUICK_START_GUIDE_VEEV_DESCRIPTION_2 = 'QUICK_START_GUIDE_VEEV_DESCRIPTION_2';
export const QUICK_START_GUIDE_VEEV_DESCRIPTION_3 = 'QUICK_START_GUIDE_VEEV_DESCRIPTION_3';
export const QUICK_START_GUIDE_VEEV_DESCRIPTION_4 = 'QUICK_START_GUIDE_VEEV_DESCRIPTION_4';
export const QUICK_START_GUIDE_VEEV_DESCRIPTION_5 = 'QUICK_START_GUIDE_VEEV_DESCRIPTION_5';
export const QUICK_START_GUIDE_VEEV_TITLE_1 = 'QUICK_START_GUIDE_VEEV_TITLE_1';
export const QUICK_START_GUIDE_VEEV_TITLE_2 = 'QUICK_START_GUIDE_VEEV_TITLE_2';
export const QUICK_START_GUIDE_VEEV_TITLE_3 = 'QUICK_START_GUIDE_VEEV_TITLE_3';
export const QUICK_START_GUIDE_VEEV_TITLE_4 = 'QUICK_START_GUIDE_VEEV_TITLE_4';
export const QUICK_START_GUIDE_VEEV_TITLE_5 = 'QUICK_START_GUIDE_VEEV_TITLE_5';
export const QUIT_SET_UP_CONFIRM_BUTTON_TEXT = 'QUIT_SET_UP_CONFIRM_BUTTON_TEXT';
export const QUIT_SET_UP_DESCRIPTION_TEXT = 'QUIT_SET_UP_DESCRIPTION_TEXT';
export const QUIT_SET_UP_EXTRA_FEATURES_DESCRIPTION_TEXT = 'QUIT_SET_UP_EXTRA_FEATURES_DESCRIPTION_TEXT';
export const QUIT_SET_UP_SKIP_BUTTON_TEXT = 'QUIT_SET_UP_SKIP_BUTTON_TEXT';
export const QUIT_SET_UP_TITLE_TEXT = 'QUIT_SET_UP_TITLE_TEXT';
export const QUIT_SET_UP_TROUBLESHOOT_DESCRIPTION_TEXT = 'QUIT_SET_UP_TROUBLESHOOT_DESCRIPTION_TEXT';
export const REGISTRATION_FAIL_TRY_AGAIN_ACTIVATION = 'REGISTRATION_FAIL_TRY_AGAIN_ACTIVATION';
export const REGISTRATION_FAIL_TRY_AGAIN_ACTIVATION_BUTTON_TEXT = 'REGISTRATION_FAIL_TRY_AGAIN_ACTIVATION_BUTTON_TEXT';
export const REGISTRATION_FAILED_CONTACT_BUTTON_TEXT = 'REGISTRATION_FAILED_CONTACT_BUTTON_TEXT';
export const REGISTRATION_FAILED_GOT_IT_BUTTON_TEXT = 'REGISTRATION_FAILED_GOT_IT_BUTTON_TEXT';
export const REGISTRATION_FAILED_TRY_AGAIN_BUTTON_TEXT = 'REGISTRATION_FAILED_TRY_AGAIN_BUTTON_TEXT';
export const REGISTRATION_FAILED_WEB_BUTTON_TEXT = 'REGISTRATION_FAILED_WEB_BUTTON_TEXT';
export const REMOVE_DEVICE_CONFIRM_POPUP_CANCEL_TEXT = 'REMOVE_DEVICE_CONFIRM_POPUP_CANCEL_TEXT';
export const REMOVE_DEVICE_CONFIRM_POPUP_CONFIRM_TEXT = 'REMOVE_DEVICE_CONFIRM_POPUP_CONFIRM_TEXT';
export const REMOVE_DEVICE_CONFIRM_POPUP_MESSAGE_TEXT = 'REMOVE_DEVICE_CONFIRM_POPUP_MESSAGE_TEXT';
export const REMOVE_DEVICE_CONFIRM_POPUP_TITLE_TEXT = 'REMOVE_DEVICE_CONFIRM_POPUP_TITLE_TEXT';
export const RENAME_DEVICE_CONFIRM_POPUP_PRIMARY_TEXT = 'RENAME_DEVICE_CONFIRM_POPUP_PRIMARY_TEXT';
export const RENAME_DEVICE_CONFIRM_POPUP_SECONDARY_TEXT = 'RENAME_DEVICE_CONFIRM_POPUP_SECONDARY_TEXT';
export const RES_CHAIN_FAULT_DETECTED = 'RES_CHAIN_FAULT_DETECTED';
export const RESET_TO_DEFAULT_BUTTON_TEXT = 'RESET_TO_DEFAULT_BUTTON_TEXT';
export const RESPONSIVE_FLOW_ALLEGRO_TITLE_TEXT_KEY = 'RESPONSIVE_FLOW_ALLEGRO_TITLE_TEXT_KEY';
export const RESPONSIVE_FLOW_BUBBLY_TITLE_TEXT_KEY = 'RESPONSIVE_FLOW_BUBBLY_TITLE_TEXT_KEY';
export const RESPONSIVE_FLOW_CRESCENDO_TITLE_TEXT_KEY = 'RESPONSIVE_FLOW_CRESCENDO_TITLE_TEXT_KEY';
export const RESPONSIVE_FLOW_MODE_DESCRIPTION = 'RESPONSIVE_FLOW_MODE_DESCRIPTION';
export const RESPONSIVE_FLOW_MODE_TITLE = 'RESPONSIVE_FLOW_MODE_TITLE';
export const RESPONSIVE_FLOW_OFF_SETTINGS_TEXT_KEY = 'RESPONSIVE_FLOW_OFF_SETTINGS_TEXT_KEY';
export const RESPONSIVE_FLOW_OFF_TITLE_TEXT_KEY = 'RESPONSIVE_FLOW_OFF_TITLE_TEXT_KEY';
export const RESPONSIVE_FLOW_POPUP_DESCRIPTION = 'RESPONSIVE_FLOW_POPUP_DESCRIPTION';
export const RESPONSIVE_FLOW_POPUP_TITLE = 'RESPONSIVE_FLOW_POPUP_TITLE';
export const RESPONSIVE_FLOW_SIREN_TITLE_TEXT_KEY = 'RESPONSIVE_FLOW_SIREN_TITLE_TEXT_KEY';
export const RESPONSIVE_FLOW_VIVALDI_TITLE_TEXT_KEY = 'RESPONSIVE_FLOW_VIVALDI_TITLE_TEXT_KEY';
export const RESPONSIVE_TEXT = 'RESPONSIVE_TEXT';
export const S1_PULL_UP_RES_TEST_FAILURE = 'S1_PULL_UP_RES_TEST_FAILURE';
export const SELF_TEST_FAIL = 'SELF_TEST_FAIL';
export const SIREN_FLOW_TEXT = 'SIREN_FLOW_TEXT';
export const SIREN_FLOW_TILE_TEXT = 'SIREN_FLOW_TILE_TEXT';
export const SKIP_LEARN_HOW_TO_USE = 'SKIP_LEARN_HOW_TO_USE';
export const SOYUZ_FIRST_ITEM_TITLE = 'SOYUZ_FIRST_ITEM_TITLE';
export const SOYUZ_FOURTH_ITEM_TITLE = 'SOYUZ_FOURTH_ITEM_TITLE';
export const SOYUZ_SECOND_ITEM_TITLE = 'SOYUZ_SECOND_ITEM_TITLE';
export const SOYUZ_THIRD_ITEM_TITLE = 'SOYUZ_THIRD_ITEM_TITLE';
export const START_CLEANING_BUTTON_TEXT = 'START_CLEANING_BUTTON_TEXT';
export const START_FINDING_BUTTON_TEXT = 'START_FINDING_BUTTON_TEXT';
export const STOP_FINDING_BUTTON_TEXT = 'STOP_FINDING_BUTTON_TEXT';
export const SYSTEM_FAULT_EXT_VOLTAGE_REF_FAILURE_ERROR_MESSAGE = 'SYSTEM_FAULT_EXT_VOLTAGE_REF_FAILURE_ERROR_MESSAGE';
export const SYSTEM_FAULT_HEATER_BROKEN_OR_ABSENT = 'SYSTEM_FAULT_HEATER_BROKEN_OR_ABSENT';
export const SYSTEM_FAULT_INV_MANUF_INFO_BLOCK_CRC = 'SYSTEM_FAULT_INV_MANUF_INFO_BLOCK_CRC';
export const SYSTEM_FAULT_RTC_OSC_FAULT = 'SYSTEM_FAULT_RTC_OSC_FAULT';
export const SYSTEM_FAULT_TOO_MUCH_UNEXP_DUTY_CYCLE_VAR = 'SYSTEM_FAULT_TOO_MUCH_UNEXP_DUTY_CYCLE_VAR';
export const SYSTEM_FAULT_UNEXP_HEATER_CURRENT_SW_ON = 'SYSTEM_FAULT_UNEXP_HEATER_CURRENT_SW_ON';
export const TERMS_SUMMARY_CONFIRM_BUTTON_TEXT = 'TERMS_SUMMARY_CONFIRM_BUTTON_TEXT';
export const TERMS_SUMMARY_DECLINE_BUTTON_TEXT = 'TERMS_SUMMARY_DECLINE_BUTTON_TEXT';
export const TERMS_SUMMARY_ERROR_NOTIFICATION_TEXT = 'TERMS_SUMMARY_ERROR_NOTIFICATION_TEXT';
export const TERMS_SUMMARY_TITLE_TEXT = 'TERMS_SUMMARY_TITLE_TEXT';
export const TERMS_SUMMARY_UPDATE_TITLE_TEXT = 'TERMS_SUMMARY_UPDATE_TITLE_TEXT';
export const TIPS_CORTEZ_BATTERY_DESCRIPTION_1 = 'TIPS_CORTEZ_BATTERY_DESCRIPTION_1';
export const TIPS_CORTEZ_BATTERY_DESCRIPTION_2 = 'TIPS_CORTEZ_BATTERY_DESCRIPTION_2';
export const TIPS_CORTEZ_BATTERY_DESCRIPTION_3 = 'TIPS_CORTEZ_BATTERY_DESCRIPTION_3';
export const TIPS_CORTEZ_BATTERY_DESCRIPTION_4 = 'TIPS_CORTEZ_BATTERY_DESCRIPTION_4';
export const TIPS_CORTEZ_BATTERY_DESCRIPTION_5 = 'TIPS_CORTEZ_BATTERY_DESCRIPTION_5';
export const TIPS_CORTEZ_BATTERY_DESCRIPTION_6 = 'TIPS_CORTEZ_BATTERY_DESCRIPTION_6';
export const TIPS_CORTEZ_BATTERY_TITLE_1 = 'TIPS_CORTEZ_BATTERY_TITLE_1';
export const TIPS_CORTEZ_BATTERY_TITLE_2 = 'TIPS_CORTEZ_BATTERY_TITLE_2';
export const TIPS_CORTEZ_BATTERY_TITLE_3 = 'TIPS_CORTEZ_BATTERY_TITLE_3';
export const TIPS_CORTEZ_BATTERY_TITLE_4 = 'TIPS_CORTEZ_BATTERY_TITLE_4';
export const TIPS_CORTEZ_BATTERY_TITLE_5 = 'TIPS_CORTEZ_BATTERY_TITLE_5';
export const TIPS_CORTEZ_BATTERY_TITLE_6 = 'TIPS_CORTEZ_BATTERY_TITLE_6';
export const TIPS_CORTEZ_CLEANING_DESCRIPTION_1 = 'TIPS_CORTEZ_CLEANING_DESCRIPTION_1';
export const TIPS_CORTEZ_CLEANING_DESCRIPTION_2 = 'TIPS_CORTEZ_CLEANING_DESCRIPTION_2';
export const TIPS_CORTEZ_CLEANING_DESCRIPTION_3 = 'TIPS_CORTEZ_CLEANING_DESCRIPTION_3';
export const TIPS_CORTEZ_CLEANING_DESCRIPTION_4 = 'TIPS_CORTEZ_CLEANING_DESCRIPTION_4';
export const TIPS_CORTEZ_CLEANING_DESCRIPTION_5 = 'TIPS_CORTEZ_CLEANING_DESCRIPTION_5';
export const TIPS_CORTEZ_CLEANING_DESCRIPTION_6 = 'TIPS_CORTEZ_CLEANING_DESCRIPTION_6';
export const TIPS_CORTEZ_CLEANING_DESCRIPTION_7 = 'TIPS_CORTEZ_CLEANING_DESCRIPTION_7';
export const TIPS_CORTEZ_CLEANING_DESCRIPTION_8 = 'TIPS_CORTEZ_CLEANING_DESCRIPTION_8';
export const TIPS_CORTEZ_CLEANING_DESCRIPTION_9 = 'TIPS_CORTEZ_CLEANING_DESCRIPTION_9';
export const TIPS_CORTEZ_CLEANING_TITLE_1 = 'TIPS_CORTEZ_CLEANING_TITLE_1';
export const TIPS_CORTEZ_CLEANING_TITLE_2 = 'TIPS_CORTEZ_CLEANING_TITLE_2';
export const TIPS_CORTEZ_CLEANING_TITLE_3 = 'TIPS_CORTEZ_CLEANING_TITLE_3';
export const TIPS_CORTEZ_CLEANING_TITLE_4 = 'TIPS_CORTEZ_CLEANING_TITLE_4';
export const TIPS_CORTEZ_CLEANING_TITLE_5 = 'TIPS_CORTEZ_CLEANING_TITLE_5';
export const TIPS_CORTEZ_CLEANING_TITLE_6 = 'TIPS_CORTEZ_CLEANING_TITLE_6';
export const TIPS_CORTEZ_CLEANING_TITLE_7 = 'TIPS_CORTEZ_CLEANING_TITLE_7';
export const TIPS_CORTEZ_CLEANING_TITLE_8 = 'TIPS_CORTEZ_CLEANING_TITLE_8';
export const TIPS_CORTEZ_CLEANING_TITLE_9 = 'TIPS_CORTEZ_CLEANING_TITLE_9';
export const TIPS_CORTEZ_DOS_DESCRIPTION_1 = 'TIPS_CORTEZ_DOS_DESCRIPTION_1';
export const TIPS_CORTEZ_DOS_DESCRIPTION_2 = 'TIPS_CORTEZ_DOS_DESCRIPTION_2';
export const TIPS_CORTEZ_DOS_DESCRIPTION_3 = 'TIPS_CORTEZ_DOS_DESCRIPTION_3';
export const TIPS_CORTEZ_DOS_DESCRIPTION_4 = 'TIPS_CORTEZ_DOS_DESCRIPTION_4';
export const TIPS_CORTEZ_DOS_DESCRIPTION_5 = 'TIPS_CORTEZ_DOS_DESCRIPTION_5';
export const TIPS_CORTEZ_DOS_TITLE_1 = 'TIPS_CORTEZ_DOS_TITLE_1';
export const TIPS_CORTEZ_DOS_TITLE_2 = 'TIPS_CORTEZ_DOS_TITLE_2';
export const TIPS_CORTEZ_DOS_TITLE_3 = 'TIPS_CORTEZ_DOS_TITLE_3';
export const TIPS_CORTEZ_DOS_TITLE_4 = 'TIPS_CORTEZ_DOS_TITLE_4';
export const TIPS_CORTEZ_DOS_TITLE_5 = 'TIPS_CORTEZ_DOS_TITLE_5';
export const TIPS_CORTEZ_TITLE = 'TIPS_CORTEZ_TITLE';
export const TIPS_IQOS_DESCRIPTION = 'TIPS_IQOS_DESCRIPTION';
export const TIPS_MULTI_BATTERY_DESCRIPTION_1 = 'TIPS_MULTI_BATTERY_DESCRIPTION_1';
export const TIPS_MULTI_BATTERY_DESCRIPTION_2 = 'TIPS_MULTI_BATTERY_DESCRIPTION_2';
export const TIPS_MULTI_BATTERY_TITLE_1 = 'TIPS_MULTI_BATTERY_TITLE_1';
export const TIPS_MULTI_BATTERY_TITLE_2 = 'TIPS_MULTI_BATTERY_TITLE_2';
export const TIPS_MULTI_CLEANING_DESCRIPTION_1 = 'TIPS_MULTI_CLEANING_DESCRIPTION_1';
export const TIPS_MULTI_CLEANING_DESCRIPTION_2 = 'TIPS_MULTI_CLEANING_DESCRIPTION_2';
export const TIPS_MULTI_CLEANING_DESCRIPTION_3 = 'TIPS_MULTI_CLEANING_DESCRIPTION_3';
export const TIPS_MULTI_CLEANING_DESCRIPTION_4 = 'TIPS_MULTI_CLEANING_DESCRIPTION_4';
export const TIPS_MULTI_CLEANING_DESCRIPTION_5 = 'TIPS_MULTI_CLEANING_DESCRIPTION_5';
export const TIPS_MULTI_CLEANING_DESCRIPTION_6 = 'TIPS_MULTI_CLEANING_DESCRIPTION_6';
export const TIPS_MULTI_CLEANING_DESCRIPTION_7 = 'TIPS_MULTI_CLEANING_DESCRIPTION_7';
export const TIPS_MULTI_CLEANING_DESCRIPTION_8 = 'TIPS_MULTI_CLEANING_DESCRIPTION_8';
export const TIPS_MULTI_CLEANING_DESCRIPTION_9 = 'TIPS_MULTI_CLEANING_DESCRIPTION_9';
export const TIPS_MULTI_CLEANING_TITLE_1 = 'TIPS_MULTI_CLEANING_TITLE_1';
export const TIPS_MULTI_CLEANING_TITLE_2 = 'TIPS_MULTI_CLEANING_TITLE_2';
export const TIPS_MULTI_CLEANING_TITLE_3 = 'TIPS_MULTI_CLEANING_TITLE_3';
export const TIPS_MULTI_CLEANING_TITLE_4 = 'TIPS_MULTI_CLEANING_TITLE_4';
export const TIPS_MULTI_CLEANING_TITLE_5 = 'TIPS_MULTI_CLEANING_TITLE_5';
export const TIPS_MULTI_CLEANING_TITLE_6 = 'TIPS_MULTI_CLEANING_TITLE_6';
export const TIPS_MULTI_CLEANING_TITLE_7 = 'TIPS_MULTI_CLEANING_TITLE_7';
export const TIPS_MULTI_CLEANING_TITLE_8 = 'TIPS_MULTI_CLEANING_TITLE_8';
export const TIPS_MULTI_CLEANING_TITLE_9 = 'TIPS_MULTI_CLEANING_TITLE_9';
export const TIPS_MULTI_DOS_DESCRIPTION_1 = 'TIPS_MULTI_DOS_DESCRIPTION_1';
export const TIPS_MULTI_DOS_DESCRIPTION_2 = 'TIPS_MULTI_DOS_DESCRIPTION_2';
export const TIPS_MULTI_DOS_DESCRIPTION_3 = 'TIPS_MULTI_DOS_DESCRIPTION_3';
export const TIPS_MULTI_DOS_DESCRIPTION_4 = 'TIPS_MULTI_DOS_DESCRIPTION_4';
export const TIPS_MULTI_DOS_DESCRIPTION_5 = 'TIPS_MULTI_DOS_DESCRIPTION_5';
export const TIPS_MULTI_DOS_TITLE_1 = 'TIPS_MULTI_DOS_TITLE_1';
export const TIPS_MULTI_DOS_TITLE_2 = 'TIPS_MULTI_DOS_TITLE_2';
export const TIPS_MULTI_DOS_TITLE_3 = 'TIPS_MULTI_DOS_TITLE_3';
export const TIPS_MULTI_DOS_TITLE_4 = 'TIPS_MULTI_DOS_TITLE_4';
export const TIPS_MULTI_DOS_TITLE_5 = 'TIPS_MULTI_DOS_TITLE_5';
export const TIPS_MULTI_TITLE = 'TIPS_MULTI_TITLE';
export const TIPS_NEXT_TIPS_TEXT = 'TIPS_NEXT_TIPS_TEXT';
export const TIPS_ORDINARY_BATTERY_DESCRIPTION_1 = 'TIPS_ORDINARY_BATTERY_DESCRIPTION_1';
export const TIPS_ORDINARY_BATTERY_DESCRIPTION_2 = 'TIPS_ORDINARY_BATTERY_DESCRIPTION_2';
export const TIPS_ORDINARY_BATTERY_DESCRIPTION_3 = 'TIPS_ORDINARY_BATTERY_DESCRIPTION_3';
export const TIPS_ORDINARY_BATTERY_DESCRIPTION_4 = 'TIPS_ORDINARY_BATTERY_DESCRIPTION_4';
export const TIPS_ORDINARY_BATTERY_DESCRIPTION_5 = 'TIPS_ORDINARY_BATTERY_DESCRIPTION_5';
export const TIPS_ORDINARY_BATTERY_DESCRIPTION_6 = 'TIPS_ORDINARY_BATTERY_DESCRIPTION_6';
export const TIPS_ORDINARY_BATTERY_TITLE_1 = 'TIPS_ORDINARY_BATTERY_TITLE_1';
export const TIPS_ORDINARY_BATTERY_TITLE_2 = 'TIPS_ORDINARY_BATTERY_TITLE_2';
export const TIPS_ORDINARY_BATTERY_TITLE_3 = 'TIPS_ORDINARY_BATTERY_TITLE_3';
export const TIPS_ORDINARY_BATTERY_TITLE_4 = 'TIPS_ORDINARY_BATTERY_TITLE_4';
export const TIPS_ORDINARY_BATTERY_TITLE_5 = 'TIPS_ORDINARY_BATTERY_TITLE_5';
export const TIPS_ORDINARY_BATTERY_TITLE_6 = 'TIPS_ORDINARY_BATTERY_TITLE_6';
export const TIPS_ORDINARY_CLEANING_DESCRIPTION_1 = 'TIPS_ORDINARY_CLEANING_DESCRIPTION_1';
export const TIPS_ORDINARY_CLEANING_DESCRIPTION_2 = 'TIPS_ORDINARY_CLEANING_DESCRIPTION_2';
export const TIPS_ORDINARY_CLEANING_DESCRIPTION_3 = 'TIPS_ORDINARY_CLEANING_DESCRIPTION_3';
export const TIPS_ORDINARY_CLEANING_DESCRIPTION_4 = 'TIPS_ORDINARY_CLEANING_DESCRIPTION_4';
export const TIPS_ORDINARY_CLEANING_DESCRIPTION_5 = 'TIPS_ORDINARY_CLEANING_DESCRIPTION_5';
export const TIPS_ORDINARY_CLEANING_DESCRIPTION_6 = 'TIPS_ORDINARY_CLEANING_DESCRIPTION_6';
export const TIPS_ORDINARY_CLEANING_DESCRIPTION_7 = 'TIPS_ORDINARY_CLEANING_DESCRIPTION_7';
export const TIPS_ORDINARY_CLEANING_DESCRIPTION_8 = 'TIPS_ORDINARY_CLEANING_DESCRIPTION_8';
export const TIPS_ORDINARY_CLEANING_DESCRIPTION_9 = 'TIPS_ORDINARY_CLEANING_DESCRIPTION_9';
export const TIPS_ORDINARY_CLEANING_TITLE_1 = 'TIPS_ORDINARY_CLEANING_TITLE_1';
export const TIPS_ORDINARY_CLEANING_TITLE_2 = 'TIPS_ORDINARY_CLEANING_TITLE_2';
export const TIPS_ORDINARY_CLEANING_TITLE_3 = 'TIPS_ORDINARY_CLEANING_TITLE_3';
export const TIPS_ORDINARY_CLEANING_TITLE_4 = 'TIPS_ORDINARY_CLEANING_TITLE_4';
export const TIPS_ORDINARY_CLEANING_TITLE_5 = 'TIPS_ORDINARY_CLEANING_TITLE_5';
export const TIPS_ORDINARY_CLEANING_TITLE_6 = 'TIPS_ORDINARY_CLEANING_TITLE_6';
export const TIPS_ORDINARY_CLEANING_TITLE_7 = 'TIPS_ORDINARY_CLEANING_TITLE_7';
export const TIPS_ORDINARY_CLEANING_TITLE_8 = 'TIPS_ORDINARY_CLEANING_TITLE_8';
export const TIPS_ORDINARY_CLEANING_TITLE_9 = 'TIPS_ORDINARY_CLEANING_TITLE_9';
export const TIPS_ORDINARY_DOS_DESCRIPTION_1 = 'TIPS_ORDINARY_DOS_DESCRIPTION_1';
export const TIPS_ORDINARY_DOS_DESCRIPTION_2 = 'TIPS_ORDINARY_DOS_DESCRIPTION_2';
export const TIPS_ORDINARY_DOS_DESCRIPTION_3 = 'TIPS_ORDINARY_DOS_DESCRIPTION_3';
export const TIPS_ORDINARY_DOS_DESCRIPTION_4 = 'TIPS_ORDINARY_DOS_DESCRIPTION_4';
export const TIPS_ORDINARY_DOS_DESCRIPTION_5 = 'TIPS_ORDINARY_DOS_DESCRIPTION_5';
export const TIPS_ORDINARY_DOS_TITLE_1 = 'TIPS_ORDINARY_DOS_TITLE_1';
export const TIPS_ORDINARY_DOS_TITLE_2 = 'TIPS_ORDINARY_DOS_TITLE_2';
export const TIPS_ORDINARY_DOS_TITLE_3 = 'TIPS_ORDINARY_DOS_TITLE_3';
export const TIPS_ORDINARY_DOS_TITLE_4 = 'TIPS_ORDINARY_DOS_TITLE_4';
export const TIPS_ORDINARY_DOS_TITLE_5 = 'TIPS_ORDINARY_DOS_TITLE_5';
export const TIPS_ORDINARY_TITLE = 'TIPS_ORDINARY_TITLE';
export const TIPS_P4_BATTERY_DESCRIPTION_1 = 'TIPS_P4_BATTERY_DESCRIPTION_1';
export const TIPS_P4_BATTERY_DESCRIPTION_2 = 'TIPS_P4_BATTERY_DESCRIPTION_2';
export const TIPS_P4_BATTERY_DESCRIPTION_3 = 'TIPS_P4_BATTERY_DESCRIPTION_3';
export const TIPS_P4_BATTERY_TITLE_1 = 'TIPS_P4_BATTERY_TITLE_1';
export const TIPS_P4_BATTERY_TITLE_2 = 'TIPS_P4_BATTERY_TITLE_2';
export const TIPS_P4_BATTERY_TITLE_3 = 'TIPS_P4_BATTERY_TITLE_3';
export const TIPS_P4_DESCRIPTION = 'TIPS_P4_DESCRIPTION';
export const TIPS_P4_POD_DESCRIPTION_1 = 'TIPS_P4_POD_DESCRIPTION_1';
export const TIPS_P4_POD_TITLE_1 = 'TIPS_P4_POD_TITLE_1';
export const TIPS_P4_RESPONSIVE_DRAW_DESCRIPTION_1 = 'TIPS_P4_RESPONSIVE_DRAW_DESCRIPTION_1';
export const TIPS_P4_RESPONSIVE_DRAW_TITLE_1 = 'TIPS_P4_RESPONSIVE_DRAW_TITLE_1';
export const TIPS_P4_TITLE = 'TIPS_P4_TITLE';
export const TIPS_QUICK_START_GUIDE_CORTEZ_DESCRIPTION_1 = 'TIPS_QUICK_START_GUIDE_CORTEZ_DESCRIPTION_1';
export const TIPS_QUICK_START_GUIDE_CORTEZ_DESCRIPTION_2 = 'TIPS_QUICK_START_GUIDE_CORTEZ_DESCRIPTION_2';
export const TIPS_QUICK_START_GUIDE_CORTEZ_DESCRIPTION_3 = 'TIPS_QUICK_START_GUIDE_CORTEZ_DESCRIPTION_3';
export const TIPS_QUICK_START_GUIDE_CORTEZ_DESCRIPTION_4 = 'TIPS_QUICK_START_GUIDE_CORTEZ_DESCRIPTION_4';
export const TIPS_QUICK_START_GUIDE_CORTEZ_DESCRIPTION_5 = 'TIPS_QUICK_START_GUIDE_CORTEZ_DESCRIPTION_5';
export const TIPS_QUICK_START_GUIDE_CORTEZ_DESCRIPTION_6 = 'TIPS_QUICK_START_GUIDE_CORTEZ_DESCRIPTION_6';
export const TIPS_QUICK_START_GUIDE_CORTEZ_DESCRIPTION_7 = 'TIPS_QUICK_START_GUIDE_CORTEZ_DESCRIPTION_7';
export const TIPS_QUICK_START_GUIDE_CORTEZ_TITLE_1 = 'TIPS_QUICK_START_GUIDE_CORTEZ_TITLE_1';
export const TIPS_QUICK_START_GUIDE_CORTEZ_TITLE_2 = 'TIPS_QUICK_START_GUIDE_CORTEZ_TITLE_2';
export const TIPS_QUICK_START_GUIDE_CORTEZ_TITLE_3 = 'TIPS_QUICK_START_GUIDE_CORTEZ_TITLE_3';
export const TIPS_QUICK_START_GUIDE_CORTEZ_TITLE_4 = 'TIPS_QUICK_START_GUIDE_CORTEZ_TITLE_4';
export const TIPS_QUICK_START_GUIDE_CORTEZ_TITLE_5 = 'TIPS_QUICK_START_GUIDE_CORTEZ_TITLE_5';
export const TIPS_QUICK_START_GUIDE_CORTEZ_TITLE_6 = 'TIPS_QUICK_START_GUIDE_CORTEZ_TITLE_6';
export const TIPS_QUICK_START_GUIDE_CORTEZ_TITLE_7 = 'TIPS_QUICK_START_GUIDE_CORTEZ_TITLE_7';
export const TIPS_QUICK_START_GUIDE_MULTI_DESCRIPTION_1 = 'TIPS_QUICK_START_GUIDE_MULTI_DESCRIPTION_1';
export const TIPS_QUICK_START_GUIDE_MULTI_DESCRIPTION_2 = 'м';
export const TIPS_QUICK_START_GUIDE_MULTI_DESCRIPTION_3 = 'TIPS_QUICK_START_GUIDE_MULTI_DESCRIPTION_3';
export const TIPS_QUICK_START_GUIDE_MULTI_DESCRIPTION_4 = 'TIPS_QUICK_START_GUIDE_MULTI_DESCRIPTION_4';
export const TIPS_QUICK_START_GUIDE_MULTI_DESCRIPTION_5 = 'TIPS_QUICK_START_GUIDE_MULTI_DESCRIPTION_5';
export const TIPS_QUICK_START_GUIDE_MULTI_DESCRIPTION_6 = 'TIPS_QUICK_START_GUIDE_MULTI_DESCRIPTION_6';
export const TIPS_QUICK_START_GUIDE_MULTI_TITLE_1 = 'TIPS_QUICK_START_GUIDE_MULTI_TITLE_1';
export const TIPS_QUICK_START_GUIDE_MULTI_TITLE_2 = 'TIPS_QUICK_START_GUIDE_MULTI_TITLE_2';
export const TIPS_QUICK_START_GUIDE_MULTI_TITLE_3 = 'TIPS_QUICK_START_GUIDE_MULTI_TITLE_3';
export const TIPS_QUICK_START_GUIDE_MULTI_TITLE_4 = 'TIPS_QUICK_START_GUIDE_MULTI_TITLE_4';
export const TIPS_QUICK_START_GUIDE_MULTI_TITLE_5 = 'TIPS_QUICK_START_GUIDE_MULTI_TITLE_5';
export const TIPS_QUICK_START_GUIDE_MULTI_TITLE_6 = 'TIPS_QUICK_START_GUIDE_MULTI_TITLE_6';
export const TIPS_QUICK_START_GUIDE_ORDINARY_DESCRIPTION_1 = 'TIPS_QUICK_START_GUIDE_ORDINARY_DESCRIPTION_1';
export const TIPS_QUICK_START_GUIDE_ORDINARY_DESCRIPTION_2 = 'TIPS_QUICK_START_GUIDE_ORDINARY_DESCRIPTION_2';
export const TIPS_QUICK_START_GUIDE_ORDINARY_DESCRIPTION_3 = 'TIPS_QUICK_START_GUIDE_ORDINARY_DESCRIPTION_3';
export const TIPS_QUICK_START_GUIDE_ORDINARY_DESCRIPTION_4 = 'TIPS_QUICK_START_GUIDE_ORDINARY_DESCRIPTION_4';
export const TIPS_QUICK_START_GUIDE_ORDINARY_DESCRIPTION_5 = 'TIPS_QUICK_START_GUIDE_ORDINARY_DESCRIPTION_5';
export const TIPS_QUICK_START_GUIDE_ORDINARY_DESCRIPTION_6 = 'TIPS_QUICK_START_GUIDE_ORDINARY_DESCRIPTION_6';
export const TIPS_QUICK_START_GUIDE_ORDINARY_TITLE_1 = 'TIPS_QUICK_START_GUIDE_ORDINARY_TITLE_1';
export const TIPS_QUICK_START_GUIDE_ORDINARY_TITLE_2 = 'TIPS_QUICK_START_GUIDE_ORDINARY_TITLE_2';
export const TIPS_QUICK_START_GUIDE_ORDINARY_TITLE_3 = 'TIPS_QUICK_START_GUIDE_ORDINARY_TITLE_3';
export const TIPS_QUICK_START_GUIDE_ORDINARY_TITLE_4 = 'TIPS_QUICK_START_GUIDE_ORDINARY_TITLE_4';
export const TIPS_QUICK_START_GUIDE_ORDINARY_TITLE_5 = 'TIPS_QUICK_START_GUIDE_ORDINARY_TITLE_5';
export const TIPS_QUICK_START_GUIDE_ORDINARY_TITLE_6 = 'TIPS_QUICK_START_GUIDE_ORDINARY_TITLE_6';
export const TIPS_QUICK_START_GUIDE_P4_DESCRIPTION_1 = 'TIPS_QUICK_START_GUIDE_P4_DESCRIPTION_1';
export const TIPS_QUICK_START_GUIDE_P4_DESCRIPTION_2 = 'TIPS_QUICK_START_GUIDE_P4_DESCRIPTION_2';
export const TIPS_QUICK_START_GUIDE_P4_DESCRIPTION_3 = 'TIPS_QUICK_START_GUIDE_P4_DESCRIPTION_3';
export const TIPS_QUICK_START_GUIDE_P4_DESCRIPTION_4 = 'TIPS_QUICK_START_GUIDE_P4_DESCRIPTION_4';
export const TIPS_QUICK_START_GUIDE_P4_DESCRIPTION_5 = 'TIPS_QUICK_START_GUIDE_P4_DESCRIPTION_5';
export const TIPS_QUICK_START_GUIDE_P4_TITLE_1 = 'TIPS_QUICK_START_GUIDE_P4_TITLE_1';
export const TIPS_QUICK_START_GUIDE_P4_TITLE_2 = 'TIPS_QUICK_START_GUIDE_P4_TITLE_2';
export const TIPS_QUICK_START_GUIDE_P4_TITLE_3 = 'TIPS_QUICK_START_GUIDE_P4_TITLE_3';
export const TIPS_QUICK_START_GUIDE_P4_TITLE_4 = 'TIPS_QUICK_START_GUIDE_P4_TITLE_4';
export const TIPS_QUICK_START_GUIDE_P4_TITLE_5 = 'TIPS_QUICK_START_GUIDE_P4_TITLE_5';
export const TIPS_QUICK_START_GUIDE_SOYUZ_DESCRIPTION_1 = 'TIPS_QUICK_START_GUIDE_SOYUZ_DESCRIPTION_1';
export const TIPS_QUICK_START_GUIDE_SOYUZ_DESCRIPTION_2 = 'TIPS_QUICK_START_GUIDE_SOYUZ_DESCRIPTION_2';
export const TIPS_QUICK_START_GUIDE_SOYUZ_DESCRIPTION_3 = 'TIPS_QUICK_START_GUIDE_SOYUZ_DESCRIPTION_3';
export const TIPS_QUICK_START_GUIDE_SOYUZ_DESCRIPTION_4 = 'TIPS_QUICK_START_GUIDE_SOYUZ_DESCRIPTION_4';
export const TIPS_QUICK_START_GUIDE_SOYUZ_DESCRIPTION_5 = 'TIPS_QUICK_START_GUIDE_SOYUZ_DESCRIPTION_5';
export const TIPS_QUICK_START_GUIDE_SOYUZ_DESCRIPTION_6 = 'TIPS_QUICK_START_GUIDE_SOYUZ_DESCRIPTION_6';
export const TIPS_QUICK_START_GUIDE_SOYUZ_DESCRIPTION_7 = 'TIPS_QUICK_START_GUIDE_SOYUZ_DESCRIPTION_7';
export const TIPS_QUICK_START_GUIDE_SOYUZ_TITLE_1 = 'TIPS_QUICK_START_GUIDE_SOYUZ_TITLE_1';
export const TIPS_QUICK_START_GUIDE_SOYUZ_TITLE_2 = 'TIPS_QUICK_START_GUIDE_SOYUZ_TITLE_2';
export const TIPS_QUICK_START_GUIDE_SOYUZ_TITLE_3 = 'TIPS_QUICK_START_GUIDE_SOYUZ_TITLE_3';
export const TIPS_QUICK_START_GUIDE_SOYUZ_TITLE_4 = 'TIPS_QUICK_START_GUIDE_SOYUZ_TITLE_4';
export const TIPS_QUICK_START_GUIDE_SOYUZ_TITLE_5 = 'TIPS_QUICK_START_GUIDE_SOYUZ_TITLE_5';
export const TIPS_QUICK_START_GUIDE_SOYUZ_TITLE_6 = 'TIPS_QUICK_START_GUIDE_SOYUZ_TITLE_6';
export const TIPS_QUICK_START_GUIDE_SOYUZ_TITLE_7 = 'TIPS_QUICK_START_GUIDE_SOYUZ_TITLE_7';
export const TIPS_SOYUZ_BATTERY_DESCRIPTION_1 = 'TIPS_SOYUZ_BATTERY_DESCRIPTION_1';
export const TIPS_SOYUZ_BATTERY_DESCRIPTION_2 = 'TIPS_SOYUZ_BATTERY_DESCRIPTION_2';
export const TIPS_SOYUZ_BATTERY_DESCRIPTION_3 = 'TIPS_SOYUZ_BATTERY_DESCRIPTION_3';
export const TIPS_SOYUZ_BATTERY_DESCRIPTION_4 = 'TIPS_SOYUZ_BATTERY_DESCRIPTION_4';
export const TIPS_SOYUZ_BATTERY_DESCRIPTION_5 = 'TIPS_SOYUZ_BATTERY_DESCRIPTION_5';
export const TIPS_SOYUZ_BATTERY_DESCRIPTION_6 = 'TIPS_SOYUZ_BATTERY_DESCRIPTION_6';
export const TIPS_SOYUZ_BATTERY_TITLE_1 = 'TIPS_SOYUZ_BATTERY_TITLE_1';
export const TIPS_SOYUZ_BATTERY_TITLE_2 = 'TIPS_SOYUZ_BATTERY_TITLE_2';
export const TIPS_SOYUZ_BATTERY_TITLE_3 = 'TIPS_SOYUZ_BATTERY_TITLE_3';
export const TIPS_SOYUZ_BATTERY_TITLE_4 = 'TIPS_SOYUZ_BATTERY_TITLE_4';
export const TIPS_SOYUZ_BATTERY_TITLE_5 = 'TIPS_SOYUZ_BATTERY_TITLE_5';
export const TIPS_SOYUZ_BATTERY_TITLE_6 = 'TIPS_SOYUZ_BATTERY_TITLE_6';
export const TIPS_SOYUZ_CLEANING_DESCRIPTION_1 = 'TIPS_SOYUZ_CLEANING_DESCRIPTION_1';
export const TIPS_SOYUZ_CLEANING_DESCRIPTION_2 = 'TIPS_SOYUZ_CLEANING_DESCRIPTION_2';
export const TIPS_SOYUZ_CLEANING_DESCRIPTION_3 = 'TIPS_SOYUZ_CLEANING_DESCRIPTION_3';
export const TIPS_SOYUZ_CLEANING_DESCRIPTION_4 = 'TIPS_SOYUZ_CLEANING_DESCRIPTION_4';
export const TIPS_SOYUZ_CLEANING_DESCRIPTION_5 = 'TIPS_SOYUZ_CLEANING_DESCRIPTION_5';
export const TIPS_SOYUZ_CLEANING_DESCRIPTION_6 = 'TIPS_SOYUZ_CLEANING_DESCRIPTION_6';
export const TIPS_SOYUZ_CLEANING_DESCRIPTION_7 = 'TIPS_SOYUZ_CLEANING_DESCRIPTION_7';
export const TIPS_SOYUZ_CLEANING_DESCRIPTION_8 = 'TIPS_SOYUZ_CLEANING_DESCRIPTION_8';
export const TIPS_SOYUZ_CLEANING_DESCRIPTION_9 = 'TIPS_SOYUZ_CLEANING_DESCRIPTION_9';
export const TIPS_SOYUZ_CLEANING_TITLE_1 = 'TIPS_SOYUZ_CLEANING_TITLE_1';
export const TIPS_SOYUZ_CLEANING_TITLE_2 = 'TIPS_SOYUZ_CLEANING_TITLE_2';
export const TIPS_SOYUZ_CLEANING_TITLE_3 = 'TIPS_SOYUZ_CLEANING_TITLE_3';
export const TIPS_SOYUZ_CLEANING_TITLE_4 = 'TIPS_SOYUZ_CLEANING_TITLE_4';
export const TIPS_SOYUZ_CLEANING_TITLE_5 = 'TIPS_SOYUZ_CLEANING_TITLE_5';
export const TIPS_SOYUZ_CLEANING_TITLE_6 = 'TIPS_SOYUZ_CLEANING_TITLE_6';
export const TIPS_SOYUZ_CLEANING_TITLE_7 = 'TIPS_SOYUZ_CLEANING_TITLE_7';
export const TIPS_SOYUZ_CLEANING_TITLE_8 = 'TIPS_SOYUZ_CLEANING_TITLE_8';
export const TIPS_SOYUZ_CLEANING_TITLE_9 = 'TIPS_SOYUZ_CLEANING_TITLE_9';
export const TIPS_SOYUZ_DOS_DESCRIPTION_1 = 'TIPS_SOYUZ_DOS_DESCRIPTION_1';
export const TIPS_SOYUZ_DOS_DESCRIPTION_2 = 'TIPS_SOYUZ_DOS_DESCRIPTION_2';
export const TIPS_SOYUZ_DOS_DESCRIPTION_3 = 'TIPS_SOYUZ_DOS_DESCRIPTION_3';
export const TIPS_SOYUZ_DOS_DESCRIPTION_4 = 'TIPS_SOYUZ_DOS_DESCRIPTION_4';
export const TIPS_SOYUZ_DOS_DESCRIPTION_5 = 'TIPS_SOYUZ_DOS_DESCRIPTION_5';
export const TIPS_SOYUZ_DOS_TITLE_1 = 'TIPS_SOYUZ_DOS_TITLE_1';
export const TIPS_SOYUZ_DOS_TITLE_2 = 'TIPS_SOYUZ_DOS_TITLE_2';
export const TIPS_SOYUZ_DOS_TITLE_3 = 'TIPS_SOYUZ_DOS_TITLE_3';
export const TIPS_SOYUZ_DOS_TITLE_4 = 'TIPS_SOYUZ_DOS_TITLE_4';
export const TIPS_SOYUZ_DOS_TITLE_5 = 'TIPS_SOYUZ_DOS_TITLE_5';
export const TIPS_SOYUZ_TITLE = 'TIPS_SOYUZ_TITLE';
export const TRIPLET_FLOW_TILE_TEXT = 'TRIPLET_FLOW_TILE_TEXT';
export const TROUBLESHOOTING_CONSENT_DESCRIPTION_TEXT = 'TROUBLESHOOTING_CONSENT_DESCRIPTION_TEXT';
export const UNLOCK_BUTTON_TEXT = 'UNLOCK_BUTTON_TEXT';
export const UNLOCK_POPUP_CANCEL_BUTTON_TEXT = 'UNLOCK_POPUP_CANCEL_BUTTON_TEXT';
export const UNLOCK_POPUP_TITLE = 'UNLOCK_POPUP_TITLE';
export const UNLOCK_POPUP_TITLE_DESCRIPTION = 'UNLOCK_POPUP_TITLE_DESCRIPTION';
export const UNLOCK_POPUP_UNLOCK_BUTTON_TEXT = 'UNLOCK_POPUP_UNLOCK_BUTTON_TEXT';
export const UNLOCK_SUCCESS_NOTIFICATION = 'UNLOCK_SUCCESS_NOTIFICATION';
export const UNPAIR_DEVICE_CONFIRM_POPUP_DESCRIPTION_TEXT = 'UNPAIR_DEVICE_CONFIRM_POPUP_DESCRIPTION_TEXT';
export const UNPAIR_DEVICE_CONFIRM_POPUP_PRIMARY_TEXT = 'UNPAIR_DEVICE_CONFIRM_POPUP_PRIMARY_TEXT';
export const UNPAIR_DEVICE_CONFIRM_POPUP_SECONDARY_TEXT = 'UNPAIR_DEVICE_CONFIRM_POPUP_SECONDARY_TEXT';
export const UNPAIR_DEVICE_CONFIRM_POPUP_TITLE_TEXT = 'UNPAIR_DEVICE_CONFIRM_POPUP_TITLE_TEXT';
export const VIBRATE_MODE_DEVICE_SECTION_TITLE = 'VIBRATE_MODE_DEVICE_SECTION_TITLE';
export const VIBRATION_STATE_OFF = 'VIBRATION_STATE_OFF';
export const VIBRATION_STATE_ON = 'VIBRATION_STATE_ON';
export const VIBRATOR_VMG_PULL_DOWN_TEST_FAILURE = 'VIBRATOR_VMG_PULL_DOWN_TEST_FAILURE';
export const VIVALDI_FLOW_TEXT = 'VIVALDI_FLOW_TEXT';
export const VIVALDI_FLOW_TILE_TEXT = 'VIVALDI_FLOW_TILE_TEXT';
export const WELCOME_PAGE_ABOUT_LEDS = 'WELCOME_PAGE_ABOUT_LEDS';
export const WELCOME_PAGE_CHECKING_BATTERY = 'WELCOME_PAGE_CHECKING_BATTERY';
export const WELCOME_PAGE_DEVICE_TOUR = 'WELCOME_PAGE_DEVICE_TOUR';
export const WELCOME_PAGE_HEADER = 'WELCOME_PAGE_HEADER';
export const WELCOME_PAGE_START_BUTTON_TEXT = 'WELCOME_PAGE_START_BUTTON_TEXT';
export const WELCOME_PAGE_WELCOME_TEXT = 'WELCOME_PAGE_WELCOME_TEXT';
export const WELCOME_TEXT = 'WELCOME_TEXT';
export const YAP_ACTIVATING_DEVICE = 'YAP_ACTIVATING_DEVICE';
export const YAP_ACTIVATION_DEVICES_AVAILABLE_ACTIVATE_BUTTON_TEXT =
    'YAP_ACTIVATION_DEVICES_AVAILABLE_ACTIVATE_BUTTON_TEXT';
export const YAP_ACTIVATION_DEVICES_AVAILABLE_ACTIVATION_DESCRIPTION_TEXT =
    'YAP_ACTIVATION_DEVICES_AVAILABLE_ACTIVATION_DESCRIPTION_TEXT';
export const YAP_ACTIVATION_PAUSED_BLUETOOTH_DESCRIPTION = 'YAP_ACTIVATION_PAUSED_BLUETOOTH_DESCRIPTION';
export const YAP_ACTIVATION_PAUSED_TITLE = 'YAP_ACTIVATION_PAUSED_TITLE';
export const YAP_ACTIVATION_REGISTRATION_STATE_ACTIVATION_FLOW_PROGRESS_TEXT =
    'YAP_ACTIVATION_REGISTRATION_STATE_ACTIVATION_FLOW_PROGRESS_TEXT';
export const YAP_ACTIVATION_STATE_ACTIVATED_ACTION_BUTTON_TEXT = 'YAP_ACTIVATION_STATE_ACTIVATED_ACTION_BUTTON_TEXT';
export const YAP_ACTIVATION_STATE_ACTIVATED_DESCRIPTION_TEXT = 'YAP_ACTIVATION_STATE_ACTIVATED_DESCRIPTION_TEXT';
export const YAP_ACTIVATION_STATE_ACTIVATED_TEXT = 'YAP_ACTIVATION_STATE_ACTIVATED_TEXT';
export const YAP_DEACTIVATION_FAILED__TITLE_TEXT = 'YAP_DEACTIVATION_FAILED__TITLE_TEXT';
export const YAP_DEACTIVATION_FAILED_CANCEL_BUTTON_TEXT = 'YAP_DEACTIVATION_FAILED_CANCEL_BUTTON_TEXT';
export const YAP_DEACTIVATION_FAILED_DESCRIPTION_TEXT = 'YAP_DEACTIVATION_FAILED_DESCRIPTION_TEXT';
export const YAP_DEACTIVATION_FAILED_TRY_AGAIN_BUTTON_TEXT = 'YAP_DEACTIVATION_FAILED_TRY_AGAIN_BUTTON_TEXT';
export const YAP_DEACTIVATION_STATE_TEXT = 'YAP_DEACTIVATION_STATE_TEXT';
export const YAP_DEACTIVATION_SUCCESSFULLY_TEXT = 'YAP_DEACTIVATION_SUCCESSFULLY_TEXT';
export const YAP_DEACTIVE_CONFIRM_POPUP_ACCEPT_BUTTON_TEXT = 'YAP_DEACTIVE_CONFIRM_POPUP_ACCEPT_BUTTON_TEXT';
export const YAP_DEACTIVE_CONFIRM_POPUP_CANCEL_BUTTON_TEXT = 'YAP_DEACTIVE_CONFIRM_POPUP_CANCEL_BUTTON_TEXT';
export const YAP_DEACTIVE_CONFIRM_POPUP_DESCRIPTION_TEXT = 'YAP_DEACTIVE_CONFIRM_POPUP_DESCRIPTION_TEXT';
export const YAP_DEACTIVE_CONFIRM_POPUP_TITLE_TEXT = 'YAP_DEACTIVE_CONFIRM_POPUP_TITLE_TEXT';
export const YAP_DEVICE_LIST_NOT_ACTIVATED = 'YAP_DEVICE_LIST_NOT_ACTIVATED';
export const YAP_GO_TO_DEVICES_TEXT = 'YAP_GO_TO_DEVICES_TEXT';
export const YAP_REGISTERING = 'YAP_REGISTERING';
export const YAP_SKIP_BUTTON_TEXT = 'YAP_SKIP_BUTTON_TEXT';
export const YAP_UNREGISTRATION_STATE_TEXT = 'YAP_UNREGISTRATION_STATE_TEXT';
export const YAP_UNREGISTRATION_SUCCESSFULLY_TEXT = 'YAP_UNREGISTRATION_SUCCESSFULLY_TEXT';
export const LOGIN_DESCRIPTION_TEXT = 'LOGIN_DESCRIPTION_TEXT';
export const LOGIN_BUTTON_TEXT = 'LOGIN_BUTTON_TEXT';
export const LEGAL_DISCLAIMER_TITLE = 'LEGAL_DISCLAIMER_TITLE';
export const LEGAL_DISCLAIMER_LINK_TITLE = 'LEGAL_DISCLAIMER_LINK_TITLE';
export const LEGAL_DISCLAIMER_SCREEN_DESCRIPTION_TEXT = 'LEGAL_DISCLAIMER_SCREEN_DESCRIPTION_TEXT';
export const FORGOT_PASSWORD_BUTTON_TEXT = 'FORGOT_PASSWORD_BUTTON_TEXT';
export const LOGIN_HINT_TEXT = 'LOGIN_HINT_TEXT';
export const PASSWORD_HINT_TEXT = 'PASSWORD_HINT_TEXT';
export const NOT_VALID_USERNAME_TEXT = 'NOT_VALID_USERNAME_TEXT';
export const LOGIN_INVALID_CREDENTIAL_WARNING_TEXT = 'LOGIN_INVALID_CREDENTIAL_WARNING_TEXT';
export const REQUIRED_FIELD_TEXT = 'REQUIRED_FIELD_TEXT';
export const REGISTER_BUTTON_TEXT = 'REGISTER_BUTTON_TEXT';
export const NEW_TO_IQOS_TEXT = 'NEW_TO_IQOS_TEXT';
export const NOT_VALID_PASSWORD_TEXT = 'NOT_VALID_PASSWORD_TEXT';
export const LOGIN_TITLE_TEXT = 'LOGIN_TITLE_TEXT';
export const AGE_GATE_DESCRIPTION = 'AGE_GATE_DESCRIPTION';
export const BIRTH_DATE_PICKER_TITLE = 'BIRTH_DATE_PICKER_TITLE';
export const AGE_GATE_ERROR_NOTIFICATION_TEXT = 'AGE_GATE_ERROR_NOTIFICATION_TEXT';
export const AGE_GATE_BUTTON_CONFIRM = 'AGE_GATE_BUTTON_CONFIRM';
export const AGE_GATE_MONTH = 'AGE_GATE_MONTH';
export const AGE_GATE_YEAR = 'AGE_GATE_YEAR';
export const AGE_GATE_JANUARY_TEXT = 'AGE_GATE_JANUARY_TEXT';
export const AGE_GATE_FEBRUARY_TEXT = 'AGE_GATE_FEBRUARY_TEXT';
export const AGE_GATE_MARCH_TEXT = 'AGE_GATE_MARCH_TEXT';
export const AGE_GATE_APRIL_TEXT = 'AGE_GATE_APRIL_TEXT';
export const AGE_GATE_MAY_TEXT = 'AGE_GATE_MAY_TEXT';
export const AGE_GATE_JUNE_TEXT = 'AGE_GATE_JUNE_TEXT';
export const AGE_GATE_JULY_TEXT = 'AGE_GATE_JULY_TEXT';
export const AGE_GATE_AUGUST_TEXT = 'AGE_GATE_AUGUST_TEXT';
export const AGE_GATE_SEPTEMBER_TEXT = 'AGE_GATE_SEPTEMBER_TEXT';
export const AGE_GATE_OCTOBER_TEXT = 'AGE_GATE_OCTOBER_TEXT';
export const AGE_GATE_NOVEMBER_TEXT = 'AGE_GATE_NOVEMBER_TEXT';
export const AGE_GATE_DECEMBER_TEXT = 'AGE_GATE_DECEMBER_TEXT';
export const PASSWORD_TITLE_TEXT = 'PASSWORD_TITLE_TEXT';
export const LEGAL_DISCLAIMER_SCREEN_CONTINUE_BUTTON_TEXT = 'LEGAL_DISCLAIMER_SCREEN_CONTINUE_BUTTON_TEXT';
export const YAP_ACTIVATION_DEVICES_DISCONNECTED_DESCRIPTION_TEXT =
    'YAP_ACTIVATION_DEVICES_DISCONNECTED_DESCRIPTION_TEXT';
export const LOGOUT_BUTTON_TEXT = 'LOGOUT_BUTTON_TEXT';
export const DEVICE_SETTINGS_NEAR_SHOP = 'DEVICE_SETTINGS_NEAR_SHOP';
export const MANY_REGISTER_DEVICE_REQUEST_ERROR_POPUP_TITLE_TEXT =
    'MANY_REGISTER_DEVICE_REQUEST_ERROR_POPUP_TITLE_TEXT';
export const MANY_REGISTER_DEVICE_REQUEST_ERROR_POPUP_DESCRIPTION_TEXT =
    'MANY_REGISTER_DEVICE_REQUEST_ERROR_POPUP_DESCRIPTION_TEXT';
export const MANY_REGISTER_DEVICE_REQUEST_ERROR_POPUP_OK_GOT_IT_BUTTON_TEXT =
    'MANY_REGISTER_DEVICE_REQUEST_ERROR_POPUP_OK_GOT_IT_BUTTON_TEXT';
export const REACHED_LIMIT_REGISTERED_DEVICE_ERROR_POPUP_TITLE_TEXT =
    'REACHED_LIMIT_REGISTERED_DEVICE_ERROR_POPUP_TITLE_TEXT';
export const REACHED_LIMIT_REGISTERED_DEVICE_ERROR_POPUP_DESCRIPTION_TEXT =
    'REACHED_LIMIT_REGISTERED_DEVICE_ERROR_POPUP_DESCRIPTION_TEXT';
export const REACHED_LIMIT_REGISTERED_DEVICE_ERROR_POPUP_OK_GOT_IT_BUTTON_TEXT =
    'REACHED_LIMIT_REGISTERED_DEVICE_ERROR_POPUP_OK_GOT_IT_BUTTON_TEXT';
