import React, {useEffect, useRef, useState} from 'react';

import imgDesktopCleaningMode1 from '../../../../../../../assets/images/settings/cleaning-mode-icon-desktop.png';
import imgDesktopCleaningMode2 from '../../../../../../../assets/images/settings/cleaning-mode-icon-desktop@2x.png';
import imgDesktopCleaningMode3 from '../../../../../../../assets/images/settings/cleaning-mode-icon-desktop@3x.png';
import imgMobileCleaningMode1 from '../../../../../../../assets/images/settings/cleaning-mode-icon-mobile.png';
import imgMobileCleaningMode2 from '../../../../../../../assets/images/settings/cleaning-mode-icon-mobile@2x.png';
import imgMobileCleaningMode3 from '../../../../../../../assets/images/settings/cleaning-mode-icon-mobile@3x.png';
import ButtonDark from '../../../../../../components/Button/ButtonDark/ButtonDark';
import CommonLoaderScreen from '../../../../../../components/Loader/CommonLoaderScreen';
import * as localizationKeys from '../../../../../../consts/app/localizationKeys';
import * as iotHolderStatusTypes from '../../../../../../consts/iot/iotHolderStatusTypes';
import * as iotMessageTypes from '../../../../../../consts/iot/iotMessageTypes';
import useDidUpdate from '../../../../../../hooks/effects/useDidUpdate';
import useErrorHandler from '../../../../../../hooks/effects/useErrorHandler';
import useHideLoader from '../../../../../../hooks/effects/useHideLoader';
import analyticsService from '../../../../../../services/analyticsService';
import getDeviceMessageRequestService from '../../../../../../services/deviceMessageRequestService';
import deviceConfigService from '../../../../../../services/iotDevice/iotDeviceConfigService';
import iotMessageService from '../../../../../../services/iotMessageService';
import layoutService from '../../../../../../services/layoutService';
import {getLocalizedStrings} from '../../../../../../services/localization/localizationService';
import pairingService from '../../../../../../services/pairingService';
import {
    hideAllLoaders,
    setNotificationFailed,
    setNotificationInfo,
    setNotificationSuccess,
    showLoader,
} from '../../../../../../state/ducks/global';
import DeviceModePopup from '../../../DeviceModePopup/DeviceModePopup';
import styles from '../DeviceModeComponents/DeviceMode.module.scss';

const LOADER_NAME = 'CLEANING_MODE_PAGE_LOADER';

const DeviceCleaningModePopup = (props) => {
    const {dispatch, iotDevice, iotMessage, isConnected, setIsVisibleCleaningPopup, layout} = props;
    const {device, holder} = iotDevice;
    const [isCleaningStarted, setIsCleaningStarted] = useState(false);
    const [isHolderStatusInProgress, setIsHolderStatusInProgress] = useState(false);
    const [isCleaningInProgress, setIsCleaningInProgress] = useState(false);
    const isHolderStatusFinished = iotMessageService.isHolderStatusFinished(iotMessage);
    const isDeviceCleaningFinished = iotMessageService.isDeviceCleaningFinished(iotMessage);
    const isChargingInfoShow = deviceConfigService.isChargingWhileCleaningNeeded(iotDevice);
    const needToCheckHolderStatus = !!holder;
    const holder_state = holder?.holder_state;
    const inhalations_from_cleaning = holder?.inhalations_from_cleaning;
    const is_door_opened = device?.is_door_opened;
    const isReadyToStart = needToCheckHolderStatus ? holder_state !== iotHolderStatusTypes.UNPLUGGED : true;
    const isDesktopLayout = layoutService.isDesktopLayout(layout);
    let bluetoothErrorTimeout = useRef(false);

    const CLEANING_ERROR_TIMEOUT = 70000;

    const onStartCleaningBtnClick = () => {
        setIsCleaningInProgress(false);
        checkHolderState();
    };
    const localizedStrings = getLocalizedStrings();

    useDidUpdate(() => {
        if (!isReadyToStart) {
            clearTimeout(bluetoothErrorTimeout.current);
            setIsVisibleCleaningPopup(false);
        }

        if (holder_state === iotHolderStatusTypes.CLEANING) {
            setIsCleaningStarted(true);
            setIsCleaningInProgress(true);
            return;
        } else {
            setIsCleaningStarted(false);
        }

        if (holder_state === iotHolderStatusTypes.UNPLUGGED) {
            dispatch(setNotificationInfo(localizedStrings[localizationKeys.CLEANING_UNPLUGGED_NOTIFICATION_TEXT]));
            return;
        }

        if (isCleaningInProgress && holder_state !== iotHolderStatusTypes.CLEANING) {
            checkHolderState();
        }
    }, [holder_state]);

    useHideLoader(isHolderStatusFinished || !isConnected, LOADER_NAME);

    useEffect(() => {
        if (holder_state === iotHolderStatusTypes.CLEANING) {
            setIsCleaningStarted(true);
            setIsCleaningInProgress(true);
        }
    }, []);

    const setCleaningBluetoothError = () => {
        if (!isHolderStatusFinished && isReadyToStart) {
            dispatch(hideAllLoaders());
            pairingService.disconnectAndForwardToMyDevicesPage();
            dispatch(setNotificationFailed(getLocalizedStrings()[localizationKeys.IO_ERROR_NOTIFICATION_TEXT]));
        }
    };

    useEffect(() => {
        if (isDeviceCleaningFinished && isReadyToStart && !isHolderStatusFinished) {
            bluetoothErrorTimeout.current = setTimeout(setCleaningBluetoothError, CLEANING_ERROR_TIMEOUT);
        }

        if (isHolderStatusFinished) {
            clearTimeout(bluetoothErrorTimeout.current);
        }
    }, [isDeviceCleaningFinished, isHolderStatusFinished]);

    const checkHolderState = () => {
        if (needToCheckHolderStatus && !isHolderStatusInProgress) {
            dispatch(showLoader({name: LOADER_NAME}));
            setIsHolderStatusInProgress(true);
            getDeviceMessageRequestService().publishHolderStatusMessage();
        }
    };

    useErrorHandler({
        type: iotMessageTypes.CLEANING,
        handler: () => {
            setIsVisibleCleaningPopup(false);
            dispatch(setNotificationFailed(localizedStrings[localizationKeys.CLEANING_FAILED_NOTIFICATION_TEXT]));
        },
    });

    useDidUpdate(() => {
        if (isHolderStatusFinished) {
            setIsHolderStatusInProgress(false);
            if (isCleaningInProgress && holder_state !== iotHolderStatusTypes.CLEANING) {
                setIsCleaningInProgress(false);
                setIsVisibleCleaningPopup(false);
                if (inhalations_from_cleaning) {
                    dispatch(
                        setNotificationFailed(localizedStrings[localizationKeys.CLEANING_FAILED_NOTIFICATION_TEXT])
                    );
                } else {
                    dispatch(
                        setNotificationSuccess(localizedStrings[localizationKeys.CLEANING_SUCCESSFUL_NOTIFICATION_TEXT])
                    );
                }
            } else if (!inhalations_from_cleaning) {
                dispatch(setNotificationInfo(localizedStrings[localizationKeys.CLEANING_NOT_NEEDED_NOTIFICATION_TEXT]));
            } else if (holder_state !== iotHolderStatusTypes.READY_TO_USE) {
                dispatch(
                    setNotificationInfo(
                        localizedStrings[localizationKeys.CLEANING_HOLDER_NOT_CHARGED_NOTIFICATION_TEXT]
                    )
                );
            } else if (is_door_opened) {
                dispatch(setNotificationInfo(localizedStrings[localizationKeys.CLEANING_UNPLUGGED_NOTIFICATION_TEXT]));
            } else if (!isCleaningStarted) {
                setIsCleaningStarted(true);

                analyticsService.pushUsageSettingsAutoCleaningEvent();

                getDeviceMessageRequestService().publishCleaningMessage();
            }
        }
    }, [isHolderStatusFinished]);

    const headerImageSet = isDesktopLayout
        ? [imgDesktopCleaningMode1, imgDesktopCleaningMode2, imgDesktopCleaningMode3]
        : [imgMobileCleaningMode1, imgMobileCleaningMode2, imgMobileCleaningMode3];

    return (
        <DeviceModePopup
            imgAlt='cleaning-mode-icon'
            imgClassName={`${styles.ImageCleaningHeaderIcon} ${styles.CenterElement}`}
            imgUrls={headerImageSet}
            isClosingBySwipeEnabled
            onClose={isCleaningStarted ? null : () => setIsVisibleCleaningPopup(false)}
            subTitle={localizedStrings[localizationKeys.CLEANING_MODE_DESCRIPTION]}
            title={localizedStrings[localizationKeys.CLEANING_MODE_TITLE]}
            layout={layout}
        >
            {isChargingInfoShow && (
                <p className={'ica--txt-regular ica--txt-regular-fixed'}>
                    {localizedStrings[localizationKeys.CLEANING_MODE_DESCRIPTION_USB_NOTIFICATION]}
                </p>
            )}
            <div className={styles.DevicePicto}>
                <CommonLoaderScreen
                    isMedium
                    customImgClassName={styles.ImageCleaningContainer}
                    isAnimated={isCleaningStarted}
                    iotDevice={iotDevice}
                />
            </div>
            {isReadyToStart && !isCleaningStarted && (
                <ButtonDark showArrow onClick={onStartCleaningBtnClick}>
                    {localizedStrings[localizationKeys.START_CLEANING_BUTTON_TEXT]}
                </ButtonDark>
            )}
        </DeviceModePopup>
    );
};

export default DeviceCleaningModePopup;
