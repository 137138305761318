import stringFormatService from '../../services/stringFormatService';
import stringUtils from '../../utils/stringUtils';
import {config} from '../config';

const getConnectionCareUrl = () => config.data.externalLinks.connectingCareUrl;
const getDcsRegisterUrl = () => config.data.externalLinks.dcsRegisterUrl;
const getDcsSupportUrl = () => config.data.externalLinks.dcsSupportUrl;
const getIqosHomeUrl = () => config.data.externalLinks.iqosHomeUrl;
const getIqosStoreUrl = () => config.data.externalLinks.iqosStoreUrl;
const getLoginUrl = () => config.data.externalLinks.loginUrl;
const getProductUnregistrationUrl = () => config.data.externalLinks.productUnregistrationUrl;

export default {
    getDcsRegisterUrlFormatted: () => stringFormatService.getLanguageFormattedString(getDcsRegisterUrl()),
    getDcsSupportUrlFormatted: () => stringFormatService.getLanguageFormattedString(getDcsSupportUrl()),
    getIqosHomeUrlFormatted: () => stringFormatService.getLanguageFormattedString(getIqosHomeUrl()),
    getIqosStoreUrlFormatted: () => stringFormatService.getLanguageFormattedString(getIqosStoreUrl()),
    getLoginUrlFormatted: () => stringFormatService.getLanguageFormattedString(getLoginUrl()),
    getProductUnregistrationUrlFormatted: () =>
        stringFormatService.getLanguageFormattedString(getProductUnregistrationUrl()),
    getConnectingCareUrlFormatted: (urlCodentify, l1Code) => {
        const url = getConnectionCareUrl();
        const urlWithLanguage = stringFormatService.getLanguageFormattedString(url);
        const urlFormatted = stringUtils.formatString(urlWithLanguage, urlCodentify, l1Code);

        return urlFormatted;
    },
};
