import tutorialTypes from '../consts/app/tutorialTypes';
import bleConnectionType from '../consts/ble/bleConnectionType';
import * as routePaths from '../consts/route/routePaths';
import urlUtils from '../utils/urlUtils';
import routerService from './routerService';

const forwardToAboutDevicePage = () => routerService.forwardTo(routePaths.ABOUT_DEVICE);
const forwardToActivationSuccessPage = () => routerService.forwardTo(routePaths.ACTIVATION_SUCCESS);
const forwardToAgeGatePage = () => routerService.forwardTo(routePaths.AGE_GATE);
const forwardToAppIntroPage = () => routerService.forwardTo(routePaths.APP_INTRO);
const forwardToBrowserVerificationPage = () => routerService.forwardTo(routePaths.BROWSER_VERIFICATION);
const forwardToDeviceActivationPage = () => routerService.forwardTo(routePaths.ACTIVATION);
const forwardToDeviceRegistrationPage = () => routerService.forwardTo(routePaths.REGISTRATION);
const forwardToDeviceSettingsPage = () => routerService.forwardTo(routePaths.DEVICE_SETTINGS);
const forwardToLoginPage = () => routerService.forwardTo(routePaths.LOGIN);
const forwardToMyDevicesPage = () => routerService.forwardTo(routePaths.MY_DEVICES);
const forwardToNoDevicesPage = () => routerService.forwardTo(routePaths.NO_DEVICES);
const forwardToNotCompatibleBrowserPage = () => routerService.forwardTo(routePaths.NOT_COMPATIBLE_BROWSER);
const forwardToPairingAlertPage = () => routerService.forwardTo(routePaths.PAIRING_ALERT);
const forwardToPairingGuidePage = (deviceType, isNew) =>
    routerService.forwardTo(urlUtils.join(routePaths.PAIRING_GUIDE, deviceType, isNew ? bleConnectionType.NEW : ''));
const forwardToPairingSuccessPage = () => routerService.forwardTo(routePaths.PAIRING_SUCCESS);
const forwardToQuickStartGuidePage = () => forwardToTutorialPage(tutorialTypes.TUTORIAL_TYPE_QUICK_START_GUIDE);
const forwardToRoot = () => routerService.forwardTo(routePaths.ROOT);
const forwardToSelectDevice = () => routerService.replace(routePaths.SELECT_DEVICE);
const forwardToSelectMarketPage = () => routerService.forwardTo(routePaths.SELECT_MARKET);
const forwardToTipsAndTricksPage = () => routerService.forwardTo(routePaths.TIPS_AND_TRICKS);
const forwardToTNCDataCollectionPage = () => routerService.forwardTo(routePaths.TNC_DATA_COLLECTION);
const forwardToTNCPreferenceCenterPage = () => routerService.forwardTo(routePaths.TNC_DATA_PREFERENCE);
const forwardToTNCPreferenceCenterSettingsPage = () => routerService.forwardTo(routePaths.TNC_DATA_PREFERENCE_SETTINGS);
const forwardToTNCTermsOfUsePage = () => routerService.forwardTo(routePaths.TNC_TERMS_OF_USE);
const forwardToTutorialIntroPage = (tutorialType) =>
    routerService.forwardTo(urlUtils.join(routePaths.TUTORIAL_INTRO, tutorialType));
const forwardToTutorialList = () => routerService.forwardTo(routePaths.TUTORIAL_LIST);
const forwardToTutorialPage = (tutorialType) =>
    routerService.forwardTo(urlUtils.join(routePaths.TUTORIAL, tutorialType));
const forwardToUpdateFWPage = () => routerService.forwardTo(routePaths.UPDATE_FW);
const forwardToWelcomeUserPage = () => routerService.forwardTo(routePaths.WELCOME_USER);
const forwardToLegalDisclaimerPage = () => routerService.forwardTo(routePaths.LEGAL_DISCLAIMER);

export default {
    forwardToAboutDevicePage,
    forwardToActivationSuccessPage,
    forwardToAgeGatePage,
    forwardToAppIntroPage,
    forwardToBrowserVerificationPage,
    forwardToDeviceActivationPage,
    forwardToDeviceRegistrationPage,
    forwardToDeviceSettingsPage,
    forwardToLoginPage,
    forwardToMyDevicesPage,
    forwardToNoDevicesPage,
    forwardToNotCompatibleBrowserPage,
    forwardToPairingAlertPage,
    forwardToPairingGuidePage,
    forwardToPairingSuccessPage,
    forwardToQuickStartGuidePage,
    forwardToRoot,
    forwardToSelectDevice,
    forwardToSelectMarketPage,
    forwardToTipsAndTricksPage,
    forwardToTNCDataCollectionPage,
    forwardToTNCPreferenceCenterPage,
    forwardToTNCPreferenceCenterSettingsPage,
    forwardToTNCTermsOfUsePage,
    forwardToTutorialIntroPage,
    forwardToTutorialList,
    forwardToTutorialPage,
    forwardToUpdateFWPage,
    forwardToWelcomeUserPage,
    forwardToLegalDisclaimerPage,
};
