import React from 'react';

import ButtonDark from '../../../components/Button/ButtonDark/ButtonDark';
import * as localizationKeys from '../../../consts/app/localizationKeys';
import analyticsService from '../../../services/analyticsService';
import appRouterService from '../../../services/appRouterService';
import {getLocalizedStrings} from '../../../services/localization/localizationService';
import elementsTesIds from '../../../test/consts/elementsTesIds';
import styles from '../NoDevices.module.scss';

const SetupNewDeviceButton = () => {
    const onClick = () => {
        analyticsService.pushPrePairingSetupNewIqosEvent();
        appRouterService.forwardToPairingAlertPage();
    };
    const localizedStrings = getLocalizedStrings();

    return (
        <ButtonDark
            className={styles.Button}
            onClick={onClick}
            showArrow
            testId={elementsTesIds.DEVICE_LIST_BTN_SETUP_NEW_DEVICE}
        >
            {localizedStrings[localizationKeys.DEVICE_LIST_NO_DEVICE_SET_UP_BUTTON_TEXT]}
        </ButtonDark>
    );
};

export default SetupNewDeviceButton;
