import IoTClient from '../iot/iotClient';
import log from '../logger/log';

const initNewClient = (mqttOptions) => {
    const {brokerUrl, username, clientId} = mqttOptions;

    const options = {
        brokerUrl: brokerUrl || 'wss://mqtt-ws-iam-sandbox.mbedcloudintegration.net:443/mqtt',
        clientId: clientId || 'clientid',
        connectTimeout: 10 * 1000,
        password: null,
        reconnectPeriod: 10 * 1000,
        username,
    };

    log.debug(`trying to init mqtt client with the following options:`);
    console.log(options);

    new IoTClient(true, options);
};

const updateClientCredentials = (awsCredentials) => {
    const {accessKeyId, secretAccessKey, sessionToken} = awsCredentials;
    const client = new IoTClient();
    client.updateWebSocketCredentials(accessKeyId, secretAccessKey, sessionToken);
};

const attachMessageHandler = (handler) => {
    const client = new IoTClient();
    client.attachMessageHandler(handler);
};

const attachConnectHandler = (onConnectHandler) => {
    const client = new IoTClient();
    client.attachConnectHandler(onConnectHandler);
};

const attachErrorHandler = (onErrorHandler) => {
    const client = new IoTClient();
    client.attachErrorHandler(onErrorHandler);
};

const attachCloseHandler = (handler) => {
    const client = new IoTClient();
    client.attachCloseHandler(handler);
};

const publish = (topic, message) => {
    const client = new IoTClient();
    return client.publish(topic, message);
};

const subscribe = (topic) => {
    const client = new IoTClient();
    client.subscribe(topic);
};

const unsubscribe = (topic) => {
    const client = new IoTClient();
    client.unsubscribe(topic);
};

const disconnect = () => {
    const client = new IoTClient();
    client.disconnect();
};

const isIotClientInitialized = () => {
    const client = new IoTClient();
    return client.isInitialized();
};

export default {
    attachCloseHandler,
    attachConnectHandler,
    attachErrorHandler,
    attachMessageHandler,
    disconnect,
    initNewClient,
    isIotClientInitialized,
    publish,
    subscribe,
    unsubscribe,
    updateClientCredentials,
};
