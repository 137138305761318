import React from 'react';
import {Route, Switch} from 'react-router-dom';

import * as routePaths from '../../consts/route/routePaths';
import AboutDevicePage from '../AboutDevicePage/AboutDevicePage';
import ActivationSuccessPage from '../ActivationSuccessPage/ActivationSuccessPage';
import AgeGatePage from '../AgeGatePage/AgeGatePage';
import AppIntroPage from '../BrowserCompatibility/AppIntroPage/AppIntroPage';
import BrowserVerificationPage from '../BrowserCompatibility/BrowserVerificationPage/BrowserVerificationPage';
import NotCompatibleBrowserPage from '../BrowserCompatibility/NotCompatibleBrowserPage/NotCompatibleBrowserPage';
import CleaningGuidePage from '../CleaningGuidePages/CleaningGuidePage';
import DeviceActivationPage from '../DeviceActivationPage/DeviceActivationPage';
import DeviceRegistrationPage from '../DeviceRegistrationPage/DeviceRegistrationPage';
import PreferenceCenterSettingsPage from '../DeviceSettingsPage/components/PreferenceCenterSettingsPage';
import DeviceSettingsPage from '../DeviceSettingsPage/DeviceSettingsPage';
import LegalDisclaimerPage from '../LegalDisclaimerPage/LegalDisclaimerPage';
import LoginPage from '../LoginPage/LoginPage';
import MyDevicesPage from '../MyDevicesPage/MyDevicesPage';
import NoDevicesPage from '../NoDevicesPage/NoDevicesPage';
import NotFound from '../NotFound/NotFound';
import PairingAlertPage from '../PairingAlertPage/PairingAlertPage';
import PairingGuidePage from '../PairingGuidePage/PairingGuidePage';
import PairingSuccessPage from '../PairingSuccessPage/PairingSuccessPage';
import ResetDevicePage from '../ResetDevicePage/ResetDevicePage';
import Root from '../Root/Root';
import SelectMarketPage from '../SelectMarketPage/SelectMarketPage';
import SelectYourDevicePage from '../SelectYourDevicePage/SelectYourDevicePage';
import TipsAndTricksPage from '../TipsAndTricksPage/TipsAndTricksPage';
import TNC from '../TNC/TNC';
import TutorialIntroPage from '../TutorialPages/TutorialIntroPage/TutorialIntroPage';
import TutorialList from '../TutorialPages/TutorialList/TutorialList';
import TutorialPage from '../TutorialPages/TutorialPage/TutorialPage';
import UpdateFWPage from '../UpdateFWPage/UpdateFWPage';
import WelcomePage from '../WelcomePage/WelcomePage';
import WelcomeUserPage from '../WelcomeUserPage/WelcomeUserPage';
import AppRoute from './AppRoute';

const Routes = () => {
    return (
        <Switch>
            <Route exact path={routePaths.ROOT} component={Root} />
            <AppRoute path={routePaths.ABOUT_DEVICE} component={AboutDevicePage} />
            <AppRoute path={routePaths.ACTIVATION_SUCCESS} component={ActivationSuccessPage} />
            <AppRoute path={routePaths.ACTIVATION} component={DeviceActivationPage} />
            <AppRoute path={routePaths.AGE_GATE} component={AgeGatePage} />
            <AppRoute path={routePaths.APP_INTRO} component={AppIntroPage} />
            <AppRoute path={routePaths.BROWSER_VERIFICATION} component={BrowserVerificationPage} />
            <AppRoute path={routePaths.CLEAN_TUTORIAL} component={CleaningGuidePage} />
            <AppRoute path={routePaths.CLEAN_TUTORIAL} component={CleaningGuidePage} />
            <AppRoute path={routePaths.DEVICE_SETTINGS} component={DeviceSettingsPage} />
            <AppRoute path={routePaths.LOGIN} component={LoginPage} />
            <AppRoute path={routePaths.MY_DEVICES} component={MyDevicesPage} />
            <AppRoute path={routePaths.NO_DEVICES} component={NoDevicesPage} />
            <AppRoute path={routePaths.NOT_COMPATIBLE_BROWSER} component={NotCompatibleBrowserPage} />
            <AppRoute path={routePaths.NOT_FOUND} component={NotFound} />
            <AppRoute path={routePaths.PAIRING_ALERT} component={PairingAlertPage} />
            <AppRoute path={routePaths.PAIRING_GUIDE_PARAMETERIZED} component={PairingGuidePage} />
            <AppRoute path={routePaths.PAIRING_SUCCESS} component={PairingSuccessPage} />
            <AppRoute path={routePaths.REGISTRATION} component={DeviceRegistrationPage} />
            <AppRoute path={routePaths.RESET_DEVICE} component={ResetDevicePage} />
            <AppRoute path={routePaths.SELECT_DEVICE} component={SelectYourDevicePage} />
            <AppRoute path={routePaths.SELECT_MARKET} component={SelectMarketPage} />
            <AppRoute path={routePaths.TIPS_AND_TRICKS} component={TipsAndTricksPage} />
            <AppRoute path={routePaths.TNC_DATA_PREFERENCE_SETTINGS} component={PreferenceCenterSettingsPage} />
            <AppRoute path={routePaths.TNC} component={TNC} />
            <AppRoute path={routePaths.TUTORIAL_INTRO_PARAMETERIZED} component={TutorialIntroPage} />
            <AppRoute path={routePaths.TUTORIAL_LIST} component={TutorialList} />
            <AppRoute path={routePaths.TUTORIAL_PARAMETERIZED} component={TutorialPage} />
            <AppRoute path={routePaths.UPDATE_FW} component={UpdateFWPage} />
            <AppRoute path={routePaths.WELCOME} component={WelcomePage} />
            <AppRoute path={routePaths.WELCOME_USER} component={WelcomeUserPage} />
            <AppRoute path={routePaths.LEGAL_DISCLAIMER} component={LegalDisclaimerPage} />
        </Switch>
    );
};

export default Routes;
