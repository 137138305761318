import React from 'react';
import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';

import BackButton from '../../../components/BackButton/BackButton';
import ButtonDark from '../../../components/Button/ButtonDark/ButtonDark';
import Carousel from '../../../components/Carousel/Carousel';
import GuideSliderItem from '../../../components/GuideSliderItem/GuideSliderItem';
import Container from '../../../components/Layout/Container/Container';
import Video from '../../../components/Video/Video';
import * as localizationKeys from '../../../consts/app/localizationKeys';
import tutorialTypes from '../../../consts/app/tutorialTypes';
import * as routeParams from '../../../consts/route/routeParams';
import * as routePaths from '../../../consts/route/routePaths';
import appRouterService from '../../../services/appRouterService';
import layoutService from '../../../services/layoutService';
import {getLocalizedStrings} from '../../../services/localization/localizationService';
import routerService from '../../../services/routerService';
import {makeSelectLayoutType} from '../../../state/selectors/global';
import {makeSelectIotDeviceData} from '../../../state/selectors/iotDevice';
import elementsTesIds from '../../../test/consts/elementsTesIds';
import tutorialPageService from '../tutorialPageService';
import styles from './TutorialPage.module.scss';

const mapDispatchToProps = createStructuredSelector({
    iotDevice: makeSelectIotDeviceData(),
    layout: makeSelectLayoutType(),
});

const TutorialPage = (props) => {
    const {
        iotDevice,
        layout,
        match: {params},
    } = props;
    const {device} = iotDevice;
    const deviceType = device?.type;
    const tutorialType = params[routeParams.TUTORIAL_TYPE];
    const isQuickStartGuide = tutorialType === tutorialTypes.TUTORIAL_TYPE_QUICK_START_GUIDE;
    const isDesktopLayout = layoutService.isDesktopLayout(layout);
    const deviceTypeContent = tutorialPageService.getTutorialBaseContent(deviceType, tutorialType);
    const nextTutorialData = !isQuickStartGuide
        ? tutorialPageService.getNextTutorialData(deviceType, tutorialType)
        : null;
    const {nextTutorialType, nextTutorialTitle} = nextTutorialData || {};

    const onLastSlideButtonClick = () => {
        appRouterService.forwardToTutorialIntroPage(nextTutorialType);
    };

    const onBackButtonClick = isQuickStartGuide ? routerService.goBack : appRouterService.forwardToTutorialList;

    const lastSlideButton = !isQuickStartGuide ? (
        <LastSlideButton text={nextTutorialTitle} onClick={onLastSlideButtonClick} />
    ) : null;

    return deviceTypeContent ? (
        <>
            {!isQuickStartGuide && (
                <Container className={styles.BackButtonContainer} isFloatHeight>
                    <BackButton
                        onClick={onBackButtonClick}
                        testId={isQuickStartGuide ? elementsTesIds.QSG_PAGE_BTN_BACK : null}
                    />
                </Container>
            )}
            <div className={styles.Page}>
                <Carousel
                    arrowNextTestId={isQuickStartGuide ? elementsTesIds.QSG_PAGE_BTN_NEXT : null}
                    arrowPrevTestId={isQuickStartGuide ? elementsTesIds.QSG_PAGE_BTN_PREV : null}
                    controlArrowClassName={styles.SliderButton}
                    dotsClassName={styles.Dots}
                    isCommonNextSlideButtonVisible={!isDesktopLayout}
                    isControlArrowVisible
                    itemsCount={deviceTypeContent?.length}
                    lastSlideButton={lastSlideButton}
                    lastSlideRoutePath={isQuickStartGuide ? routePaths.DEVICE_SETTINGS : null}
                    layout={layout}
                    nextSlideButtonClassName={styles.NextSlideButton}
                >
                    {deviceTypeContent.map((element, index) => {
                        const {header, description, video} = element;

                        return (
                            <div key={index.toString()} className={styles.ContentWrapper}>
                                <GuideSliderItem
                                    header={header}
                                    description={description}
                                    element={<GuideVideo src={video} />}
                                />
                            </div>
                        );
                    })}
                </Carousel>
            </div>
        </>
    ) : null;
};

const GuideVideo = ({src}) => <Video autoplay loop muted playsinline src={src} />;

const LastSlideButton = ({text, onClick}) => {
    const localizedStrings = getLocalizedStrings();

    return (
        <ButtonDark showArrow onClick={onClick} className={styles.LastButtonContainer}>
            <span className={styles.LastButtonNextWord}>{localizedStrings[localizationKeys.TIPS_NEXT_TIPS_TEXT]}</span>
            {localizedStrings[localizationKeys[text]]}
        </ButtonDark>
    );
};

export default connect(mapDispatchToProps)(TutorialPage);
