import {createSelector} from 'reselect';

const selectState = (state) => state.yapEncrypted;

const makeSelectAsset = (deviceSerialNumber) =>
    createSelector(selectState, (state) => state.yapAssets?.find((asset) => asset.serialNumber === deviceSerialNumber));

const makeSelectAssetByAssetId = (assetId) =>
    createSelector(selectState, (state) => state.yapAssets?.find((asset) => asset.assetId === assetId));

const makeSelectFirstAsset = () =>
    createSelector(selectState, (state) => (state.yapAssets ? state.yapAssets[0] : null));

const makeSelectIsYapActivationInProgress = () =>
    createSelector(selectState, (state) => state.isYapActivationInProgress);

const makeSelectIsDeviceSyncInProgress = () => createSelector(selectState, (state) => state.isYapSyncInProgress);

const makeSelectIsYapDeviceConnected = () => createSelector(selectState, (state) => state.isYapDeviceConnected);

const makeSelectYapLastRequestId = () => createSelector(selectState, (state) => state.lastRequestId);

const makeSelectYapLastCheckRequestDate = () => createSelector(selectState, (state) => state.lastCheckRequestDate || 0);

export {
    makeSelectAsset,
    makeSelectAssetByAssetId,
    makeSelectFirstAsset,
    makeSelectIsYapActivationInProgress,
    makeSelectIsDeviceSyncInProgress,
    makeSelectIsYapDeviceConnected,
    makeSelectYapLastRequestId,
    makeSelectYapLastCheckRequestDate,
};
