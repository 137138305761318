import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';

import Container from '../../components/Layout/Container/Container';
import SkipButton from '../../components/SkipButton/SkipButton';
import * as localizationKeys from '../../consts/app/localizationKeys';
import analyticsService from '../../services/analyticsService';
import appRouterService from '../../services/appRouterService';
import deviceListSortServices from '../../services/device/deviceListSortService';
import layoutService from '../../services/layoutService';
import {getLocalizedStrings} from '../../services/localization/localizationService';
import marketConfigService from '../../services/marketConfigService';
import {setMessage} from '../../state/ducks/iotDevice/actions';
import {makeSelectLayoutType} from '../../state/selectors/global';
import {dispatch} from '../../state/store';
import PairingSkipPopup from '../PairingSkipPopup/PairingSkipPopup';
import SelectDeviceItem from './components/SelectDeviceItem';
import styles from './SelectYourDevicePage.module.scss';

const mapStateToProps = createStructuredSelector({
    layout: makeSelectLayoutType(),
});

const SelectYourDevicePage = (props) => {
    const {layout} = props;

    useEffect(() => {
        analyticsService.pushPairingDeviceSelectionEvent();
    }, []);

    const [isPairingSkipPopupVisible, setIsPairingSkipPopupVisible] = useState(false);

    const isDesktopLayout = layoutService.isDesktopLayout(layout);

    const isIccJourneyScreensEnabled = marketConfigService.isIccJourneyScreensEnabled();

    const onSkipPopupTryAgainButtonClick = () => {
        setIsPairingSkipPopupVisible(!isPairingSkipPopupVisible);
    };

    const onSkipPopupSkipButtonClick = () => {
        if (isIccJourneyScreensEnabled) {
            appRouterService.forwardToMyDevicesPage();
        } else {
            appRouterService.forwardToNoDevicesPage();
        }
    };
    const onDeviceClick = (deviceType) => {
        dispatch(setMessage(null));
        appRouterService.forwardToPairingGuidePage(deviceType, true);
    };

    const selectDeviceData = deviceListSortServices.getSortedDeviceList();
    const localizedStrings = getLocalizedStrings();

    return (
        <>
            <div className={`${styles.Page} page`}>
                <Container isFloatHeight>
                    <SkipButton isLeft onClick={onSkipPopupTryAgainButtonClick} />
                </Container>
                <Container className={`${styles.Container}`}>
                    <div>
                        <h2 className={styles.Title}>{localizedStrings[localizationKeys.DEVICE_LIST_CHOOSER_TITLE]}</h2>
                        <div className={styles.DevicesList}>
                            {selectDeviceData.map((device) => {
                                const {type, name, imageSet, testId} = device;
                                const {desktop, mobile} = imageSet;
                                const isDeviceSupported = marketConfigService.isDeviceSupported(type);

                                if (isDeviceSupported) {
                                    return (
                                        <SelectDeviceItem
                                            image={isDesktopLayout ? desktop : mobile}
                                            key={type}
                                            name={name}
                                            onClick={onDeviceClick}
                                            testId={testId}
                                            type={type}
                                        />
                                    );
                                } else {
                                    return null;
                                }
                            })}
                        </div>
                    </div>
                </Container>
            </div>
            {isPairingSkipPopupVisible && (
                <PairingSkipPopup onSkip={onSkipPopupSkipButtonClick} onTryAgain={onSkipPopupTryAgainButtonClick} />
            )}
        </>
    );
};

export default connect(mapStateToProps)(SelectYourDevicePage);
