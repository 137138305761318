import * as storageKeys from '../../consts/app/storageKeys';
import localStorageClient from './localStorageClient';
import sessionStorageClient from './sessionStorageClient';

const getDeviceDataFromStorage = () => sessionStorageClient.getEncodedItem(storageKeys.DEVICE_DATA);
const removeDeviceDataFromStorage = () => sessionStorageClient.removeItem(storageKeys.DEVICE_DATA);
const setDeviceDataToStorage = (data) => sessionStorageClient.setDecodedItem(storageKeys.DEVICE_DATA, data);

const getAppInstanceIdFromStorage = () => localStorageClient.getItem(storageKeys.APP_INSTANCE_ID);
const setAppInstanceIdToStorage = (data) => localStorageClient.setItem(storageKeys.APP_INSTANCE_ID, data);

const setAccessTokenToStorage = (data) => localStorageClient.setDecodedItem(storageKeys.ACCESS_TOKEN, data);
const removeAccessTokenFromStorage = () => localStorageClient.removeItem(storageKeys.ACCESS_TOKEN);
const getAccessTokenFromStorage = () => localStorageClient.getEncodedItem(storageKeys.ACCESS_TOKEN);

const setAccessTokenExpirationToStorage = (data) =>
    localStorageClient.setDecodedItem(storageKeys.ACCESS_TOKEN_EXPIRATION, data);
const removeAccessTokenExpirationFromStorage = () => localStorageClient.removeItem(storageKeys.ACCESS_TOKEN_EXPIRATION);
const getAccessTokenExpirationFromStorage = () => {
    try {
        const date = localStorageClient.getEncodedItem(storageKeys.ACCESS_TOKEN_EXPIRATION);

        return new Date(date).getTime();
    } catch (e) {
        return null;
    }
};

const setRefreshTokenToStorage = (data) => localStorageClient.setDecodedItem(storageKeys.REFRESH_TOKEN, data);
const removeRefreshTokenFromStorage = () => localStorageClient.removeItem(storageKeys.REFRESH_TOKEN);
const getRefreshTokenFromStorage = () => localStorageClient.getEncodedItem(storageKeys.REFRESH_TOKEN);

const setSpiceTokenToStorage = (data) => localStorageClient.setDecodedItem(storageKeys.SPICE_TOKEN, data);
const removeSpiceTokenFromStorage = () => localStorageClient.removeItem(storageKeys.SPICE_TOKEN);
const getSpiceTokenFromStorage = () => localStorageClient.getEncodedItem(storageKeys.SPICE_TOKEN);

const setUsernameToStorage = (data) => localStorageClient.setDecodedItem(storageKeys.USER_NAME, data);
const removeUsernameFromStorage = () => localStorageClient.removeItem(storageKeys.USER_NAME);
const getUsernameFromStorage = () => localStorageClient.getEncodedItem(storageKeys.USER_NAME);

const setMarketCodeToLocalStorage = (data) => localStorageClient.setDecodedItem(storageKeys.MARKET_CODE, data);
const removeMarketCodeFromLocalStorage = () => localStorageClient.removeItem(storageKeys.MARKET_CODE);
const getMarketCodeFromLocalStorage = () => localStorageClient.getEncodedItem(storageKeys.MARKET_CODE);

const setLanguageCodeToLocalStorage = (data) => localStorageClient.setDecodedItem(storageKeys.LANGUAGE_CODE, data);
const removeLanguageCodeFromLocalStorage = () => localStorageClient.removeItem(storageKeys.LANGUAGE_CODE);
const getLanguageCodeFromLocalStorage = () => localStorageClient.getEncodedItem(storageKeys.LANGUAGE_CODE);

const getIsCompatiblePageOpenedFromStorage = () => sessionStorageClient.getEncodedItem(storageKeys.COMPATIBLE_PAGE);
const setIsCompatiblePageOpenedToStorage = (data) =>
    sessionStorageClient.setDecodedItem(storageKeys.COMPATIBLE_PAGE, data);
const removeIsCompatiblePageOpenedFromStorage = () => sessionStorageClient.removeItem(storageKeys.COMPATIBLE_PAGE);

const removeUserSessionDataFromStorage = () => {
    removeAccessTokenFromStorage();
    removeAccessTokenExpirationFromStorage();
    removeLanguageCodeFromLocalStorage();
    removeMarketCodeFromLocalStorage();
    removeRefreshTokenFromStorage();
    removeSpiceTokenFromStorage();
    removeUsernameFromStorage();
};

const getGamGigyaAccountDataFromStorage = () => localStorageClient.getItem(storageKeys.GAM_GIGYA_ACCOUNT_DATA);

export default {
    getAccessTokenExpirationFromStorage,
    getAccessTokenFromStorage,
    getAppInstanceIdFromStorage,
    getDeviceDataFromStorage,
    getIsCompatiblePageOpenedFromStorage,
    getLanguageCodeFromLocalStorage,
    getMarketCodeFromLocalStorage,
    getRefreshTokenFromStorage,
    getSpiceTokenFromStorage,
    getUsernameFromStorage,
    removeDeviceDataFromStorage,
    removeIsCompatiblePageOpenedFromStorage,
    removeSpiceTokenFromStorage,
    removeUsernameFromStorage,
    removeUserSessionDataFromStorage,
    setAccessTokenExpirationToStorage,
    setAccessTokenToStorage,
    setAppInstanceIdToStorage,
    setDeviceDataToStorage,
    setIsCompatiblePageOpenedToStorage,
    setLanguageCodeToLocalStorage,
    setMarketCodeToLocalStorage,
    setRefreshTokenToStorage,
    setSpiceTokenToStorage,
    setUsernameToStorage,
    getGamGigyaAccountDataFromStorage,
};
