import queryParameters from '../consts/route/queryParameters';

const getDataFromRoot = (attr) => getRoot().getAttribute(attr);
const getDataLanguageFromRoot = () => getDataFromRoot(queryParameters.DATA_LANGUAGE);
const getDataUserTokenFromRoot = () => getDataFromRoot(queryParameters.DATA_USER_TOKEN);
const getElementBySelector = (selector) => document.querySelector(selector);
const getRoot = () => document.getElementById('root');
const getTokenUrlFromRoot = () => getDataFromRoot(queryParameters.DATA_REFRESH_TOKEN_URL);
const getDeviceCodentifyFromRoot = () => getDataFromRoot(queryParameters.DATA_DEVICE_CODENTIFY);

export default {
    getDataFromRoot,
    getDataLanguageFromRoot,
    getDataUserTokenFromRoot,
    getElementBySelector,
    getRoot,
    getTokenUrlFromRoot,
    getDeviceCodentifyFromRoot,
};
