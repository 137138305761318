export const IOT_DEVICE_CLEAR = 'app/iotDevice/IOT_DEVICE_CLEAR';
export const IOT_DEVICE_CLEAR_DATA = 'app/iotDevice/IOT_DEVICE_CLEAR_DATA';
export const IOT_DEVICE_SET_DEVICE_CONNECTION_STATUS = 'app/iotDevice/IOT_DEVICE_SET_DEVICE_CONNECTION_STATUS';
export const IOT_DEVICE_SET_SHOULD_SHOW_SBL_POPUP = 'app/iotDevice/IOT_DEVICE_SET_SHOULD_SHOW_SBL_POPUP';
export const IOT_DEVICE_SET_DEVICE_ACTIVATION_STATUS = 'app/iotDevice/IOT_DEVICE_SET_DEVICE_ACTIVATION_STATUS';
export const IOT_DEVICE_SET_FIRMWARE_DATA = 'app/iotDevice/IOT_DEVICE_SET_FIRMWARE_DATA';
export const IOT_DEVICE_SET_IS_RESET = 'app/iotDevice/IOT_DEVICE_SET_IS_RESET';
export const IOT_DEVICE_SET_IS_VIBRATION_ALARM_ACTIVE = 'app/iotDevice/IOT_DEVICE_SET_IS_VIBRATION_ALARM_ACTIVE';
export const IOT_DEVICE_SET_MESSAGE = 'app/iotDevice/IOT_DEVICE_SET_MESSAGE';
export const IOT_DEVICE_UPDATE_DATA = 'app/iotDevice/IOT_DEVICE_UPDATE_DATA';
