const hex2bin = (hex) => {
    let bytes = [];
    for (let i = 0; i < hex.length - 1; i += 2) bytes.push(parseInt(hex.substr(i, 2), 16));
    return new Uint8Array(bytes);
};

const buffer2hex = (data) => {
    let out = [];
    for (let i = 0; i < data.byteLength; i++) {
        out.push(('0' + data.getUint8(i, true).toString(16)).slice(-2));
    }
    return out.join('');
};

const hex2dec = (num) => {
    return ConvertBase(num).from(16).to(10);
};

const ConvertBase = (num) => {
    return {
        from: (baseFrom) => {
            return {
                to: (baseTo) => {
                    return parseInt(num, baseFrom).toString(baseTo);
                },
            };
        },
    };
};

export default {
    buffer2hex,
    hex2bin,
    hex2dec,
};
