import React from 'react';
import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';

import BackButton from '../../../components/BackButton/BackButton';
import Container from '../../../components/Layout/Container/Container';
import TableCell from '../../../components/TableCell/TableCell';
import appRouterService from '../../../services/appRouterService';
import {makeSelectLayoutType} from '../../../state/selectors/global';
import {makeSelectIotDeviceData} from '../../../state/selectors/iotDevice';
import TutorialListHeader from './components/TutorialListHeader/TutorialListHeader';
import styles from './TutorialList.module.scss';
import {getTutorialListData} from './tutorialListData';

const mapDispatchToProps = createStructuredSelector({
    iotDevice: makeSelectIotDeviceData(),
    layout: makeSelectLayoutType(),
});

const TutorialList = (props) => {
    const {iotDevice, layout} = props;
    const {device} = iotDevice || {};
    const deviceType = device?.type;
    const deviceTypeContent = getTutorialListData()[deviceType];
    const {header, buttons} = deviceTypeContent || {};

    const onButtonClick = (tutorialType) => {
        appRouterService.forwardToTutorialIntroPage(tutorialType);
    };

    return (
        <>
            <div className={styles.TutorialsListContainer}>
                <Container isFloatHeight>
                    <BackButton onClick={appRouterService.forwardToDeviceSettingsPage} />
                </Container>
                <TutorialListHeader
                    image={header.image}
                    title={header.title}
                    description={header.desc}
                    layout={layout}
                />
                <div className={styles.TutorialListTable}>
                    {buttons.map((element, index) => {
                        const {icons, name, type} = element || {};

                        return (
                            <TableCell
                                key={index.toString()}
                                imgUrls={icons}
                                name={name}
                                classNameContainer={styles.TableCell}
                                className={styles.TableSpan}
                                onClick={() => onButtonClick(type)}
                            />
                        );
                    })}
                </div>
            </div>
        </>
    );
};

export default connect(mapDispatchToProps)(TutorialList);
