import {setDeviceActivationStatus, setIsDeviceReady, updateIotDeviceData} from '../../state/ducks/iotDevice/actions';
import {makeSelectFirstAsset} from '../../state/selectors/yapEncrypted';
import {dispatch, getState} from '../../state/store';
import deviceTypesService from '../device/deviceTypesService';
import log from '../logger/log';
import {deviceInfoMapping} from '../mapping/iotMappings';
import gamClient from './gamClient';
import gamClientService from './gamClientService';

const initGamData = async () => {
    const asset = makeSelectFirstAsset()(getState());
    const data = {
        device: {
            type: asset.type,
            name: deviceTypesService.getProductNameByType(asset.type),
            mediaId: asset.materialId,
        },
        device_global_features_config: {
            is_holder_check: false,
            is_led_intensity: true,
            is_vibration: false,
            is_self_cleaning: false,
            is_device_alarm: true,
        },
        device_management_features_config: {
            connect_care_mode: false,
            device_led_intensity_mode: true,
            device_parent_protection_mode: true,
            holder_led_intensity_mode: false,
            device_holder_charged_vibration: false,
            device_self_cleaning: 0,
            programmable_vibrations: {setting_supported: false, common_vibration_switcher: false},
            device_alarm: true,
            device_alarm_duration: 30,
            device_vape_cloud_size_mode: true,
            device_responsive_draw_profile_mode: true,
        },
    };

    const mappedData = deviceInfoMapping(data);
    dispatch(updateIotDeviceData(mappedData));

    dispatch(setIsDeviceReady());
};

const initGam = async () => {
    await gamClient.fetchGamToken();
    await gamClientService.getGamAssets();
};

const isDeviceActivatedInGam = async () => {
    let isDeviceActivated = false;
    const state = getState();

    let gamAsset = makeSelectFirstAsset()(state);
    if (gamAsset?.assetId) {
        isDeviceActivated = gamAsset.yapActivated;
    }

    log.info(`gamService: isDeviceActivatedInGam: ${isDeviceActivated}`);
    dispatch(setDeviceActivationStatus(isDeviceActivated));
    return isDeviceActivated;
};

export default {
    initGam,
    initGamData,
    isDeviceActivatedInGam,
};
