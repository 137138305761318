import {setNotificationFailed} from '../../state/ducks/global';
import {setMwDeviceIsDisconnectedWithNoReconnect} from '../../state/ducks/mwDevice';
import {dispatch} from '../../state/store';
import helpers from '../../utils/helpers';
import DeviceRequestTimeoutError from '../errors/deviceRequestTimeoutError';
import DisconnectEventEmitter from '../events/disconnectEventEmitter';
import log from '../logger/log';
import pairingService from '../pairingService';
import iotService from '../scpCloud/iotService';
import mwIotMessageRequestService from '../scpCloud/mwIotMessageRequestService';
import uiIotMessageResponseChecker from '../scpCloud/uiIotMessageResponseChecker';
import IqosBleClient from './iqosBleClient';
import scpCharacteristicService from './scpCharacteristicService';

const DEVICE_REQUEST_TIMEOUT_MS = 10 * 1000;

const connect = async ({isNewDevice, onConnect, onError}) => {
    try {
        let deviceSelectTimeout;

        const options = {
            onDeviceConnect: () => {
                pairingService.addBleAvailabilityListener();
            },
            onDisconnected: async (isDisconnectedByUser) => {
                const disconnectEventEmitter = new DisconnectEventEmitter();

                if (isDisconnectedByUser) {
                    dispatch(setMwDeviceIsDisconnectedWithNoReconnect(true));
                }

                disconnectEventEmitter.emitDeviceDisconnect();
                disconnectEventEmitter.removeAllDisconnectListeners();
                scpCharacteristicService.disable();
                iotService.unsubscribeFromDeviceTopic();
                uiIotMessageResponseChecker.clearResponsesTimeouts();
                mwIotMessageRequestService.publishDisconnectedEvent();
            },
            onForcedDisconnect: () => {
                pairingService.disconnectAndForwardToMyDevicesPage();
            },
            onDeviceReconnectSuccess: () => {
                iotService.initIoT();
            },
            onReconnectFail: async () => {
                dispatch(setNotificationFailed(`Couldn't reconnect to your device`));
            },
            onDeviceSelect: () => {
                const deviceSelectPromise = new Promise((res, rej) => {
                    deviceSelectTimeout = setTimeout(() => {
                        rej(new DeviceRequestTimeoutError());
                    }, DEVICE_REQUEST_TIMEOUT_MS);
                });

                deviceSelectPromise.catch((e) => {
                    helpers.runFunction(onError, [e]);
                });
            },
            onFinishDeviceSelect: () => clearTimeout(deviceSelectTimeout),
        };

        const iqosBleClient = new IqosBleClient(options);

        await iqosBleClient.connect({isNewDevice, onConnect, onError});
    } catch (e) {
        log.debug(`iqosBleService: device connection failed, error: ${e}`);
        helpers.runFunction(onError, [e]);
        throw e;
    }
};

const disconnect = () => {
    const iqosBleClient = new IqosBleClient();

    iqosBleClient.disconnect();
};

const isDeviceConnected = () => {
    const iqosBleClient = new IqosBleClient();

    return iqosBleClient.isDeviceConnected();
};

export default {
    connect,
    disconnect,
    isDeviceConnected,
};
