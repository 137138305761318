import Paho from 'paho-mqtt';

import stringUtils from '../../utils/stringUtils';
import log from '../logger/log';

let instance = null;

export default class CmClient {
    constructor(assetId, createNew = false, options) {
        if (createNew && instance) {
            instance.disconnect();
            instance = null;
        }

        if (instance) {
            return instance;
        }

        if (createNew) {
            instance = this;
            this.assetId = assetId;
            this.initClient(options);
            return instance;
        }
    }

    initClient(options) {
        if (options?.endpoint && options?.authToken?.token) {
            const path = stringUtils.formatString('/mqtt?{0}', options.authToken.token);
            log.info(
                `CmClient: start connection for assetId: ${this.assetId} with endpoint: ${options.endpoint} and path: ${path}`
            );
            this.client = new Paho.Client(options.endpoint, 443, path, options.serialNumber);
            this.topicPublish = options.topicPublish;
        } else {
            throw new Error("CM Client can't be initialized.");
        }
    }

    connect() {
        const connectOptions = {
            useSSL: true,
            onFailure: (e) => {
                log.error(`CM Client on connect error: ${e}`);
            },
        };
        this.client.connect(connectOptions);
    }

    isConnected(assetId) {
        if (this.assetId !== assetId) {
            return false;
        }
        return this.client && this.client.isConnected();
    }

    disconnect() {
        if (this.isConnected(this.assetId)) {
            this.client.disconnect();
        }
        instance = null;
    }

    subscribe(topic) {
        log.info(`CmClient: subscribe on topic: ${topic}`);
        this.client.subscribe(topic);
    }

    unsubscribe(topic) {
        if (this.isConnected(this.assetId)) {
            log.info(`CmClient: unsubscribe from topic: ${topic}`);
            this.client.unsubscribe(topic);
        }
    }

    publish(message) {
        if (this.isConnected(this.assetId) && this.topicPublish) {
            log.info(`CmClient: publish message, topic: ${this.topicPublish}, message: ${message})}`);
            this.client.publish(this.topicPublish, message);
        }
    }

    attachMessageHandler(onNewMessageHandler) {
        this.client.onMessageArrived = (message) => {
            onNewMessageHandler(message);
        };
    }

    attachConnectHandler(onConnectHandler) {
        this.client.onConnected = () => {
            log.info(`CmClient: connected`);
            onConnectHandler();
        };
    }

    attachCloseHandler(onCloseHandler) {
        this.client.onConnectionLost = (message) => {
            onCloseHandler(message);
        };
    }
}
