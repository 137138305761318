import React from 'react';

import Button from '../../../../components/Button/Button';
import ButtonDark from '../../../../components/Button/ButtonDark/ButtonDark';
import PopupInfo from '../../../../components/Popup/PopupInfo';
import * as localizationKeys from '../../../../consts/app/localizationKeys';
import {getLocalizedStrings} from '../../../../services/localization/localizationService';
import elementsTesIds from '../../../../test/consts/elementsTesIds';
import styles from './DeviceLockPopups.module.scss';

const DeviceUnlockedPopup = ({onClose, onLockDevice}) => {
    const localizedStrings = getLocalizedStrings();

    return (
        <PopupInfo onClose={onClose}>
            <h2 className={styles.PopupHeaderTitle}>{localizedStrings[localizationKeys.LOCK_POPUP_TITLE]}</h2>
            <>
                <div className={`${styles.PopupDescription} ica--txt-regular`}>
                    {localizedStrings[localizationKeys.LOCK_POPUP_TITLE_DESCRIPTION]}
                </div>
                <ButtonDark
                    className={styles.Button}
                    onClick={onLockDevice}
                    showArrow
                    testId={elementsTesIds.LOCK_POPUP_BTN_LOCK}
                >
                    {localizedStrings[localizationKeys.LOCK_POPUP_LOCK_BUTTON_TEXT]}
                </ButtonDark>
                <Button showUnderline onClick={onClose} testId={elementsTesIds.LOCK_POPUP_BTN_CANCEL}>
                    {localizedStrings[localizationKeys.LOCK_POPUP_CANCEL_BUTTON_TEXT]}
                </Button>
            </>
        </PopupInfo>
    );
};

export default DeviceUnlockedPopup;
