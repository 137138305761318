import appConfig from '../config/appConfig';
import dceMarketTypes from '../consts/market/dceMarketTypes';

const isDCE1 = () => appConfig.getIsDCE1();
const isDCE1Black = () => appConfig.getDCE1Type() === dceMarketTypes.BLACK;
const isDCE1White = () => appConfig.getDCE1Type() === dceMarketTypes.WHITE;
const isDCS = () => appConfig.getIsDCS();

export default {
    isDCE1,
    isDCE1Black,
    isDCE1White,
    isDCS,
};
