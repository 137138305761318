import appConfig from '../../config/appConfig';
import {makeSelectConsumerData} from '../../state/selectors/consumer';
import {makeMwDeviceInternalId} from '../../state/selectors/mwDevice';
import {getState} from '../../state/store';
import dateTimeUtils from '../../utils/dateTimeUtils';
import appInstanceService from '../appInstanceService';
import cloudWatchProvider from '../aws/cloudWatchProvider';

const logLevels = {
    DEBUG: {
        id: 1,
        name: 'DEBUG',
    },
    INFO: {
        id: 2,
        name: 'INFO',
    },
    ERROR: {
        id: 3,
        name: 'ERROR',
    },
    SILENT: {
        id: 4,
        name: 'SILENT',
    },
};

let instance = null;

const LOG_COUNT_TO_PUSH = 10;
const TIME_TO_PUSH_MS = 5 * 1000;

class SmartLogger {
    constructor() {
        if (instance) {
            return instance;
        }

        instance = this;
    }

    logsExposeTimeout = null;
    queue = [];
    isPushInProgress = false;

    level = logLevels.SILENT.id;

    setLevel = (level) => (this.level = level);
    getLevel = () => logLevels[this.level]?.id;

    debug = (msg) => this.log(logLevels.DEBUG, msg);
    error = (msg) => this.log(logLevels.ERROR, msg);
    info = (msg) => this.log(logLevels.INFO, msg);

    log = (level, msg) => {
        if (appConfig.getIsAwsLoggingEnabled() && level.id >= this.getLevel()) {
            const logMessage = this.getFullLogMessage(level.name, msg);

            if (this.queue.length >= LOG_COUNT_TO_PUSH - 1 || level === logLevels.ERROR) {
                this.addToQueue(logMessage);
                this.exposeEvents();
            } else {
                this.addToQueue(logMessage);
                this.setTimeOutIfNeeded();
            }
        }
    };

    addToQueue = (message) =>
        this.queue.push({
            message,
            timestamp: dateTimeUtils.getTimeNowInMilliseconds(),
        });

    exposeEvents = async () => {
        console.log(`smartLogger: exposeEvents this.isPushInProgress=${this.isPushInProgress}`);
        if (!this.isPushInProgress) {
            if (this.queue.length) {
                this.isPushInProgress = true;
                const logsToPush = this.queue.splice(0, this.queue.length);

                console.log(`smartLogger: putEvents this.isPushInProgress=${this.isPushInProgress}`);
                await cloudWatchProvider.putEvents({
                    eventMessages: logsToPush,
                    onError: () => {
                        this.queue.unshift(...logsToPush); //return logs to queue
                        this.isPushInProgress = false;
                        console.log(`smartLogger: onError this.isPushInProgress=${this.isPushInProgress}`);
                        this.setTimeOutIfNeeded();
                    },
                    onComplete: () => {
                        this.isPushInProgress = false;
                        console.log(`smartLogger: onComplete this.isPushInProgress=${this.isPushInProgress}`);
                    },
                });
            }
        } else {
            this.setTimeOutIfNeeded();
        }
    };

    collectData = () => {
        const consumerData = makeSelectConsumerData()(getState());
        const deviceSerialNumber = makeMwDeviceInternalId()(getState());
        const consumerId = consumerData?.consumerId;
        const appInstanceId = appInstanceService.getAppInstanceId();

        return {
            appInstanceId,
            consumerId: this.getLogValue(consumerId),
            dusn: this.getLogValue(deviceSerialNumber),
            version: appConfig.getAppVersion(),
            env: appConfig.getEnv(),
        };
    };

    getLogValue = (prop) => (prop !== undefined ? prop : 'n/a');

    getFullLogMessage = (level, msg) => {
        const {env, version, appInstanceId, consumerId, dusn} = this.collectData();
        const date = new Date().toISOString();

        return `[${level}] [${date}] [${env}] [${version}] [${appInstanceId}] [${consumerId}] [${dusn}] [${msg}]`;
    };

    setTimeOutIfNeeded = () => {
        if (this.queue.length && !this.logsExposeTimeout) {
            this.logsExposeTimeout = setTimeout(() => {
                this.logsExposeTimeout = null;
                this.exposeEvents();
            }, TIME_TO_PUSH_MS);
        }
    };
}

export default SmartLogger;
