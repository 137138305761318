import converter from '../../utils/converter';
import helpers from '../../utils/helpers';
import log from '../logger/log';
import ConnectedExponentialBackOff from './connectedExponentialBackOff';
import IqosBleClient from './iqosBleClient';

let instance = null;

const RESPONSE_TIMEOUT_MS = 10 * 1000;
const WRITE_FRAME_RETRY_COUNT = 1;

export default class ScpCharacteristicClient {
    constructor(createNew = false) {
        if (createNew && instance) {
            instance = null;
        }

        if (instance) {
            return instance;
        }

        instance = this;
        this.isActive = true;

        this.iqosBleClient = new IqosBleClient();
        this.resetState();
        this.addCharacteristicListener();
    }

    disable = () => {
        this.isActive = false;
        this.clearResponseTimeout();
        this.clearQueue();
    };

    responseTimeout;
    framesQueue = [];
    state = {};

    resetState = () => {
        this.state = {
            currentFrame: undefined,
            framesToResponse: [],
            onSuccess: undefined,
            onError: undefined,
            responseFrameHex: '',
            responses: [],
            retryCount: WRITE_FRAME_RETRY_COUNT,
        };
    };

    resetResponseState = () => {
        this.state.responseFrameHex = '';
    };

    addCharacteristicListener = async () => {
        await this.iqosBleClient.addScpCharacteristicListener(this.onScpCharacteristicResponse);
    };

    onScpCharacteristicResponse = (value) => {
        this.clearResponseTimeout();
        this.state.responseFrameHex += converter.buffer2hex(value);

        const {responseFrameHex, currentFrame} = this.state;
        const restFrameCount = value.getUint8(0);

        log.debug(
            `ScpCharacteristicClient: frame: ${currentFrame}, received from SCP frame: ${responseFrameHex}, restFrameHexCount=${restFrameCount}`
        );

        if (restFrameCount === 0) {
            this.state.responses.push(responseFrameHex);
            this.writeFrames();
        }
    };

    addFramesToQueue = ({frames, onSuccess, onError, processImmediately = true}) => {
        log.debug(`ScpCharacteristicClient: add frames to queue, frames: ${JSON.stringify(frames)}`);

        this.framesQueue.push({frames, onSuccess, onError});

        if (processImmediately) {
            this.processQueue();
        }
    };

    processQueue = () => {
        if (!this.state.framesToResponse.length) {
            this.processFrames();
        }
    };

    processFrames = () => {
        this.resetState();

        if (this.framesQueue.length) {
            const {frames, onSuccess, onError} = this.framesQueue.shift();

            log.debug(`ScpCharacteristicClient: request SCP with frames: ${JSON.stringify(frames)}`);

            this.state.framesToResponse = frames;
            this.state.onSuccess = onSuccess;
            this.state.onError = onError;
            this.writeFrames();
        }
    };

    writeFrames = () => {
        const {framesToResponse, responses} = this.state;
        const responseNumber = responses.length;
        const itWasLastFrame = !framesToResponse || responseNumber >= framesToResponse.length;

        if (itWasLastFrame) {
            this.onFramesResponse();
        } else {
            const frame = framesToResponse[responseNumber];
            this.resetResponseState();
            this.writeFrame(frame);
        }
    };

    writeFrame = (frame) => {
        const exponentialBackOff = new ConnectedExponentialBackOff();

        this.state.currentFrame = frame;
        const success = () => {
            if (this.isActive && this.iqosBleClient.isDeviceConnected()) {
                log.debug(`ScpCharacteristicClient: write frame: ${frame}, success`);
            }
        };
        const fail = (e) => {
            this.clearResponseTimeout();
            if (this.isActive) {
                log.debug(`ScpCharacteristicClient: write frame - ${frame}: error: ${e}`);

                // if (this.iqosBleClient.isDevicePaired()) { //IA: removed after some issue with turning-off Bluetooth during pairing
                this.onFrameResponseError();
                // }
            } else {
                helpers.runFunction(this.state.onError, true);
            }
        };
        const toTry = async () => {
            if (this.isActive) {
                if (this.iqosBleClient.isDeviceConnected()) {
                    log.debug(`ScpCharacteristicClient: write frame - ${frame}`);
                    this.initResponseTimeout(frame, exponentialBackOff);
                    await this.iqosBleClient.writeValueToScpCharacteristic(frame, true);
                } else {
                    log.debug(
                        `ScpCharacteristicClient: writeFrame - writing frame: ${frame} is stopped, device is disconnected`
                    );
                }
            } else {
                helpers.runFunction(this.state.onError, true);
            }
        };

        exponentialBackOff.run(3, 200, toTry, success, fail);
    };

    initResponseTimeout = (frame, exponentialBackOff) => {
        this.clearResponseTimeout();
        this.responseTimeout = setTimeout(() => {
            this.onResponseTimeout(frame, exponentialBackOff);
        }, RESPONSE_TIMEOUT_MS);
    };

    onResponseTimeout = (frame, exponentialBackOff) => {
        exponentialBackOff.unsubscribe();

        if (this.isActive) {
            if (this.state.retryCount > 0) {
                this.state.retryCount = this.state.retryCount - 1;

                log.debug(
                    `ScpCharacteristicClient: onResponseTimeout, there is no response from device for ${frame} frame. Try to write frame again, retry #${
                        WRITE_FRAME_RETRY_COUNT - this.state.retryCount
                    }`
                );

                this.writeFrames();
            } else {
                this.onFrameResponseError();
            }
        } else {
            helpers.runFunction(this.state.onError, true);
        }
    };

    onFrameResponseError = () => {
        const {onError} = this.state;

        this.clearResponseTimeout();
        helpers.runFunction(onError);
    };

    clearResponseTimeout = () => clearTimeout(this.responseTimeout);

    clearQueue = () => {
        helpers.runFunction(this.state.onError, true);
        this.resetState();

        this.framesQueue.forEach((frameQueue) => {
            const {onError} = frameQueue;
            helpers.runFunction(onError, true);
        });
        this.framesQueue = [];
    };

    onFramesResponse = () => {
        const {responses, onSuccess} = this.state;

        if (onSuccess) {
            onSuccess(responses);
        }

        this.processFrames();
    };
}
