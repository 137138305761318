import React from 'react';
import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';

import imgBattery from '../../../assets/images/welcome/battery-img.png';
import imgBattery2 from '../../../assets/images/welcome/battery-img@2x.png';
import imgBattery3 from '../../../assets/images/welcome/battery-img@3x.png';
import imgDevice from '../../../assets/images/welcome/device-img.png';
import imgDevice2 from '../../../assets/images/welcome/device-img@2x.png';
import imgDevice3 from '../../../assets/images/welcome/device-img@3x.png';
import imgHero from '../../../assets/images/welcome/hero-img.png';
import imgHero2 from '../../../assets/images/welcome/hero-img@2x.png';
import imgHero3 from '../../../assets/images/welcome/hero-img@3x.png';
import imgLeds from '../../../assets/images/welcome/leds-img.png';
import imgLeds2 from '../../../assets/images/welcome/leds-img@2x.png';
import imgLeds3 from '../../../assets/images/welcome/leds-img@3x.png';
import ButtonWhite from '../../components/Button/ButtonWhite/ButtonWhite';
import Header from '../../components/Header/Header';
import Image from '../../components/Image/Image';
import SkipButton from '../../components/SkipButton/SkipButton';
import TableCell from '../../components/TableCell/TableCell';
import * as localizationKeys from '../../consts/app/localizationKeys';
import * as routePaths from '../../consts/route/routePaths';
import {getLocalizedStrings} from '../../services/localization/localizationService';
import {makeSelectIotDeviceData} from '../../state/selectors/iotDevice';
import elementsTesIds from '../../test/consts/elementsTesIds';
import styles from './WelcomePage.module.css';

const mapStateToProps = createStructuredSelector({
    iotDevice: makeSelectIotDeviceData(),
});

const WelcomePage = (props) => {
    const {iotDevice} = props;
    const {device} = iotDevice;
    const localizedStrings = getLocalizedStrings();

    return (
        <div>
            <Header>
                <SkipButton to={routePaths.MY_DEVICES} />
            </Header>
            <h2 className={styles.Header}>
                {localizedStrings.formatString(localizedStrings[localizationKeys.WELCOME_PAGE_HEADER], <br />)}
            </h2>
            <div
                data-testid={elementsTesIds.WELCOME_LABEL_WELCOME}
                className={`ica--txt-regular ${styles.WelcomeText}`}
            >
                {localizedStrings.formatString(
                    localizedStrings[localizationKeys.WELCOME_PAGE_WELCOME_TEXT],
                    <br />,
                    device?.name
                )}
            </div>
            <Image src={[imgHero, imgHero2, imgHero3]} className={styles.HeaderImg} />

            <TableCell
                imgUrls={[imgDevice, imgDevice2, imgDevice3]}
                name={localizedStrings[localizationKeys.WELCOME_PAGE_DEVICE_TOUR]}
            />
            <TableCell
                imgUrls={[imgBattery, imgBattery2, imgBattery3]}
                name={localizedStrings[localizationKeys.WELCOME_PAGE_CHECKING_BATTERY]}
            />
            <TableCell
                imgUrls={[imgLeds, imgLeds2, imgLeds3]}
                name={localizedStrings[localizationKeys.WELCOME_PAGE_ABOUT_LEDS]}
            />

            <div className={styles.ButtonContainer}>
                <ButtonWhite showArrow to={routePaths.MY_DEVICES}>
                    {localizedStrings[localizationKeys.WELCOME_PAGE_START_BUTTON_TEXT]}
                </ButtonWhite>
            </div>
        </div>
    );
};

export default connect(mapStateToProps)(WelcomePage);
