import * as iotMessageErrorCodes from '../../consts/iot/iotMessageErrorCodes';
import * as iotMessageStatusTypes from '../../consts/iot/iotMessageStatusTypes';
import * as iotMessageTypes from '../../consts/iot/iotMessageTypes';
import {
    clearDeviceConnectionCareState,
    clearIotDevice,
    clearIotDeviceData,
    setFWUFinished,
    setFWUStarted,
    setIsConnected,
    setIsDeviceReady,
    setIsDisconnected,
    setMessage,
    setShouldShowSBLModePopup,
    updateIotDeviceData,
} from '../../state/ducks/iotDevice/actions';
import {clearMwDeviceInternalId} from '../../state/ducks/mwDevice';
import {
    makeSelectIotDeviceData,
    makeSelectIsFirmwareFinished,
    makeSelectIsReset,
} from '../../state/selectors/iotDevice';
import {dispatch, getState} from '../../state/store';
import appRouterService from '../appRouterService';
import deviceAssetService from '../device/deviceAssetService';
import getDeviceMessageRequestService from '../deviceMessageRequestService';
import ErrorHandlerService from '../errorHandlerService';
import iccConsumerProducts from '../icc/iccConsumerProducts';
import deviceConfigService from '../iotDevice/iotDeviceConfigService';
import iotDeviceResetService from '../iotDevice/iotDeviceResetService';
import log from '../logger/log';
import {deviceInfoMapping, deviceParentModeMapping, uiResponseBaseMapping} from '../mapping/iotMappings';
import productService from '../productService';
import iotService from './iotService';
import uiIotMessageResponseChecker from './uiIotMessageResponseChecker';

const DEVICE_RESET_TIMEOUT = 60 * 1000;
let deviceResetTimeout;

const onMessage = (topic, message) => {
    const {request_id, type, status, body} = message;
    const data = uiResponseBaseMapping(body?.data);
    const error_code = body?.error?.code;

    if (request_id !== undefined) {
        uiIotMessageResponseChecker.clearResponseTimeout(request_id);
    }

    switch (type) {
        case iotMessageTypes.CONNECTION:
            onConnectionMessage(topic, status, data);
            break;
        case iotMessageTypes.DEVICE_INFO:
            onDeviceInfoMessage(topic, status, data);
            break;
        case iotMessageTypes.DEVICE_STATUS_CHARACTERISTIC:
            onDeviceStatusCharacteristic(data);
            break;
        case iotMessageTypes.CONNECTION_CARE:
            onConnectionCareMessage(status, data);
            break;
        case iotMessageTypes.LED:
            if (data) {
                const ledData = {ledData: data};

                dispatch(updateIotDeviceData(ledData));
            }
            break;
        case iotMessageTypes.VIBRATION:
            if (data) {
                const vibrationData = {vibrationData: data};

                dispatch(updateIotDeviceData(vibrationData));
            }
            break;
        case iotMessageTypes.FIRMWARE_UPDATE:
            onFWUMessage(status, data, error_code);
            break;
        case iotMessageTypes.PARENT_PROTECTION:
            const mappedData = deviceParentModeMapping(data);

            dispatch(updateIotDeviceData(mappedData));
            break;
        case iotMessageTypes.HOLDER_STATUS:
        case iotMessageTypes.CLEANING:
            dispatch(updateIotDeviceData(data));
            break;
        case iotMessageTypes.RESPONSIVE_DRAW_PROFILES:
            if (data) {
                const responsiveDrawData = {responsiveDrawData: data};

                dispatch(updateIotDeviceData(responsiveDrawData));
            }
            break;
        case iotMessageTypes.VAPE_CLOUD_SIZE:
            if (data) {
                const vapeCloudSizeData = {vapeCloudSizeData: data};

                dispatch(updateIotDeviceData(vapeCloudSizeData));
            }
            break;

        case iotMessageTypes.BATTERY_INFORMATION_CHARACTERISTIC:
            if (data) {
                dispatch(updateIotDeviceData(data));
            }
            break;

        default:
            break;
    }

    if (status === iotMessageStatusTypes.ERROR) {
        new ErrorHandlerService().handleError(message);
    }

    dispatch(setMessage(message));
};

const onConnectionMessage = (topic, status) => {
    switch (status) {
        case iotMessageStatusTypes.CONNECTED:
            clearDeviceResetTimeout();
            break;
        case iotMessageStatusTypes.DISCONNECTED:
            const state = getState();
            const isReset = makeSelectIsReset()(state);
            const isFirmwareFinished = makeSelectIsFirmwareFinished()(state);

            dispatch(setIsDisconnected());
            dispatch(clearMwDeviceInternalId());
            iotService.disconnectIoT();

            if (isReset || isFirmwareFinished) {
                initDeviceResetTimeout();
            }
            break;
        default:
            break;
    }
};

const onDeviceInfoMessage = (topic, status, data) => {
    const dataInternalId = data?.deviceSerialNumber;
    const isIdentificationFlow = !!dataInternalId;

    if (isIdentificationFlow) {
        onIdentifyDevice(topic, dataInternalId);
    }

    if (data) {
        const mappedData = deviceInfoMapping(data);

        dispatch(updateIotDeviceData(mappedData));
        loadIotDeviceAssets(data);
    }

    switch (status) {
        case iotMessageStatusTypes.FINISHED:
            const isReset = makeSelectIsReset()(getState());

            if (isReset) {
                iotDeviceResetService.onResetDevice();
            }

            productService.setProductRegistrationAttempts(0);
            dispatch(setIsDeviceReady());

            const isParentProtectionMode = deviceConfigService.isParentProtectionMode(data);

            if (isParentProtectionMode) {
                getDeviceMessageRequestService().publishParentProtectionStatusMessage();
            }

            break;
        default:
            break;
    }
};

const onDeviceStatusCharacteristic = (data) => {
    if (data) {
        const mappedData = deviceInfoMapping(data);

        if (mappedData.holder?.mediaId) {
            loadIotDeviceAssets(mappedData);
        }

        dispatch(updateIotDeviceData(mappedData));
    }
};

const loadIotDeviceAssets = (data) => {
    const {device, holder} = data || {};
    const mediaNamesList = deviceAssetService.getDeviceAssetsList([device?.mediaId, holder?.mediaId]);

    if (mediaNamesList.length) {
        iccConsumerProducts.getProductsAssets(mediaNamesList);
    }
};

const onConnectionCareMessage = (status, data) => {
    switch (status) {
        case iotMessageStatusTypes.STARTED:
            dispatch(clearDeviceConnectionCareState());
            break;
        case iotMessageStatusTypes.IN_PROGRESS:
        case iotMessageStatusTypes.FINISHED:
            if (data) {
                dispatch(updateIotDeviceData(data));
            }
            break;
        default:
            break;
    }
};

const onFWUMessage = (status, data, error_code) => {
    switch (status) {
        case iotMessageStatusTypes.PACKAGE_LOADED:
            updatePackageLoadedData(data, true);
            break;
        case iotMessageStatusTypes.STARTED:
            dispatch(setFWUStarted());
            dispatch(updateIotDeviceData(data));

            break;
        case iotMessageStatusTypes.FINISHED:
            dispatch(setFWUFinished());
            dispatch(updateIotDeviceData(data));

            break;
        case iotMessageStatusTypes.ERROR:
            onFWUErrorMessage(data, error_code);
            break;
        default:
            dispatch(updateIotDeviceData(data));
    }
};

const onFWUErrorMessage = (data, error_code) => {
    switch (error_code) {
        case iotMessageErrorCodes.FW_CHECK_ERROR_DOWNLOAD_OR_PARSE:
            updatePackageLoadedData(data, false);

            break;
        default:
            break;
    }
};

const onIdentifyDevice = async (topic, deviceSerialNumber) => {
    const deviceFromState = makeSelectIotDeviceData()(getState());
    const deviceFromStateInternalId = deviceFromState?.deviceSerialNumber;
    const isSameDeviceInState = deviceFromStateInternalId === deviceSerialNumber;

    if (deviceFromStateInternalId) {
        if (isSameDeviceInState) {
            dispatch(clearIotDeviceData());
            dispatch(setShouldShowSBLModePopup(true));
        } else {
            dispatch(clearIotDevice());
        }
    }

    // dispatch(setMwDeviceInternalId({deviceSerialNumber, isIdentified: true}));
    dispatch(setIsConnected());
    iotService.resubscribe(topic);
};

const onDisconnect = () => {
    appRouterService.forwardToMyDevicesPage();
};

const initDeviceResetTimeout = () => {
    deviceResetTimeout = setTimeout(onDeviceResetTimeout, DEVICE_RESET_TIMEOUT);
};

const onDeviceResetTimeout = () => {
    log.info(`uiIotMessageResponseService: onDeviceResetTimeout, device reset timeout error`);
    onDisconnect();
    iotDeviceResetService.onResetDevice();
};

const clearDeviceResetTimeout = () => {
    clearTimeout(deviceResetTimeout);
};

const updatePackageLoadedData = (data, package_loaded) => {
    const {targets_holder} = data;
    const firmwareData = {firmware: {package_loaded}};
    const updateData = targets_holder ? {holder: firmwareData} : {device: firmwareData};

    dispatch(updateIotDeviceData(updateData));
};

export default {
    onMessage,
};
