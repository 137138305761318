import UAMWebSocketClient from '../uam/uamWebSocketClient';

let gamReconnectTimeout;
const clearReconnectTimeout = () => clearTimeout(gamReconnectTimeout);

const initGamWebSocket = async (assetId) => {
    clearReconnectTimeout();

    if (isGamWebSocketConnected(assetId)) {
        return;
    }

    const onError = () => {
        const RECONNECT_TIMEOUT_MS = 5000;

        disconnectGamWebSocket(false);
        gamReconnectTimeout = setTimeout(() => initGamWebSocket(assetId), RECONNECT_TIMEOUT_MS);
    };

    const gamTopicSubscribeSettings = null; //await gamClientService.assetSubscribe(assetId);

    if (gamTopicSubscribeSettings) {
        const uamClient = new UAMWebSocketClient(assetId, true, gamTopicSubscribeSettings);

        uamClient.attachCloseHandler(async (message) => {
            //normal close from code
            if (message?.code !== 1000) {
                onError();
            }
        });

        uamClient.attachConnectHandler(() => {
            //subscribeOnMessage();
        });
    } else {
        onError();
    }
};

const disconnectGamWebSocket = () => {
    const uamWebSocketClient = new UAMWebSocketClient();

    clearReconnectTimeout();
    uamWebSocketClient.disconnect();
};

const isGamWebSocketConnected = (assetId) => new UAMWebSocketClient().isConnectedCheck(assetId);

export default {
    initGamWebSocket,
    disconnectGamWebSocket,
};
