import React from 'react';

import Button from '../../../../components/Button/Button';
import ButtonDark from '../../../../components/Button/ButtonDark/ButtonDark';
import PopupWarning from '../../../../components/Popup/PopupWarning';
import * as localizationKeys from '../../../../consts/app/localizationKeys';
import {getLocalizedStrings} from '../../../../services/localization/localizationService';
import styles from './DeviceDeletePopup.module.scss';

const DeviceDeletePopup = ({onClose, onDeviceDelete, isUamMode}) => {
    const localizedStrings = getLocalizedStrings();

    const titleText =
        localizedStrings[
            isUamMode
                ? localizationKeys.YAP_DEACTIVE_CONFIRM_POPUP_TITLE_TEXT
                : localizationKeys.REMOVE_DEVICE_CONFIRM_POPUP_TITLE_TEXT
        ];
    const descriptionText =
        localizedStrings[
            isUamMode
                ? localizationKeys.YAP_DEACTIVE_CONFIRM_POPUP_DESCRIPTION_TEXT
                : localizationKeys.REMOVE_DEVICE_CONFIRM_POPUP_MESSAGE_TEXT
        ];
    const confirmBtnText =
        localizedStrings[
            isUamMode
                ? localizationKeys.YAP_DEACTIVE_CONFIRM_POPUP_ACCEPT_BUTTON_TEXT
                : localizationKeys.REMOVE_DEVICE_CONFIRM_POPUP_CONFIRM_TEXT
        ];
    const cancelBtnText =
        localizedStrings[
            isUamMode
                ? localizationKeys.YAP_DEACTIVE_CONFIRM_POPUP_CANCEL_BUTTON_TEXT
                : localizationKeys.REMOVE_DEVICE_CONFIRM_POPUP_CANCEL_TEXT
        ];

    return (
        <PopupWarning onClose={onClose}>
            <div className={styles.PopupHeader}>
                <h2 className={styles.PopupHeaderTitle}>{titleText}</h2>
            </div>
            <p className={`${styles.PopupBody} ica--txt-regular`}>{descriptionText}</p>
            <ButtonDark showArrow className={styles.PopupButton} onClick={onDeviceDelete}>
                {confirmBtnText}
            </ButtonDark>
            <Button showUnderline onClick={onClose}>
                {cancelBtnText}
            </Button>
        </PopupWarning>
    );
};

export default DeviceDeletePopup;
