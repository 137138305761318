import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';

import CommonLoaderScreen from '../../../components/Loader/CommonLoaderScreen';
import * as localizationKeys from '../../../consts/app/localizationKeys';
import useDidUpdate from '../../../hooks/effects/useDidUpdate';
import appErrorService from '../../../services/app/appErrorService';
import backendService from '../../../services/app/backendService';
import appRouterService from '../../../services/appRouterService';
import cmClientService from '../../../services/communicationLayer/cmClientService';
import gamClientService from '../../../services/gam/gamClientService';
import {getLocalizedStrings} from '../../../services/localization/localizationService';
import log from '../../../services/logger/log';
import uamClientService from '../../../services/uam/uamClientService';
import uamWebSocketService from '../../../services/uam/uamWebSocketService';
import {makeSelectIotDeviceMergedWithIccProduct} from '../../../state/selectors/consumer';
import {makeSelectIsNetworkOnline} from '../../../state/selectors/global';
import {makeSelectDeviceActivationStatus} from '../../../state/selectors/iotDevice';
import {makeSelectIsYapActivationInProgress} from '../../../state/selectors/yapEncrypted';
import elementsTesIds from '../../../test/consts/elementsTesIds';
import pairingGuidePageService from '../../PairingGuidePage/pairingGuidePageService';
import styles from '../DeviceActivationPage.module.scss';
import ActivationFailedPopup from './ActivationFailedPopup';

const mapStateToProps = createStructuredSelector({
    iotProduct: makeSelectIotDeviceMergedWithIccProduct(),
    isDeviceActivated: makeSelectDeviceActivationStatus(),
    isYapActivationInProgress: makeSelectIsYapActivationInProgress(),
    isNetworkOnline: makeSelectIsNetworkOnline(),
});

const DeviceUAMActivationPage = (props) => {
    const {iotProduct, isDeviceActivated, isYapActivationInProgress, isNetworkOnline} = props;
    const {device, deviceSerialNumber} = iotProduct;
    const {name, type} = device;
    const localizedStrings = getLocalizedStrings();

    const [isVisibleActivationFailedPopup, setIsVisibleActivationFailedPopup] = useState(false);

    const activateDevice = async () => {
        try {
            if (backendService.isGamBackend()) {
                await gamClientService.activateDevice();
            } else {
                await uamClientService.activateDevice(deviceSerialNumber);
            }
        } catch (e) {
            appErrorService.showGlobalErrorWithAppReset();
        }
    };

    useDidUpdate(() => {
        if (isNetworkOnline) {
            if (backendService.isGamBackend()) {
                return gamClientService.checkRequestStatus();
            } else {
                return uamClientService.checkRequestStatusBySN(deviceSerialNumber);
            }
        }
    }, [isNetworkOnline]);

    useDidUpdate(() => {
        if (!isYapActivationInProgress) {
            if (isDeviceActivated) {
                log.info('End activation process. Device is successfully activated.');
                uamWebSocketService.disconnectUamWebSocket();
                cmClientService.disconnectCmClient();

                pairingGuidePageService.makeFinishRedirect(type, appRouterService.forwardToActivationSuccessPage);
            } else {
                log.info('End activation process. Device activation is failed.');
                setIsVisibleActivationFailedPopup(true);
            }
        }
    }, [isYapActivationInProgress]);

    useEffect(() => {
        activateDevice();
    }, []);

    const onActivationTryAgainBtnClick = async () => {
        setIsVisibleActivationFailedPopup(false);
        await activateDevice();
    };

    const onActivationFailedPopupCancelBtnClick = () => {
        appRouterService.forwardToMyDevicesPage();
    };

    const header = localizedStrings.formatString(localizedStrings[localizationKeys.YAP_ACTIVATING_DEVICE], name);
    const description =
        localizedStrings[localizationKeys.YAP_ACTIVATION_REGISTRATION_STATE_ACTIVATION_FLOW_PROGRESS_TEXT];

    return (
        <div className={styles.CommonLoaderContainer}>
            <CommonLoaderScreen
                isBig
                header={header}
                description={description}
                iotDevice={iotProduct}
                headerTestId={elementsTesIds.ACTIVATION_FLOW_ACTIVATING_TITLE}
            />
            {isVisibleActivationFailedPopup && (
                <ActivationFailedPopup
                    onTryAgain={onActivationTryAgainBtnClick}
                    onClose={onActivationFailedPopupCancelBtnClick}
                />
            )}
        </div>
    );
};

export default connect(mapStateToProps)(DeviceUAMActivationPage);
