import React from 'react';
import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';

import illustrationDataUseIcon1 from '../../../../assets/images/icons/illustration-data-use.png';
import illustrationDataUseIcon2 from '../../../../assets/images/icons/illustration-data-use@2x.png';
import illustrationDataUseIcon3 from '../../../../assets/images/icons/illustration-data-use@3x.png';
import Button from '../../../components/Button/Button';
import ButtonBorder from '../../../components/Button/ButtonBorder/ButtonBorder';
import Image from '../../../components/Image/Image';
import Container from '../../../components/Layout/Container/Container';
import * as localizationKeys from '../../../consts/app/localizationKeys';
import * as tncTypes from '../../../consts/tnc/tncTypes';
import analyticsService from '../../../services/analyticsService';
import appRouterService from '../../../services/appRouterService';
import {getLocalizedStrings} from '../../../services/localization/localizationService';
import tncService from '../../../services/tncService';
import {hideLoader, showLoader} from '../../../state/ducks/global';
import {makeSelectAcceptedTncByType, makeSelectTncByType} from '../../../state/selectors/consumer';
import {dispatch} from '../../../state/store';
import TermsOfUseDescription from '../TermsOfUseDescription/TermsOfUseDescription';
import styles from './DataCollectionPage.module.scss';

const mapStateToProps = createStructuredSelector({
    dataCollectionTnc: makeSelectTncByType(tncTypes.WEB_PREFERENCE_CENTER),
    acceptedTermsOfUse: makeSelectAcceptedTncByType(tncTypes.WEB_PREFERENCE_CENTER),
});

const DataCollectionPage = (props) => {
    const {dataCollectionTnc, acceptedTermsOfUse} = props;

    if (!dataCollectionTnc) return null;

    const {description, legals, updateDescription} = dataCollectionTnc;
    if (!legals) return null;

    const setTnc = async (isAccepted) => {
        dispatch(showLoader());

        analyticsService.pushTNCDataCollectionInteractionEvent(isAccepted);

        await tncService.setDataCollectionTnc(legals, isAccepted);

        dispatch(hideLoader());
    };

    const onAcceptButtonClick = () => setTnc(true);
    const onDeclineButtonClick = () => setTnc(false);

    const isUpdatedTnc = acceptedTermsOfUse != null;
    const summaryTitleText = isUpdatedTnc
        ? localizationKeys.DATA_COLLECTION_UPDATE_TITLE_TEXT
        : localizationKeys.DATA_COLLECTION_TITLE_TEXT;
    const descriptionText = isUpdatedTnc ? updateDescription : description;
    const localizedStrings = getLocalizedStrings();

    return (
        <div className={styles.Page}>
            <Container className={styles.Container}>
                <section className={styles.Header}>
                    <Image
                        className={styles.Image}
                        src={[illustrationDataUseIcon1, illustrationDataUseIcon2, illustrationDataUseIcon3]}
                    />
                </section>
                <section>
                    <h2 className={styles.Title}>{localizedStrings[summaryTitleText]}</h2>
                    <TermsOfUseDescription innerHtml={descriptionText} className={styles.Description} />
                </section>
                <div className={styles.Footer}>
                    <div className={styles.ButtonBlock}>
                        <ButtonBorder className={styles.ButtonAgree} onClick={onDeclineButtonClick}>
                            {localizedStrings[localizationKeys.DATA_COLLECTION_DECLINE_BUTTON_TEXT]}
                        </ButtonBorder>
                        <ButtonBorder className={styles.ButtonAgree} onClick={onAcceptButtonClick}>
                            {localizedStrings[localizationKeys.DATA_COLLECTION_CONFIRM_BUTTON_TEXT]}
                        </ButtonBorder>
                    </div>
                    <Button
                        onClick={appRouterService.forwardToTNCPreferenceCenterPage}
                        className={styles.PreferenceCenter}
                        showUnderline
                    >
                        {localizedStrings[localizationKeys.DATA_COLLECTION_PREFERENCE_CENTER_BUTTON_TEXT]}
                    </Button>
                </div>
            </Container>
        </div>
    );
};

export default connect(mapStateToProps)(DataCollectionPage);
