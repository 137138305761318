import React, {useState} from 'react';

import PopupRounded from '../../../components/Popup/PopupRounded';
import appConfig from '../../../config/appConfig';
import logState from '../../../services/logger/logState';
import styles from './Logs.module.css';

const Logs = () => {
    const [isVisibleLogs, setIsVisibleLogs] = useState(false);
    const [logs, setLogs] = useState(null);

    const onLogsOpen = () => {
        if (appConfig.getIsLogsVisible()) {
            setLogs(logState.getLogState());
            setIsVisibleLogs(true);
        }
    };
    const onLogsClose = () => setIsVisibleLogs(false);

    const clearLogs = () => {
        setLogs(null);
        logState.clearLogState();
        onLogsClose();
    };

    const title = appConfig.getIsAppVersionVisible() ? appConfig.getAppVersion() : 'Logs';

    return (
        <>
            <div className={styles.LogsIcon} onClick={onLogsOpen}>
                {title}
            </div>
            {isVisibleLogs && (
                <PopupRounded onClose={onLogsClose} className={styles.LogsPopup}>
                    {isVisibleLogs && (
                        <>
                            <div onClick={clearLogs} className={styles.ClearLogsBtn}>
                                Clear logs
                            </div>
                            {logs?.map(({log}, i) => {
                                return (
                                    <div className={styles.LogContainer} key={i}>
                                        <div className={styles.Log}>{log}</div>
                                    </div>
                                );
                            })}
                        </>
                    )}
                </PopupRounded>
            )}
        </>
    );
};

export default Logs;
