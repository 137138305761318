import configAppGetters from './configGetters/configAppGetters';
import configAppLinksGetters from './configGetters/configAppLinksGetters';
import configAwsGetters from './configGetters/configAwsGetters';
import configCmGetters from './configGetters/configCmGetters';
import configExternalLinksGetters from './configGetters/configExternalLinksGetters';
import configGamGetters from './configGetters/configGamGetters';
import configIccGetters from './configGetters/configIccGetters';
import configLocalizationGetters from './configGetters/configLocalizationGetters';
import configLoginGetters from './configGetters/configLoginGetters';
import configLogsGetters from './configGetters/configLogsGetters';
import configMarketGetters from './configGetters/configMarketGetters';
import configScpGetters from './configGetters/configScpGetters';
import configUamGetters from './configGetters/configUamGetters';
import configUnmarkedGetters from './configGetters/configUnmarkedGetters';

export default {
    ...configAppGetters,
    ...configAppLinksGetters,
    ...configAwsGetters,
    ...configCmGetters,
    ...configExternalLinksGetters,
    ...configGamGetters,
    ...configIccGetters,
    ...configLocalizationGetters,
    ...configLoginGetters,
    ...configLogsGetters,
    ...configMarketGetters,
    ...configScpGetters,
    ...configUamGetters,
    ...configUnmarkedGetters,
};
